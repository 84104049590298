import "./planconditions.css";
import "../conditions/conditions.css";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";

function PlanConditions() {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    magicFunction2(
      panel,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    setExpanded(isExpanded ? panel : false);
  };

  const { menuStore, treatmentStore } = useStore();
  const { ActiveTreatmentMenu } = menuStore;
  const {
    resetForm2,
    magicFunction2,
    selectPreventionAndHygiene,
    selectProfessionalToothCleaning,
    selectTopicalFluoride,
    selectProfessionalToothCleaning2,
    selectScalingOrRootPlanning,
    selectPokcetReduction,
    selectBoneRegeneration,
    selectGingivalGraft,
    selectPlasticPerioSurgery,
    selectCrownLengthening,
    selectGingivectomy,
    selectLaserGingivectomy,
    selectRootCanalTreatment,
    selectRootCanalReTreatment,
    selectApicectomy,
    selectImplant,
    selectExtraction,
    selectWisdomToothExtraction,
    selectSinusLift,
    selectBoneRegeneration2,
    selectFullArchImplantsOrDenture,
    selectRemoveImplant,
    selectSurgicalGuide,
    selectRestoration,
    selectPost,
    selectSplint,
    selectExternalBleach,
    selectInternalBleach,
    selectCoreBuldUp,
    selectProvisionalRestoration,
    selectVeneer,
    selectOnlay,
    selectCrown,
    selectBridge,
    selectAdhesiveBridge,
    selectDenture,
    selectRemoveBridgeOrDenture,
    selectArticulatedStudyModels,
    selectProvisionalBridge,
    selectProvisionalCrown,
    selectFixedBraces,
    selectLingualBraces,
    selectClearAligners,
    selectRemovableAppliance,
    selectFunctionalAppliance,
    selectCosmeticOrthodontics,
    selectRetainer,
    selectMiniImplantsForAnchorage,
    selectTractionOfRetainedTeeth,
    selectArticulatedStudyModels2,
    selectBehaviouralAdviceAndStressControl,
    selectOcclusalSplint,
    selectOcclusalEquilibration,
    selectArticulatedStudyModels3,
  } = treatmentStore;
  const { checked, setChecked } = useState;

  useEffect(() => {
    setExpanded();
  }, [resetForm2]);

  function handleRestorationCheckbox(event) {
    const { name, value, checked } = event.target;
    magicFunction2(
      undefined,
      undefined,
      value,
      checked,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }
  function handleProvisionalRestoration(event) {
    const { name, value, checked } = event.target;
    magicFunction2(
      undefined,
      undefined,
      value,
      checked,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }
  function handlecoreBuldUp(event) {
    const { name, value, checked } = event.target;
    magicFunction2(
      undefined,
      undefined,
      value,
      checked,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }
  function handleRootCanalTreatment(event) {
    const { name, value, checked } = event.target;
    magicFunction2(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      checked,
      undefined
    );
  }

  return (
    <div className="planConditionsContainer">
    {/* Prevention Hygiene Başlangıç */}
    {ActiveTreatmentMenu.preventionHygieneMenu && (
      <div className="planPreventionHygine">
        <Button
          onClick={() =>
            magicFunction2(
              "1",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Önleme ve Hijyen
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "2",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Profesyonel Diş Temizleme
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "3",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Topikal Florür
        </Button>
      </div>
    )}
    {/* Prevention Hygiene Başlangıç */}
    {/* Perio Başlangıç */}
    {ActiveTreatmentMenu.perioMenu && (
      <div className="planPerio">
        <Button
          onClick={() =>
            magicFunction2(
              "4",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Profesyonel Diş Temizleme
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "5",
              undefined,
              undefined,
              undefined, //Pocket Reduction
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Ölçeklendirme/Kök Planlama
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "6",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Cep Azaltma
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "7",
              undefined, //Prevention & Hygiene
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Kemik Rejenerasyonu
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "8",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Dişeti Grefti
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              //Crown Lengthening
              "9",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Plastik Perio Cerrahisi
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              //Gingivectomy
              "10",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Kron Uzatma
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              //Laser Gingivectomy
              "11",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Gingivektomi
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "12",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Lazer Gingivektomi
        </Button>
      </div>
    )}
    {/* Perio Bitiş */}
    {/* Endodontics Başlangıç */}
    {ActiveTreatmentMenu.endodonticsMenu && (
      <div className="planEndodontics">
        <Accordion
          className="accordion"
          expanded={expanded === "13"}
          onChange={handleChange("13")}
        >
          <AccordionSummary
            className="accordionSummary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className="typography">Kök Kanal Tedavisi</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleRootCanalTreatment}
                      value={1}
                      checked={checked}
                    />
                  }
                  label="1 Kanal"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleRootCanalTreatment}
                      value={2}
                      checked={checked}
                    />
                  }
                  label="2 Kanal"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleRootCanalTreatment}
                      value={3}
                      checked={checked}
                    />
                  }
                  label="3 Kanal"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleRootCanalTreatment}
                      value={4}
                      checked={checked}
                    />
                  }
                  label="3 Veya Daha Fazla Kanal"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "14"}
          onChange={handleChange("14")}
        >
          <AccordionSummary
            className="accordionSummary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className="typography">Kanal Tedavisi</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="1canal"
                control={<Radio />}
                label="1 Kanal"
              />
              <FormControlLabel
                value="2canal"
                control={<Radio />}
                label="2 Kanal"
              />
              <FormControlLabel
                value="3canal"
                control={<Radio />}
                label="3 Kanal"
              />
              <FormControlLabel
                value="3morecanal"
                control={<Radio />}
                label="3 Veya Daha Fazla Kanal"
              />
            </RadioGroup>
          </AccordionDetails>
        </Accordion>
        <Button
          onClick={() =>
            magicFunction2(
              "16",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Apikektomi
        </Button>
      </div>
    )}
    {/* Endodontics Bitiş */}
    {/* Surgery Implants Başlangıç */}
    {ActiveTreatmentMenu.surgeryImplantsMenu && (
      <div className="planSurgeryImplants">
        <Button
          onClick={() =>
            magicFunction2(
              "17",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          İmplant
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "18",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Diş Çekimi
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "19",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Yirmilik Diş Çekimi
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "20",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Sinüs Kaldırma
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "21",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Kemik Rejenerasyonu
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "22",
              undefined, //Prevention & Hygiene
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Tam Ark İmplantları/Protez
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "23",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          İmplant Çıkarma
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "24",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Cerrahi Rehber
        </Button>
      </div>
    )}
    {/* Surgery Implants Bitiş */}
    {/* Restorative Bleach Başlangıç */}
    {ActiveTreatmentMenu.restorativeBleachMenu && (
      <div className="planRestorativeBleach">
        <Accordion
          className="accordion"
          expanded={expanded === "25"}
          onChange={handleChange("25")}
        >
          <AccordionSummary
            className="accordionSummary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className="typography">Restorasyon</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleRestorationCheckbox}
                    value={1}
                    checked={checked}
                  />
                }
                label="Yanak"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleRestorationCheckbox}
                    value={2}
                    checked={checked}
                  />
                }
                label="Distal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleRestorationCheckbox}
                    value={3}
                    checked={checked}
                  />
                }
                label="Lingual"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleRestorationCheckbox}
                    value={4}
                    checked={checked}
                  />
                }
                label="Mesial"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleRestorationCheckbox}
                    value={5}
                    checked={checked}
                  />
                }
                label="Oklüzal"
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Button
          onClick={() =>
            magicFunction2(
              "26",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Post
        </Button>

        <Button
          onClick={() =>
            magicFunction2(
              "27",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Atel
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "28",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Dış Çamaşır Suyu
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "29",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          İç Çamaşır Suyu
        </Button>

        <Accordion
          className="accordion"
          expanded={expanded === "30"}
          onChange={handleChange("30")}
        >
          <AccordionSummary
            className="accordionSummary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className="typography">Çekirdek Oluşturma</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handlecoreBuldUp}
                    value={1}
                    checked={checked}
                  />
                }
                label="Yanak"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handlecoreBuldUp}
                    value={2}
                    checked={checked}
                  />
                }
                label="Distal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handlecoreBuldUp}
                    value={3}
                    checked={checked}
                  />
                }
                label="Lingual"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handlecoreBuldUp}
                    value={4}
                    checked={checked}
                  />
                }
                label="Mesial"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handlecoreBuldUp}
                    value={5}
                    checked={checked}
                  />
                }
                label="Oklüzal"
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "31"}
          onChange={handleChange("31")}
        >
          <AccordionSummary
            className="accordionSummary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className="typography">Geçici Restorasyon</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleProvisionalRestoration}
                    value={1}
                    checked={checked}
                  />
                }
                label="Yanak"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleProvisionalRestoration}
                    value={2}
                    checked={checked}
                  />
                }
                label="Distal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleProvisionalRestoration}
                    value={3}
                    checked={checked}
                  />
                }
                label="Lingual"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleProvisionalRestoration}
                    value={4}
                    checked={checked}
                  />
                }
                label="Mesial"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleProvisionalRestoration}
                    value={5}
                    checked={checked}
                  />
                }
                label="Oklüzal"
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      </div>
    )}
    {/* Restorative Bleach Bitiş */}
    {/* Prosthodontics Başlangıç */}
    {ActiveTreatmentMenu.prosthodonticsMenu && (
      <div className="planProsthodontics">
        <Button
          onClick={() =>
            magicFunction2(
              "32",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Kaplama
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "33",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Onlay
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "34",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Taç
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "35",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Köprü
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "36",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Yapışkanlı Köprü
        </Button>

        <Accordion
          className="accordion"
          expanded={expanded === "37"}
          onChange={handleChange("37")}
        >
          <AccordionSummary
            className="accordionSummary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className="typography">Takma Diş</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="chromeCobalt"
                  control={<Radio />}
                  label="Krom Kobalt"
                />
                <FormControlLabel
                  value="acrylic"
                  control={<Radio />}
                  label="Akrilik"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Button
          onClick={() =>
            magicFunction2(
              "38",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Köprü/Protez Çıkarma
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "39",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Eklemli Çalışma Modelleri
        </Button>

        <Button
          onClick={() =>
            magicFunction2(
              "40",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Geçici Köprü
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "41",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Geçici Taç
        </Button>
      </div>
    )}
    {/* Prosthodontics Bitiş */}
    {/* Ortho Başlangıç */}
    {ActiveTreatmentMenu.orthoMenu && (
      <div className="planOrtho">
        <Accordion
          className="accordion"
          expanded={expanded === "42"}
          onChange={handleChange("42")}
        >
          <AccordionSummary
            className="accordionSummary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className="typography">Sabit Braketler</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="fixedBraces"
                  control={<Radio />}
                  label="Braket"
                />
                <FormControlLabel
                  value="metalBraces"
                  control={<Radio />}
                  label="Metalik Braket"
                />
                <FormControlLabel
                  value="ceramicBraces"
                  control={<Radio />}
                  label="Seramik Braket"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Button
          onClick={() =>
            magicFunction2(
              "43",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Lingual Braketler
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "44",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Hizalayıcıları Temizle
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "45",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Çıkarılabilir Cihaz
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "46",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Fonksiyonel Cihaz
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "47",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Kozmetik Ortodonti
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "48",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Avans
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "49",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Ankraj için Mini İmplantlar
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "50",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Tutulan Dişlerin Traksiyonu
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "51",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Eklemli Çalışma Modelleri
        </Button>
      </div>
    )}
    {/* Ortho Bitiş */}
    {/* TMJ Occlusion Muscles Başlangıç */}
    {ActiveTreatmentMenu.tmjOcclussionMusclesMenu && (
      <div className="planTmjOcclusionMuscles">
        <Button
          onClick={() =>
            magicFunction2(
              "52",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Davranışsal Tavsiye ve Stres Kontrolü
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "53",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Oklüzal Atel
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "54",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Oklüzal Denge
        </Button>
        <Button
          onClick={() =>
            magicFunction2(
              "55",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Eklemli Çalışma Modelleri
        </Button>
      </div>
    )}
    {/* TMJ Occlusion Muscles Bitiş */}
  </div>
  );
}
export default observer(PlanConditions);
