import '../patientPlansList/patientPlansList.css'
import { useEffect, useState } from 'react'
import { useStore } from '../../stores/store'
import { observer } from 'mobx-react-lite'
import { Link, useNavigate } from 'react-router-dom'
import { Button, ButtonGroup, Modal, Card } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import ReactEcharts from 'echarts-for-react'
import EchartTest from './echartTest'
import moment from 'moment'
import '../doctorPlanList/doctorPlansList.css'
import financeStore from '../../stores/financeStore'

export default observer(function ReportAppointmentList() {
  const { appointmentStore, drStore, patientStore } = useStore()
  useEffect(() => {
    appointmentStore.loadAppointments()
    drStore.getDr()
    patientStore.loadPatients()
    
  }, [])

  if (
    appointmentStore.appointments === undefined ||
    patientStore.patients === undefined ||
    drStore === undefined
  ) {
    return (
      <Card className="" style={{ textAlign: 'center' }}>
        <Card.Body>
          <Button className="loadingBtnStyle" disabled>
            Yükleniyor...
          </Button>
        </Card.Body>
      </Card>
    )
  }

  function getPatientName(id) {
    let patientInfo = patientStore.patients.find((patient) => {
      return patient.id === id
    })

    if (patientInfo !== undefined) {
      return patientInfo.fullName
    } else {
      return 'Hasta Bulunamadı'
    }
  }

  function getDoctorName(id) {
    let doctorInfo = drStore.doctors.find((doctor) => {
      return doctor.id === id
    })

    if (doctorInfo !== undefined) {
      return doctorInfo.fullName
    } else {
      return 'Doctor Bulunamadı'
    }
  }
  return (
    <div className="appointmentsPlanGroup">
      <div className="row text-center">
        <span className="reportTitle">Randevu Sayısı</span>
      </div>

      {/* plan sayısı table start */}
      <Table bordered hover size="sm">
        <thead
          className="pricesTableBody"
          style={{ textAlign: 'center', fontSize: 20 }}
        >
          <tr className="pricesTableThHead">
            <th className="pricesTableTh">Başlık</th>
            <th className="pricesTableTh">Hasta</th>
            <th className="pricesTableTh">Doktor</th>
            <th className="pricesTableTh">Randevu Tarihi </th>
            <th className="pricesTableTh">Randevu Bitiş Tarihi</th>
            <th className="pricesTableTh">Açıklama</th>
            <th className="pricesTableTh">Durumu</th>
          </tr>
        </thead>
        <tbody className="pricesTableTbody">
          {appointmentStore.appointments?.map((appointments) => (
            <tr key={appointments.id}>
              <td className="pricesTableTd">{appointments.title}</td>
              <td className="pricesTableTd">
                {getPatientName(appointments.patientId)}
              </td>
              <td className="pricesTableTd">
                {getDoctorName(appointments.doctorId)}
              </td>
              <td className="pricesTableTd">
                {appointments.appointmentStart.split('T')[0]} -
                {appointments.appointmentStart.split('T')[1].split('Z')[0]}
              </td>
              <td className="pricesTableTd">
                {appointments.appointmentEnd.split('T')[0]} -
                {appointments.appointmentEnd.split('T')[1].split('Z')[0]}
              </td>
              <td className="pricesTableTd">{appointments.note}</td>
              <td className="pricesTableTd">
                {appointments.confirmation ? 'Onaylandı' : 'Onaylanmadı'}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* plan sayısı table end */}
    </div>
  )
})

// export default observer(ReportPatientCount);
