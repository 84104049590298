import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { useStore } from "../../stores/store";

const PaymentAdd = () => {
  const { drStore, financeStore } = useStore();
  var str = "";

  const initialPayment = financeStore.selectedPayment ?? {
    id: " ",
    payments: 0,
    currency: 0,
    doctorId: "",
    createdTime: "",
    explanation: "",
    userId: "",
  };
  const [payment, setPayment] = useState(initialPayment);

  const initialButton = financeStore.saveButton;
  const [saveButton, setSaveButton] = useState(initialButton);

  useEffect(() => {
    setSaveButton(initialButton);
  }, [financeStore.saveButton]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setPayment({ ...payment, [name]: value });
  }

  function handleSubmit() {
    financeStore.addPayment(payment, drStore.selectedDoctor.id);
  }

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <div>
        <input
          className="datePicker"
          type="date"
          name="createdTime"
          value={payment.createdTime}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <TextField
          style={{ marginBottom: "1rem", width: "80%" }}
          name="fullName"
          value={drStore.selectedDoctor.fullName}
          label="İsim Soyisim"
        />
      </div>
      <div>
        <TextField
          style={{ marginBottom: "1rem", width: "80%" }}
          name="explanation"
          value={payment.explanation}
          onChange={handleInputChange}
          label="Açıklama"
        />
      </div>
      <div>
        <TextField
          style={{ marginBottom: "1rem", width: "80%" }}
          name="payments"
          type="number"
          value={payment.payments}
          onChange={handleInputChange}
          label="Ödenen Miktar"
        />
      </div>
      <Button
        className="my-1 receiptBtn"
        variant="danger"
        onClick={() => {
          handleSubmit();
        }}
      >
        Ödeme Yap
      </Button>
    </div>
  );
};
export default observer(PaymentAdd);
