import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { eventTupleToStore } from "@fullcalendar/react";
import { useReactToPrint } from "react-to-print";
import "./payment.css";

export default observer(function Payment() {
  const { drStore, financeStore } = useStore();
  var str = "";

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const initialPayment = financeStore.selectedPayment ?? {
    id: " ",
    payments: 0,
    currency: 0,
    doctorId: "",
    createdTime: "",
    explanation: "",
    userId: "",
  };
  const [payment, setPayment] = useState(initialPayment);

  const initialButton = financeStore.saveButton;
  const [saveButton, setSaveButton] = useState(initialButton);

  useEffect(() => {
    setSaveButton(initialButton);
  }, [financeStore.saveButton]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setPayment({ ...payment, [name]: value });
  }

  function handleSubmit() {
    financeStore.addPayment(payment, drStore.selectedDoctor.id);
  }

  function cevir(sayi, separator) {
    var sayarr = sayi.split(separator);
    var items = [
      ["", ""],
      ["BIR", "ON"],
      ["IKI", "YIRMI"],
      ["UC", "OTUZ"],
      ["DORT", "KIRK"],
      ["BES", "ELLI"],
      ["ALTI", "ALTMIS"],
      ["YEDI", "YETMIS"],
      ["SEKIZ", "SEKSEN"],
      ["DOKUZ", "DOKSAN"],
    ];
    for (let eleman = 0; eleman < sayarr.length; eleman++) {
      for (let basamak = 1; basamak <= sayarr[eleman].length; basamak++) {
        let basamakd = 1 + (sayarr[eleman].length - basamak);
        try {
          switch (basamakd) {
            case 6:
              str =
                str +
                " " +
                items[sayarr[eleman].charAt(basamak - 1)][0] +
                " YUZ";
              break;
            case 5:
              str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][1];
              break;
            case 4:
              if (items[sayarr[eleman].charAt(basamak - 1)][0] !== "BIR")
                str =
                  str +
                  " " +
                  items[sayarr[eleman].charAt(basamak - 1)][0] +
                  " BIN";
              else str = str + " BIN";
              break;
            case 3:
              if (items[sayarr[eleman].charAt(basamak - 1)][0] === "") {
                str = str + "";
              } else if (items[sayarr[eleman].charAt(basamak - 1)][0] !== "BIR")
                str =
                  str +
                  " " +
                  items[sayarr[eleman].charAt(basamak - 1)][0] +
                  " YUZ";
              else str = str + " YUZ";
              break;
            case 2:
              str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][1];
              break;
            default:
              str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][0];
              break;
          }
        } catch (err) {
          alert(err.description);
          alert("eleman" + basamak);
          break;
        }
      }
      if (eleman !== 1) str = str + " TL";
      else {
        if (sayarr[1] !== "00") str = str + " KRS";
      }
    }
    return str;
  }
  if (payment.id === " " || saveButton !== false) {
    return (
      <div>
        <div ref={componentRef}>
          <Row>
            <Col xs="7"></Col>
            <Col xs="5">
              <div className="paymentTableHead">TEDİYE MAKBUZU</div>
              <div className="paymentTableDate">
                <span>Tarih: </span>{" "}
                <input
                  className="paymentTableDateInput"
                  type="date"
                  name="createdTime"
                  value={payment.createdTime}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
          <div className="paymentDoctorNameInputDiv">
            <span>SAYIN: </span>{" "}
            <label className="">{drStore.selectedDoctor.fullName}</label>
          </div>
          <table className="table paymentTableStyle table-bordered">
            <thead>
              <tr className="paymentTableTr">
                <th style={{ width: "85%" }} scope="col">
                  AÇIKLAMA
                </th>
                <th scope="col">TL.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    value={payment.explanation}
                    name="explanation"
                    onChange={handleInputChange}
                  />
                </td>
                <td>
                  <input
                    className="tableInputStyle"
                    type="number"
                    name="payments"
                    onChange={handleInputChange}
                    value={payment.payments}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Row>
            <Col xs="7">
              <div>
                <label className="paymentTableFooterInput">
                  Toplam
                  <span> {cevir(payment.payments.toString(), ".")} </span> ödeme
                  yapılmıştır.
                </label>
              </div>
            </Col>
          </Row>

          <div className="_paymentTableFooter">
            <div>
              <div className="signatureBox">İMZA</div>
              <div>ÖDEME YAPAN</div>
              <div>{sessionStorage.getItem("userName")}</div>
            </div>

            <div>
              <div className="signatureBox">İMZA</div>
              <div>ÖDEME ALAN</div>
              <div>{drStore.selectedDoctor.fullName}</div>
            </div>
          </div>
        </div>
        <Button
          className="my-1 receiptBtn"
          variant="outline-success"
          disabled={!saveButton}
          onClick={() => {
            handleSubmit();
            handlePrint();
          }}
        >
          {saveButton ? "Kaydet" : "Kaydedildi"}
        </Button>
        {/* <Button className="my-1 receiptBtn" variant="outline-success "
      onClick={()=> {
        handleSubmit()
        handlePrint()
      }}>
        Kaydet
      </Button> */}
      </div>
    );
  } else {
    return (
      <div>
        <div ref={componentRef}>
          <Row>
            <Col xs="7"></Col>
            <Col xs="5">
              <div className="paymentTableHead">TEDİYE MAKBUZU</div>
              <div className="paymentTableDate">
                <span>Tarih: </span>{" "}
                <input
                  className="paymentTableDateInput"
                  type="date"
                  name="createdTime"
                  value={moment(payment.createdTime).utc().format("YYYY-MM-DD")}
                />
              </div>
            </Col>
          </Row>
          <div className="paymentDoctorNameInputDiv">
            <span>SAYIN: </span>{" "}
            <label className="">{drStore.selectedDoctor.fullName}</label>
          </div>
          <table className="table paymentTableStyle table-bordered">
            <thead>
              <tr className="paymentTableTr">
                <th style={{ width: "85%" }} scope="col">
                  AÇIKLAMA
                </th>
                <th scope="col">TL.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    value={payment.explanation}
                    name="explanation"
                  />
                </td>
                <td>
                  <input
                    className="tableInputStyle"
                    type="number"
                    name="payments"
                    value={payment.payments}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Row>
            <Col xs="7">
              <div>
                <label className="paymentTableFooterInput">
                  Toplam
                  <span> {cevir(payment.payments.toString(), ".")} </span> ödeme
                  yapılmıştır.
                </label>
              </div>
            </Col>
          </Row>
          <div className="_paymentTableFooter">
            <div>
              <div className="signatureBox">İMZA</div>
              <div>ÖDEME YAPAN</div>
              <div>{sessionStorage.getItem("userName")}</div>
            </div>

            <div>
              <div className="signatureBox">İMZA</div>
              <div>ÖDEME ALAN</div>
              <div>{drStore.selectedDoctor.fullName}</div>
            </div>
          </div>
        </div>
        <Button
          className="mx-1 my-1 receiptBtn"
          variant="outline-dark"
          onClick={handlePrint}
        >
          Yazdır
        </Button>
      </div>
    );
  }
});
