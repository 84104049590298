import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-toastify/dist/ReactToastify.min.css';
// import Login from './pages/login/Login'
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from 'history';
import "./components/calendar/calendar.css"
import "antd/dist/antd.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import $ from 'jquery';  
import Popper from 'popper.js';  
import 'bootstrap/dist/js/bootstrap.bundle.min'; 


export const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter history={history}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
