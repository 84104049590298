import '../patientPlansList/patientPlansList.css'
import { observer } from 'mobx-react-lite'
import '../doctorPlanList/doctorPlansList.css'
import ReactEcharts from 'echarts-for-react'
import { useState, useEffect } from 'react'
import { useStore } from '../../stores/store'
import PlanStore from '../../stores/planStore'

let planCount = {
  tamamlanan: 0,
  tamamlanmayan: 0,
}

let appointmentCount = {
  gelen: 0,
  gelmeyen: 0,
}

let tedaviCesitleri = []

let treatmentCount = {
  tamamlanan:0,
  tamamlanmayan:0
}

const option1 = {
  tooltip: {
    trigger: 'item',
  },
  legend: {
    top: '5%',
    left: 'center',
  },
  series: [
    {
      name: 'Planlar',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: true,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2,
      },
      label: {
        show: false,
        position: 'center',
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '20',
          fontWeight: 'bold',
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        { value: 1048, name: 'Tamamlanan' },
        { value: 735, name: 'Tamamlanmayan' },
      ],
    },
  ],
}
const option2 = {
  tooltip: {
    trigger: 'item',
  },
  legend: {
    top: '5%',
    left: 'center',
  },
  series: [
    {
      name: 'Randevular',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2,
      },
      label: {
        show: false,
        position: 'center',
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '20',
          fontWeight: 'bold',
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        { value: 0, name: 'Gelen Hasta' },
        { value: 0, name: 'Gelmeyen Hasta' },
      ],
    },
  ],
}
const option4 = {
  tooltip: {
    trigger: 'item',
  },
  legend: {
    top: '5%',
    left: 'center',
  },
  series: [
    {
      name: 'Tedavi Çeşitleri',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2,
      },
      label: {
        show: false,
        position: 'center',
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '20',
          fontWeight: 'bold',
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        // { value: 1048, name: 'Search Engine' },
        // { value: 735, name: 'Direct' },
        // { value: 580, name: 'Email' },
        // { value: 484, name: 'Union Ads' },
        // { value: 300, name: 'Video Ads' },
      ],
    },
  ],
}
const option3 = {
  tooltip: {
    trigger: 'item',
  },
  legend: {
    top: '5%',
    left: 'center',
  },
  series: [
    {
      name: 'Tedaviler',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2,
      },
      label: {
        show: false,
        position: 'center',
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '40',
          fontWeight: 'bold',
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        { value: 0, name: 'Tamamlanan' },
        { value: 0, name: 'Tamamlanmayan' },
      ],
    },
  ],
}
const optionForBigChart = {
  title: {
    text: 'Tedavi Çeşitleri',
  },
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    data: ['Tedavi-1', 'Tedavi-2', 'Tedavi-3', 'Tedavi-4', 'Tedavi-5'],
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  toolbox: {
    feature: {
      saveAsImage: {},
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: ['2022-11-17', '2022-11-18', '2022-11-19', '2022-11-20', '2022-11-21', '2022-11-22', '2022-11-23'],
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      name: 'Tedavi-1',
      type: 'line',

      data: [2, 3, 5, 1, 0, 0, 2],
    },
    {
      name: 'Tedavi-2',
      type: 'line',

      data: [1, 12, 3, 6, 9, 12, 12],
    },
    {
      name: 'Tedavi-3',
      type: 'line',

      data: [4, 7, 7, 8, 6, 0, 0],
    },
    {
      name: 'Tedavi-4',
      type: 'line',

      data: [2, 2, 2, 3, 3, 9, 8],
    },
    {
      name: 'Tedavi-5',
      type: 'line',

      data: [0, 0, 0, 3, 1, 1, 2],
    },
  ],
}

function EchartTest() {
  const { drStore, planStore, treatmentStore, appointmentStore } = useStore()

  useEffect(() => {
    appointmentStore.loadAppointments()
    drStore.getDr()
    // patientStore.loadPatients()
    planStore.getPlans()
  }, [])

  if (planStore.plans === undefined) {
    option1.series[0].data[0].value = 0
    option1.series[0].data[1].value = 0
  } else {
    planCount.tamamlanan = planStore.plans.filter(
      (plan) => plan.isApproved === true,
    )
    planCount.tamamlanmayan = planStore.plans.filter(
      (plan) => plan.isApproved === false,
    )
    option1.series[0].data[0].value = planCount.tamamlanan.length
    option1.series[0].data[1].value = planCount.tamamlanmayan.length
  }

  if (appointmentStore.appointments === undefined) {
    option2.series[0].data[0].value = 0
    option2.series[0].data[1].value = 0
  } else {
    appointmentCount.gelen = appointmentStore.appointments.filter(
      (appointment) => appointment.confirmation === true,
    )
    appointmentCount.gelmeyen = appointmentStore.appointments.filter(
      (appointment) => appointment.confirmation === false,
    )

    option2.series[0].data[0].value = appointmentCount.gelen.length
    option2.series[0].data[1].value = appointmentCount.gelmeyen.length
  }

  if (planStore.plans === undefined) {
    option3.series[0].data[0].value = 0
  } else {
    treatmentCount.tamamlanan = planStore.plans.map((plan) =>
    plan.treatmentStages.map((stages) =>
      stages.treatments.map((treat) => treat.isCompleted === true),
    ),
  )
    treatmentCount.tamamlanmayan =  planStore.plans.map((plan) =>
      plan.treatmentStages.map((stages) =>
        stages.treatments.map((treat) => treat.isCompleted === false),
      ),
    )
    option3.series[0].data[0].value = treatmentCount.tamamlanan.length
    option3.series[0].data[0].value = treatmentCount.tamamlanmayan.length

  }

  // if(planStore.plan)



  //   return (<ReactEcharts option={option} />);

  return (
    <>
      <div className="chartArea">
      <div className="row bigChartBottomMargin">
        <div className="col-12">
          <ReactEcharts option={optionForBigChart} />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="row text-center">
            <h1 className="chartTitle">Planlar</h1>
          </div>
          <div className="row text-center">
            <ReactEcharts option={option1} />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="row text-center">
            <h1 className="chartTitle">Randevular</h1>
          </div>
          <div className="row text-center">
            <ReactEcharts option={option2} />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="row text-center">
            <h1 className="chartTitle">Tedaviler</h1>
          </div>
          <div className="row text-center">
            <ReactEcharts option={option3} />
          </div>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="row text-center">
            <h1 className="chartTitle">Tedaviler</h1>
          </div>
          <div className="row text-center">
            <ReactEcharts option={option3} />
          </div>
        </div> */}
        {/* <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="row text-center">
            <h1 className="chartTitle">Tedavi Çeşitleri</h1>
          </div>
          <div className="row text-center">
            <ReactEcharts option={option4} />
          </div>
        </div> */}
      </div>
      </div>
    </>
  )
}

export default observer(EchartTest)
