import "./resume.css";
import { Assignment, Person } from "@material-ui/icons";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export default observer(function Resume() {
  const { planStore, patientStore } = useStore();


  return (
    <div className="resume">
      <div className="resumeWrapper">
        <div className="resumePlanNameContainer">
          <span className="resumePlanNameIcon">
            <Assignment className="resumeIcon" />
          </span>
          <span className="resumePlaneNameEditText">
            <input
              className="resumeTextInput"
              type="text"
              placeholder={planStore.selectedPlan.name}
            />
          </span>
        </div>
        <div className="resumePatientNameContainer">
          <span className="resumePatientIcon">
            <Person className="resumeIcon" />
          </span>
          {(planStore.selectedPlan.patient === null &&
          <span className="resumePatientName"> {patientStore.selectedPatient.fullName} </span>)}
          {(planStore.selectedPlan.patient !== null &&
          <span className="resumePatientName"> {planStore.selectedPlan.patient.fullName} </span>)}
        </div>
      </div>
    </div>
  );
});
