import { useLocation } from 'react-router-dom'
import EchartTest from '../../components/report/echartTest'
import { Button, ButtonGroup, Modal, Card } from 'react-bootstrap'
import { useState, useEffect } from 'react'

import ReportApprovedPlanList from '../../components/report/ReportApprovedPlanList'
import ReportNotApprovedPlanList from '../../components/report/ReportNotApprovedPlanList'
import ReportCompletedTreatments from '../../components/report/ReportCompletedTreatments'
import ReportNotCompletedTreatments from '../../components/report/ReportNotCompletedTreatments'
import ReportPatientCount from '../../components/report/ReportPatientCount'
import ReportTotalCollectionPaymentsWithCreditCard from '../../components/report/ReportTotalCollectionPaymentsWithCreditCard'
import ReportTotalReceive from '../../components/report/ReportTotalReceive'
import ReportAppointmentList from '../../components/report/ReportAppointmentList'
import ReportCashPayments from '../../components/report/ReportCashPayments'
import ReportTreatments from '../../components/report/ReportTreatments'
import './specialReports.css'
export default function SpecialReports() {
  const location = useLocation()
  const { reportTypeObject, filterStartDate, filterEndDate, chartOptions } =
    location.state

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
  }
  return (
    <>
      <div className="container-fluid">
        <EchartTest></EchartTest>
        {reportTypeObject &&
        reportTypeObject.id === 'doctorBasedReportSelection' ? (
          <>
            {reportTypeObject.planCount ? (
              <>
                <ReportApprovedPlanList></ReportApprovedPlanList>
                <br />
                <ReportNotApprovedPlanList></ReportNotApprovedPlanList>
                <br />
              </>
            ) : (
              // approved and total plan count ikisi bir arada olacak
              <div></div>
            )}
            {reportTypeObject.patientCount ? (
              <>
                <ReportPatientCount></ReportPatientCount>
                <br />
              </>
            ) : (
              <div></div>
            )}
            {reportTypeObject.totalReceiveCount ? (
              <>
                <ReportTotalReceive></ReportTotalReceive>
                <br />
              </>
            ) : (
              <div></div>
            )}
            {reportTypeObject.totalPayment ? (
              <>{/* <ReportCollectionPayments></ReportCollectionPayments> */}</>
            ) : (
              <div></div>
            )}
            {reportTypeObject.completedTreatmentsCount ? (
              <>
                <ReportCompletedTreatments></ReportCompletedTreatments>
                <br />
                <ReportNotCompletedTreatments></ReportNotCompletedTreatments>
                <br />
              </>
            ) : (
              <div></div>
            )}
            {reportTypeObject.appointmentCount ? (
              <>
                <ReportAppointmentList></ReportAppointmentList>
                <br />
              </>
            ) : (
              <div></div>
            )}
            {/* <ReportApprovedPlanList></ReportApprovedPlanList> */}
          </>
        ) : (
          <div></div>
        )}
        {reportTypeObject &&
        reportTypeObject.id === 'patientBasedReportSelection' ? (
          <>
            {reportTypeObject.totalDept ? <div></div> : <div></div>}
            {reportTypeObject.totalPayment ? <div></div> : <div></div>}
            {reportTypeObject.completedTreatmentsCount ? (
              <>
                <ReportCompletedTreatments></ReportCompletedTreatments>
                <br />
                <ReportNotCompletedTreatments></ReportNotCompletedTreatments>
                <br />
              </>
            ) : (
              <div></div>
            )}
            {reportTypeObject.appointmentCount ? (
              <>
                <ReportAppointmentList></ReportAppointmentList>
                <br />
              </>
            ) : (
              <div></div>
            )}
          </>
        ) : (
          // <ReportCompletedTreatments></ReportCompletedTreatments>
          <div></div>
        )}
        {reportTypeObject &&
        reportTypeObject.id === 'treatmentsBasedReportSelection' ? (
          <>
            <ReportTreatments></ReportTreatments>
            <br />
          </> // zaten tek seçenek var direkt componenti bas
        ) : (
          <div></div>
        )}
        {reportTypeObject &&
        reportTypeObject.id === 'patientAdvisorBasedReportSelection' ? (
          <>
            {reportTypeObject.givenOfferCount ? <div></div> : <div></div>}
            {reportTypeObject.approvedOfferCount ? <div></div> : <div></div>}
          </>
        ) : (
          // <ReportNotCompletedTreatments></ReportNotCompletedTreatments>
          <div></div>
        )}
        {reportTypeObject &&
        reportTypeObject.id === 'financeReportSelection' ? (
          <>
            {reportTypeObject.creditCardPayments ? (
              <>
                <ReportTotalCollectionPaymentsWithCreditCard></ReportTotalCollectionPaymentsWithCreditCard>
                <br />
              </>
            ) : (
              <div></div>
            )}
            {reportTypeObject.cashPayments ? (
              <>
                <ReportCashPayments></ReportCashPayments>
              </>
            ) : (
              <div></div>
            )}
          </>
        ) : (
          // <ReportTotalCollection></ReportTotalCollection>
          <div></div>
        )}

        <div className="row">
          <div className="col-12 export-button-flex">
            <Button
              variant="outline-dark"
              onClick={handleShow}
              className="exportButton"
            >
              Export Excell
            </Button>
            <Button variant="outline-dark" onClick={handleShow} className="exportButton">
              Export Pdf
            </Button>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Export</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center m-5">
            <span className="exportSpanWarning">PEK YAKINDA</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
