import agent from "../api/agent";
import { runInAction, makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

export default class PricesStore {
  prices = undefined;
  selectedPrice = undefined;
  prcs = null;
  loading = false;
  editing = false;

  constructor() {
    makeAutoObservable(this);
  }

  getPrices = async () => {
    try {
      const prices = await agent.Prices.list();
      if (prices.isSuccess === true) {
        runInAction(() => {
          this.loading = true;
          this.prices = prices.data
          this.loading = false;
        });
      }
    } catch (error) {
      throw error;
    }
  };
  getList = async (page, size) => {
    try {
     
      const prices = await agent.Prices.listPages(page, size);
      if (prices.isSuccess === true) {
        runInAction(() => {
          this.loading = true;
          this.prices = prices.data
          this.loading = false;
        });
      }
     
    } catch (error) {
      throw error;
    }
  };
  updatePrice = async (creds) => {
    try {
      runInAction(()=> { this.loading = true;
        this.editing = true; });
      
      const prices = await agent.Prices.update(creds);
      if (prices.isSuccess === true) {
        toast.success("Tedavi güncelleme işlemi başarılı.");
        runInAction(() => {
          this.prices = [...this.prices.filter(a => a.id !== creds.id),creds]
        this.prices = this.prices.sort((a, b) => parseFloat(a.treatmentCode) - parseFloat(b.treatmentCode));

        });
        console.log(prices.data);
      }
      runInAction(()=> { this.loading = false; this.editing = false;});
      
    } catch (error) {
      throw error;
    }
  };

  deletePrice = async (id) => {
    try {
      runInAction(()=> { this.loading = true; });
      const deletePrice = await agent.Prices.delete(id);
      if (deletePrice.isSuccess === true) {
        toast.success("Tedavi silme işlemi başarılı.");
        runInAction(() => {
          this.prcs = deletePrice.data;
        });
      }
      runInAction(()=> { this.loading = false; });
    } catch (error) {
      throw error;
    }
  };

  selectPrice = (id) => {
    try {
      const price = this.prices.find((x) => x.id === id);
      console.log(this.prices);
      runInAction(() => {
        this.selectedPrice = price;
      });
    } catch (error) {
      console.log(error);
    }
  };
}
