import { runInAction, makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";

export default class PatientStore {
  patients = undefined;
  selectedPatient = JSON.parse(sessionStorage.getItem("planID"));
  editMode = false;
  loading = false;
  adding = false;
  updating = false;
  patientsWizard = [];
  deleted = false;
  advisors = [];

  constructor() {
    makeAutoObservable(this);
  }
  loadPatientWizard = async () => {
    runInAction(() => {
      this.loading = true;
    });
    try {
      const patients = await agent.Patients.list();
      runInAction(() => {
        this.patients = patients.data;
      });
      this.patients.forEach((element) => {
        runInAction(() => {
          this.patientsWizard.find((x) => x.id === element.id)
            ? (element.id = element.id)
            : this.patientsWizard.push({
                label: element.fullName,
                id: element.id,
                identity: element.identityNumber,
              });
        });
      });
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
  loadPatients = async () => {
    runInAction(() => {
      this.loading = true;
    });
    try {
      const patients = await agent.Patients.list();
      const advisors = await agent.Patients.advisorList();
      runInAction(() => {
        this.patients = patients.data;
      });
      advisors.data.forEach(advisor => {
        runInAction(()=>{
          this.advisors.push({
            label: advisor.fullName,
            id: advisor.id,
          })
        })
      });
      console.log(JSON.stringify(this.advisors))
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  selectPatient = (id) => {
    try {
      const patient = this.patients.find((x) => x.id === id);
      runInAction(() => {
        this.selectedPatient = patient;
        sessionStorage.setItem("patient", JSON.stringify(patient));
        sessionStorage.setItem("patientName", JSON.stringify(patient.fullName));
      });
      } catch (error) {
      console.log(error);
    }
  };
  cancelSelectedPatient = () => {
    try {
      runInAction(() => {
        this.selectedPatient = undefined;
      });
    } catch (error) {
      console.log(error);
    }
  };

  createPatient = async (patient) => {
    try {
      runInAction(() => {
        this.loading = true;
        this.adding = true;
      });
      patient.id = uuid();
      patient.birthDate = new Date(patient.birthDate).toISOString();
      let result = await agent.Patients.create(patient);
      runInAction(() => {
        this.patients.push(result.data);
        this.selectedPatient = result;
        this.adding = false;
      });
      toast.success("Hasta ekleme işlemi başarılı.");
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
    }
  };

  updatePatient = async (patient) => {
    console.log(JSON.stringify(patient))
    try {
      runInAction(() => {
        this.updating = true;
      });
      await agent.Patients.update(patient);
      runInAction(() => {
        this.patients = [
          ...this.patients.filter((a) => a.id !== patient.id),
          patient,
        ];
        this.selectedPatient = patient;
      toast.success("Hasta güncelleme işlemi başarılı.");

        this.updating = false;
      });
    } catch (error) {
      console.log(error);
    }
  };

  deletePatient = async (id) => {
    this.editMode = false;
    try {
      runInAction(() => {
        this.deleted = true;
      });
      await agent.Patients.delete(id);
      runInAction(() => {
        this.patients = [...this.patients.filter((a) => a.id !== id)];
      });
      runInAction(() => {
        this.deleted = false;
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleFormOpen(id) {
    id ? this.selectPatient(id) : this.cancelSelectedPatient();
    this.editMode = true;
  }

  handleFormClose() {
    this.editMode = false;
  }

  addAnamnesis = async (anamnesis) => {
    try {
      runInAction(() => {
        this.loading = true;
        this.adding = true;
      });
      anamnesis.id = uuid();
      anamnesis.patientId = this.selectedPatient.id;
      let result = await agent.Patients.createAnamnesis(anamnesis);
      runInAction(() => {
        this.selectedPatient.anamneses = result.data;
        this.adding = false;
      });
      toast.success("Anamnez ekleme işlemi başarılı.");
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
    }
  }

  updateAnamnesis = async (anamnesis) => {
    try {
      runInAction(() => {
        this.loading = true;
        this.adding = true;
      });
      let result = await agent.Patients.updateAnamnesis(anamnesis);
      runInAction(() => {
        this.selectedPatient.anamneses = result.data;
        this.adding = false;
      });
      toast.success("Anamnez güncelleme işlemi başarılı.");
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
    }
  }
}
