import "../patientPlansList/patientPlansList.css";
import { useEffect, useState } from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Modal, Card } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import ReactEcharts from "echarts-for-react";
import EchartTest from "./echartTest";
import moment from "moment";
import "../doctorPlanList/doctorPlansList.css";

export default observer(function ReportPatientCount() {
  const { pricesStore } = useStore();
//   console.log(JSON.stringify(pricesStore))
console.log(useStore())

//   const { selectPlan, dateFilter } = planStore;
  useEffect(() => {
    pricesStore.getPrices();
  }, []);

  if (pricesStore.patients === undefined){

     return (
        <Card className="" style={{ textAlign: "center" }}>
          <Card.Body>
            <Button className="loadingBtnStyle" disabled>
              Yükleniyor...
            </Button>
          </Card.Body>
        </Card>
      );
  }
//   console.log(JSON.stringify(patientStore.patients));
pricesStore.patients.map(patients => {
    console.log(JSON.stringify(patients))
  })
  return (
    <div className="patientsPlanGroup">
      <div className="row text-center">
        <span className="reportTitle">Hasta Sayısı</span>
      </div>

      {/* plan sayısı table start */}
      {/* <Table className="table table-hover table-lg table-responsive-md"> */}
      <Table bordered hover size="sm">
        <thead
          className="pricesTableBody"
          style={{ textAlign: "center", fontSize: 20 }}
        >
          <tr className="pricesTableThHead">
            <th className="pricesTableTh">Hasta İsmi</th>
            <th className="pricesTableTh">E-Mail</th>
            <th className="pricesTableTh">Cinsiyet</th>
            <th className="pricesTableTh">Doğum Günü</th>
            <th className="pricesTableTh">Hasta Danışmanı</th>
            

          </tr>
        </thead>
        <tbody className="pricesTableTbody">
          {pricesStore.patients?.map(
            (patients) =>
             (
                <tr key={patients.id}>
                  <td className="pricesTableTd">{patients.fullName}</td>
                  <td className="pricesTableTd">{patients.email}</td>
                  {patients.gender ? <td className="pricesTableTd">Erkek</td> : <td className="pricesTableTd">Kadın</td>}
                  <td className="pricesTableTd">{patients.birthDate.split("T")[0]}</td>
                  <td className="pricesTableTd">{patients.advisor.fullName}</td>
                </tr>
              )
          )}
        </tbody>
      </Table>
      {/* plan sayısı table end */}
    </div>
  );
});

// export default observer(ReportPatientCount);
