import { runInAction, makeAutoObservable } from "mobx";
import React from "react";
import agent from "../api/agent";

export default class ConditionStore {
  constructor() {
    makeAutoObservable(this);
  }
  loading = false;
  resetForm = false;
  resetForm2 = false;
  loadedCount = 0;
  selectedPlan = sessionStorage.getItem("planID");
  selectedTeeth = [];
  selectedSurfaces = [];
  selectedLocations = undefined;
  selectedDegree = undefined;
  currentCondition = undefined;
  selectedCondition = undefined;
  tooth = {
    name: "",
    selected: "",
    missing: "",
    class: "tooth",
    carries: {
      ////1
      buccal: false,
      distal: false,
      lingual: false,
      mesial: false,
      occlusal: false,
    },
    fracture: {
      ////2
      crown: false,
      root: false,
    },
    severelyDamaged: false, ////3
    wear: {
      ////4
      mild: false,
      moderate: false,
      severe: false,
    },
    plaqueHygiene: {
      ////5
      mild: false,
      moderate: false,
      severe: false,
    },
    gingivitis: {
      ////6
      mild: false,
      moderate: false,
      severe: false,
    },
    gingivalRecession: {
      ////7
      mild: false,
      moderate: false,
      severe: false,
    },
    periodontitis: {
      ////8
      mild: false,
      moderate: false,
      severe: false,
    },
    mobility: {
      ////9
      mild: false,
      moderate: false,
      severe: false,
    },
    gummySmile: false, ////10
    gingivalOvergrowth: false, ////11
    largeSinus: false, ////12
    necrosis: false, ////13
    rootCanalTreatment: {
      ////14
      satisfactory: false,
      unSatisfactory: false,
    },
    apicalLesion: {
      ////15
      mild: false,
      moderate: false,
      severe: false,
    },
    brokenInstrumentInCanal: false, ////16
    rootResorption: {
      ////17
      external: false,
      internal: false,
    },
    missingTeeth: false, ////18
    implant: {
      ////19
      ok: false,
      malpositioned: false,
      withBoneLose: false,
      withGingivalRecession: false,
    },
    impacted: false, ////20
    impactedInfected: false, ////21
    largeSinus2: false, ////22
    restoration: {
      ////23
      buccal: false,
      distal: false,
      lingual: false,
      mesial: false,
      occlusal: false,
      ok: false,
      leaking: false,
      worn: false,
      fractured: false,
      discolored: false,
      unaesthetic: false,
    },
    post: {
      ////24
      ok: false,
      unSatisfactory: false,
    },
    splint: {
      ////25
      ok: false,
      unSatisfactory: false,
    },
    disColored: false, ////26
    veneer: {
      ////27
      ok: false,
      leaking: false,
      worn: false,
      fractured: false,
      discolored: false,
      unaesthetic: false,
    },
    crown: {
      ////28
      ok: false,
      leaking: false,
      worn: false,
      fractured: false,
      discolored: false,
      unaesthetic: false,
    },
    onlay: {
      ////29
      ok: false,
      leaking: false,
      worn: false,
      fractured: false,
      discolored: false,
      unaesthetic: false,
    },
    bridge: {
      ////30
      ok: false,
      leaking: false,
      worn: false,
      fractured: false,
      discolored: false,
      unaesthetic: false,
    },
    denture: {
      ////31
      ok: false,
      leaking: false,
      worn: false,
      fractured: false,
      discolored: false,
      unaesthetic: false,
    },
    gengivaDefault: true,
    boneDefault: true,
    boneLineDefault: true,
    //buradan sonrası plan için
  };

  conditionModel = {
    planId: "",
    conditionType: 0,
    teeths: [],
    toothSurfaces: [],
    locations: 0,
    degree: 0,
    status: 0,
    observations: "",
  };
  odontogram = [];

  magicFunction = (
    conditionType,
    teeth,
    toothSurface,
    toothSurfaceValue,
    locations,
    degree,
    status,
    conditionObject
  ) => {
    if (teeth !== undefined) {
      let item = this.selectedTeeth.find((x) => x.name === teeth);
      if (item) {
        runInAction(() => {
          let testTeeth = this.selectedTeeth.filter((z) => z.name !== teeth);
          this.selectedTeeth = testTeeth;
          this.currentCondition.teeths = [];
          this.selectedTeeth.forEach((element) => {
            this.currentCondition.teeths.push(
              parseInt(element.name.substr(-2))
            );
          });
          this.currentCondition.teeths = this.currentCondition.teeths.sort();
        });
      } else {
        runInAction(() => {
          let addedItem = this.tooth;
          addedItem.name = teeth;
          this.selectedTeeth.push(this.cloneOdontogram(addedItem));
          this.currentCondition.teeths = [];
          this.selectedTeeth.forEach((element) => {
            this.currentCondition.teeths.push(
              parseInt(element.name.substr(-2))
            );
          });
          this.currentCondition.teeths = this.currentCondition.teeths.sort();
        });
      }
    }
    if (conditionType !== undefined) {
      console.log(conditionType);
      this.currentCondition.conditionType = parseInt(conditionType);
      if (this.selectedCondition === undefined) {
        this.selectedCondition = parseInt(conditionType);
      } else if (this.selectedCondition === conditionType) {
        this.selectedCondition = parseInt(conditionType);
      } else {
        this.selectedCondition = parseInt(conditionType);
      }
    }
    if (toothSurface !== undefined) {
      toothSurfaceValue
        ? runInAction(() => {
            this.selectedSurfaces.push(parseInt(toothSurface));
            this.currentCondition.toothSurfaces = this.selectedSurfaces;
            this.currentCondition.toothSurfaces =
              this.currentCondition.toothSurfaces.sort();
          })
        : runInAction(() => {
            let surface = this.selectedSurfaces.filter(
              (z) => z !== parseInt(toothSurface)
            );
            this.selectedSurfaces = surface;
            this.currentCondition.toothSurfaces = this.selectedSurfaces;
          });
    }
    if (locations !== undefined) {
      runInAction(() => {
        this.selectedLocations = parseInt(locations);
        this.currentCondition.locations = parseInt(locations);
      });
    }

    if (degree !== undefined) {
      runInAction(() => {
        this.selectedDegree = parseInt(degree);
        this.currentCondition.degree = parseInt(degree);
      });
    }
    if (status !== undefined) {
      runInAction(() => {
        this.selectedStatus = parseInt(status);
        this.currentCondition.status = parseInt(status);
      });
    }
    if (conditionObject !== undefined) {
    }
    this.loadOdontogram();
  };

  loadOdontogram(passedPlan) {
    if (passedPlan !== undefined) this.selectedPlan = passedPlan; //gönderilen plan varsa o planınn özerlliklerine göre ekranı güncelliyor
    if (this.currentCondition === undefined)
      this.currentCondition = JSON.parse(JSON.stringify(this.conditionModel));
    this.odontogram = [];

    for (let j = 11; j <= 48; j++) {
      let addedItem = JSON.parse(JSON.stringify(this.tooth));
      if (!this.tooth.missingTeeth) {
        addedItem.name = "tooth" + j;
      }
      let selectedCheck = this.selectedTeeth.find(
        (item) => item.name === addedItem.name
      );
      if (selectedCheck) {
        addedItem.selected = "selected";
        if (this.selectedCondition !== undefined) {
          if (this.selectedCondition === 1) {
            this.selectedSurfaces?.forEach((element) => {
              if (element === 1) addedItem.carries.buccal = true;
              if (element === 2) addedItem.carries.distal = true;
              if (element === 3) addedItem.carries.lingual = true;
              if (element === 4) addedItem.carries.mesial = true;
              if (element === 5) addedItem.carries.occlusal = true;
            });
          } else if (this.selectedCondition === 2) {
            if (this.selectedLocations === 1) {
              addedItem.fracture.root = true;
              addedItem.fracture.crown = false;
            }
            if (this.selectedLocations === 2) {
              addedItem.fracture.crown = true;
              addedItem.fracture.root = false;
            }
          } else if (this.selectedCondition === 3) {
            addedItem.severelyDamaged = !addedItem.severelyDamaged;
          } else if (this.selectedCondition === 4) {
            if (this.selectedDegree === 1) addedItem.wear.mild = true;
            if (this.selectedDegree === 2) addedItem.wear.moderate = true;
            if (this.selectedDegree === 3) addedItem.wear.severe = true;
          } else if (this.selectedCondition === 7) {
            if (this.selectedDegree === 1) {
              addedItem.gengivaDefault = false;
              addedItem.boneDefault = false;
              addedItem.gingivalRecession.mild = true;
            }
            if (this.selectedDegree === 2) {
              addedItem.gingivalRecession.moderate = true;
              addedItem.gengivaDefault = false;
              addedItem.boneDefault = false;
            }
            if (this.selectedDegree === 3) {
              addedItem.gingivalRecession.severe = true;
              addedItem.gengivaDefault = false;
              addedItem.boneDefault = false;
            }
          } else if (this.selectedCondition === 8) {
            if (this.selectedDegree === 1) {
              addedItem.periodontitis.mild = true;
              addedItem.boneDefault = false;
            }
            if (this.selectedDegree === 2) {
              addedItem.periodontitis.moderate = true;
              addedItem.boneDefault = false;
            }
            if (this.selectedDegree === 3) {
              addedItem.periodontitis.severe = true;
              addedItem.boneDefault = false;
            }
          } else if (this.selectedCondition === 10) {
            addedItem.gummySmile = !addedItem.gummySmile;
            addedItem.gengivaDefault = false;
          } else if (this.selectedCondition === 11) {
            addedItem.gingivalOvergrowth = !addedItem.gingivalOvergrowth;
            addedItem.gengivaDefault = false;
          } else if (this.selectedCondition === 12) {
            addedItem.largeSinus = !addedItem.largeSinus;
            addedItem.boneLineDefault = false;
          } else if (this.selectedCondition === 13) {
            addedItem.necrosis = !addedItem.necrosis;
          } else if (this.selectedCondition === 14) {
            if (this.selectedStatus === 1) {
              addedItem.rootCanalTreatment.satisfactory = true;
              addedItem.rootCanalTreatment.unSatisfactory = false;
            }
            if (this.selectedStatus === 2) {
              addedItem.rootCanalTreatment.satisfactory = false;
              addedItem.rootCanalTreatment.unSatisfactory = true;
            }
          } else if (this.selectedCondition === 15) {
            if (this.selectedDegree === 1) addedItem.apicalLesion.mild = true;
            if (this.selectedDegree === 2)
              addedItem.apicalLesion.moderate = true;
            if (this.selectedDegree === 3) addedItem.apicalLesion.severe = true;
          } else if (this.selectedCondition === 16) {
            addedItem.brokenInstrumentInCanal =
              !addedItem.brokenInstrumentInCanal;
          } else if (this.selectedCondition === 17) {
            if (this.selectedLocations === 3) {
              addedItem.rootResorption.external = true;
              addedItem.rootResorption.internal = false;
            }
            if (this.selectedLocations === 4) {
              addedItem.rootResorption.internal = true;
              addedItem.rootResorption.external = false;
            }
          } else if (this.selectedCondition === 18) {
            addedItem.missingTeeth = !addedItem.missingTeeth;
          } else if (this.selectedCondition === 19) {
            if (this.selectedStatus === 3) {
              addedItem.implant.ok = true;
              addedItem.implant.malpositioned = false;
              addedItem.implant.withBoneLose = false;
              addedItem.implant.withGingivalRecession = false;
            }
            if (this.selectedStatus === 4) {
              addedItem.implant.ok = false;
              addedItem.implant.malpositioned = true;
              addedItem.implant.withBoneLose = false;
              addedItem.implant.withGingivalRecession = false;
            }
            if (this.selectedStatus === 5) {
              addedItem.implant.ok = false;
              addedItem.implant.malpositioned = false;
              addedItem.implant.withBoneLose = true;
              addedItem.implant.withGingivalRecession = false;
            }
            if (this.selectedStatus === 6) {
              addedItem.implant.ok = false;
              addedItem.implant.malpositioned = false;
              addedItem.implant.withBoneLose = false;
              addedItem.implant.withGingivalRecession = true;
            }
          } else if (this.selectedCondition === 20) {
            addedItem.impacted = !addedItem.impacted;
          } else if (this.selectedCondition === 21) {
            addedItem.impactedInfected = !addedItem.impactedInfected;
          } else if (this.selectedCondition === 22) {
            addedItem.largeSinus2 = !addedItem.largeSinus2;
            addedItem.boneLineDefault = false;
          }
          if (this.selectedCondition === 23) {
            this.selectedSurfaces?.forEach((element) => {
              if (element === 1) addedItem.restoration.buccal = true;
              if (element === 2) addedItem.restoration.distal = true;
              if (element === 3) addedItem.restoration.lingual = true;
              if (element === 4) addedItem.restoration.mesial = true;
              if (element === 5) addedItem.restoration.occlusal = true;
            });
            if (this.selectedStatus === 3) {
              addedItem.restoration.ok = true;
              addedItem.restoration.leaking = false;
              addedItem.restoration.worn = false;
              addedItem.restoration.fractured = false;
              addedItem.restoration.discolored = false;
              addedItem.restoration.unaesthetic = false;
            }
            if (this.selectedStatus === 7) {
              addedItem.restoration.ok = false;
              addedItem.restoration.leaking = true;
              addedItem.restoration.worn = false;
              addedItem.restoration.fractured = false;
              addedItem.restoration.discolored = false;
              addedItem.restoration.unaesthetic = false;
            }
            if (this.selectedStatus === 8) {
              addedItem.restoration.ok = false;
              addedItem.restoration.leaking = false;
              addedItem.restoration.worn = true;
              addedItem.restoration.fractured = false;
              addedItem.restoration.discolored = false;
              addedItem.restoration.unaesthetic = false;
            }
            if (this.selectedStatus === 9) {
              addedItem.restoration.ok = false;
              addedItem.restoration.leaking = false;
              addedItem.restoration.worn = false;
              addedItem.restoration.fractured = true;
              addedItem.restoration.discolored = false;
              addedItem.restoration.unaesthetic = false;
            }
            if (this.selectedStatus === 10) {
              addedItem.restoration.ok = false;
              addedItem.restoration.leaking = false;
              addedItem.restoration.worn = false;
              addedItem.restoration.fractured = false;
              addedItem.restoration.discolored = true;
              addedItem.restoration.unaesthetic = false;
            }
            if (this.selectedStatus === 11) {
              addedItem.restoration.ok = false;
              addedItem.restoration.leaking = false;
              addedItem.restoration.worn = false;
              addedItem.restoration.fractured = false;
              addedItem.restoration.discolored = false;
              addedItem.restoration.unaesthetic = true;
            }
          } else if (this.selectedCondition === 24) {
            if (this.selectedStatus === 1) {
              addedItem.post.ok = true;
              addedItem.post.unSatisfactory = false;
            }
            if (this.selectedStatus === 2) {
              addedItem.post.ok = false;
              addedItem.post.unSatisfactory = true;
            }
          } else if (this.selectedCondition === 25) {
            if (this.selectedStatus === 1) {
              addedItem.splint.ok = true;
              addedItem.splint.unSatisfactory = false;
            }
            if (this.selectedStatus === 2) {
              addedItem.splint.ok = false;
              addedItem.splint.unSatisfactory = true;
            }
          } else if (this.selectedCondition === 26) {
            addedItem.disColored = !addedItem.disColored;
          }
          if (this.selectedCondition === 27) {
            if (this.selectedStatus === 3) {
              addedItem.veneer.ok = true;
              addedItem.veneer.leaking = false;
              addedItem.veneer.worn = false;
              addedItem.veneer.fractured = false;
              addedItem.veneer.discolored = false;
              addedItem.veneer.unaesthetic = false;
            }
            if (this.selectedStatus === 7) {
              addedItem.veneer.ok = false;
              addedItem.veneer.leaking = true;
              addedItem.veneer.worn = false;
              addedItem.veneer.fractured = false;
              addedItem.veneer.discolored = false;
              addedItem.veneer.unaesthetic = false;
            }
            if (this.selectedStatus === 8) {
              addedItem.veneer.ok = false;
              addedItem.veneer.leaking = false;
              addedItem.veneer.worn = true;
              addedItem.veneer.fractured = false;
              addedItem.veneer.discolored = false;
              addedItem.veneer.unaesthetic = false;
            }
            if (this.selectedStatus === 9) {
              addedItem.veneer.ok = false;
              addedItem.veneer.leaking = false;
              addedItem.veneer.worn = false;
              addedItem.veneer.fractured = true;
              addedItem.veneer.discolored = false;
              addedItem.veneer.unaesthetic = false;
            }
            if (this.selectedStatus === 10) {
              addedItem.veneer.ok = false;
              addedItem.veneer.leaking = false;
              addedItem.veneer.worn = false;
              addedItem.veneer.fractured = false;
              addedItem.veneer.discolored = true;
              addedItem.veneer.unaesthetic = false;
            }
            if (this.selectedStatus === 11) {
              addedItem.veneer.ok = false;
              addedItem.veneer.leaking = false;
              addedItem.veneer.worn = false;
              addedItem.veneer.fractured = false;
              addedItem.veneer.discolored = false;
              addedItem.veneer.unaesthetic = true;
            }
          }
          if (this.selectedCondition === 28) {
            if (this.selectedStatus === 3) {
              addedItem.crown.ok = true;
              addedItem.crown.leaking = false;
              addedItem.crown.worn = false;
              addedItem.crown.fractured = false;
              addedItem.crown.discolored = false;
              addedItem.crown.unaesthetic = false;
            }
            if (this.selectedStatus === 7) {
              addedItem.crown.ok = false;
              addedItem.crown.leaking = true;
              addedItem.crown.worn = false;
              addedItem.crown.fractured = false;
              addedItem.crown.discolored = false;
              addedItem.crown.unaesthetic = false;
            }
            if (this.selectedStatus === 8) {
              addedItem.crown.ok = false;
              addedItem.crown.leaking = false;
              addedItem.crown.worn = true;
              addedItem.crown.fractured = false;
              addedItem.crown.discolored = false;
              addedItem.crown.unaesthetic = false;
            }
            if (this.selectedStatus === 9) {
              addedItem.crown.ok = false;
              addedItem.crown.leaking = false;
              addedItem.crown.worn = false;
              addedItem.crown.fractured = true;
              addedItem.crown.discolored = false;
              addedItem.crown.unaesthetic = false;
            }
            if (this.selectedStatus === 10) {
              addedItem.crown.ok = false;
              addedItem.crown.leaking = false;
              addedItem.crown.worn = false;
              addedItem.crown.fractured = false;
              addedItem.crown.discolored = true;
              addedItem.crown.unaesthetic = false;
            }
            if (this.selectedStatus === 11) {
              addedItem.crown.ok = false;
              addedItem.crown.leaking = false;
              addedItem.crown.worn = false;
              addedItem.crown.fractured = false;
              addedItem.crown.discolored = false;
              addedItem.crown.unaesthetic = true;
            }
          }
          if (this.selectedCondition === 29) {
            if (this.selectedStatus === 3) {
              addedItem.onlay.ok = true;
              addedItem.onlay.leaking = false;
              addedItem.onlay.worn = false;
              addedItem.onlay.fractured = false;
              addedItem.onlay.discolored = false;
              addedItem.onlay.unaesthetic = false;
            }
            if (this.selectedStatus === 7) {
              addedItem.onlay.ok = false;
              addedItem.onlay.leaking = true;
              addedItem.onlay.worn = false;
              addedItem.onlay.fractured = false;
              addedItem.onlay.discolored = false;
              addedItem.onlay.unaesthetic = false;
            }
            if (this.selectedStatus === 8) {
              addedItem.onlay.ok = false;
              addedItem.onlay.leaking = false;
              addedItem.onlay.worn = true;
              addedItem.onlay.fractured = false;
              addedItem.onlay.discolored = false;
              addedItem.onlay.unaesthetic = false;
            }
            if (this.selectedStatus === 9) {
              addedItem.onlay.ok = false;
              addedItem.onlay.leaking = false;
              addedItem.onlay.worn = false;
              addedItem.onlay.fractured = true;
              addedItem.onlay.discolored = false;
              addedItem.onlay.unaesthetic = false;
            }
            if (this.selectedStatus === 10) {
              addedItem.onlay.ok = false;
              addedItem.onlay.leaking = false;
              addedItem.onlay.worn = false;
              addedItem.onlay.fractured = false;
              addedItem.onlay.discolored = true;
              addedItem.onlay.unaesthetic = false;
            }
            if (this.selectedStatus === 11) {
              addedItem.onlay.ok = false;
              addedItem.onlay.leaking = false;
              addedItem.onlay.worn = false;
              addedItem.onlay.fractured = false;
              addedItem.onlay.discolored = false;
              addedItem.onlay.unaesthetic = true;
            }
          }
          if (this.selectedCondition === 30) {
            if (this.selectedStatus === 3) {
              addedItem.bridge.ok = true;
              addedItem.bridge.leaking = false;
              addedItem.bridge.worn = false;
              addedItem.bridge.fractured = false;
              addedItem.bridge.discolored = false;
              addedItem.bridge.unaesthetic = false;
            }
            if (this.selectedStatus === 7) {
              addedItem.bridge.ok = false;
              addedItem.bridge.leaking = true;
              addedItem.bridge.worn = false;
              addedItem.bridge.fractured = false;
              addedItem.bridge.discolored = false;
              addedItem.bridge.unaesthetic = false;
            }
            if (this.selectedStatus === 8) {
              addedItem.bridge.ok = false;
              addedItem.bridge.leaking = false;
              addedItem.bridge.worn = true;
              addedItem.bridge.fractured = false;
              addedItem.bridge.discolored = false;
              addedItem.bridge.unaesthetic = false;
            }
            if (this.selectedStatus === 9) {
              addedItem.bridge.ok = false;
              addedItem.bridge.leaking = false;
              addedItem.bridge.worn = false;
              addedItem.bridge.fractured = true;
              addedItem.bridge.discolored = false;
              addedItem.bridge.unaesthetic = false;
            }
            if (this.selectedStatus === 10) {
              addedItem.bridge.ok = false;
              addedItem.bridge.leaking = false;
              addedItem.bridge.worn = false;
              addedItem.bridge.fractured = false;
              addedItem.bridge.discolored = true;
              addedItem.bridge.unaesthetic = false;
            }
            if (this.selectedStatus === 11) {
              addedItem.bridge.ok = false;
              addedItem.bridge.leaking = false;
              addedItem.bridge.worn = false;
              addedItem.bridge.fractured = false;
              addedItem.bridge.discolored = false;
              addedItem.bridge.unaesthetic = true;
            }
          }
          if (this.selectedCondition === 31) {
            if (this.selectedStatus === 3) {
              addedItem.denture.ok = true;
              addedItem.denture.leaking = false;
              addedItem.denture.worn = false;
              addedItem.denture.fractured = false;
              addedItem.denture.discolored = false;
              addedItem.denture.unaesthetic = false;
            }
            if (this.selectedStatus === 7) {
              addedItem.denture.ok = false;
              addedItem.denture.leaking = true;
              addedItem.denture.worn = false;
              addedItem.denture.fractured = false;
              addedItem.denture.discolored = false;
              addedItem.denture.unaesthetic = false;
            }
            if (this.selectedStatus === 8) {
              addedItem.denture.ok = false;
              addedItem.denture.leaking = false;
              addedItem.denture.worn = true;
              addedItem.denture.fractured = false;
              addedItem.denture.discolored = false;
              addedItem.denture.unaesthetic = false;
            }
            if (this.selectedStatus === 9) {
              addedItem.denture.ok = false;
              addedItem.denture.leaking = false;
              addedItem.denture.worn = false;
              addedItem.denture.fractured = true;
              addedItem.denture.discolored = false;
              addedItem.denture.unaesthetic = false;
            }
            if (this.selectedStatus === 10) {
              addedItem.denture.ok = false;
              addedItem.denture.leaking = false;
              addedItem.denture.worn = false;
              addedItem.denture.fractured = false;
              addedItem.denture.discolored = true;
              addedItem.denture.unaesthetic = false;
            }
            if (this.selectedStatus === 11) {
              addedItem.denture.ok = false;
              addedItem.denture.leaking = false;
              addedItem.denture.worn = false;
              addedItem.denture.fractured = false;
              addedItem.denture.discolored = false;
              addedItem.denture.unaesthetic = true;
            }
          }
        }
        this.odontogram.push(this.cloneOdontogram(addedItem));
      } else this.odontogram.push(this.cloneOdontogram(addedItem));
      if (j === 18) j = 20;
      if (j === 29) j = 30;
      if (j === 39) j = 40;
    } //buradan sonrası backend den gelen datayı ekrana yazdırıyor
    this.loadedCount = 0;
    if (this.selectedPlan.id !== undefined) {
      this.selectedPlan.conditions.forEach((condition) => {
        this.loadedCount += 1;
        if (condition.conditionType === 1) {
          condition.teeths.forEach((teeth) => {
            condition.toothSurfaces.forEach((surface) => {
              if (surface === 1) {
                let updatedTeeth = this.odontogram.find(
                  (x) => x.name.substr(-2) === teeth.toString()
                );
                updatedTeeth.carries.buccal = true;
              }
              if (surface === 2) {
                let updatedTeeth = this.odontogram.find(
                  (x) => x.name.substr(-2) === teeth.toString()
                );
                updatedTeeth.carries.distal = true;
              }
              if (surface === 3) {
                let updatedTeeth = this.odontogram.find(
                  (x) => x.name.substr(-2) === teeth.toString()
                );
                updatedTeeth.carries.lingual = true;
              }
              if (surface === 4) {
                let updatedTeeth = this.odontogram.find(
                  (x) => x.name.substr(-2) === teeth.toString()
                );
                updatedTeeth.carries.mesial = true;
              }
              if (surface === 5) {
                let updatedTeeth = this.odontogram.find(
                  (x) => x.name.substr(-2) === teeth.toString()
                );
                updatedTeeth.carries.occlusal = true;
              }
              let updatedOdontogram = this.odontogram.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram = updatedOdontogram;
              });
            });
          });
        }
        if (condition.conditionType === 2) {
          condition.teeths.forEach((teeth) => {
            if (condition.locations === 1) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.fracture.root = true;
            }
            if (condition.locations === 2) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.fracture.crown = true;
            }
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 3) {
          condition.teeths.forEach((teeth) => {
            let updatedTeeth = this.odontogram.find(
              (x) => x.name.substr(-2) === teeth.toString()
            );
            updatedTeeth.severelyDamaged = true;
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }

        if (condition.conditionType === 4) {
          condition.teeths.forEach((teeth) => {
            if (condition.degree === 1) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.wear.mild = true;
            }
            if (condition.degree === 2) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.wear.moderate = true;
            }
            if (condition.degree === 3) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.wear.severe = true;
            }
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 7) {
          condition.teeths.forEach((teeth) => {
            if (condition.degree === 1) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.gingivalRecession.mild = true;
              updatedTeeth.gengivaDefault = false;
              updatedTeeth.boneDefault = false;
            }
            if (condition.degree === 2) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.gingivalRecession.moderate = true;
              updatedTeeth.gengivaDefault = false;
              updatedTeeth.boneDefault = false;
            }
            if (condition.degree === 3) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.gingivalRecession.severe = true;
              updatedTeeth.gengivaDefault = false;
              updatedTeeth.boneDefault = false;
            }
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 8) {
          condition.teeths.forEach((teeth) => {
            if (condition.degree === 1) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.periodontitis.mild = true;
              updatedTeeth.boneDefault = false;
            }
            if (condition.degree === 2) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.periodontitis.moderate = true;
              updatedTeeth.boneDefault = false;
            }
            if (condition.degree === 3) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.periodontitis.severe = true;
              updatedTeeth.boneDefault = false;
            }
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 10) {
          condition.teeths.forEach((teeth) => {
            let updatedTeeth = this.odontogram.find(
              (x) => x.name.substr(-2) === teeth.toString()
            );
            updatedTeeth.gummySmile = true;
            updatedTeeth.gengivaDefault = false;
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 11) {
          condition.teeths.forEach((teeth) => {
            let updatedTeeth = this.odontogram.find(
              (x) => x.name.substr(-2) === teeth.toString()
            );
            updatedTeeth.gingivalOvergrowth = true;
            updatedTeeth.gengivaDefault = false;
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 12) {
          condition.teeths.forEach((teeth) => {
            let updatedTeeth = this.odontogram.find(
              (x) => x.name.substr(-2) === teeth.toString()
            );
            updatedTeeth.largeSinus = true;
            updatedTeeth.boneLineDefault = false;
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 13) {
          condition.teeths.forEach((teeth) => {
            let updatedTeeth = this.odontogram.find(
              (x) => x.name.substr(-2) === teeth.toString()
            );
            updatedTeeth.necrosis = true;
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 14) {
          condition.teeths.forEach((teeth) => {
            if (condition.status === 1) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.rootCanalTreatment.satisfactory = true;
            }
            if (condition.status === 2) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.rootCanalTreatment.unSatisfactory = true;
            }
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 15) {
          condition.teeths.forEach((teeth) => {
            if (condition.degree === 1) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.apicalLesion.mild = true;
            }
            if (condition.degree === 2) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.apicalLesion.moderate = true;
            }
            if (condition.degree === 3) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.apicalLesion.severe = true;
            }
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 16) {
          condition.teeths.forEach((teeth) => {
            let updatedTeeth = this.odontogram.find(
              (x) => x.name.substr(-2) === teeth.toString()
            );
            updatedTeeth.brokenInstrumentInCanal = true;
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 17) {
          condition.teeths.forEach((teeth) => {
            if (condition.locations === 3) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.rootResorption.external = true;
            }
            if (condition.locations === 4) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.rootResorption.internal = true;
            }
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 18) {
          condition.teeths.forEach((teeth) => {
            let updatedTeeth = this.odontogram.find(
              (x) => x.name.substr(-2) === teeth.toString()
            );
            updatedTeeth.missingTeeth = true;
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 19) {
          condition.teeths.forEach((teeth) => {
            if (condition.status === 3) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.implant.ok = true;
            }
            if (condition.status === 4) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.implant.malpositioned = true;
            }
            if (condition.status === 5) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.implant.withBoneLose = true;
            }
            if (condition.status === 6) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.implant.withGingivalRecession = true;
            }
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 20) {
          condition.teeths.forEach((teeth) => {
            let updatedTeeth = this.odontogram.find(
              (x) => x.name.substr(-2) === teeth.toString()
            );
            updatedTeeth.impacted = true;
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 21) {
          condition.teeths.forEach((teeth) => {
            let updatedTeeth = this.odontogram.find(
              (x) => x.name.substr(-2) === teeth.toString()
            );
            updatedTeeth.impactedInfected = true;
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 22) {
          condition.teeths.forEach((teeth) => {
            let updatedTeeth = this.odontogram.find(
              (x) => x.name.substr(-2) === teeth.toString()
            );
            updatedTeeth.largeSinus2 = true;
            updatedTeeth.boneLineDefault = false;
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 23) {
          condition.teeths.forEach((teeth) => {
            condition.toothSurfaces.forEach((surface) => {
              if (surface === 1) {
                let updatedTeeth = this.odontogram.find(
                  (x) => x.name.substr(-2) === teeth.toString()
                );
                updatedTeeth.restoration.buccal = true;
              }
              if (surface === 2) {
                let updatedTeeth = this.odontogram.find(
                  (x) => x.name.substr(-2) === teeth.toString()
                );
                updatedTeeth.restoration.distal = true;
              }
              if (surface === 3) {
                let updatedTeeth = this.odontogram.find(
                  (x) => x.name.substr(-2) === teeth.toString()
                );
                updatedTeeth.restoration.lingual = true;
              }
              if (surface === 4) {
                let updatedTeeth = this.odontogram.find(
                  (x) => x.name.substr(-2) === teeth.toString()
                );
                updatedTeeth.restoration.mesial = true;
              }
              if (surface === 5) {
                let updatedTeeth = this.odontogram.find(
                  (x) => x.name.substr(-2) === teeth.toString()
                );
                updatedTeeth.restoration.occlusal = true;
              }
              let updatedOdontogram = this.odontogram.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram = updatedOdontogram;
              });
            });
            if (condition.status === 3) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.restoration.ok = true;
            }
            if (condition.status === 7) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.restoration.leaking = true;
            }
            if (condition.status === 8) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.restoration.worn = true;
            }
            if (condition.status === 9) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.restoration.fractured = true;
            }
            if (condition.status === 10) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.restoration.discolored = true;
            }
            if (condition.status === 11) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.restoration.unaesthetic = true;
            }
          });
        }
        if (condition.conditionType === 24) {
          condition.teeths.forEach((teeth) => {
            if (condition.status === 1) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.post.ok = true;
              updatedTeeth.post.unSatisfactory = false;
            }
            if (condition.status === 2) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.post.unSatisfactory = true;
              updatedTeeth.post.ok = false;
            }
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 25) {
          condition.teeths.forEach((teeth) => {
            if (condition.status === 1) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.splint.ok = true;
              updatedTeeth.splint.unSatisfactory = false;
            }
            if (condition.status === 2) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.splint.unSatisfactory = true;
              updatedTeeth.splint.ok = false;
            }
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 26) {
          condition.teeths.forEach((teeth) => {
            let updatedTeeth = this.odontogram.find(
              (x) => x.name.substr(-2) === teeth.toString()
            );
            updatedTeeth.disColored = true;
            let updatedOdontogram = this.odontogram.filter(
              (z) => z.name !== teeth.name
            );
            runInAction(() => {
              this.odontogram = updatedOdontogram;
            });
          });
        }
        if (condition.conditionType === 27) {
          condition.teeths.forEach((teeth) => {
            if (condition.status === 3) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.veneer.ok = true;
            }
            if (condition.status === 7) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.veneer.leaking = true;
            }
            if (condition.status === 8) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.veneer.worn = true;
            }
            if (condition.status === 9) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.veneer.fractured = true;
            }
            if (condition.status === 10) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.veneer.discolored = true;
            }
            if (condition.status === 11) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.veneer.unaesthetic = true;
            }
          });
        }
        if (condition.conditionType === 28) {
          condition.teeths.forEach((teeth) => {
            if (condition.status === 3) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.crown.ok = true;
            }
            if (condition.status === 7) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.crown.leaking = true;
            }
            if (condition.status === 8) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.crown.worn = true;
            }
            if (condition.status === 9) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.crown.fractured = true;
            }
            if (condition.status === 10) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.crown.discolored = true;
            }
            if (condition.status === 11) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.crown.unaesthetic = true;
            }
          });
        }
        if (condition.conditionType === 29) {
          condition.teeths.forEach((teeth) => {
            if (condition.status === 3) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.onlay.ok = true;
            }
            if (condition.status === 7) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.onlay.leaking = true;
            }
            if (condition.status === 8) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.onlay.worn = true;
            }
            if (condition.status === 9) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.onlay.fractured = true;
            }
            if (condition.status === 10) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.onlay.discolored = true;
            }
            if (condition.status === 11) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.onlay.unaesthetic = true;
            }
          });
        }
        if (condition.conditionType === 30) {
          condition.teeths.forEach((teeth) => {
            if (condition.status === 3) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.bridge.ok = true;
            }
            if (condition.status === 7) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.bridge.leaking = true;
            }
            if (condition.status === 8) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.bridge.worn = true;
            }
            if (condition.status === 9) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.bridge.fractured = true;
            }
            if (condition.status === 10) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.bridge.discolored = true;
            }
            if (condition.status === 11) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.bridge.unaesthetic = true;
            }
          });
        }
        if (condition.conditionType === 31) {
          condition.teeths.forEach((teeth) => {
            if (condition.status === 3) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.denture.ok = true;
            }
            if (condition.status === 7) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.denture.leaking = true;
            }
            if (condition.status === 8) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.denture.worn = true;
            }
            if (condition.status === 9) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.denture.fractured = true;
            }
            if (condition.status === 10) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.denture.discolored = true;
            }
            if (condition.status === 11) {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.denture.unaesthetic = true;
            }
          });
        }
      });
    }
  }

  createCondition = async (condition) => {
    condition.planId = this.selectedPlan.id;
    try {
      let result = await agent.Condition.create(condition);

      runInAction(() => {
        this.selectedPlan.conditions.push(result.data);
        this.resetForm = !this.resetForm;
        this.currentCondition = undefined;
        this.selectedTeeth = [];
        this.selectedCondition = undefined;
        this.selectedSurfaces = [];
      });
    } catch (error) {
      console.log(error);
    }
  };

  deleteCondition = async (id) => {
    try {
      await agent.Condition.delete(id);
      runInAction(() => {
        this.resetForm = !this.resetForm;
        this.selectedPlan.conditions = [
          ...this.selectedPlan.conditions.filter((a) => a.id !== id),
        ];
      });
    } catch (error) {
      console.log(error);
    }
  };
  selectTeeth(teeth) {
    let item = this.selectedTeeth.find((x) => x.name === teeth);
    item
      ? runInAction(() => {
          let testTeeth = this.selectedTeeth.filter((z) => z.name !== teeth);
          this.selectedTeeth = testTeeth;
          let teethNumber = teeth.split("h");
          teethNumber[1] = teethNumber[1] + ", ";
          this.currentCondition.Teeths = [
            ...this.currentCondition.Teeths.filter((a) => a !== teethNumber[1]),
          ];
        })
      : runInAction(() => {
          let addedItem = this.tooth;
          addedItem.name = teeth;
          this.selectedTeeth.push(this.cloneOdontogram(addedItem));
          let teethNumber = teeth.split("h");
          teethNumber[1] = teethNumber[1] + ", ";
          this.currentCondition.Teeths.push(teethNumber[1]);
        });

    this.odontogram.forEach((element) => {
      if (element.name === teeth && element.selected === "selected") {
        runInAction(() => {
          element.selected = "";
        });
      } else if (element.name === teeth) {
        runInAction(() => {
          element.selected = "selected";
        });
      }
    });
  }

  selectCarries = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "buccal") item.carries.buccal = checked;
          if (value === "distal") item.carries.distal = checked;
          if (value === "lingual") item.carries.lingual = checked;
          if (value === "mesial") item.carries.mesial = checked;
          if (value === "occlusal") item.carries.occlusal = checked;
        });
      }
    });
  };

  selectFracture = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "root") {
            item.fracture.root = checked;
            item.fracture.crown = !checked;
          }
          if (value === "crown") {
            item.fracture.crown = checked;
            item.fracture.root = !checked;
          }
        });
      }
    });
  };

  selectSeverelyDamaged = (value) => {
    this.selectCondition(value);
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.severelyDamaged = !item.severelyDamaged;
        });
      }
    });
  };

  selectWear = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "mild") {
            item.wear.mild = checked;
            item.wear.moderate = !checked;
            item.wear.severe = !checked;
          } else if (value === "moderate") {
            item.wear.mild = !checked;
            item.wear.moderate = checked;
            item.wear.severe = !checked;
          } else if (value === "severe") {
            item.wear.mild = !checked;
            item.wear.moderate = !checked;
            item.wear.severe = checked;
          }
        });
      }
    });
  };

  selectPlaqueHygiene = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "mild") {
            item.plaqueHygiene.mild = checked;
            item.plaqueHygiene.moderate = !checked;
            item.plaqueHygiene.severe = !checked;
          } else if (value === "moderate") {
            item.plaqueHygiene.mild = !checked;
            item.plaqueHygiene.moderate = checked;
            item.plaqueHygiene.severe = !checked;
          } else if (value === "severe") {
            item.plaqueHygiene.mild = !checked;
            item.plaqueHygiene.moderate = !checked;
            item.plaqueHygiene.severe = checked;
          }
        });
      }
    });
  };

  selectGingivitis = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "mild") {
            item.gingivitis.mild = checked;
            item.gingivitis.moderate = !checked;
            item.gingivitis.severe = !checked;
          } else if (value === "moderate") {
            item.gingivitis.mild = !checked;
            item.gingivitis.moderate = checked;
            item.gingivitis.severe = !checked;
          } else if (value === "severe") {
            item.gingivitis.mild = !checked;
            item.gingivitis.moderate = !checked;
            item.gingivitis.severe = checked;
          }
        });
      }
    });
  };

  selectGingivalRecession = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.gengivaDefault = false;
          item.boneDefault = false;
          if (value === "mild") {
            item.gingivalRecession.mild = checked;
            item.gingivalRecession.moderate = !checked;
            item.gingivalRecession.severe = !checked;
          } else if (value === "moderate") {
            item.gingivalRecession.mild = !checked;
            item.gingivalRecession.moderate = checked;
            item.gingivalRecession.severe = !checked;
          } else if (value === "severe") {
            item.gingivalRecession.mild = !checked;
            item.gingivalRecession.moderate = !checked;
            item.gingivalRecession.severe = checked;
          }
        });
      }
    });
  };
  selectPeriodontitis = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.boneDefault = false;
          if (value === "mild") {
            item.periodontitis.mild = checked;
            item.periodontitis.moderate = !checked;
            item.periodontitis.severe = !checked;
          } else if (value === "moderate") {
            item.periodontitis.mild = !checked;
            item.periodontitis.moderate = checked;
            item.periodontitis.severe = !checked;
          } else if (value === "severe") {
            item.periodontitis.mild = !checked;
            item.periodontitis.moderate = !checked;
            item.periodontitis.severe = checked;
          }
        });
      }
    });
  };

  selectMobility = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "mild") {
            item.mobility.mild = checked;
            item.mobility.moderate = !checked;
            item.mobility.severe = !checked;
          } else if (value === "moderate") {
            item.mobility.mild = !checked;
            item.mobility.moderate = checked;
            item.mobility.severe = !checked;
          } else if (value === "severe") {
            item.mobility.mild = !checked;
            item.mobility.moderate = !checked;
            item.mobility.severe = checked;
          }
        });
      }
    });
  };

  selectGummySmile = (value) => {
    this.selectCondition(value);
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.gengivaDefault = !item.gengivaDefault;
          item.gummySmile = !item.gummySmile;
        });
      }
    });
  };
  selectGingivalOvergrowth = (value) => {
    this.selectCondition(value);
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.gengivaDefault = !item.gengivaDefault;
          item.gingivalOvergrowth = !item.gingivalOvergrowth;
        });
      }
    });
  };

  selectLargeSinus = (value) => {
    this.selectCondition(value);
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.boneLineDefault = !item.boneLineDefault;
          item.largeSinus = !item.largeSinus;
        });
      }
    });
  };
  selectNecrosis = (value) => {
    this.selectCondition(value);
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.necrosis = !item.necrosis;
        });
      }
    });
  };

  selectRootCanalTreatment = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "satisfactory") {
            item.rootCanalTreatment.satisfactory = checked;
            item.rootCanalTreatment.unSatisfactory = !checked;
          }
          if (value === "unsatisfactory") {
            item.rootCanalTreatment.satisfactory = !checked;
            item.rootCanalTreatment.unSatisfactory = checked;
          }
        });
      }
    });
  };

  selectApicalLesion = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "mild") {
            item.apicalLesion.mild = checked;
            item.apicalLesion.moderate = !checked;
            item.apicalLesion.severe = !checked;
          } else if (value === "moderate") {
            item.apicalLesion.mild = !checked;
            item.apicalLesion.moderate = checked;
            item.apicalLesion.severe = !checked;
          } else if (value === "severe") {
            item.apicalLesion.mild = !checked;
            item.apicalLesion.moderate = !checked;
            item.apicalLesion.severe = checked;
          }
        });
      }
    });
  };

  selectBrokenInstrumentInCanal = (value) => {
    this.selectCondition(value);
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.brokenInstrumentInCanal = !item.brokenInstrumentInCanal;
        });
      }
    });
  };

  selectRootResorption = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "external") item.rootResorption.external = checked;
          if (value === "internal") item.rootResorption.internal = checked;
        });
      }
    });
  };

  selectMissingTeeth = (value) => {
    this.selectCondition(value);
    console.log("fonksiyon");
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.missingTeeth = !item.missingTeeth;
        });
      }
    });
  };

  selectImplant = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "ok") item.implant.ok = checked;
          if (value === "malpositioned") item.implant.malpositioned = checked;
          if (value === "withBoneLose") item.implant.withBoneLose = checked;
          if (value === "withGingivalRecession")
            item.implant.withGingivalRecession = checked;
        });
      }
    });
  };

  selectImpacted = (value) => {
    this.selectCondition(value);
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.impacted = !item.impacted;
        });
      }
    });
  };

  selectImpactedInfected = (value) => {
    this.selectCondition(value);
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.impactedInfected = !item.impactedInfected;
        });
      }
    });
  };

  selectLargeSinus2 = (value) => {
    this.selectCondition(value);
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.boneLineDefault = !item.boneLineDefault;
          item.largeSinus2 = !item.largeSinus2;
        });
      }
    });
  };

  selectRestoration = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "buccal") item.restoration.buccal = checked;
          if (value === "distal") item.restoration.distal = checked;
          if (value === "lingual") item.restoration.lingual = checked;
          if (value === "mesial") item.restoration.mesial = checked;
          if (value === "occlusal") item.restoration.occlusal = checked;
          if (value === "ok") item.restoration.ok = checked;
          if (value === "leaking") item.restoration.leaking = checked;
          if (value === "worn") item.restoration.worn = checked;
          if (value === "fractured") item.restoration.fractured = checked;
          if (value === "discolored") item.restoration.discolored = checked;
          if (value === "unaesthetic") item.restoration.unaesthetic = checked;
        });
      }
    });
  };

  selectPost = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "ok") {
            item.post.ok = checked;
            item.post.unsatisfactory = !checked;
          }
          if (value === "unSatisfactory") {
            item.post.ok = !checked;
            item.post.unSatisfactory = checked;
          }
        });
      }
    });
  };

  selectSplint = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "ok") {
            item.splint.ok = checked;
            item.splint.unSatisfactory = !checked;
          }
          if (value === "unSatisfactory") {
            item.splint.ok = !checked;
            item.splint.unSatisfactory = checked;
          }
        });
      }
    });
  };

  selectDisColored = (value) => {
    this.selectCondition(value);
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.disColored = !item.disColored;
        });
      }
    });
  };

  selectVeneer = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "ok") item.veneer.ok = checked;
          if (value === "leaking") item.veneer.leaking = checked;
          if (value === "worn") item.veneer.worn = checked;
          if (value === "fractured") item.veneer.fractured = checked;
          if (value === "discolored") item.veneer.discolored = checked;
          if (value === "unaesthetic") item.veneer.unaesthetic = checked;
        });
      }
    });
  };

  selectCrown = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "ok") item.crown.ok = checked;
          if (value === "leaking") item.crown.leaking = checked;
          if (value === "worn") item.crown.worn = checked;
          if (value === "fractured") item.crown.fractured = checked;
          if (value === "discolored") item.crown.discolored = checked;
          if (value === "unaesthetic") item.crown.unaesthetic = checked;
        });
      }
    });
  };

  selectOnlay = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "ok") item.onlay.ok = checked;
          if (value === "leaking") item.onlay.leaking = checked;
          if (value === "worn") item.onlay.worn = checked;
          if (value === "fractured") item.onlay.fractured = checked;
          if (value === "discolored") item.onlay.discolored = checked;
          if (value === "unaesthetic") item.onlay.unaesthetic = checked;
        });
      }
    });
  };

  selectBridge = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "ok") item.bridge.ok = checked;
          if (value === "leaking") item.bridge.leaking = checked;
          if (value === "worn") item.bridge.worn = checked;
          if (value === "fractured") item.bridge.fractured = checked;
          if (value === "discolored") item.bridge.discolored = checked;
          if (value === "unaesthetic") item.bridge.unaesthetic = checked;
        });
      }
    });
  };

  selectDenture = (value, checked) => {
    this.odontogram.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "ok") item.denture.ok = checked;
          if (value === "leaking") item.denture.leaking = checked;
          if (value === "worn") item.denture.worn = checked;
          if (value === "fractured") item.denture.fractured = checked;
          if (value === "discolored") item.denture.discolored = checked;
          if (value === "unaesthetic") item.denture.unaesthetic = checked;
        });
      }
    });
  };

  cloneOdontogram(item) {
    var clone = {};
    for (var key in item) {
      if (item.hasOwnProperty(key))
        //ensure not adding inherited props
        clone[key] = item[key];
    }
    return clone;
  }
}
