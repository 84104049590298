import "./profilemenu.css"
export default function ProfileMenu() {
    return (
        <div className='profileMenuContainer'>
            <div className="col-md-12">
                <div className="row">
                    <div className="modal fade" id="profileMenuModal" tabIndex="-1" aria-labelledby="profileMenuModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="profileMenuModalLabel">Profil Settings</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Full Name</label>
                                            <input type="text" className='form-control' placeholder='Full Name' /><br />
                                            <label>Email</label>
                                            <input type="email" className='form-control' placeholder='Email' />
                                        </div>
                                        <div className="col-md-6">
                                            <label>User Name</label>
                                            <input type="text" className='form-control' placeholder='User Name' /><br />
                                            <label>Password</label>
                                            <input type="password" className='form-control' placeholder='Password' />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary">Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
