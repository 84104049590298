import React from 'react'
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import {Button,Spinner} from "react-bootstrap"


export default observer(function DoctorShowDays() {
    const { drStore } = useStore();
    const {selectedDoctor} = drStore;

    if (drStore.selectedDoctor === undefined) {
        return (
          <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Doktor Listesi Yükleniyor...
          </Button>
        );
      }
      const doctor = selectedDoctor;

  return (
    <div>
            <tr>
                        <td className="doctorWorkDays">
                          {doctor.sunday === true
                            ? `Pazartesi
                        ${doctor.sundayStart === 1 ? ": 00:00" : ""}
                        ${doctor.sundayStart === 2 ? ": 00:30" : ""}
                        ${doctor.sundayStart === 3 ? ": 01:00" : ""}
                        ${doctor.sundayStart === 4 ? ": 01:30" : ""}
                        ${doctor.sundayStart === 5 ? ": 02:00" : ""}
                        ${doctor.sundayStart === 6 ? ": 02:30" : ""}
                        ${doctor.sundayStart === 7 ? ": 03:00" : ""}
                        ${doctor.sundayStart === 8 ? ": 03:30" : ""}
                        ${doctor.sundayStart === 9 ? ": 04:00" : ""}
                        ${doctor.sundayStart === 10 ? ": 04:30" : ""}
                        ${doctor.sundayStart === 11 ? ": 05:00" : ""}
                        ${doctor.sundayStart === 12 ? ": 05:30" : ""}
                        ${doctor.sundayStart === 13 ? ": 06:00" : ""}
                        ${doctor.sundayStart === 14 ? ": 06:30" : ""}
                        ${doctor.sundayStart === 15 ? ": 07:00" : ""}
                        ${doctor.sundayStart === 16 ? ": 07:30" : ""}
                        ${doctor.sundayStart === 17 ? ": 08:00" : ""}
                        ${doctor.sundayStart === 18 ? ": 08:30" : ""}
                        ${doctor.sundayStart === 19 ? ": 09:00" : ""}
                        ${doctor.sundayStart === 20 ? ": 09:30" : ""}
                        ${doctor.sundayStart === 21 ? ": 10:00" : ""}
                        ${doctor.sundayStart === 22 ? ": 10:30" : ""}
                        ${doctor.sundayStart === 23 ? ": 11:00" : ""}
                        ${doctor.sundayStart === 24 ? ": 11:30" : ""}
                        ${doctor.sundayStart === 25 ? ": 12:00" : ""}
                        ${doctor.sundayStart === 26 ? ": 12:30" : ""}
                        ${doctor.sundayStart === 27 ? ": 13:00" : ""}
                        ${doctor.sundayStart === 28 ? ": 13:30" : ""}
                        ${doctor.sundayStart === 29 ? ": 14:00" : ""}
                        ${doctor.sundayStart === 30 ? ": 14:30" : ""}
                        ${doctor.sundayStart === 31 ? ": 15:00" : ""}
                        ${doctor.sundayStart === 32 ? ": 15:30" : ""}
                        ${doctor.sundayStart === 33 ? ": 16:00" : ""}
                        ${doctor.sundayStart === 34 ? ": 16:30" : ""}
                        ${doctor.sundayStart === 35 ? ": 17:00" : ""}
                        ${doctor.sundayStart === 36 ? ": 17:30" : ""}
                        ${doctor.sundayStart === 37 ? ": 18:00" : ""}
                        ${doctor.sundayStart === 38 ? ": 18:30" : ""}
                        ${doctor.sundayStart === 39 ? ": 19:00" : ""}
                        ${doctor.sundayStart === 40 ? ": 19:30" : ""}
                        ${doctor.sundayStart === 41 ? ": 20:00" : ""}
                        ${doctor.sundayStart === 42 ? ": 20:30" : ""}
                        ${doctor.sundayStart === 43 ? ": 21:00" : ""}
                        ${doctor.sundayStart === 44 ? ": 21:30" : ""}
                        ${doctor.sundayStart === 45 ? ": 22:00" : ""}
                        ${doctor.sundayStart === 46 ? ": 22:30" : ""}
                        ${doctor.sundayStart === 47 ? ": 23:00" : ""}
                        ${doctor.sundayStart === 48 ? ": 23:30" : ""}
                        -
                        ${doctor.sundayEnd === 1 ? "00:00" : ""}
                        ${doctor.sundayEnd === 2 ? "00:30" : ""}
                        ${doctor.sundayEnd === 3 ? "01:00" : ""}
                        ${doctor.sundayEnd === 4 ? "01:30" : ""}
                        ${doctor.sundayEnd === 5 ? "02:00" : ""}
                        ${doctor.sundayEnd === 6 ? "02:30" : ""}
                        ${doctor.sundayEnd === 7 ? "03:00" : ""}
                        ${doctor.sundayEnd === 8 ? "03:30" : ""}
                        ${doctor.sundayEnd === 9 ? "04:00" : ""}
                        ${doctor.sundayEnd === 10 ? "04:30" : ""}
                        ${doctor.sundayEnd === 11 ? "05:00" : ""}
                        ${doctor.sundayEnd === 12 ? "05:30" : ""}
                        ${doctor.sundayEnd === 13 ? "06:00" : ""}
                        ${doctor.sundayEnd === 14 ? "06:30" : ""}
                        ${doctor.sundayEnd === 15 ? "07:00" : ""}
                        ${doctor.sundayEnd === 16 ? "07:30" : ""}
                        ${doctor.sundayEnd === 17 ? "08:00" : ""}
                        ${doctor.sundayEnd === 18 ? "08:30" : ""}
                        ${doctor.sundayEnd === 19 ? "09:00" : ""}
                        ${doctor.sundayEnd === 20 ? "09:30" : ""}
                        ${doctor.sundayEnd === 21 ? "10:00" : ""}
                        ${doctor.sundayEnd === 22 ? "10:30" : ""}
                        ${doctor.sundayEnd === 23 ? "11:00" : ""}
                        ${doctor.sundayEnd === 24 ? "11:30" : ""}
                        ${doctor.sundayEnd === 25 ? "12:00" : ""}
                        ${doctor.sundayEnd === 26 ? "12:30" : ""}
                        ${doctor.sundayEnd === 27 ? "13:00" : ""}
                        ${doctor.sundayEnd === 28 ? "13:30" : ""}
                        ${doctor.sundayEnd === 29 ? "14:00" : ""}
                        ${doctor.sundayEnd === 30 ? "14:30" : ""}
                        ${doctor.sundayEnd === 31 ? "15:00" : ""}
                        ${doctor.sundayEnd === 32 ? "15:30" : ""}
                        ${doctor.sundayEnd === 33 ? "16:00" : ""}
                        ${doctor.sundayEnd === 34 ? "16:30" : ""}
                        ${doctor.sundayEnd === 35 ? "17:00" : ""}
                        ${doctor.sundayEnd === 36 ? "17:30" : ""}
                        ${doctor.sundayEnd === 37 ? "18:00" : ""}
                        ${doctor.sundayEnd === 38 ? "18:30" : ""}
                        ${doctor.sundayEnd === 39 ? "19:00" : ""}
                        ${doctor.sundayEnd === 40 ? "19:30" : ""}
                        ${doctor.sundayEnd === 41 ? "20:00" : ""}
                        ${doctor.sundayEnd === 42 ? "20:30" : ""}
                        ${doctor.sundayEnd === 43 ? "21:00" : ""}
                        ${doctor.sundayEnd === 44 ? "21:30" : ""}
                        ${doctor.sundayEnd === 45 ? "22:00" : ""}
                        ${doctor.sundayEnd === 46 ? "22:30" : ""}
                        ${doctor.sundayEnd === 47 ? "23:00" : ""}
                        ${doctor.sundayEnd === 48 ? "23:30" : ""}
                          `
                            : ""}
                        </td>
                        <td className="doctorWorkDays">
                          {doctor.tuesday === true
                            ? `Salı
                        ${doctor.tuesdayStart === 1 ? ": 00:00" : ""}
                        ${doctor.tuesdayStart === 2 ? ": 00:30" : ""}
                        ${doctor.tuesdayStart === 3 ? ": 01:00" : ""}
                        ${doctor.tuesdayStart === 4 ? ": 01:30" : ""}
                        ${doctor.tuesdayStart === 5 ? ": 02:00" : ""}
                        ${doctor.tuesdayStart === 6 ? ": 02:30" : ""}
                        ${doctor.tuesdayStart === 7 ? ": 03:00" : ""}
                        ${doctor.tuesdayStart === 8 ? ": 03:30" : ""}
                        ${doctor.tuesdayStart === 9 ? ": 04:00" : ""}
                        ${doctor.tuesdayStart === 10 ? ": 04:30" : ""}
                        ${doctor.tuesdayStart === 11 ? ": 05:00" : ""}
                        ${doctor.tuesdayStart === 12 ? ": 05:30" : ""}
                        ${doctor.tuesdayStart === 13 ? ": 06:00" : ""}
                        ${doctor.tuesdayStart === 14 ? ": 06:30" : ""}
                        ${doctor.tuesdayStart === 15 ? ": 07:00" : ""}
                        ${doctor.tuesdayStart === 16 ? ": 07:30" : ""}
                        ${doctor.tuesdayStart === 17 ? ": 08:00" : ""}
                        ${doctor.tuesdayStart === 18 ? ": 08:30" : ""}
                        ${doctor.tuesdayStart === 19 ? ": 09:00" : ""}
                        ${doctor.tuesdayStart === 20 ? ": 09:30" : ""}
                        ${doctor.tuesdayStart === 21 ? ": 10:00" : ""}
                        ${doctor.tuesdayStart === 22 ? ": 10:30" : ""}
                        ${doctor.tuesdayStart === 23 ? ": 11:00" : ""}
                        ${doctor.tuesdayStart === 24 ? ": 11:30" : ""}
                        ${doctor.tuesdayStart === 25 ? ": 12:00" : ""}
                        ${doctor.tuesdayStart === 26 ? ": 12:30" : ""}
                        ${doctor.tuesdayStart === 27 ? ": 13:00" : ""}
                        ${doctor.tuesdayStart === 28 ? ": 13:30" : ""}
                        ${doctor.tuesdayStart === 29 ? ": 14:00" : ""}
                        ${doctor.tuesdayStart === 30 ? ": 14:30" : ""}
                        ${doctor.tuesdayStart === 31 ? ": 15:00" : ""}
                        ${doctor.tuesdayStart === 32 ? ": 15:30" : ""}
                        ${doctor.tuesdayStart === 33 ? ": 16:00" : ""}
                        ${doctor.tuesdayStart === 34 ? ": 16:30" : ""}
                        ${doctor.tuesdayStart === 35 ? ": 17:00" : ""}
                        ${doctor.tuesdayStart === 36 ? ": 17:30" : ""}
                        ${doctor.tuesdayStart === 37 ? ": 18:00" : ""}
                        ${doctor.tuesdayStart === 38 ? ": 18:30" : ""}
                        ${doctor.tuesdayStart === 39 ? ": 19:00" : ""}
                        ${doctor.tuesdayStart === 40 ? ": 19:30" : ""}
                        ${doctor.tuesdayStart === 41 ? ": 20:00" : ""}
                        ${doctor.tuesdayStart === 42 ? ": 20:30" : ""}
                        ${doctor.tuesdayStart === 43 ? ": 21:00" : ""}
                        ${doctor.tuesdayStart === 44 ? ": 21:30" : ""}
                        ${doctor.tuesdayStart === 45 ? ": 22:00" : ""}
                        ${doctor.tuesdayStart === 46 ? ": 22:30" : ""}
                        ${doctor.tuesdayStart === 47 ? ": 23:00" : ""}
                        ${doctor.tuesdayStart === 48 ? ": 23:30" : ""}
                        -
                        ${doctor.tuesdayEnd === 1 ? "00:00" : ""}
                        ${doctor.tuesdayEnd === 2 ? "00:30" : ""}
                        ${doctor.tuesdayEnd === 3 ? "01:00" : ""}
                        ${doctor.tuesdayEnd === 4 ? "01:30" : ""}
                        ${doctor.tuesdayEnd === 5 ? "02:00" : ""}
                        ${doctor.tuesdayEnd === 6 ? "02:30" : ""}
                        ${doctor.tuesdayEnd === 7 ? "03:00" : ""}
                        ${doctor.tuesdayEnd === 8 ? "03:30" : ""}
                        ${doctor.tuesdayEnd === 9 ? "04:00" : ""}
                        ${doctor.tuesdayEnd === 10 ? "04:30" : ""}
                        ${doctor.tuesdayEnd === 11 ? "05:00" : ""}
                        ${doctor.tuesdayEnd === 12 ? "05:30" : ""}
                        ${doctor.tuesdayEnd === 13 ? "06:00" : ""}
                        ${doctor.tuesdayEnd === 14 ? "06:30" : ""}
                        ${doctor.tuesdayEnd === 15 ? "07:00" : ""}
                        ${doctor.tuesdayEnd === 16 ? "07:30" : ""}
                        ${doctor.tuesdayEnd === 17 ? "08:00" : ""}
                        ${doctor.tuesdayEnd === 18 ? "08:30" : ""}
                        ${doctor.tuesdayEnd === 19 ? "09:00" : ""}
                        ${doctor.tuesdayEnd === 20 ? "09:30" : ""}
                        ${doctor.tuesdayEnd === 21 ? "10:00" : ""}
                        ${doctor.tuesdayEnd === 22 ? "10:30" : ""}
                        ${doctor.tuesdayEnd === 23 ? "11:00" : ""}
                        ${doctor.tuesdayEnd === 24 ? "11:30" : ""}
                        ${doctor.tuesdayEnd === 25 ? "12:00" : ""}
                        ${doctor.tuesdayEnd === 26 ? "12:30" : ""}
                        ${doctor.tuesdayEnd === 27 ? "13:00" : ""}
                        ${doctor.tuesdayEnd === 28 ? "13:30" : ""}
                        ${doctor.tuesdayEnd === 29 ? "14:00" : ""}
                        ${doctor.tuesdayEnd === 30 ? "14:30" : ""}
                        ${doctor.tuesdayEnd === 31 ? "15:00" : ""}
                        ${doctor.tuesdayEnd === 32 ? "15:30" : ""}
                        ${doctor.tuesdayEnd === 33 ? "16:00" : ""}
                        ${doctor.tuesdayEnd === 34 ? "16:30" : ""}
                        ${doctor.tuesdayEnd === 35 ? "17:00" : ""}
                        ${doctor.tuesdayEnd === 36 ? "17:30" : ""}
                        ${doctor.tuesdayEnd === 37 ? "18:00" : ""}
                        ${doctor.tuesdayEnd === 38 ? "18:30" : ""}
                        ${doctor.tuesdayEnd === 39 ? "19:00" : ""}
                        ${doctor.tuesdayEnd === 40 ? "19:30" : ""}
                        ${doctor.tuesdayEnd === 41 ? "20:00" : ""}
                        ${doctor.tuesdayEnd === 42 ? "20:30" : ""}
                        ${doctor.tuesdayEnd === 43 ? "21:00" : ""}
                        ${doctor.tuesdayEnd === 44 ? "21:30" : ""}
                        ${doctor.tuesdayEnd === 45 ? "22:00" : ""}
                        ${doctor.tuesdayEnd === 46 ? "22:30" : ""}
                        ${doctor.tuesdayEnd === 47 ? "23:00" : ""}
                        ${doctor.tuesdayEnd === 48 ? "23:30" : ""}
                          `
                            : ""}
                        </td>
                        <td className="doctorWorkDays">
                          {doctor.wednesday === true
                            ? `Çarşamba
                        ${doctor.wednesdayStart === 1 ? ": 00:00" : ""}
                        ${doctor.wednesdayStart === 2 ? ": 00:30" : ""}
                        ${doctor.wednesdayStart === 3 ? ": 01:00" : ""}
                        ${doctor.wednesdayStart === 4 ? ": 01:30" : ""}
                        ${doctor.wednesdayStart === 5 ? ": 02:00" : ""}
                        ${doctor.wednesdayStart === 6 ? ": 02:30" : ""}
                        ${doctor.wednesdayStart === 7 ? ": 03:00" : ""}
                        ${doctor.wednesdayStart === 8 ? ": 03:30" : ""}
                        ${doctor.wednesdayStart === 9 ? ": 04:00" : ""}
                        ${doctor.wednesdayStart === 10 ? ": 04:30" : ""}
                        ${doctor.wednesdayStart === 11 ? ": 05:00" : ""}
                        ${doctor.wednesdayStart === 12 ? ": 05:30" : ""}
                        ${doctor.wednesdayStart === 13 ? ": 06:00" : ""}
                        ${doctor.wednesdayStart === 14 ? ": 06:30" : ""}
                        ${doctor.wednesdayStart === 15 ? ": 07:00" : ""}
                        ${doctor.wednesdayStart === 16 ? ": 07:30" : ""}
                        ${doctor.wednesdayStart === 17 ? ": 08:00" : ""}
                        ${doctor.wednesdayStart === 18 ? ": 08:30" : ""}
                        ${doctor.wednesdayStart === 19 ? ": 09:00" : ""}
                        ${doctor.wednesdayStart === 20 ? ": 09:30" : ""}
                        ${doctor.wednesdayStart === 21 ? ": 10:00" : ""}
                        ${doctor.wednesdayStart === 22 ? ": 10:30" : ""}
                        ${doctor.wednesdayStart === 23 ? ": 11:00" : ""}
                        ${doctor.wednesdayStart === 24 ? ": 11:30" : ""}
                        ${doctor.wednesdayStart === 25 ? ": 12:00" : ""}
                        ${doctor.wednesdayStart === 26 ? ": 12:30" : ""}
                        ${doctor.wednesdayStart === 27 ? ": 13:00" : ""}
                        ${doctor.wednesdayStart === 28 ? ": 13:30" : ""}
                        ${doctor.wednesdayStart === 29 ? ": 14:00" : ""}
                        ${doctor.wednesdayStart === 30 ? ": 14:30" : ""}
                        ${doctor.wednesdayStart === 31 ? ": 15:00" : ""}
                        ${doctor.wednesdayStart === 32 ? ": 15:30" : ""}
                        ${doctor.wednesdayStart === 33 ? ": 16:00" : ""}
                        ${doctor.wednesdayStart === 34 ? ": 16:30" : ""}
                        ${doctor.wednesdayStart === 35 ? ": 17:00" : ""}
                        ${doctor.wednesdayStart === 36 ? ": 17:30" : ""}
                        ${doctor.wednesdayStart === 37 ? ": 18:00" : ""}
                        ${doctor.wednesdayStart === 38 ? ": 18:30" : ""}
                        ${doctor.wednesdayStart === 39 ? ": 19:00" : ""}
                        ${doctor.wednesdayStart === 40 ? ": 19:30" : ""}
                        ${doctor.wednesdayStart === 41 ? ": 20:00" : ""}
                        ${doctor.wednesdayStart === 42 ? ": 20:30" : ""}
                        ${doctor.wednesdayStart === 43 ? ": 21:00" : ""}
                        ${doctor.wednesdayStart === 44 ? ": 21:30" : ""}
                        ${doctor.wednesdayStart === 45 ? ": 22:00" : ""}
                        ${doctor.wednesdayStart === 46 ? ": 22:30" : ""}
                        ${doctor.wednesdayStart === 47 ? ": 23:00" : ""}
                        ${doctor.wednesdayStart === 48 ? ": 23:30" : ""}
                        -
                        ${doctor.wednesdayEnd === 1 ? "00:00" : ""}
                        ${doctor.wednesdayEnd === 2 ? "00:30" : ""}
                        ${doctor.wednesdayEnd === 3 ? "01:00" : ""}
                        ${doctor.wednesdayEnd === 4 ? "01:30" : ""}
                        ${doctor.wednesdayEnd === 5 ? "02:00" : ""}
                        ${doctor.wednesdayEnd === 6 ? "02:30" : ""}
                        ${doctor.wednesdayEnd === 7 ? "03:00" : ""}
                        ${doctor.wednesdayEnd === 8 ? "03:30" : ""}
                        ${doctor.wednesdayEnd === 9 ? "04:00" : ""}
                        ${doctor.wednesdayEnd === 10 ? "04:30" : ""}
                        ${doctor.wednesdayEnd === 11 ? "05:00" : ""}
                        ${doctor.wednesdayEnd === 12 ? "05:30" : ""}
                        ${doctor.wednesdayEnd === 13 ? "06:00" : ""}
                        ${doctor.wednesdayEnd === 14 ? "06:30" : ""}
                        ${doctor.wednesdayEnd === 15 ? "07:00" : ""}
                        ${doctor.wednesdayEnd === 16 ? "07:30" : ""}
                        ${doctor.wednesdayEnd === 17 ? "08:00" : ""}
                        ${doctor.wednesdayEnd === 18 ? "08:30" : ""}
                        ${doctor.wednesdayEnd === 19 ? "09:00" : ""}
                        ${doctor.wednesdayEnd === 20 ? "09:30" : ""}
                        ${doctor.wednesdayEnd === 21 ? "10:00" : ""}
                        ${doctor.wednesdayEnd === 22 ? "10:30" : ""}
                        ${doctor.wednesdayEnd === 23 ? "11:00" : ""}
                        ${doctor.wednesdayEnd === 24 ? "11:30" : ""}
                        ${doctor.wednesdayEnd === 25 ? "12:00" : ""}
                        ${doctor.wednesdayEnd === 26 ? "12:30" : ""}
                        ${doctor.wednesdayEnd === 27 ? "13:00" : ""}
                        ${doctor.wednesdayEnd === 28 ? "13:30" : ""}
                        ${doctor.wednesdayEnd === 29 ? "14:00" : ""}
                        ${doctor.wednesdayEnd === 30 ? "14:30" : ""}
                        ${doctor.wednesdayEnd === 31 ? "15:00" : ""}
                        ${doctor.wednesdayEnd === 32 ? "15:30" : ""}
                        ${doctor.wednesdayEnd === 33 ? "16:00" : ""}
                        ${doctor.wednesdayEnd === 34 ? "16:30" : ""}
                        ${doctor.wednesdayEnd === 35 ? "17:00" : ""}
                        ${doctor.wednesdayEnd === 36 ? "17:30" : ""}
                        ${doctor.wednesdayEnd === 37 ? "18:00" : ""}
                        ${doctor.wednesdayEnd === 38 ? "18:30" : ""}
                        ${doctor.wednesdayEnd === 39 ? "19:00" : ""}
                        ${doctor.wednesdayEnd === 40 ? "19:30" : ""}
                        ${doctor.wednesdayEnd === 41 ? "20:00" : ""}
                        ${doctor.wednesdayEnd === 42 ? "20:30" : ""}
                        ${doctor.wednesdayEnd === 43 ? "21:00" : ""}
                        ${doctor.wednesdayEnd === 44 ? "21:30" : ""}
                        ${doctor.wednesdayEnd === 45 ? "22:00" : ""}
                        ${doctor.wednesdayEnd === 46 ? "22:30" : ""}
                        ${doctor.wednesdayEnd === 47 ? "23:00" : ""}
                        ${doctor.wednesdayEnd === 48 ? "23:30" : ""}
                          `
                            : ""}
                        </td>
                        <td className="doctorWorkDays">
                          {doctor.thursday === true
                            ? `Perşembe
                        ${doctor.thursdayStart === 1 ? ": 00:00" : ""}
                        ${doctor.thursdayStart === 2 ? ": 00:30" : ""}
                        ${doctor.thursdayStart === 3 ? ": 01:00" : ""}
                        ${doctor.thursdayStart === 4 ? ": 01:30" : ""}
                        ${doctor.thursdayStart === 5 ? ": 02:00" : ""}
                        ${doctor.thursdayStart === 6 ? ": 02:30" : ""}
                        ${doctor.thursdayStart === 7 ? ": 03:00" : ""}
                        ${doctor.thursdayStart === 8 ? ": 03:30" : ""}
                        ${doctor.thursdayStart === 9 ? ": 04:00" : ""}
                        ${doctor.thursdayStart === 10 ? ": 04:30" : ""}
                        ${doctor.thursdayStart === 11 ? ": 05:00" : ""}
                        ${doctor.thursdayStart === 12 ? ": 05:30" : ""}
                        ${doctor.thursdayStart === 13 ? ": 06:00" : ""}
                        ${doctor.thursdayStart === 14 ? ": 06:30" : ""}
                        ${doctor.thursdayStart === 15 ? ": 07:00" : ""}
                        ${doctor.thursdayStart === 16 ? ": 07:30" : ""}
                        ${doctor.thursdayStart === 17 ? ": 08:00" : ""}
                        ${doctor.thursdayStart === 18 ? ": 08:30" : ""}
                        ${doctor.thursdayStart === 19 ? ": 09:00" : ""}
                        ${doctor.thursdayStart === 20 ? ": 09:30" : ""}
                        ${doctor.thursdayStart === 21 ? ": 10:00" : ""}
                        ${doctor.thursdayStart === 22 ? ": 10:30" : ""}
                        ${doctor.thursdayStart === 23 ? ": 11:00" : ""}
                        ${doctor.thursdayStart === 24 ? ": 11:30" : ""}
                        ${doctor.thursdayStart === 25 ? ": 12:00" : ""}
                        ${doctor.thursdayStart === 26 ? ": 12:30" : ""}
                        ${doctor.thursdayStart === 27 ? ": 13:00" : ""}
                        ${doctor.thursdayStart === 28 ? ": 13:30" : ""}
                        ${doctor.thursdayStart === 29 ? ": 14:00" : ""}
                        ${doctor.thursdayStart === 30 ? ": 14:30" : ""}
                        ${doctor.thursdayStart === 31 ? ": 15:00" : ""}
                        ${doctor.thursdayStart === 32 ? ": 15:30" : ""}
                        ${doctor.thursdayStart === 33 ? ": 16:00" : ""}
                        ${doctor.thursdayStart === 34 ? ": 16:30" : ""}
                        ${doctor.thursdayStart === 35 ? ": 17:00" : ""}
                        ${doctor.thursdayStart === 36 ? ": 17:30" : ""}
                        ${doctor.thursdayStart === 37 ? ": 18:00" : ""}
                        ${doctor.thursdayStart === 38 ? ": 18:30" : ""}
                        ${doctor.thursdayStart === 39 ? ": 19:00" : ""}
                        ${doctor.thursdayStart === 40 ? ": 19:30" : ""}
                        ${doctor.thursdayStart === 41 ? ": 20:00" : ""}
                        ${doctor.thursdayStart === 42 ? ": 20:30" : ""}
                        ${doctor.thursdayStart === 43 ? ": 21:00" : ""}
                        ${doctor.thursdayStart === 44 ? ": 21:30" : ""}
                        ${doctor.thursdayStart === 45 ? ": 22:00" : ""}
                        ${doctor.thursdayStart === 46 ? ": 22:30" : ""}
                        ${doctor.thursdayStart === 47 ? ": 23:00" : ""}
                        ${doctor.thursdayStart === 48 ? ": 23:30" : ""}
                        -
                        ${doctor.thursdayEnd === 1 ? "00:00" : ""}
                        ${doctor.thursdayEnd === 2 ? "00:30" : ""}
                        ${doctor.thursdayEnd === 3 ? "01:00" : ""}
                        ${doctor.thursdayEnd === 4 ? "01:30" : ""}
                        ${doctor.thursdayEnd === 5 ? "02:00" : ""}
                        ${doctor.thursdayEnd === 6 ? "02:30" : ""}
                        ${doctor.thursdayEnd === 7 ? "03:00" : ""}
                        ${doctor.thursdayEnd === 8 ? "03:30" : ""}
                        ${doctor.thursdayEnd === 9 ? "04:00" : ""}
                        ${doctor.thursdayEnd === 10 ? "04:30" : ""}
                        ${doctor.thursdayEnd === 11 ? "05:00" : ""}
                        ${doctor.thursdayEnd === 12 ? "05:30" : ""}
                        ${doctor.thursdayEnd === 13 ? "06:00" : ""}
                        ${doctor.thursdayEnd === 14 ? "06:30" : ""}
                        ${doctor.thursdayEnd === 15 ? "07:00" : ""}
                        ${doctor.thursdayEnd === 16 ? "07:30" : ""}
                        ${doctor.thursdayEnd === 17 ? "08:00" : ""}
                        ${doctor.thursdayEnd === 18 ? "08:30" : ""}
                        ${doctor.thursdayEnd === 19 ? "09:00" : ""}
                        ${doctor.thursdayEnd === 20 ? "09:30" : ""}
                        ${doctor.thursdayEnd === 21 ? "10:00" : ""}
                        ${doctor.thursdayEnd === 22 ? "10:30" : ""}
                        ${doctor.thursdayEnd === 23 ? "11:00" : ""}
                        ${doctor.thursdayEnd === 24 ? "11:30" : ""}
                        ${doctor.thursdayEnd === 25 ? "12:00" : ""}
                        ${doctor.thursdayEnd === 26 ? "12:30" : ""}
                        ${doctor.thursdayEnd === 27 ? "13:00" : ""}
                        ${doctor.thursdayEnd === 28 ? "13:30" : ""}
                        ${doctor.thursdayEnd === 29 ? "14:00" : ""}
                        ${doctor.thursdayEnd === 30 ? "14:30" : ""}
                        ${doctor.thursdayEnd === 31 ? "15:00" : ""}
                        ${doctor.thursdayEnd === 32 ? "15:30" : ""}
                        ${doctor.thursdayEnd === 33 ? "16:00" : ""}
                        ${doctor.thursdayEnd === 34 ? "16:30" : ""}
                        ${doctor.thursdayEnd === 35 ? "17:00" : ""}
                        ${doctor.thursdayEnd === 36 ? "17:30" : ""}
                        ${doctor.thursdayEnd === 37 ? "18:00" : ""}
                        ${doctor.thursdayEnd === 38 ? "18:30" : ""}
                        ${doctor.thursdayEnd === 39 ? "19:00" : ""}
                        ${doctor.thursdayEnd === 40 ? "19:30" : ""}
                        ${doctor.thursdayEnd === 41 ? "20:00" : ""}
                        ${doctor.thursdayEnd === 42 ? "20:30" : ""}
                        ${doctor.thursdayEnd === 43 ? "21:00" : ""}
                        ${doctor.thursdayEnd === 44 ? "21:30" : ""}
                        ${doctor.thursdayEnd === 45 ? "22:00" : ""}
                        ${doctor.thursdayEnd === 46 ? "22:30" : ""}
                        ${doctor.thursdayEnd === 47 ? "23:00" : ""}
                        ${doctor.thursdayEnd === 48 ? "23:30" : ""}
                          `
                            : ""}
                        </td>
                        <td className="doctorWorkDays">
                          {doctor.friday === true
                            ? `Cuma
                        ${doctor.fridayStart === 1 ? ": 00:00" : ""}
                        ${doctor.fridayStart === 2 ? ": 00:30" : ""}
                        ${doctor.fridayStart === 3 ? ": 01:00" : ""}
                        ${doctor.fridayStart === 4 ? ": 01:30" : ""}
                        ${doctor.fridayStart === 5 ? ": 02:00" : ""}
                        ${doctor.fridayStart === 6 ? ": 02:30" : ""}
                        ${doctor.fridayStart === 7 ? ": 03:00" : ""}
                        ${doctor.fridayStart === 8 ? ": 03:30" : ""}
                        ${doctor.fridayStart === 9 ? ": 04:00" : ""}
                        ${doctor.fridayStart === 10 ? ": 04:30" : ""}
                        ${doctor.fridayStart === 11 ? ": 05:00" : ""}
                        ${doctor.fridayStart === 12 ? ": 05:30" : ""}
                        ${doctor.fridayStart === 13 ? ": 06:00" : ""}
                        ${doctor.fridayStart === 14 ? ": 06:30" : ""}
                        ${doctor.fridayStart === 15 ? ": 07:00" : ""}
                        ${doctor.fridayStart === 16 ? ": 07:30" : ""}
                        ${doctor.fridayStart === 17 ? ": 08:00" : ""}
                        ${doctor.fridayStart === 18 ? ": 08:30" : ""}
                        ${doctor.fridayStart === 19 ? ": 09:00" : ""}
                        ${doctor.fridayStart === 20 ? ": 09:30" : ""}
                        ${doctor.fridayStart === 21 ? ": 10:00" : ""}
                        ${doctor.fridayStart === 22 ? ": 10:30" : ""}
                        ${doctor.fridayStart === 23 ? ": 11:00" : ""}
                        ${doctor.fridayStart === 24 ? ": 11:30" : ""}
                        ${doctor.fridayStart === 25 ? ": 12:00" : ""}
                        ${doctor.fridayStart === 26 ? ": 12:30" : ""}
                        ${doctor.fridayStart === 27 ? ": 13:00" : ""}
                        ${doctor.fridayStart === 28 ? ": 13:30" : ""}
                        ${doctor.fridayStart === 29 ? ": 14:00" : ""}
                        ${doctor.fridayStart === 30 ? ": 14:30" : ""}
                        ${doctor.fridayStart === 31 ? ": 15:00" : ""}
                        ${doctor.fridayStart === 32 ? ": 15:30" : ""}
                        ${doctor.fridayStart === 33 ? ": 16:00" : ""}
                        ${doctor.fridayStart === 34 ? ": 16:30" : ""}
                        ${doctor.fridayStart === 35 ? ": 17:00" : ""}
                        ${doctor.fridayStart === 36 ? ": 17:30" : ""}
                        ${doctor.fridayStart === 37 ? ": 18:00" : ""}
                        ${doctor.fridayStart === 38 ? ": 18:30" : ""}
                        ${doctor.fridayStart === 39 ? ": 19:00" : ""}
                        ${doctor.fridayStart === 40 ? ": 19:30" : ""}
                        ${doctor.fridayStart === 41 ? ": 20:00" : ""}
                        ${doctor.fridayStart === 42 ? ": 20:30" : ""}
                        ${doctor.fridayStart === 43 ? ": 21:00" : ""}
                        ${doctor.fridayStart === 44 ? ": 21:30" : ""}
                        ${doctor.fridayStart === 45 ? ": 22:00" : ""}
                        ${doctor.fridayStart === 46 ? ": 22:30" : ""}
                        ${doctor.fridayStart === 47 ? ": 23:00" : ""}
                        ${doctor.fridayStart === 48 ? ": 23:30" : ""}
                        -
                        ${doctor.fridayEnd === 1 ? "00:00" : ""}
                        ${doctor.fridayEnd === 2 ? "00:30" : ""}
                        ${doctor.fridayEnd === 3 ? "01:00" : ""}
                        ${doctor.fridayEnd === 4 ? "01:30" : ""}
                        ${doctor.fridayEnd === 5 ? "02:00" : ""}
                        ${doctor.fridayEnd === 6 ? "02:30" : ""}
                        ${doctor.fridayEnd === 7 ? "03:00" : ""}
                        ${doctor.fridayEnd === 8 ? "03:30" : ""}
                        ${doctor.fridayEnd === 9 ? "04:00" : ""}
                        ${doctor.fridayEnd === 10 ? "04:30" : ""}
                        ${doctor.fridayEnd === 11 ? "05:00" : ""}
                        ${doctor.fridayEnd === 12 ? "05:30" : ""}
                        ${doctor.fridayEnd === 13 ? "06:00" : ""}
                        ${doctor.fridayEnd === 14 ? "06:30" : ""}
                        ${doctor.fridayEnd === 15 ? "07:00" : ""}
                        ${doctor.fridayEnd === 16 ? "07:30" : ""}
                        ${doctor.fridayEnd === 17 ? "08:00" : ""}
                        ${doctor.fridayEnd === 18 ? "08:30" : ""}
                        ${doctor.fridayEnd === 19 ? "09:00" : ""}
                        ${doctor.fridayEnd === 20 ? "09:30" : ""}
                        ${doctor.fridayEnd === 21 ? "10:00" : ""}
                        ${doctor.fridayEnd === 22 ? "10:30" : ""}
                        ${doctor.fridayEnd === 23 ? "11:00" : ""}
                        ${doctor.fridayEnd === 24 ? "11:30" : ""}
                        ${doctor.fridayEnd === 25 ? "12:00" : ""}
                        ${doctor.fridayEnd === 26 ? "12:30" : ""}
                        ${doctor.fridayEnd === 27 ? "13:00" : ""}
                        ${doctor.fridayEnd === 28 ? "13:30" : ""}
                        ${doctor.fridayEnd === 29 ? "14:00" : ""}
                        ${doctor.fridayEnd === 30 ? "14:30" : ""}
                        ${doctor.fridayEnd === 31 ? "15:00" : ""}
                        ${doctor.fridayEnd === 32 ? "15:30" : ""}
                        ${doctor.fridayEnd === 33 ? "16:00" : ""}
                        ${doctor.fridayEnd === 34 ? "16:30" : ""}
                        ${doctor.fridayEnd === 35 ? "17:00" : ""}
                        ${doctor.fridayEnd === 36 ? "17:30" : ""}
                        ${doctor.fridayEnd === 37 ? "18:00" : ""}
                        ${doctor.fridayEnd === 38 ? "18:30" : ""}
                        ${doctor.fridayEnd === 39 ? "19:00" : ""}
                        ${doctor.fridayEnd === 40 ? "19:30" : ""}
                        ${doctor.fridayEnd === 41 ? "20:00" : ""}
                        ${doctor.fridayEnd === 42 ? "20:30" : ""}
                        ${doctor.fridayEnd === 43 ? "21:00" : ""}
                        ${doctor.fridayEnd === 44 ? "21:30" : ""}
                        ${doctor.fridayEnd === 45 ? "22:00" : ""}
                        ${doctor.fridayEnd === 46 ? "22:30" : ""}
                        ${doctor.fridayEnd === 47 ? "23:00" : ""}
                        ${doctor.fridayEnd === 48 ? "23:30" : ""}
                          `
                            : ""}
                        </td>
                        <td className="doctorWorkDays">
                          {doctor.saturday === true
                            ? `Cumartesi
                        ${doctor.saturdayStart === 1 ? ": 00:00" : ""}
                        ${doctor.saturdayStart === 2 ? ": 00:30" : ""}
                        ${doctor.saturdayStart === 3 ? ": 01:00" : ""}
                        ${doctor.saturdayStart === 4 ? ": 01:30" : ""}
                        ${doctor.saturdayStart === 5 ? ": 02:00" : ""}
                        ${doctor.saturdayStart === 6 ? ": 02:30" : ""}
                        ${doctor.saturdayStart === 7 ? ": 03:00" : ""}
                        ${doctor.saturdayStart === 8 ? ": 03:30" : ""}
                        ${doctor.saturdayStart === 9 ? ": 04:00" : ""}
                        ${doctor.saturdayStart === 10 ? ": 04:30" : ""}
                        ${doctor.saturdayStart === 11 ? ": 05:00" : ""}
                        ${doctor.saturdayStart === 12 ? ": 05:30" : ""}
                        ${doctor.saturdayStart === 13 ? ": 06:00" : ""}
                        ${doctor.saturdayStart === 14 ? ": 06:30" : ""}
                        ${doctor.saturdayStart === 15 ? ": 07:00" : ""}
                        ${doctor.saturdayStart === 16 ? ": 07:30" : ""}
                        ${doctor.saturdayStart === 17 ? ": 08:00" : ""}
                        ${doctor.saturdayStart === 18 ? ": 08:30" : ""}
                        ${doctor.saturdayStart === 19 ? ": 09:00" : ""}
                        ${doctor.saturdayStart === 20 ? ": 09:30" : ""}
                        ${doctor.saturdayStart === 21 ? ": 10:00" : ""}
                        ${doctor.saturdayStart === 22 ? ": 10:30" : ""}
                        ${doctor.saturdayStart === 23 ? ": 11:00" : ""}
                        ${doctor.saturdayStart === 24 ? ": 11:30" : ""}
                        ${doctor.saturdayStart === 25 ? ": 12:00" : ""}
                        ${doctor.saturdayStart === 26 ? ": 12:30" : ""}
                        ${doctor.saturdayStart === 27 ? ": 13:00" : ""}
                        ${doctor.saturdayStart === 28 ? ": 13:30" : ""}
                        ${doctor.saturdayStart === 29 ? ": 14:00" : ""}
                        ${doctor.saturdayStart === 30 ? ": 14:30" : ""}
                        ${doctor.saturdayStart === 31 ? ": 15:00" : ""}
                        ${doctor.saturdayStart === 32 ? ": 15:30" : ""}
                        ${doctor.saturdayStart === 33 ? ": 16:00" : ""}
                        ${doctor.saturdayStart === 34 ? ": 16:30" : ""}
                        ${doctor.saturdayStart === 35 ? ": 17:00" : ""}
                        ${doctor.saturdayStart === 36 ? ": 17:30" : ""}
                        ${doctor.saturdayStart === 37 ? ": 18:00" : ""}
                        ${doctor.saturdayStart === 38 ? ": 18:30" : ""}
                        ${doctor.saturdayStart === 39 ? ": 19:00" : ""}
                        ${doctor.saturdayStart === 40 ? ": 19:30" : ""}
                        ${doctor.saturdayStart === 41 ? ": 20:00" : ""}
                        ${doctor.saturdayStart === 42 ? ": 20:30" : ""}
                        ${doctor.saturdayStart === 43 ? ": 21:00" : ""}
                        ${doctor.saturdayStart === 44 ? ": 21:30" : ""}
                        ${doctor.saturdayStart === 45 ? ": 22:00" : ""}
                        ${doctor.saturdayStart === 46 ? ": 22:30" : ""}
                        ${doctor.saturdayStart === 47 ? ": 23:00" : ""}
                        ${doctor.saturdayStart === 48 ? ": 23:30" : ""}
                        -
                        ${doctor.saturdayEnd === 1 ? "00:00" : ""}
                        ${doctor.saturdayEnd === 2 ? "00:30" : ""}
                        ${doctor.saturdayEnd === 3 ? "01:00" : ""}
                        ${doctor.saturdayEnd === 4 ? "01:30" : ""}
                        ${doctor.saturdayEnd === 5 ? "02:00" : ""}
                        ${doctor.saturdayEnd === 6 ? "02:30" : ""}
                        ${doctor.saturdayEnd === 7 ? "03:00" : ""}
                        ${doctor.saturdayEnd === 8 ? "03:30" : ""}
                        ${doctor.saturdayEnd === 9 ? "04:00" : ""}
                        ${doctor.saturdayEnd === 10 ? "04:30" : ""}
                        ${doctor.saturdayEnd === 11 ? "05:00" : ""}
                        ${doctor.saturdayEnd === 12 ? "05:30" : ""}
                        ${doctor.saturdayEnd === 13 ? "06:00" : ""}
                        ${doctor.saturdayEnd === 14 ? "06:30" : ""}
                        ${doctor.saturdayEnd === 15 ? "07:00" : ""}
                        ${doctor.saturdayEnd === 16 ? "07:30" : ""}
                        ${doctor.saturdayEnd === 17 ? "08:00" : ""}
                        ${doctor.saturdayEnd === 18 ? "08:30" : ""}
                        ${doctor.saturdayEnd === 19 ? "09:00" : ""}
                        ${doctor.saturdayEnd === 20 ? "09:30" : ""}
                        ${doctor.saturdayEnd === 21 ? "10:00" : ""}
                        ${doctor.saturdayEnd === 22 ? "10:30" : ""}
                        ${doctor.saturdayEnd === 23 ? "11:00" : ""}
                        ${doctor.saturdayEnd === 24 ? "11:30" : ""}
                        ${doctor.saturdayEnd === 25 ? "12:00" : ""}
                        ${doctor.saturdayEnd === 26 ? "12:30" : ""}
                        ${doctor.saturdayEnd === 27 ? "13:00" : ""}
                        ${doctor.saturdayEnd === 28 ? "13:30" : ""}
                        ${doctor.saturdayEnd === 29 ? "14:00" : ""}
                        ${doctor.saturdayEnd === 30 ? "14:30" : ""}
                        ${doctor.saturdayEnd === 31 ? "15:00" : ""}
                        ${doctor.saturdayEnd === 32 ? "15:30" : ""}
                        ${doctor.saturdayEnd === 33 ? "16:00" : ""}
                        ${doctor.saturdayEnd === 34 ? "16:30" : ""}
                        ${doctor.saturdayEnd === 35 ? "17:00" : ""}
                        ${doctor.saturdayEnd === 36 ? "17:30" : ""}
                        ${doctor.saturdayEnd === 37 ? "18:00" : ""}
                        ${doctor.saturdayEnd === 38 ? "18:30" : ""}
                        ${doctor.saturdayEnd === 39 ? "19:00" : ""}
                        ${doctor.saturdayEnd === 40 ? "19:30" : ""}
                        ${doctor.saturdayEnd === 41 ? "20:00" : ""}
                        ${doctor.saturdayEnd === 42 ? "20:30" : ""}
                        ${doctor.saturdayEnd === 43 ? "21:00" : ""}
                        ${doctor.saturdayEnd === 44 ? "21:30" : ""}
                        ${doctor.saturdayEnd === 45 ? "22:00" : ""}
                        ${doctor.saturdayEnd === 46 ? "22:30" : ""}
                        ${doctor.saturdayEnd === 47 ? "23:00" : ""}
                        ${doctor.saturdayEnd === 48 ? "23:30" : ""}
                          `
                            : ""}
                        </td>
                        <td className="doctorWorkDays">
                          {doctor.monday === true
                            ? `Pazar
                        ${doctor.mondayStart === 1 ? ": 00:00" : ""}
                        ${doctor.mondayStart === 2 ? ": 00:30" : ""}
                        ${doctor.mondayStart === 3 ? ": 01:00" : ""}
                        ${doctor.mondayStart === 4 ? ": 01:30" : ""}
                        ${doctor.mondayStart === 5 ? ": 02:00" : ""}
                        ${doctor.mondayStart === 6 ? ": 02:30" : ""}
                        ${doctor.mondayStart === 7 ? ": 03:00" : ""}
                        ${doctor.mondayStart === 8 ? ": 03:30" : ""}
                        ${doctor.mondayStart === 9 ? ": 04:00" : ""}
                        ${doctor.mondayStart === 10 ? ": 04:30" : ""}
                        ${doctor.mondayStart === 11 ? ": 05:00" : ""}
                        ${doctor.mondayStart === 12 ? ": 05:30" : ""}
                        ${doctor.mondayStart === 13 ? ": 06:00" : ""}
                        ${doctor.mondayStart === 14 ? ": 06:30" : ""}
                        ${doctor.mondayStart === 15 ? ": 07:00" : ""}
                        ${doctor.mondayStart === 16 ? ": 07:30" : ""}
                        ${doctor.mondayStart === 17 ? ": 08:00" : ""}
                        ${doctor.mondayStart === 18 ? ": 08:30" : ""}
                        ${doctor.mondayStart === 19 ? ": 09:00" : ""}
                        ${doctor.mondayStart === 20 ? ": 09:30" : ""}
                        ${doctor.mondayStart === 21 ? ": 10:00" : ""}
                        ${doctor.mondayStart === 22 ? ": 10:30" : ""}
                        ${doctor.mondayStart === 23 ? ": 11:00" : ""}
                        ${doctor.mondayStart === 24 ? ": 11:30" : ""}
                        ${doctor.mondayStart === 25 ? ": 12:00" : ""}
                        ${doctor.mondayStart === 26 ? ": 12:30" : ""}
                        ${doctor.mondayStart === 27 ? ": 13:00" : ""}
                        ${doctor.mondayStart === 28 ? ": 13:30" : ""}
                        ${doctor.mondayStart === 29 ? ": 14:00" : ""}
                        ${doctor.mondayStart === 30 ? ": 14:30" : ""}
                        ${doctor.mondayStart === 31 ? ": 15:00" : ""}
                        ${doctor.mondayStart === 32 ? ": 15:30" : ""}
                        ${doctor.mondayStart === 33 ? ": 16:00" : ""}
                        ${doctor.mondayStart === 34 ? ": 16:30" : ""}
                        ${doctor.mondayStart === 35 ? ": 17:00" : ""}
                        ${doctor.mondayStart === 36 ? ": 17:30" : ""}
                        ${doctor.mondayStart === 37 ? ": 18:00" : ""}
                        ${doctor.mondayStart === 38 ? ": 18:30" : ""}
                        ${doctor.mondayStart === 39 ? ": 19:00" : ""}
                        ${doctor.mondayStart === 40 ? ": 19:30" : ""}
                        ${doctor.mondayStart === 41 ? ": 20:00" : ""}
                        ${doctor.mondayStart === 42 ? ": 20:30" : ""}
                        ${doctor.mondayStart === 43 ? ": 21:00" : ""}
                        ${doctor.mondayStart === 44 ? ": 21:30" : ""}
                        ${doctor.mondayStart === 45 ? ": 22:00" : ""}
                        ${doctor.mondayStart === 46 ? ": 22:30" : ""}
                        ${doctor.mondayStart === 47 ? ": 23:00" : ""}
                        ${doctor.mondayStart === 48 ? ": 23:30" : ""}
                        -
                        ${doctor.mondayEnd === 1 ? "00:00" : ""}
                        ${doctor.mondayEnd === 2 ? "00:30" : ""}
                        ${doctor.mondayEnd === 3 ? "01:00" : ""}
                        ${doctor.mondayEnd === 4 ? "01:30" : ""}
                        ${doctor.mondayEnd === 5 ? "02:00" : ""}
                        ${doctor.mondayEnd === 6 ? "02:30" : ""}
                        ${doctor.mondayEnd === 7 ? "03:00" : ""}
                        ${doctor.mondayEnd === 8 ? "03:30" : ""}
                        ${doctor.mondayEnd === 9 ? "04:00" : ""}
                        ${doctor.mondayEnd === 10 ? "04:30" : ""}
                        ${doctor.mondayEnd === 11 ? "05:00" : ""}
                        ${doctor.mondayEnd === 12 ? "05:30" : ""}
                        ${doctor.mondayEnd === 13 ? "06:00" : ""}
                        ${doctor.mondayEnd === 14 ? "06:30" : ""}
                        ${doctor.mondayEnd === 15 ? "07:00" : ""}
                        ${doctor.mondayEnd === 16 ? "07:30" : ""}
                        ${doctor.mondayEnd === 17 ? "08:00" : ""}
                        ${doctor.mondayEnd === 18 ? "08:30" : ""}
                        ${doctor.mondayEnd === 19 ? "09:00" : ""}
                        ${doctor.mondayEnd === 20 ? "09:30" : ""}
                        ${doctor.mondayEnd === 21 ? "10:00" : ""}
                        ${doctor.mondayEnd === 22 ? "10:30" : ""}
                        ${doctor.mondayEnd === 23 ? "11:00" : ""}
                        ${doctor.mondayEnd === 24 ? "11:30" : ""}
                        ${doctor.mondayEnd === 25 ? "12:00" : ""}
                        ${doctor.mondayEnd === 26 ? "12:30" : ""}
                        ${doctor.mondayEnd === 27 ? "13:00" : ""}
                        ${doctor.mondayEnd === 28 ? "13:30" : ""}
                        ${doctor.mondayEnd === 29 ? "14:00" : ""}
                        ${doctor.mondayEnd === 30 ? "14:30" : ""}
                        ${doctor.mondayEnd === 31 ? "15:00" : ""}
                        ${doctor.mondayEnd === 32 ? "15:30" : ""}
                        ${doctor.mondayEnd === 33 ? "16:00" : ""}
                        ${doctor.mondayEnd === 34 ? "16:30" : ""}
                        ${doctor.mondayEnd === 35 ? "17:00" : ""}
                        ${doctor.mondayEnd === 36 ? "17:30" : ""}
                        ${doctor.mondayEnd === 37 ? "18:00" : ""}
                        ${doctor.mondayEnd === 38 ? "18:30" : ""}
                        ${doctor.mondayEnd === 39 ? "19:00" : ""}
                        ${doctor.mondayEnd === 40 ? "19:30" : ""}
                        ${doctor.mondayEnd === 41 ? "20:00" : ""}
                        ${doctor.mondayEnd === 42 ? "20:30" : ""}
                        ${doctor.mondayEnd === 43 ? "21:00" : ""}
                        ${doctor.mondayEnd === 44 ? "21:30" : ""}
                        ${doctor.mondayEnd === 45 ? "22:00" : ""}
                        ${doctor.mondayEnd === 46 ? "22:30" : ""}
                        ${doctor.mondayEnd === 47 ? "23:00" : ""}
                        ${doctor.mondayEnd === 48 ? "23:30" : ""}
                          `
                            : ""}
                        </td>
                      </tr>
        
        
    </div>
  )
})
