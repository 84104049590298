import React from 'react'
import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useStore } from '../../stores/store'
import { useNavigate } from 'react-router-dom'
import { Form, Col, Row, Container, Button } from 'react-bootstrap'
import TextField from '@mui/material/TextField'
import { toast } from 'react-toastify'
import Autocomplete from '@mui/material/Autocomplete'

import './doctors.css'

export default observer(function Doctors() {
  const { drStore } = useStore()
  const [selects, setSelects] = useState()

  const initialState = {
    fullName: '',
    email: '',
    phoneNumber: '',
    branch: '',
    commissionPercentage: 0,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    mondayStart: 0,
    mondayEnd: 0,
    wednesdayStart: 0,
    tuesdayEnd: 0,
    wednesdayStart: 0,
    wednesdayEnd: 0,
    thursdayStart: 0,
    thursdayEnd: 0,
    fridayStart: 0,
    fridayEnd: 0,
    saturdayStart: 0,
    saturdayEnd: 0,
    sundayStart: 0,
    sundayEnd: 0,
  }
  const [doctors, setLogin] = useState(initialState)

  function handleInputChange(event) {
    const { name, value } = event.target
    setLogin({ ...doctors, [name]: value })
  }

  function newDoctor() {
    const model = doctors
    console.log(model)
    if (
      model.fullName !== '' &&
      model.email !== '' &&
      model.email.includes('@') &&
      model.phoneNumber !== '' &&
      model.branch !== ''
    ) {
      drStore.newDr(model)
    }
  }

  function handleSundayStart(value) {
    if (value === '00:00') doctors.sundayStart = 1
    if (value === '00:30') doctors.sundayStart = 2
    if (value === '01:00') doctors.sundayStart = 3
    if (value === '01:30') doctors.sundayStart = 4
    if (value === '02:00') doctors.sundayStart = 5
    if (value === '02:30') doctors.sundayStart = 6
    if (value === '03:00') doctors.sundayStart = 7
    if (value === '03:30') doctors.sundayStart = 8
    if (value === '04:00') doctors.sundayStart = 9
    if (value === '04:30') doctors.sundayStart = 10
    if (value === '05:00') doctors.sundayStart = 11
    if (value === '05:30') doctors.sundayStart = 12
    if (value === '06:00') doctors.sundayStart = 13
    if (value === '06:30') doctors.sundayStart = 14
    if (value === '07:00') doctors.sundayStart = 15
    if (value === '07:30') doctors.sundayStart = 16
    if (value === '08:00') doctors.sundayStart = 17
    if (value === '08:30') doctors.sundayStart = 18
    if (value === '09:00') doctors.sundayStart = 19
    if (value === '09:30') doctors.sundayStart = 20
    if (value === '10:00') doctors.sundayStart = 21
    if (value === '10:30') doctors.sundayStart = 22
    if (value === '11:00') doctors.sundayStart = 23
    if (value === '11:30') doctors.sundayStart = 24
    if (value === '12:00') doctors.sundayStart = 25
    if (value === '12:30') doctors.sundayStart = 26
    if (value === '13:00') doctors.sundayStart = 27
    if (value === '13:30') doctors.sundayStart = 28
    if (value === '14:00') doctors.sundayStart = 29
    if (value === '14:30') doctors.sundayStart = 30
    if (value === '15:00') doctors.sundayStart = 31
    if (value === '15:30') doctors.sundayStart = 32
    if (value === '16:00') doctors.sundayStart = 33
    if (value === '16:30') doctors.sundayStart = 34
    if (value === '17:00') doctors.sundayStart = 35
    if (value === '17:30') doctors.sundayStart = 36
    if (value === '18:00') doctors.sundayStart = 37
    if (value === '18:30') doctors.sundayStart = 38
    if (value === '19:00') doctors.sundayStart = 39
    if (value === '19:30') doctors.sundayStart = 40
    if (value === '20:00') doctors.sundayStart = 41
    if (value === '20:30') doctors.sundayStart = 42
    if (value === '21:00') doctors.sundayStart = 43
    if (value === '21:30') doctors.sundayStart = 44
    if (value === '22:00') doctors.sundayStart = 45
    if (value === '22:30') doctors.sundayStart = 46
    if (value === '23:00') doctors.sundayStart = 47
    if (value === '23:30') doctors.sundayStart = 48
  }
  function handleSundayEnd(value) {
    if (value === '00:00') doctors.sundayEnd = 1
    if (value === '00:30') doctors.sundayEnd = 2
    if (value === '01:00') doctors.sundayEnd = 3
    if (value === '01:30') doctors.sundayEnd = 4
    if (value === '02:00') doctors.sundayEnd = 5
    if (value === '02:30') doctors.sundayEnd = 6
    if (value === '03:00') doctors.sundayEnd = 7
    if (value === '03:30') doctors.sundayEnd = 8
    if (value === '04:00') doctors.sundayEnd = 9
    if (value === '04:30') doctors.sundayEnd = 10
    if (value === '05:00') doctors.sundayEnd = 11
    if (value === '05:30') doctors.sundayEnd = 12
    if (value === '06:00') doctors.sundayEnd = 13
    if (value === '06:30') doctors.sundayEnd = 14
    if (value === '07:00') doctors.sundayEnd = 15
    if (value === '07:30') doctors.sundayEnd = 16
    if (value === '08:00') doctors.sundayEnd = 17
    if (value === '08:30') doctors.sundayEnd = 18
    if (value === '09:00') doctors.sundayEnd = 19
    if (value === '09:30') doctors.sundayEnd = 20
    if (value === '10:00') doctors.sundayEnd = 21
    if (value === '10:30') doctors.sundayEnd = 22
    if (value === '11:00') doctors.sundayEnd = 23
    if (value === '11:30') doctors.sundayEnd = 24
    if (value === '12:00') doctors.sundayEnd = 25
    if (value === '12:30') doctors.sundayEnd = 26
    if (value === '13:00') doctors.sundayEnd = 27
    if (value === '13:30') doctors.sundayEnd = 28
    if (value === '14:00') doctors.sundayEnd = 29
    if (value === '14:30') doctors.sundayEnd = 30
    if (value === '15:00') doctors.sundayEnd = 31
    if (value === '15:30') doctors.sundayEnd = 32
    if (value === '16:00') doctors.sundayEnd = 33
    if (value === '16:30') doctors.sundayEnd = 34
    if (value === '17:00') doctors.sundayEnd = 35
    if (value === '17:30') doctors.sundayEnd = 36
    if (value === '18:00') doctors.sundayEnd = 37
    if (value === '18:30') doctors.sundayEnd = 38
    if (value === '19:00') doctors.sundayEnd = 39
    if (value === '19:30') doctors.sundayEnd = 40
    if (value === '20:00') doctors.sundayEnd = 41
    if (value === '20:30') doctors.sundayEnd = 42
    if (value === '21:00') doctors.sundayEnd = 43
    if (value === '21:30') doctors.sundayEnd = 44
    if (value === '22:00') doctors.sundayEnd = 45
    if (value === '22:30') doctors.sundayEnd = 46
    if (value === '23:00') doctors.sundayEnd = 47
    if (value === '23:30') doctors.sundayEnd = 48
  }
  function handleTuesdayStart(value) {
    if (value === '00:00') doctors.tuesdayStart = 1
    if (value === '00:30') doctors.tuesdayStart = 2
    if (value === '01:00') doctors.tuesdayStart = 3
    if (value === '01:30') doctors.tuesdayStart = 4
    if (value === '02:00') doctors.tuesdayStart = 5
    if (value === '02:30') doctors.tuesdayStart = 6
    if (value === '03:00') doctors.tuesdayStart = 7
    if (value === '03:30') doctors.tuesdayStart = 8
    if (value === '04:00') doctors.tuesdayStart = 9
    if (value === '04:30') doctors.tuesdayStart = 10
    if (value === '05:00') doctors.tuesdayStart = 11
    if (value === '05:30') doctors.tuesdayStart = 12
    if (value === '06:00') doctors.tuesdayStart = 13
    if (value === '06:30') doctors.tuesdayStart = 14
    if (value === '07:00') doctors.tuesdayStart = 15
    if (value === '07:30') doctors.tuesdayStart = 16
    if (value === '08:00') doctors.tuesdayStart = 17
    if (value === '08:30') doctors.tuesdayStart = 18
    if (value === '09:00') doctors.tuesdayStart = 19
    if (value === '09:30') doctors.tuesdayStart = 20
    if (value === '10:00') doctors.tuesdayStart = 21
    if (value === '10:30') doctors.tuesdayStart = 22
    if (value === '11:00') doctors.tuesdayStart = 23
    if (value === '11:30') doctors.tuesdayStart = 24
    if (value === '12:00') doctors.tuesdayStart = 25
    if (value === '12:30') doctors.tuesdayStart = 26
    if (value === '13:00') doctors.tuesdayStart = 27
    if (value === '13:30') doctors.tuesdayStart = 28
    if (value === '14:00') doctors.tuesdayStart = 29
    if (value === '14:30') doctors.tuesdayStart = 30
    if (value === '15:00') doctors.tuesdayStart = 31
    if (value === '15:30') doctors.tuesdayStart = 32
    if (value === '16:00') doctors.tuesdayStart = 33
    if (value === '16:30') doctors.tuesdayStart = 34
    if (value === '17:00') doctors.tuesdayStart = 35
    if (value === '17:30') doctors.tuesdayStart = 36
    if (value === '18:00') doctors.tuesdayStart = 37
    if (value === '18:30') doctors.tuesdayStart = 38
    if (value === '19:00') doctors.tuesdayStart = 39
    if (value === '19:30') doctors.tuesdayStart = 40
    if (value === '20:00') doctors.tuesdayStart = 41
    if (value === '20:30') doctors.tuesdayStart = 42
    if (value === '21:00') doctors.tuesdayStart = 43
    if (value === '21:30') doctors.tuesdayStart = 44
    if (value === '22:00') doctors.tuesdayStart = 45
    if (value === '22:30') doctors.tuesdayStart = 46
    if (value === '23:00') doctors.tuesdayStart = 47
    if (value === '23:30') doctors.tuesdayStart = 48
  }
  function handleTuesdayEnd(value) {
    if (value === '00:00') doctors.tuesdayEnd = 1
    if (value === '00:30') doctors.tuesdayEnd = 2
    if (value === '01:00') doctors.tuesdayEnd = 3
    if (value === '01:30') doctors.tuesdayEnd = 4
    if (value === '02:00') doctors.tuesdayEnd = 5
    if (value === '02:30') doctors.tuesdayEnd = 6
    if (value === '03:00') doctors.tuesdayEnd = 7
    if (value === '03:30') doctors.tuesdayEnd = 8
    if (value === '04:00') doctors.tuesdayEnd = 9
    if (value === '04:30') doctors.tuesdayEnd = 10
    if (value === '05:00') doctors.tuesdayEnd = 11
    if (value === '05:30') doctors.tuesdayEnd = 12
    if (value === '06:00') doctors.tuesdayEnd = 13
    if (value === '06:30') doctors.tuesdayEnd = 14
    if (value === '07:00') doctors.tuesdayEnd = 15
    if (value === '07:30') doctors.tuesdayEnd = 16
    if (value === '08:00') doctors.tuesdayEnd = 17
    if (value === '08:30') doctors.tuesdayEnd = 18
    if (value === '09:00') doctors.tuesdayEnd = 19
    if (value === '09:30') doctors.tuesdayEnd = 20
    if (value === '10:00') doctors.tuesdayEnd = 21
    if (value === '10:30') doctors.tuesdayEnd = 22
    if (value === '11:00') doctors.tuesdayEnd = 23
    if (value === '11:30') doctors.tuesdayEnd = 24
    if (value === '12:00') doctors.tuesdayEnd = 25
    if (value === '12:30') doctors.tuesdayEnd = 26
    if (value === '13:00') doctors.tuesdayEnd = 27
    if (value === '13:30') doctors.tuesdayEnd = 28
    if (value === '14:00') doctors.tuesdayEnd = 29
    if (value === '14:30') doctors.tuesdayEnd = 30
    if (value === '15:00') doctors.tuesdayEnd = 31
    if (value === '15:30') doctors.tuesdayEnd = 32
    if (value === '16:00') doctors.tuesdayEnd = 33
    if (value === '16:30') doctors.tuesdayEnd = 34
    if (value === '17:00') doctors.tuesdayEnd = 35
    if (value === '17:30') doctors.tuesdayEnd = 36
    if (value === '18:00') doctors.tuesdayEnd = 37
    if (value === '18:30') doctors.tuesdayEnd = 38
    if (value === '19:00') doctors.tuesdayEnd = 39
    if (value === '19:30') doctors.tuesdayEnd = 40
    if (value === '20:00') doctors.tuesdayEnd = 41
    if (value === '20:30') doctors.tuesdayEnd = 42
    if (value === '21:00') doctors.tuesdayEnd = 43
    if (value === '21:30') doctors.tuesdayEnd = 44
    if (value === '22:00') doctors.tuesdayEnd = 45
    if (value === '22:30') doctors.tuesdayEnd = 46
    if (value === '23:00') doctors.tuesdayEnd = 47
    if (value === '23:30') doctors.tuesdayEnd = 48
  }
  function handleWednesdayStart(value) {
    if (value === '00:00') doctors.wednesdayStart = 1
    if (value === '00:30') doctors.wednesdayStart = 2
    if (value === '01:00') doctors.wednesdayStart = 3
    if (value === '01:30') doctors.wednesdayStart = 4
    if (value === '02:00') doctors.wednesdayStart = 5
    if (value === '02:30') doctors.wednesdayStart = 6
    if (value === '03:00') doctors.wednesdayStart = 7
    if (value === '03:30') doctors.wednesdayStart = 8
    if (value === '04:00') doctors.wednesdayStart = 9
    if (value === '04:30') doctors.wednesdayStart = 10
    if (value === '05:00') doctors.wednesdayStart = 11
    if (value === '05:30') doctors.wednesdayStart = 12
    if (value === '06:00') doctors.wednesdayStart = 13
    if (value === '06:30') doctors.wednesdayStart = 14
    if (value === '07:00') doctors.wednesdayStart = 15
    if (value === '07:30') doctors.wednesdayStart = 16
    if (value === '08:00') doctors.wednesdayStart = 17
    if (value === '08:30') doctors.wednesdayStart = 18
    if (value === '09:00') doctors.wednesdayStart = 19
    if (value === '09:30') doctors.wednesdayStart = 20
    if (value === '10:00') doctors.wednesdayStart = 21
    if (value === '10:30') doctors.wednesdayStart = 22
    if (value === '11:00') doctors.wednesdayStart = 23
    if (value === '11:30') doctors.wednesdayStart = 24
    if (value === '12:00') doctors.wednesdayStart = 25
    if (value === '12:30') doctors.wednesdayStart = 26
    if (value === '13:00') doctors.wednesdayStart = 27
    if (value === '13:30') doctors.wednesdayStart = 28
    if (value === '14:00') doctors.wednesdayStart = 29
    if (value === '14:30') doctors.wednesdayStart = 30
    if (value === '15:00') doctors.wednesdayStart = 31
    if (value === '15:30') doctors.wednesdayStart = 32
    if (value === '16:00') doctors.wednesdayStart = 33
    if (value === '16:30') doctors.wednesdayStart = 34
    if (value === '17:00') doctors.wednesdayStart = 35
    if (value === '17:30') doctors.wednesdayStart = 36
    if (value === '18:00') doctors.wednesdayStart = 37
    if (value === '18:30') doctors.wednesdayStart = 38
    if (value === '19:00') doctors.wednesdayStart = 39
    if (value === '19:30') doctors.wednesdayStart = 40
    if (value === '20:00') doctors.wednesdayStart = 41
    if (value === '20:30') doctors.wednesdayStart = 42
    if (value === '21:00') doctors.wednesdayStart = 43
    if (value === '21:30') doctors.wednesdayStart = 44
    if (value === '22:00') doctors.wednesdayStart = 45
    if (value === '22:30') doctors.wednesdayStart = 46
    if (value === '23:00') doctors.wednesdayStart = 47
    if (value === '23:30') doctors.wednesdayStart = 48
  }
  function handleWednesdayEnd(value) {
    if (value === '00:00') doctors.wednesdayEnd = 1
    if (value === '00:30') doctors.wednesdayEnd = 2
    if (value === '01:00') doctors.wednesdayEnd = 3
    if (value === '01:30') doctors.wednesdayEnd = 4
    if (value === '02:00') doctors.wednesdayEnd = 5
    if (value === '02:30') doctors.wednesdayEnd = 6
    if (value === '03:00') doctors.wednesdayEnd = 7
    if (value === '03:30') doctors.wednesdayEnd = 8
    if (value === '04:00') doctors.wednesdayEnd = 9
    if (value === '04:30') doctors.wednesdayEnd = 10
    if (value === '05:00') doctors.wednesdayEnd = 11
    if (value === '05:30') doctors.wednesdayEnd = 12
    if (value === '06:00') doctors.wednesdayEnd = 13
    if (value === '06:30') doctors.wednesdayEnd = 14
    if (value === '07:00') doctors.wednesdayEnd = 15
    if (value === '07:30') doctors.wednesdayEnd = 16
    if (value === '08:00') doctors.wednesdayEnd = 17
    if (value === '08:30') doctors.wednesdayEnd = 18
    if (value === '09:00') doctors.wednesdayEnd = 19
    if (value === '09:30') doctors.wednesdayEnd = 20
    if (value === '10:00') doctors.wednesdayEnd = 21
    if (value === '10:30') doctors.wednesdayEnd = 22
    if (value === '11:00') doctors.wednesdayEnd = 23
    if (value === '11:30') doctors.wednesdayEnd = 24
    if (value === '12:00') doctors.wednesdayEnd = 25
    if (value === '12:30') doctors.wednesdayEnd = 26
    if (value === '13:00') doctors.wednesdayEnd = 27
    if (value === '13:30') doctors.wednesdayEnd = 28
    if (value === '14:00') doctors.wednesdayEnd = 29
    if (value === '14:30') doctors.wednesdayEnd = 30
    if (value === '15:00') doctors.wednesdayEnd = 31
    if (value === '15:30') doctors.wednesdayEnd = 32
    if (value === '16:00') doctors.wednesdayEnd = 33
    if (value === '16:30') doctors.wednesdayEnd = 34
    if (value === '17:00') doctors.wednesdayEnd = 35
    if (value === '17:30') doctors.wednesdayEnd = 36
    if (value === '18:00') doctors.wednesdayEnd = 37
    if (value === '18:30') doctors.wednesdayEnd = 38
    if (value === '19:00') doctors.wednesdayEnd = 39
    if (value === '19:30') doctors.wednesdayEnd = 40
    if (value === '20:00') doctors.wednesdayEnd = 41
    if (value === '20:30') doctors.wednesdayEnd = 42
    if (value === '21:00') doctors.wednesdayEnd = 43
    if (value === '21:30') doctors.wednesdayEnd = 44
    if (value === '22:00') doctors.wednesdayEnd = 45
    if (value === '22:30') doctors.wednesdayEnd = 46
    if (value === '23:00') doctors.wednesdayEnd = 47
    if (value === '23:30') doctors.wednesdayEnd = 48
  }
  function handleThursdayStart(value) {
    if (value === '00:00') doctors.thursdayStart = 1
    if (value === '00:30') doctors.thursdayStart = 2
    if (value === '01:00') doctors.thursdayStart = 3
    if (value === '01:30') doctors.thursdayStart = 4
    if (value === '02:00') doctors.thursdayStart = 5
    if (value === '02:30') doctors.thursdayStart = 6
    if (value === '03:00') doctors.thursdayStart = 7
    if (value === '03:30') doctors.thursdayStart = 8
    if (value === '04:00') doctors.thursdayStart = 9
    if (value === '04:30') doctors.thursdayStart = 10
    if (value === '05:00') doctors.thursdayStart = 11
    if (value === '05:30') doctors.thursdayStart = 12
    if (value === '06:00') doctors.thursdayStart = 13
    if (value === '06:30') doctors.thursdayStart = 14
    if (value === '07:00') doctors.thursdayStart = 15
    if (value === '07:30') doctors.thursdayStart = 16
    if (value === '08:00') doctors.thursdayStart = 17
    if (value === '08:30') doctors.thursdayStart = 18
    if (value === '09:00') doctors.thursdayStart = 19
    if (value === '09:30') doctors.thursdayStart = 20
    if (value === '10:00') doctors.thursdayStart = 21
    if (value === '10:30') doctors.thursdayStart = 22
    if (value === '11:00') doctors.thursdayStart = 23
    if (value === '11:30') doctors.thursdayStart = 24
    if (value === '12:00') doctors.thursdayStart = 25
    if (value === '12:30') doctors.thursdayStart = 26
    if (value === '13:00') doctors.thursdayStart = 27
    if (value === '13:30') doctors.thursdayStart = 28
    if (value === '14:00') doctors.thursdayStart = 29
    if (value === '14:30') doctors.thursdayStart = 30
    if (value === '15:00') doctors.thursdayStart = 31
    if (value === '15:30') doctors.thursdayStart = 32
    if (value === '16:00') doctors.thursdayStart = 33
    if (value === '16:30') doctors.thursdayStart = 34
    if (value === '17:00') doctors.thursdayStart = 35
    if (value === '17:30') doctors.thursdayStart = 36
    if (value === '18:00') doctors.thursdayStart = 37
    if (value === '18:30') doctors.thursdayStart = 38
    if (value === '19:00') doctors.thursdayStart = 39
    if (value === '19:30') doctors.thursdayStart = 40
    if (value === '20:00') doctors.thursdayStart = 41
    if (value === '20:30') doctors.thursdayStart = 42
    if (value === '21:00') doctors.thursdayStart = 43
    if (value === '21:30') doctors.thursdayStart = 44
    if (value === '22:00') doctors.thursdayStart = 45
    if (value === '22:30') doctors.thursdayStart = 46
    if (value === '23:00') doctors.thursdayStart = 47
    if (value === '23:30') doctors.thursdayStart = 48
  }
  function handleThursdayEnd(value) {
    if (value === '00:00') doctors.thursdayEnd = 1
    if (value === '00:30') doctors.thursdayEnd = 2
    if (value === '01:00') doctors.thursdayEnd = 3
    if (value === '01:30') doctors.thursdayEnd = 4
    if (value === '02:00') doctors.thursdayEnd = 5
    if (value === '02:30') doctors.thursdayEnd = 6
    if (value === '03:00') doctors.thursdayEnd = 7
    if (value === '03:30') doctors.thursdayEnd = 8
    if (value === '04:00') doctors.thursdayEnd = 9
    if (value === '04:30') doctors.thursdayEnd = 10
    if (value === '05:00') doctors.thursdayEnd = 11
    if (value === '05:30') doctors.thursdayEnd = 12
    if (value === '06:00') doctors.thursdayEnd = 13
    if (value === '06:30') doctors.thursdayEnd = 14
    if (value === '07:00') doctors.thursdayEnd = 15
    if (value === '07:30') doctors.thursdayEnd = 16
    if (value === '08:00') doctors.thursdayEnd = 17
    if (value === '08:30') doctors.thursdayEnd = 18
    if (value === '09:00') doctors.thursdayEnd = 19
    if (value === '09:30') doctors.thursdayEnd = 20
    if (value === '10:00') doctors.thursdayEnd = 21
    if (value === '10:30') doctors.thursdayEnd = 22
    if (value === '11:00') doctors.thursdayEnd = 23
    if (value === '11:30') doctors.thursdayEnd = 24
    if (value === '12:00') doctors.thursdayEnd = 25
    if (value === '12:30') doctors.thursdayEnd = 26
    if (value === '13:00') doctors.thursdayEnd = 27
    if (value === '13:30') doctors.thursdayEnd = 28
    if (value === '14:00') doctors.thursdayEnd = 29
    if (value === '14:30') doctors.thursdayEnd = 30
    if (value === '15:00') doctors.thursdayEnd = 31
    if (value === '15:30') doctors.thursdayEnd = 32
    if (value === '16:00') doctors.thursdayEnd = 33
    if (value === '16:30') doctors.thursdayEnd = 34
    if (value === '17:00') doctors.thursdayEnd = 35
    if (value === '17:30') doctors.thursdayEnd = 36
    if (value === '18:00') doctors.thursdayEnd = 37
    if (value === '18:30') doctors.thursdayEnd = 38
    if (value === '19:00') doctors.thursdayEnd = 39
    if (value === '19:30') doctors.thursdayEnd = 40
    if (value === '20:00') doctors.thursdayEnd = 41
    if (value === '20:30') doctors.thursdayEnd = 42
    if (value === '21:00') doctors.thursdayEnd = 43
    if (value === '21:30') doctors.thursdayEnd = 44
    if (value === '22:00') doctors.thursdayEnd = 45
    if (value === '22:30') doctors.thursdayEnd = 46
    if (value === '23:00') doctors.thursdayEnd = 47
    if (value === '23:30') doctors.thursdayEnd = 48
  }
  function handleFridayStart(value) {
    if (value === '00:00') doctors.fridayStart = 1
    if (value === '00:30') doctors.fridayStart = 2
    if (value === '01:00') doctors.fridayStart = 3
    if (value === '01:30') doctors.fridayStart = 4
    if (value === '02:00') doctors.fridayStart = 5
    if (value === '02:30') doctors.fridayStart = 6
    if (value === '03:00') doctors.fridayStart = 7
    if (value === '03:30') doctors.fridayStart = 8
    if (value === '04:00') doctors.fridayStart = 9
    if (value === '04:30') doctors.fridayStart = 10
    if (value === '05:00') doctors.fridayStart = 11
    if (value === '05:30') doctors.fridayStart = 12
    if (value === '06:00') doctors.fridayStart = 13
    if (value === '06:30') doctors.fridayStart = 14
    if (value === '07:00') doctors.fridayStart = 15
    if (value === '07:30') doctors.fridayStart = 16
    if (value === '08:00') doctors.fridayStart = 17
    if (value === '08:30') doctors.fridayStart = 18
    if (value === '09:00') doctors.fridayStart = 19
    if (value === '09:30') doctors.fridayStart = 20
    if (value === '10:00') doctors.fridayStart = 21
    if (value === '10:30') doctors.fridayStart = 22
    if (value === '11:00') doctors.fridayStart = 23
    if (value === '11:30') doctors.fridayStart = 24
    if (value === '12:00') doctors.fridayStart = 25
    if (value === '12:30') doctors.fridayStart = 26
    if (value === '13:00') doctors.fridayStart = 27
    if (value === '13:30') doctors.fridayStart = 28
    if (value === '14:00') doctors.fridayStart = 29
    if (value === '14:30') doctors.fridayStart = 30
    if (value === '15:00') doctors.fridayStart = 31
    if (value === '15:30') doctors.fridayStart = 32
    if (value === '16:00') doctors.fridayStart = 33
    if (value === '16:30') doctors.fridayStart = 34
    if (value === '17:00') doctors.fridayStart = 35
    if (value === '17:30') doctors.fridayStart = 36
    if (value === '18:00') doctors.fridayStart = 37
    if (value === '18:30') doctors.fridayStart = 38
    if (value === '19:00') doctors.fridayStart = 39
    if (value === '19:30') doctors.fridayStart = 40
    if (value === '20:00') doctors.fridayStart = 41
    if (value === '20:30') doctors.fridayStart = 42
    if (value === '21:00') doctors.fridayStart = 43
    if (value === '21:30') doctors.fridayStart = 44
    if (value === '22:00') doctors.fridayStart = 45
    if (value === '22:30') doctors.fridayStart = 46
    if (value === '23:00') doctors.fridayStart = 47
    if (value === '23:30') doctors.fridayStart = 48
  }
  function handleFridayEnd(value) {
    if (value === '00:00') doctors.fridayEnd = 1
    if (value === '00:30') doctors.fridayEnd = 2
    if (value === '01:00') doctors.fridayEnd = 3
    if (value === '01:30') doctors.fridayEnd = 4
    if (value === '02:00') doctors.fridayEnd = 5
    if (value === '02:30') doctors.fridayEnd = 6
    if (value === '03:00') doctors.fridayEnd = 7
    if (value === '03:30') doctors.fridayEnd = 8
    if (value === '04:00') doctors.fridayEnd = 9
    if (value === '04:30') doctors.fridayEnd = 10
    if (value === '05:00') doctors.fridayEnd = 11
    if (value === '05:30') doctors.fridayEnd = 12
    if (value === '06:00') doctors.fridayEnd = 13
    if (value === '06:30') doctors.fridayEnd = 14
    if (value === '07:00') doctors.fridayEnd = 15
    if (value === '07:30') doctors.fridayEnd = 16
    if (value === '08:00') doctors.fridayEnd = 17
    if (value === '08:30') doctors.fridayEnd = 18
    if (value === '09:00') doctors.fridayEnd = 19
    if (value === '09:30') doctors.fridayEnd = 20
    if (value === '10:00') doctors.fridayEnd = 21
    if (value === '10:30') doctors.fridayEnd = 22
    if (value === '11:00') doctors.fridayEnd = 23
    if (value === '11:30') doctors.fridayEnd = 24
    if (value === '12:00') doctors.fridayEnd = 25
    if (value === '12:30') doctors.fridayEnd = 26
    if (value === '13:00') doctors.fridayEnd = 27
    if (value === '13:30') doctors.fridayEnd = 28
    if (value === '14:00') doctors.fridayEnd = 29
    if (value === '14:30') doctors.fridayEnd = 30
    if (value === '15:00') doctors.fridayEnd = 31
    if (value === '15:30') doctors.fridayEnd = 32
    if (value === '16:00') doctors.fridayEnd = 33
    if (value === '16:30') doctors.fridayEnd = 34
    if (value === '17:00') doctors.fridayEnd = 35
    if (value === '17:30') doctors.fridayEnd = 36
    if (value === '18:00') doctors.fridayEnd = 37
    if (value === '18:30') doctors.fridayEnd = 38
    if (value === '19:00') doctors.fridayEnd = 39
    if (value === '19:30') doctors.fridayEnd = 40
    if (value === '20:00') doctors.fridayEnd = 41
    if (value === '20:30') doctors.fridayEnd = 42
    if (value === '21:00') doctors.fridayEnd = 43
    if (value === '21:30') doctors.fridayEnd = 44
    if (value === '22:00') doctors.fridayEnd = 45
    if (value === '22:30') doctors.fridayEnd = 46
    if (value === '23:00') doctors.fridayEnd = 47
    if (value === '23:30') doctors.fridayEnd = 48
  }
  function handleSaturdayStart(value) {
    if (value === '00:00') doctors.saturdayStart = 1
    if (value === '00:30') doctors.saturdayStart = 2
    if (value === '01:00') doctors.saturdayStart = 3
    if (value === '01:30') doctors.saturdayStart = 4
    if (value === '02:00') doctors.saturdayStart = 5
    if (value === '02:30') doctors.saturdayStart = 6
    if (value === '03:00') doctors.saturdayStart = 7
    if (value === '03:30') doctors.saturdayStart = 8
    if (value === '04:00') doctors.saturdayStart = 9
    if (value === '04:30') doctors.saturdayStart = 10
    if (value === '05:00') doctors.saturdayStart = 11
    if (value === '05:30') doctors.saturdayStart = 12
    if (value === '06:00') doctors.saturdayStart = 13
    if (value === '06:30') doctors.saturdayStart = 14
    if (value === '07:00') doctors.saturdayStart = 15
    if (value === '07:30') doctors.saturdayStart = 16
    if (value === '08:00') doctors.saturdayStart = 17
    if (value === '08:30') doctors.saturdayStart = 18
    if (value === '09:00') doctors.saturdayStart = 19
    if (value === '09:30') doctors.saturdayStart = 20
    if (value === '10:00') doctors.saturdayStart = 21
    if (value === '10:30') doctors.saturdayStart = 22
    if (value === '11:00') doctors.saturdayStart = 23
    if (value === '11:30') doctors.saturdayStart = 24
    if (value === '12:00') doctors.saturdayStart = 25
    if (value === '12:30') doctors.saturdayStart = 26
    if (value === '13:00') doctors.saturdayStart = 27
    if (value === '13:30') doctors.saturdayStart = 28
    if (value === '14:00') doctors.saturdayStart = 29
    if (value === '14:30') doctors.saturdayStart = 30
    if (value === '15:00') doctors.saturdayStart = 31
    if (value === '15:30') doctors.saturdayStart = 32
    if (value === '16:00') doctors.saturdayStart = 33
    if (value === '16:30') doctors.saturdayStart = 34
    if (value === '17:00') doctors.saturdayStart = 35
    if (value === '17:30') doctors.saturdayStart = 36
    if (value === '18:00') doctors.saturdayStart = 37
    if (value === '18:30') doctors.saturdayStart = 38
    if (value === '19:00') doctors.saturdayStart = 39
    if (value === '19:30') doctors.saturdayStart = 40
    if (value === '20:00') doctors.saturdayStart = 41
    if (value === '20:30') doctors.saturdayStart = 42
    if (value === '21:00') doctors.saturdayStart = 43
    if (value === '21:30') doctors.saturdayStart = 44
    if (value === '22:00') doctors.saturdayStart = 45
    if (value === '22:30') doctors.saturdayStart = 46
    if (value === '23:00') doctors.saturdayStart = 47
    if (value === '23:30') doctors.saturdayStart = 48
  }
  function handleSaturdayEnd(value) {
    if (value === '00:00') doctors.saturdayEnd = 1
    if (value === '00:30') doctors.saturdayEnd = 2
    if (value === '01:00') doctors.saturdayEnd = 3
    if (value === '01:30') doctors.saturdayEnd = 4
    if (value === '02:00') doctors.saturdayEnd = 5
    if (value === '02:30') doctors.saturdayEnd = 6
    if (value === '03:00') doctors.saturdayEnd = 7
    if (value === '03:30') doctors.saturdayEnd = 8
    if (value === '04:00') doctors.saturdayEnd = 9
    if (value === '04:30') doctors.saturdayEnd = 10
    if (value === '05:00') doctors.saturdayEnd = 11
    if (value === '05:30') doctors.saturdayEnd = 12
    if (value === '06:00') doctors.saturdayEnd = 13
    if (value === '06:30') doctors.saturdayEnd = 14
    if (value === '07:00') doctors.saturdayEnd = 15
    if (value === '07:30') doctors.saturdayEnd = 16
    if (value === '08:00') doctors.saturdayEnd = 17
    if (value === '08:30') doctors.saturdayEnd = 18
    if (value === '09:00') doctors.saturdayEnd = 19
    if (value === '09:30') doctors.saturdayEnd = 20
    if (value === '10:00') doctors.saturdayEnd = 21
    if (value === '10:30') doctors.saturdayEnd = 22
    if (value === '11:00') doctors.saturdayEnd = 23
    if (value === '11:30') doctors.saturdayEnd = 24
    if (value === '12:00') doctors.saturdayEnd = 25
    if (value === '12:30') doctors.saturdayEnd = 26
    if (value === '13:00') doctors.saturdayEnd = 27
    if (value === '13:30') doctors.saturdayEnd = 28
    if (value === '14:00') doctors.saturdayEnd = 29
    if (value === '14:30') doctors.saturdayEnd = 30
    if (value === '15:00') doctors.saturdayEnd = 31
    if (value === '15:30') doctors.saturdayEnd = 32
    if (value === '16:00') doctors.saturdayEnd = 33
    if (value === '16:30') doctors.saturdayEnd = 34
    if (value === '17:00') doctors.saturdayEnd = 35
    if (value === '17:30') doctors.saturdayEnd = 36
    if (value === '18:00') doctors.saturdayEnd = 37
    if (value === '18:30') doctors.saturdayEnd = 38
    if (value === '19:00') doctors.saturdayEnd = 39
    if (value === '19:30') doctors.saturdayEnd = 40
    if (value === '20:00') doctors.saturdayEnd = 41
    if (value === '20:30') doctors.saturdayEnd = 42
    if (value === '21:00') doctors.saturdayEnd = 43
    if (value === '21:30') doctors.saturdayEnd = 44
    if (value === '22:00') doctors.saturdayEnd = 45
    if (value === '22:30') doctors.saturdayEnd = 46
    if (value === '23:00') doctors.saturdayEnd = 47
    if (value === '23:30') doctors.saturdayEnd = 48
  }
  function handleMondayStart(value) {
    if (value === '00:00') doctors.mondayStart = 1
    if (value === '00:30') doctors.mondayStart = 2
    if (value === '01:00') doctors.mondayStart = 3
    if (value === '01:30') doctors.mondayStart = 4
    if (value === '02:00') doctors.mondayStart = 5
    if (value === '02:30') doctors.mondayStart = 6
    if (value === '03:00') doctors.mondayStart = 7
    if (value === '03:30') doctors.mondayStart = 8
    if (value === '04:00') doctors.mondayStart = 9
    if (value === '04:30') doctors.mondayStart = 10
    if (value === '05:00') doctors.mondayStart = 11
    if (value === '05:30') doctors.mondayStart = 12
    if (value === '06:00') doctors.mondayStart = 13
    if (value === '06:30') doctors.mondayStart = 14
    if (value === '07:00') doctors.mondayStart = 15
    if (value === '07:30') doctors.mondayStart = 16
    if (value === '08:00') doctors.mondayStart = 17
    if (value === '08:30') doctors.mondayStart = 18
    if (value === '09:00') doctors.mondayStart = 19
    if (value === '09:30') doctors.mondayStart = 20
    if (value === '10:00') doctors.mondayStart = 21
    if (value === '10:30') doctors.mondayStart = 22
    if (value === '11:00') doctors.mondayStart = 23
    if (value === '11:30') doctors.mondayStart = 24
    if (value === '12:00') doctors.mondayStart = 25
    if (value === '12:30') doctors.mondayStart = 26
    if (value === '13:00') doctors.mondayStart = 27
    if (value === '13:30') doctors.mondayStart = 28
    if (value === '14:00') doctors.mondayStart = 29
    if (value === '14:30') doctors.mondayStart = 30
    if (value === '15:00') doctors.mondayStart = 31
    if (value === '15:30') doctors.mondayStart = 32
    if (value === '16:00') doctors.mondayStart = 33
    if (value === '16:30') doctors.mondayStart = 34
    if (value === '17:00') doctors.mondayStart = 35
    if (value === '17:30') doctors.mondayStart = 36
    if (value === '18:00') doctors.mondayStart = 37
    if (value === '18:30') doctors.mondayStart = 38
    if (value === '19:00') doctors.mondayStart = 39
    if (value === '19:30') doctors.mondayStart = 40
    if (value === '20:00') doctors.mondayStart = 41
    if (value === '20:30') doctors.mondayStart = 42
    if (value === '21:00') doctors.mondayStart = 43
    if (value === '21:30') doctors.mondayStart = 44
    if (value === '22:00') doctors.mondayStart = 45
    if (value === '22:30') doctors.mondayStart = 46
    if (value === '23:00') doctors.mondayStart = 47
    if (value === '23:30') doctors.mondayStart = 48
  }
  function handleMondayEnd(value) {
    if (value === '00:00') doctors.mondayEnd = 1
    if (value === '00:30') doctors.mondayEnd = 2
    if (value === '01:00') doctors.mondayEnd = 3
    if (value === '01:30') doctors.mondayEnd = 4
    if (value === '02:00') doctors.mondayEnd = 5
    if (value === '02:30') doctors.mondayEnd = 6
    if (value === '03:00') doctors.mondayEnd = 7
    if (value === '03:30') doctors.mondayEnd = 8
    if (value === '04:00') doctors.mondayEnd = 9
    if (value === '04:30') doctors.mondayEnd = 10
    if (value === '05:00') doctors.mondayEnd = 11
    if (value === '05:30') doctors.mondayEnd = 12
    if (value === '06:00') doctors.mondayEnd = 13
    if (value === '06:30') doctors.mondayEnd = 14
    if (value === '07:00') doctors.mondayEnd = 15
    if (value === '07:30') doctors.mondayEnd = 16
    if (value === '08:00') doctors.mondayEnd = 17
    if (value === '08:30') doctors.mondayEnd = 18
    if (value === '09:00') doctors.mondayEnd = 19
    if (value === '09:30') doctors.mondayEnd = 20
    if (value === '10:00') doctors.mondayEnd = 21
    if (value === '10:30') doctors.mondayEnd = 22
    if (value === '11:00') doctors.mondayEnd = 23
    if (value === '11:30') doctors.mondayEnd = 24
    if (value === '12:00') doctors.mondayEnd = 25
    if (value === '12:30') doctors.mondayEnd = 26
    if (value === '13:00') doctors.mondayEnd = 27
    if (value === '13:30') doctors.mondayEnd = 28
    if (value === '14:00') doctors.mondayEnd = 29
    if (value === '14:30') doctors.mondayEnd = 30
    if (value === '15:00') doctors.mondayEnd = 31
    if (value === '15:30') doctors.mondayEnd = 32
    if (value === '16:00') doctors.mondayEnd = 33
    if (value === '16:30') doctors.mondayEnd = 34
    if (value === '17:00') doctors.mondayEnd = 35
    if (value === '17:30') doctors.mondayEnd = 36
    if (value === '18:00') doctors.mondayEnd = 37
    if (value === '18:30') doctors.mondayEnd = 38
    if (value === '19:00') doctors.mondayEnd = 39
    if (value === '19:30') doctors.mondayEnd = 40
    if (value === '20:00') doctors.mondayEnd = 41
    if (value === '20:30') doctors.mondayEnd = 42
    if (value === '21:00') doctors.mondayEnd = 43
    if (value === '21:30') doctors.mondayEnd = 44
    if (value === '22:00') doctors.mondayEnd = 45
    if (value === '22:30') doctors.mondayEnd = 46
    if (value === '23:00') doctors.mondayEnd = 47
    if (value === '23:30') doctors.mondayEnd = 48
  }

  return (
    <div>
      <Container style={{ width: '95%' }} className="containerStyle">
        <Form className="my-4">
          <Row>
            <Col className="my-5">
              <Row>
                <Col style={{ textAlign: 'center' }}>
                  <TextField
                    name="fullName"
                    className="my-1"
                    onChange={handleInputChange}
                    value={doctors.fullName}
                    label="İsim Soyisim"
                  />
                  <TextField
                    name="identityNumber"
                    className="my-1"
                    label="TC Kimlik No"
                  />
                  <TextField
                    className="my-1"
                    label="Email"
                    name="email"
                    onChange={handleInputChange}
                    value={doctors.email}
                  />
                  <TextField
                    className="my-1"
                    onChange={handleInputChange}
                    label="Telefon Numarası"
                    name="phoneNumber"
                    value={doctors.phoneNumber}
                  />
                  <TextField
                    className="my-1"
                    label="Branş"
                    onChange={handleInputChange}
                    name="branch"
                    value={doctors.branch}
                  />
                  <TextField
                    className="my-1"
                    label="Komisyon Yüzdesi %"
                    onChange={handleInputChange}
                    name="commissionPercentage"
                    value={doctors.commissionPercentage}
                  />
                  <Autocomplete
                    style={{ width: '13.1rem', marginLeft: '3.1rem' }}
                    className="my-1"
                    disablePortal
                    id="combo-box-demo"
                    defaultValue={{
                      value: 1,
                      label: 'KDV Dahil',
                    }}
                    onChange={(value) => console.log(value)}
                    options={[
                      { value: 1, label: 'KDV Dahil' },
                      { value: 2, label: 'KDV Dahil Değil' },
                    ]}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Hakediş Şekli" />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col style={{ marginTop: '4.5rem' }}>
              <div className="">
                <div className="daySelectWrapper">
                  <div className="doctorDateHeader">
                    <span className="doctorDateHeaderDay">Gün Seçimi</span>
                    <span className="doctorDateHeaderStart">
                      Başlangıç Saati
                    </span>
                    <span className="doctorDateHeaderEnd">Bitiş Saati</span>
                  </div>

                  <div className="daysMenuBody">
                    <div className="doctorsTimeDays">
                      <Form>
                        <Form.Check
                          className="formCheck"
                          type="switch"
                          id="custom-switch"
                          label="Pazartesi"
                          onClick={() => (doctors.sunday = true)}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Salı"
                          onClick={() => (doctors.tuesday = true)}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Çarşamba"
                          onClick={() => (doctors.wednesday = true)}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Perşembe"
                          onClick={() => (doctors.thursday = true)}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Cuma"
                          onClick={() => (doctors.friday = true)}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Cumartesi"
                          onClick={() => (doctors.saturday = true)}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Pazar"
                          onClick={() => (doctors.monday = true)}
                        />
                      </Form>
                    </div>

                    <div className="doctorsTimeStart">
                      <select
                        onChange={(e) => handleSundayStart(e.target.value)}
                      >
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                      <select
                        onChange={(e) => handleTuesdayStart(e.target.value)}
                      >
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                      <select
                        onChange={(e) => handleWednesdayStart(e.target.value)}
                      >
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                      <select
                        onChange={(e) => handleThursdayStart(e.target.value)}
                      >
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                      <select
                        onChange={(e) => handleFridayStart(e.target.value)}
                      >
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                      <select
                        onChange={(e) => handleSaturdayStart(e.target.value)}
                      >
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                      <select
                        onChange={(e) => handleMondayStart(e.target.value)}
                      >
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                    </div>
                    <div className="doctorsTimeEnd">
                      <select onChange={(e) => handleSundayEnd(e.target.value)}>
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                      <select
                        onChange={(e) => handleTuesdayEnd(e.target.value)}
                      >
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                      <select
                        onChange={(e) => handleWednesdayEnd(e.target.value)}
                      >
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                      <select
                        onChange={(e) => handleThursdayEnd(e.target.value)}
                      >
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                      <select onChange={(e) => handleFridayEnd(e.target.value)}>
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                      <select
                        onChange={(e) => handleSaturdayEnd(e.target.value)}
                      >
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                      <select onChange={(e) => handleMondayEnd(e.target.value)}>
                        <option>00:00</option>
                        <option>00:30</option>
                        <option>01:00</option>
                        <option>01:30</option>
                        <option>02:00</option>
                        <option>02:30</option>
                        <option>03:00</option>
                        <option>03:30</option>
                        <option>04:00</option>
                        <option>04:30</option>
                        <option>05:00</option>
                        <option>05:30</option>
                        <option>06:00</option>
                        <option>06:30</option>
                        <option>07:00</option>
                        <option>07:30</option>
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                        <option>22:30</option>
                        <option>23:00</option>
                        <option>23:30</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <div className='flexCenter'>
        <Button
          disabled={drStore.loading}
          onClick={() => {
            newDoctor()
            if (doctors.fullName === '') {
              toast.error('Lütfen bir isim soyisim giriniz!')
            }
            if (doctors.email === '' || !doctors.email.includes('@')) {
              toast.error('Lütfen geçerli bir email giriniz!')
            }
            if (doctors.phoneNumber === '') {
              toast.error('Lütfen bir telefon numarası giriniz!')
            }
            if (doctors.branch === '') {
              toast.error('Lütfen bir branş giriniz!')
            }
          }}
          type="submit"
          variant="danger"
          className="doctorAddBtn"
        >
          {drStore.loading ? 'Ekleniyor...' : 'Eklemek için tıklayınız...'}
        </Button>
        </div>
      </Container>
    </div>
  )
})
