import React from 'react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useStore } from '../../stores/store'
import '../finance/finance.css'
import { Button, Accordion } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

let doctorBasedReportSelection = {
  id: 'doctorBasedReportSelection',
  planCount: false,
  approvedPlanCount: false,
  patientCount: false,
  totalReceiveCount: false,
  totalPayment: false,
  completedTreatmentsCount: false,
  appointmentCount: false,
  startDate: new Date(),
  endDate: new Date()
}

let patientBasedReportSelection = {
  id: 'patientBasedReportSelection',
  totalDept: false,
  totalPayment: false,
  completedTreatmentsCount: false,
  appointmentCount: false,
  startDate: new Date(),
  endDate: new Date()
}

let treatmentsBasedReportSelection = {
  id: 'treatmentsBasedReportSelection',
  mostSelectedTreatment: true,
  startDate: new Date(),
  endDate: new Date()
}

let patientAdvisorBasedReportSelection = {
  id: 'patientAdvisorBasedReportSelection',
  givenOfferCount: false,
  approvedOfferCount: false,
  startDate: new Date(),
  endDate: new Date()

}

let financeReportSelection = {
  id: 'financeReportSelection',
  creditCardPayments: false,
  cashPayments: false,
  startDate: new Date(),
  endDate: new Date()
}

function objectNameFinder(object) {
  switch (object) {
    case 'doctorBasedReportSelection':
      return doctorBasedReportSelection
    case 'patientBasedReportSelection':
      return patientBasedReportSelection
    case 'treatmentsBasedReportSelection':
      return treatmentsBasedReportSelection
    case 'patientAdvisorBasedReportSelection':
      return patientAdvisorBasedReportSelection
    case 'financeReportSelection':
      return financeReportSelection
    default:
      break
  }
}

// function checkboxCheckedhandler(isChecked, field, key) {
//   console.log(isChecked)
//   console.log(field)
//   console.log(key)
//   const selectedReportObjectToSend = objectNameFinder(key)
//   selectedReportObjectToSend[field] = isChecked
//   selectedReportObjectToSend.startDate = filter.startDate
//   console.log(selectedReportObjectToSend)
//   console.log('-----------------------------------------------------------')
// }

export default observer(function Report() {
  const { drStore, planStore } = useStore()
  const { filterPlans } = planStore

  const initialState = {
    id: '',
    startDate: '',
    endDate: '',
  }
  // let booleanDateControlIsSuccess = true;
  const [filter, setFilter] = useState(initialState)
  const [booleanDateControlIsSuccess, setBooleanDateControlIsSuccess] =useState()
    useState()
  function handleInputChange(event) {
    const { name, value } = event.target
    console.log('name: ', name, 'value: ', value)
    // console.log(drStore);
    setFilter({ ...filter, [name]: value }) //id: drStore.selectedDoctor.id
  }
  function handleFilter() {
    if (filter.startDate !== '' && filter.endDate !== '') {
      // filterPlans(filter);
      let reportFilterStartDate = new Date(filter.startDate)
      let reportFilterEndDate = new Date(filter.endDate)

      if (reportFilterStartDate > reportFilterEndDate) {
        setBooleanDateControlIsSuccess(false)
      } else {
        setBooleanDateControlIsSuccess(true)
      }
      console.log(booleanDateControlIsSuccess)
      console.log(filter.startDate)
      console.log(filter.endDate)
    }
  }

  function checkboxCheckedhandler(isChecked, field, key) {
    console.log(isChecked)
    console.log(field)
    console.log(key)
    const selectedReportObjectToSend = objectNameFinder(key)
    selectedReportObjectToSend[field] = isChecked
    selectedReportObjectToSend.startDate = filter.startDate
    selectedReportObjectToSend.endDate = filter.endDate
    console.log(selectedReportObjectToSend)
    console.log('-----------------------------------------------------------')
  }

  return (
    <div style={{ width: '100%' }} className="container">
      <div className="col-sm-12 my-1">
        <div className="card cardStyle">
          <div className="card-body">
            <h5 className="card-title patientCardTitle">Tarih Aralığı</h5>
            <div>
              <span className="spanText">Başlangıç Tarihi:</span>
              <input
                className=" dateRangePicker"
                type="date"
                name="startDate"
                value={filter.startDate}
                onChange={handleInputChange}
              />
              <span className="spanText">Bitiş Tarihi:</span>
              <input
                className="dateRangePicker "
                type="date"
                name="endDate"
                value={filter.endDate}
                onChange={handleInputChange}
              />
              <Button
                variant="outline-dark"
                disabled={planStore.loading}
                onClick={() => {
                  handleFilter()
                  if (filter.startDate === '') {
                    toast.error('Lütfen başlangıç tarihi seçiniz!')
                  }
                  if (filter.endDate === '') {
                    toast.error('Lütfen bitiş tarihi seçiniz!')
                  }
                }}
              >
                {planStore.loading ? 'Yükleniyor...' : 'Tarih aralığı seç'}
              </Button>
            </div>
          </div>
          <div className="row">
          {console.log("test",booleanDateControlIsSuccess)}
          {booleanDateControlIsSuccess ? (
            <></>
          ) : (
            <span className='spanText'>
              Lütfen Geçerli bir Tarih Aralığı Seçiniz - başlangıç tarihi bitiş
              tarihinden büyük olamaz
            </span>
          )}
        </div>
        </div>
        
      </div>

      <div className="col-sm-12 my-1">
        <div className="card cardStyle">
          <div className="card-body">
            <h5 className="card-title patientCardTitle">Rapor Sayfası</h5>
            <div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Doktor Raporu</Accordion.Header>
                  <Accordion.Body>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Plan Sayısı
                      </span>
                      <input
                        type="checkbox"
                        id="doctorBasedReportSelection"
                        name="planCount"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Hasta Sayısı
                      </span>
                      <input
                        type="checkbox"
                        id="doctorBasedReportSelection"
                        name="patientCount"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Toplam Alacak
                      </span>
                      <input
                        type="checkbox"
                        id="doctorBasedReportSelection"
                        name="totalReceiveCount"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Toplam Ödeme
                      </span>
                      <input
                        type="checkbox"
                        id="doctorBasedReportSelection"
                        name="totalPayment"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Tamamlanan Tedavi Sayısı
                      </span>
                      <input
                        type="checkbox"
                        id="doctorBasedReportSelection"
                        name="completedTreatmentsCount"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Randevu Sayısı
                      </span>
                      <input
                        type="checkbox"
                        id="doctorBasedReportSelection"
                        name="appointmentCount"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <br />
                    <br />
                    <Button variant="danger">
                      <Link
                        to={'/special-reports'}
                        state={{ reportTypeObject: doctorBasedReportSelection }}
                        style={{ color: 'white' }}
                        className="nolinkdecoration "
                      >
                        <span>Rapor Al</span>
                      </Link>
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Hasta Raporu</Accordion.Header>
                  <Accordion.Body>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Toplam Borç
                      </span>
                      <input
                        type="checkbox"
                        id="patientBasedReportSelection"
                        name="totalDept"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Toplam Ödeme
                      </span>
                      <input
                        type="checkbox"
                        id="patientBasedReportSelection"
                        name="totalPayment"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Tamamlanan Tedavi Sayısı
                      </span>
                      <input
                        type="checkbox"
                        id="patientBasedReportSelection"
                        name="completedTreatmentsCount"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Randevu Sayısı
                      </span>
                      <input
                        type="checkbox"
                        id="patientBasedReportSelection"
                        name="appointmentCount"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <br />
                    <br />
                    <Button variant="danger">
                      <Link
                        to={'/special-reports'}
                        state={{
                          reportTypeObject: patientBasedReportSelection,
                        }}
                        style={{ color: 'white' }}
                        className="nolinkdecoration "
                      >
                        <span>Rapor Al</span>
                      </Link>
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Tedavi Raporu</Accordion.Header>
                  <Accordion.Body>
                    <Button variant="danger">
                      <Link
                        to={'/special-reports'}
                        state={{
                          reportTypeObject: treatmentsBasedReportSelection,
                        }}
                        style={{ color: 'white' }}
                        className="nolinkdecoration "
                      >
                        <span>Rapor Al</span>
                      </Link>
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Hasta Danışman Raporu</Accordion.Header>
                  <Accordion.Body>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Verilen Teklif Sayısı
                      </span>
                      <input
                        type="checkbox"
                        id="patientAdvisorBasedReportSelection"
                        name="givenOfferCount"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Onaylanan Teklif Sayısı
                      </span>
                      <input
                        type="checkbox"
                        id="patientAdvisorBasedReportSelection"
                        name="approvedOfferCount"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <br />
                    <br />
                    <Button variant="danger">
                      <Link
                        to={'/special-reports'}
                        state={{
                          reportTypeObject: patientAdvisorBasedReportSelection,
                        }}
                        style={{ color: 'white' }}
                        className="nolinkdecoration "
                      >
                        <span>Rapor Al</span>
                      </Link>
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Finans Raporu</Accordion.Header>
                  <Accordion.Body>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Kredi Kartı Tahsilatları
                      </span>
                      <input
                        type="checkbox"
                        id="financeReportSelection"
                        name="creditCardPayments"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <span className="checkboxRightMargin">
                      <span className="checkboxRightMarginForInput">
                        Nakit Tahsilatlar
                      </span>
                      <input
                        type="checkbox"
                        id="financeReportSelection"
                        name="cashPayments"
                        onChange={(e) => {
                          checkboxCheckedhandler(
                            e.target.checked,
                            e.target.name,
                            e.target.id,
                          )
                        }}
                      />
                    </span>
                    <br />
                    <br />
                    <Button variant="danger">
                      <Link
                        to={'/special-reports'}
                        state={{ reportTypeObject: financeReportSelection }}
                        style={{ color: 'white' }}
                        className="nolinkdecoration "
                      >
                        <span>Rapor Al</span>
                      </Link>
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
