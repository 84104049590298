import React from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Container, Form } from "react-bootstrap";
import "./finance.css";
import TextField from "@mui/material/TextField";
import Receipt from "../receip/Receipt";
import moment from "moment";
import Collection from "../finance/Collection";

export default observer(function PatientCollections() {
  const { planStore, financeStore, userStore } = useStore();
  const { selectedPlan, resetForm, planSelected } = planStore;

  const {
    getCollectionPlanByPlanId,
    selectedCollectionPlan,
    resetPlanForm,
    loadCollectionPlans,
    collectionPlanSelected,
    resetGetCollection,
    getCollectionsByInstallmentId,
    loading,
    clearCollections,
    totalPaid,
  } = financeStore;

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (id, value) => {
    console.log(id);
    if (id !== undefined) {
      getCollectionsByInstallmentId(id, value);
      setShow(true);
    } else {
      setShow(true);
    }
  };
  const [showCollection, setShowCollection] = useState(false);

  const handleCloseCollection = () => {
    setShowCollection(false);
  };
  const handleShowCollection = (id, value) => {
    console.log(id);
    financeStore.editCollection = true;
    if (id !== undefined) {
      getCollectionsByInstallmentId(id, value);
      setShowCollection(true);
    }
  };

  const initialState = selectedPlan ?? {
    id: " ",
    name: "",
    patientID: "",
    createdTime: "",
    isActive: 1,
    conditions: [],
    treatmentStages: {
      id: "",
      name: "",
      description: "",
      planId: "",
      createdTime: "",
      isActive: 1,
      treatments: [],
    },
  };
  const [plan, setPlan] = useState(initialState);

  const initialState2 = selectedCollectionPlan ?? {
    id: "",
    patientId: "",
    planId: "",
    totalDebt: 0,
    collectionType: 0,
    discountRate: 0,
    discountedDebt: 0,
    numberOfInstallments: 0,
    installments: [],
  };
  const [collectionPlan, setCollectionPlan] = useState(initialState2);

  useEffect(() => {
    setPlan(initialState);
    loadCollectionPlans();
  }, [resetForm]);

  useEffect(() => {
    getCollectionPlanByPlanId(plan.id);
  }, [resetGetCollection]);

  useEffect(() => {
    setCollectionPlan(initialState2);
  }, [resetPlanForm]);

  function handleCollection(id) {
    console.log(id);
  }

  if (!planSelected)
    return (
      <Button className="loadingBtnStyle" variant="danger" disabled>
        Lütfen Bir Plan Seçiniz...
      </Button>
    );

  if (!collectionPlanSelected)
    return (
      <Button className="loadingBtnStyle" variant="danger" disabled>
        Seçili plana ait ödeme planı tanımlanmamış...
      </Button>
    );

  if (loading)
    return (
      <Button className="loadingBtnStyle" variant="danger" disabled>
        Yükleniyor...
      </Button>
    );

  return (
    <div>
      <Container>
        <Form className="my-4" style={{ textAlign: "center" }}>
          <Row>
            <Col>
              <TextField
                className="my-1"
                label="Toplam Borç"
                value={collectionPlan.totalDebt}
              />
            </Col>
            <Col>
              <TextField
                className="my-1"
                label="İskonto"
                value={"%" + collectionPlan.discountRate}
              />
            </Col>
            <Col>
              <TextField
                className="my-1"
                label="İndirim Sonrası Borç"
                value={collectionPlan.discountedDebt}
              />
            </Col>
            <Col>
              <TextField
                className="my-1"
                label="Kalan Borç"
                value={collectionPlan.discountedDebt - totalPaid}
              />
            </Col>
          </Row>
          {collectionPlan.installments.map((installment) => (
            <Row key={installment.id}>
              <Col>
                {" "}
                <TextField
                  className="my-1"
                  label={installment.installmentNumber + ". Taksit"}
                  value={installment.installmentAmount}
                />
              </Col>
              <Col>
                {" "}
                <TextField
                  className="my-1"
                  label={installment.installmentNumber + ". Taksit Tarihi"}
                  value={moment(installment.installmentDate)
                    .utc()
                    .format("DD-MM-YYYY")}
                />
              </Col>
              <Col>
                {installment.isPaid && (
                  <label
                    style={{ backgroundColor: "green" }}
                    className="my-1 labelStyle"
                  >
                    <span className="labelSpan">Ödeme Yapıldı</span>{" "}
                  </label>
                )}
                {!installment.isPaid && (
                  <label
                    style={{ backgroundColor: "red" }}
                    className="my-1 labelStyle"
                  >
                    <span className="labelSpan">Ödeme Yapılmadı</span>{" "}
                  </label>
                )}
              </Col>
              {!installment.isPaid && (
                <Col>
                  {userStore.grandPermissions.collectionCreate && (
                    <Button
                      variant="outline-dark"
                      className="my-1 btncss"
                      onClick={() =>
                        handleShowCollection(installment.id, false)
                      }
                    >
                      Ödeme Yap
                    </Button>
                  )}
                </Col>
              )}
              {installment.isPaid && (
                <Col>
                  <Button
                    variant="outline-dark"
                    className="my-1 btncss"
                    onClick={() => handleShow(installment.id, true)}
                  >
                    Görüntüle
                  </Button>
                </Col>
              )}
            </Row>
          ))}

          <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Ödeme Sayfası</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Receipt />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
            </Modal.Footer>
          </Modal>
          {financeStore.editCollection === true && (
            <Modal show={showCollection} onHide={handleCloseCollection}>
              <Modal.Header closeButton>
                <Modal.Title>Ödeme Sayfası</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Collection />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseCollection}>
                  Kapat
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Form>
      </Container>
    </div>
  );
});
