import React from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceDayGridPlugin from "@fullcalendar/resource-timegrid";
import AddAppointment from "../calendar/AddAppointment";
import { useStore } from "../../stores/store";
import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Button, Modal } from "react-bootstrap";
import scrollGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { toast } from "react-toastify";
import allLocales from "@fullcalendar/core/locales-all";

export default observer(function Calendar() {
  let message = undefined;
  const { drStore, appointmentStore, userStore } = useStore();
  const [doctors, setDoctors] = useState(null);
  const [appointments, setAppointments] = useState(null);
  const { appointmentsLoaded, setEditedAppointment } = appointmentStore;
  const color = [
    "maroon",
    "red",
    "purple",
    "silver",
    "fuchsia",
    "green",
    "gray",
    "lime",
    "olive",
    "yellow",
    "navy",
    "blue",
    "teal",
    "aqua",
    "maroon",
    "red",
    "purple",
    "silver",
    "fuchsia",
    "green",
    "gray",
    "lime",
    "olive",
    "yellow",
    "navy",
    "blue",
    "teal",
    "aqua",
  ];

  useEffect(() => {
    drStore.getDr();
  }, [drStore.dr]);

  useEffect(() => {
    appointmentStore.loadAppointments();
  }, [appointmentsLoaded]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    appointmentStore.addAppointment = true;
    setShow(true);
  };

  function someMethod() {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.next();
  }

  function handleDateSelect(selectInfo) {
    let model = {
      id: "",
      doctorId: selectInfo.resource.id,
      patientId: "",
      title: "",
      appointmentStart: selectInfo.startStr,
      appointmentEnd: selectInfo.endStr,
      note:"",
      confirmation: false
    };
    setEditedAppointment(model);
    handleShow();
  }
  function handleDateClick(clickInfo) {}
  function handleEventClick(info) {
    appointmentStore.selectAppointment(info);
    handleShow()
      // if (
      //   window.confirm(
      //     `${info.event.title}' bilgili randevuyu silmek istediğinizden emin misiniz?'`
      //   )
      // ) {
      //   appointmentStore.deleteAppointment(info.event.id);
      // }
  }

  function handleEventResize(eventResizeInfo) {
      if (
        window.confirm(
          eventResizeInfo.event.title +
            " randevusunu " +
            eventResizeInfo.event.end.toISOString() +
            " saatinde bitecek şekilde güncellemek istediğinizden emin misiniz?"
        )
      ) {
        {
          console.log("resize")
          let model = {
            id: eventResizeInfo.event.id,
            doctorId: eventResizeInfo.event._def.resourceIds[0],
            appointmentStart: eventResizeInfo.event.start.toISOString(),
            appointmentEnd: eventResizeInfo.event.end.toISOString(),
          };
          appointmentStore.updateAppointment(model);
        }
      } else {
        eventResizeInfo.revert();
      }
    
  }
  function handleEventDrop(eventDropInfo) {
      if (eventDropInfo.oldResource) {
        if (
          window.confirm(
            eventDropInfo.oldResource.title +
              " adlı doktorun randevusunu " +
              eventDropInfo.newResource.title +
              " adlı doktora atamak istediğinizden emin misiniz?"
          )
        ) {
          let model = {
            id: eventDropInfo.event.id,
            doctorId: eventDropInfo.newResource.id,
            appointmentStart: eventDropInfo.event.start.toISOString(),
            appointmentEnd: eventDropInfo.event.end.toISOString(),
          };
          appointmentStore.updateAppointment(model);
        } else {
          eventDropInfo.revert();
        }
      } else {
        if (
          window.confirm(
            eventDropInfo.event.title +
              " randevusunu " +
              eventDropInfo.event.start.toISOString().substring(11, 16) +
              " - " +
              eventDropInfo.event.end.toISOString().substring(11, 16) +
              " saat aralığına almak istediğinizden emin misiniz?"
          )
        ) {
          let model = {
            id: eventDropInfo.event.id,
            doctorId: eventDropInfo.event._def.resourceIds[0],
            appointmentStart: eventDropInfo.event.start.toISOString(),
            appointmentEnd: eventDropInfo.event.end.toISOString(),
          };
          appointmentStore.updateAppointment(model);
        } else {
          eventDropInfo.revert();
        }
      }
  }

  return (
    <div className="  demo-app-main " style={{ width: "100%" }}>
      <FullCalendar
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        plugins={[
          resourceTimeGridPlugin,
          resourceDayGridPlugin,
          interactionPlugin,
          scrollGridPlugin,
        ]}
        initialView="resourceTimeGridDay"
        //dayMinWidth={200} //scroll için(scroll premium gerektiriyor)
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "resourceTimeGridDay,resourceTimeGridTwoDay",
        }}
        locales={allLocales}
        views={{
          resourceTimeGridTwoDay: {
            type: "resourceTimeGrid",
            duration: { days: 2 },
            buttonText: "2 Günlük Görüntü",
          },
        }}
        resourceOrder="title"
        resources={
          drStore.doctors
            ? drStore.doctors.map((doctor, index) => ({
                id: doctor.id,
                title: doctor.fullName,
                eventColor: color[index],
              }))
            : []
        }
        events={
          appointmentStore.appointments
            ? appointmentStore.appointments.map((appointment) => ({
                id: appointment.id,
                resourceId: appointment.doctorId,
                start: appointment.appointmentStart,
                end: appointment.appointmentEnd,
                title: appointment.title,
              }))
            : []
        }
        buttonText={{
          day: "1 Günlük Görüntü",
          today: "Bugüne Gel",
          week: "Hafta",
        }}
        firstDay={1}
        eventOverlap={false}
        editable={true}
        selectable={true}
        dateClick={handleDateClick}
        //ref={()=>this.calendarRef}
        eventClick={handleEventClick}
        eventResize={handleEventResize}
        eventDrop={handleEventDrop}
        select={handleDateSelect}
        timeZone={"UTC"}
        locale={"tr"}
        eventTimeFormat={{
          // like '14:30:00'
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }}
      />
      {appointmentStore.addAppointment && (
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Body>
            <AddAppointment />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
});
