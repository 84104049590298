import React from "react";
import { Button } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { eventTupleToStore } from "@fullcalendar/react";
import { useReactToPrint } from "react-to-print";
import "./receipt.css";

export default observer(function Receipt() {
  const { financeStore, patientStore } = useStore();
  const {
    selectedCollectionPlan,
    collections,
    resetReceiptState,
    setReceipt,
    setReceiptState,
    addCollection,
  } = financeStore;
  const { selectedPatient, selectPatient } = patientStore;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  var str = "";

  const initialState2 = selectedCollectionPlan ?? {
    id: " ",
    patientId: "",
    planId: "",
    totalDebt: 0,
    collectionType: 0,
    discountRate: 0,
    discountedDebt: 0,
    numberOfInstallments: 0,
    installments: [],
  };
  const [collectionPlan, setCollectionPlan] = useState(initialState2);

  useEffect(() => {
    setCollectionPlan(initialState2);
  }, []);

  const initialPatientState = selectedPatient ?? {
    id: " ",
    identityNumber: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    gender: true,
    note: "",
    isActive: 1,
  };
  const [patient, setPatient] = useState(initialPatientState);

  const initialState = collections ?? {
    id: " ",
    patientId: " ",
    installmentId: "",
    collectionByCash: 0,
    collectionByCreditCard: 0,
    collectionByCheck: 0,
    totalCollection: 0,
    currency: 0,
    collectionDate: "",
    userId: "",
  };
  const [collection, setCollection] = useState(initialState);

  useEffect(() => {
    setCollection(initialState);
    setPatient(initialPatientState);
    if (collection.patientId === " ") {
      setReceipt(collection, collectionPlan.patientId);
      setCollection(initialState);
    }
  }, [resetReceiptState]);

  function handleInputChange(event) {
    setReceiptState(event);
  }

  function handleCreate() {
    addCollection(collection);
  }
  function cevir(sayi, separator) {
    var sayarr = sayi.split(separator);
    var items = [
      ["", ""],
      ["BIR", "ON"],
      ["IKI", "YIRMI"],
      ["UC", "OTUZ"],
      ["DORT", "KIRK"],
      ["BES", "ELLI"],
      ["ALTI", "ALTMIS"],
      ["YEDI", "YETMIS"],
      ["SEKIZ", "SEKSEN"],
      ["DOKUZ", "DOKSAN"],
    ];
    for (let eleman = 0; eleman < sayarr.length; eleman++) {
      for (let basamak = 1; basamak <= sayarr[eleman].length; basamak++) {
        let basamakd = 1 + (sayarr[eleman].length - basamak);
        try {
          switch (basamakd) {
            case 6:
              str =
                str +
                " " +
                items[sayarr[eleman].charAt(basamak - 1)][0] +
                " YUZ";
              break;
            case 5:
              str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][1];
              break;
            case 4:
              if (items[sayarr[eleman].charAt(basamak - 1)][0] !== "BIR")
                str =
                  str +
                  " " +
                  items[sayarr[eleman].charAt(basamak - 1)][0] +
                  " BIN";
              else str = str + " BIN";
              break;
            case 3:
              if (items[sayarr[eleman].charAt(basamak - 1)][0] === "") {
                str = str + "";
              } else if (items[sayarr[eleman].charAt(basamak - 1)][0] !== "BIR")
                str =
                  str +
                  " " +
                  items[sayarr[eleman].charAt(basamak - 1)][0] +
                  " YUZ";
              else str = str + " YUZ";
              break;
            case 2:
              str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][1];
              break;
            default:
              str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][0];
              break;
          }
        } catch (err) {
          alert(err.description);
          alert("eleman" + basamak);
          break;
        }
      }
      if (eleman !== 1) str = str + " TL";
      else {
        if (sayarr[1] !== "00") str = str + " KRS";
      }
    }
    return str;
  }

  if (collectionPlan.id === " " || patient.id === " ")
    return (
      <Button className="loadingBtnStyle" disabled>
        Yükleniyor...
      </Button>
    );

  if (collection.id !== " ") {
    return (
      <div>
        <div
          ref={componentRef}
          style={{ width: "100%", border: "1px solid black" }}
        >
          <div className="box">
            <div className="receiptTitle">TAHSİLAT MAKBUZU</div>
            <div className="receiptHead">
              <span className="mx-2">Tarih: </span>
              <input
                className="tableInputStyle"
                value={moment(collection.collectionDate)
                  .utc()
                  .format("YYYY-MM-DD")}
              />
            </div>
            <div className="receiptHeadNo">
              <span className="mx-2">Seri No: </span>
              <input
                className="tableInputStyle"
                style={{ width: "5rem" }}
                type="number"
              />
            </div>
            <div className="receiptHeadNo">
              <span className="mx-2">Sıra No: </span>
              <input
                className="tableInputStyle"
                style={{ width: "5rem" }}
                type="number"
              />
            </div>
          </div>

          <div className="box1"></div>

          <div className="tableDiv">
            {" "}
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    style={{ width: "10%" }}
                    scope="col"
                    className="theadStyle"
                  >
                    <span>Ödeyen Hasta: </span>{" "}
                    <input
                      className="tableInputStyle"
                      type="text"
                      name=""
                      value={patient.fullName}
                    />
                  </th>

                  <th
                    scope="col"
                    style={{ width: "100%" }}
                    className="theadStyle theadStyleMin"
                  >
                    <span>Kimlik Numarası: </span>{" "}
                    <input
                      className="tableInputStyle"
                      type="text"
                      name=""
                      value={patient.identityNumber}
                    />
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th scope="col" className="theadStyle">
                    İZAHAT
                  </th>
                  <th scope="col" className="theadStyle theadStyleMin">
                    Lira
                  </th>
                  <th scope="col" className="theadStyle theadStyleMin">
                    Kr.
                  </th>
                  <th
                    scope="col"
                    style={{ width: "15½" }}
                    className="theadStyle theadStyleMin"
                  >
                    Tahsil Eden
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nakit ödeme</td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      className="tableInputStyle"
                      type="text"
                      name=""
                      value={
                        collection.collectionByCash -
                        (collection.collectionByCash % 1)
                      }
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      className="tableInputStyle"
                      type="text"
                      name=""
                      value={
                        (collection.collectionByCash % 1) * 100 -
                        (((collection.collectionByCash % 1) * 100) % 1)
                      }
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input className="tableInputStyle" type="text" name="" />
                  </td>
                </tr>
                <tr>
                  <td>Kredi Kartı</td>
                  <td style={{ textAlign: "center" }}>
                    <input className="tableInputStyle" type="text" name="" />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input className="tableInputStyle" type="text" name="" />
                  </td>
                </tr>
                <tr>
                  <td>ÇEK ile ödeme</td>
                  <td style={{ textAlign: "center" }}>
                    <input className="tableInputStyle" type="text" name="" />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input className="tableInputStyle" type="text" name="" />
                  </td>
                </tr>
                <tr>
                  <td>Tahsil edilen Yekün</td>
                  <td style={{ textAlign: "center" }}>
                    <input className="tableInputStyle" type="text" name="" />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input className="tableInputStyle" type="text" name="" />
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>

          <div className="tableEnd">
            {" "}
            <span>Yalnız</span>{" "}
            <input
              className="tableInputStyleText"
              type="text"
              name=""
              value={cevir(collection.totalCollection.toString(), ".")}
            />{" "}
          </div>

          <table className="table table-sm">
            <thead>
              <tr>
                <th style={{ width: "3%" }} scope="col"></th>
                <th scope="col">BANKA</th>
                <th scope="col">ŞUBE</th>
                <th scope="col">IBAN / Hes.No.</th>
                <th scope="col">ÇEK NO.</th>
                <th scope="col">ÇEK TARİHİ</th>
                <th scope="col">TUTARI</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="_receiptTableFooter">
            <div>
              <div className="receiptSignatureBox">İMZA</div>
              <div>ÖDEME YAPAN</div>
              <div>{patientStore.selectedPatient.fullName}</div>
            </div>

            <div>
              <div className="receiptSignatureBox">İMZA</div>
              <div>ÖDEME ALAN</div>
              <div>{sessionStorage.getItem("userName")}</div>
            </div>
          </div>
        </div>
        <Button
          className="receiptBtn mx-1 my-1"
          variant="outline-dark"
          onClick={handlePrint}
        >
          Yazdır
        </Button>
      </div>
    );
  }
  if (collection.id === " ") {
    return (
      <div>
        <div
          ref={componentRef}
          style={{ width: "100%", border: "1px solid black" }}
        >
          <div className="box">
            <div className="receiptTitle">TAHSİLAT MAKBUZU</div>
            <div className="receiptHead">
              <span className="mx-2">Tarih: </span>
              <input
                className="tableInputStyle"
                type="date"
                value={moment(collection.collectionDate)
                  .utc()
                  .format("YYYY-MM-DD")}
              />
            </div>
            <div className="receiptHeadNo">
              <span className="mx-2">Seri No: </span>
              <input
                className="tableInputStyle"
                style={{ width: "5rem" }}
                type="number"
              />
            </div>
            <div className="receiptHeadNo">
              <span className="mx-2">Sıra No: </span>
              <input
                className="tableInputStyle"
                style={{ width: "5rem" }}
                type="number"
              />
            </div>
          </div>

          <div className="box1"></div>

          <div className="tableDiv">
            {" "}
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    style={{ width: "10%" }}
                    scope="col"
                    className="theadStyle"
                  >
                    <span>Ödeyen Hasta: </span>{" "}
                    <input
                      className="tableInputStyle"
                      type="text"
                      name=""
                      value={patient.fullName}
                    />
                  </th>

                  <th
                    scope="col"
                    style={{ width: "100%" }}
                    className="theadStyle theadStyleMin"
                  >
                    <span>Kimlik Numarası: </span>{" "}
                    <input
                      className="tableInputStyle"
                      type="text"
                      name=""
                      value={patient.identityNumber}
                    />
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th scope="col" className="theadStyle">
                    İZAHAT
                  </th>
                  <th scope="col" className="theadStyle theadStyleMin">
                    Lira
                  </th>
                  <th scope="col" className="theadStyle theadStyleMin">
                    Kr.
                  </th>
                  <th
                    scope="col"
                    style={{ width: "15½" }}
                    className="theadStyle theadStyleMin"
                  >
                    Tahsil Eden
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nakit ödeme</td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      className="tableInputStyle"
                      type="text"
                      name="collectionByCash"
                      onChange={handleInputChange}
                      value={collection.collectionByCash}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      className="tableInputStyle"
                      type="text"
                      name=""
                      value={collection.collectionByCash}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input className="tableInputStyle" type="text" name="" />
                  </td>
                </tr>
                <tr>
                  <td>Kredi Kartı</td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      className="tableInputStyle"
                      type="text"
                      name="collectionByCreditCard"
                      onChange={handleInputChange}
                      value={collection.collectionByCreditCard}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      className="tableInputStyle"
                      type="text"
                      name=""
                      value={collection.collectionByCreditCard}
                    />
                  </td>
                </tr>
                <tr>
                  <td>ÇEK ile ödeme</td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      className="tableInputStyle"
                      type="text"
                      name="collectionByCheck"
                      onChange={handleInputChange}
                      value={collection.collectionByCheck}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      className="tableInputStyle"
                      type="text"
                      name=""
                      value={collection.collectionByCheck}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Tahsil edilen Yekün</td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      className="tableInputStyle"
                      type="text"
                      name="totalCollection"
                      onChange={handleInputChange}
                      value={collection.totalCollection}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      className="tableInputStyle"
                      type="text"
                      name=""
                      value={collection.totalCollection}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="tableEnd">
            {" "}
            <span>Yalnız</span>{" "}
            <input
              className="tableInputStyleText"
              type="text"
              name=""
              value={cevir(collection.totalCollection.toString(), ".")}
            />{" "}
          </div>

          <table className="table table-sm">
            <thead>
              <tr>
                <th style={{ width: "3%" }} scope="col"></th>
                <th scope="col">BANKA</th>
                <th scope="col">ŞUBE</th>
                <th scope="col">IBAN / Hes.No.</th>
                <th scope="col">ÇEK NO.</th>
                <th scope="col">ÇEK TARİHİ</th>
                <th scope="col">TUTARI</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td >
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
                <td>
                  <input className="tableInputStyle" type="text" name="" />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="_receiptTableFooter">
            <div>
              <div className="receiptSignatureBox">İMZA</div>
              <div>ÖDEME YAPAN</div>
              <div>{patientStore.selectedPatient.fullName}</div>
            </div>

            <div>
              <div className="receiptSignatureBox">İMZA</div>
              <div>ÖDEME ALAN</div>
              <div>{sessionStorage.getItem("userName")}</div>
            </div>
          </div>
        </div>
        <Button
          className="my-1 receiptBtn"
          variant="outline-success"
          onClick={() => {
            handleCreate();
            handlePrint();
          }}
        >
          Kaydet
        </Button>
      </div>
    );
  }
});
