import '../patientPlansList/patientPlansList.css'
import '../doctorPlanList/doctorPlansList.css'
import { useEffect, useState } from 'react'
import { useStore } from '../../stores/store'
import { observer } from 'mobx-react-lite'
import { Link, useNavigate } from 'react-router-dom'
import { Button, ButtonGroup, Modal, Card } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import ReactEcharts from 'echarts-for-react'
import EchartTest from './echartTest'
import moment from 'moment'
import financeStore from '../../stores/financeStore'

export default observer(function ReportCashPayments() {
  const { planStore, patientStore, financeStore } = useStore()
  useEffect(() => {
    financeStore.loadCollectionPlans()
    patientStore.loadPatients()
    planStore.getPlans()
  }, [])

  console.log(useStore())

  console.log(JSON.stringify(financeStore.collectionPlans))

  function getPatientName(id) {
    let patientInfo = patientStore.patients.find((patient) => {
      return patient.id === id
    })

    if (patientInfo !== undefined) {
      return patientInfo.fullName
    } else {
      return 'Hasta Bulunamadı'
    }
  }

  function getPlanName(id) {
    let planInfo = planStore.plans.find((plan) => {
      return plan.id === id
    })

    if (planInfo !== undefined) {
      return planInfo.name
    } else {
      return 'Plan Bulunamadı'
    }
  }

  if (
    financeStore.collectionPlans === undefined ||
    patientStore.patients === undefined ||
    planStore.plans === undefined
  ) {
    return (
      <Card className="" style={{ textAlign: 'center' }}>
        <Card.Body>
          <Button className="loadingBtnStyle" disabled>
            Yükleniyor...
          </Button>
        </Card.Body>
      </Card>
    )
  }

  let cashPayments = financeStore.collectionPlans.installments?.filter((item) =>
    item.collections.every((type) => type.collectionByCash > 0),
  )
  console.log(cashPayments)

  return (
    <div className="appointmentsPlanGroup">
      <div className="row text-center">
        <span className="reportTitle">Nakit Tahsilatlar</span>
      </div>

      {/* plan sayısı table start */}
      <Table bordered hover size="sm">
        <thead
          className="pricesTableBody"
          style={{ textAlign: 'center', fontSize: 20 }}
        >
          <tr className="pricesTableThHead">
            <th style={{ width: 0 }} className=" pricesTableTh">
              Plan
            </th>
            <th style={{ width: 0 }} className=" pricesTableTh">
              Hasta
            </th>
            <th style={{ width: 0 }} className=" pricesTableTh">
              Fiyat
            </th>
            <th style={{ width: 0 }} className=" pricesTableTh">
              İndirimli Fiyat
            </th>
            <th style={{ width: 0 }} className=" pricesTableTh">
              Tahsilat Tarihi
            </th>
            <th style={{ width: 0 }} className=" pricesTableTh">
              Kaçıncı Taksit
            </th>
            <th style={{ width: 0 }} className=" pricesTableTh">
              Taksit Miktarı
            </th>
          </tr>
        </thead>
        <tbody className="pricesTableTbody">
          {cashPayments?.map((plan) => (
            <tr key={plan.id}>
              <td className="pricesTableTd">
                {getPlanName(financeStore.collectionPlans.planId)}
              </td>
              <td className="pricesTableTd">
                {getPatientName(financeStore.collectionPlans.patientId)}
              </td>
              <td className="pricesTableTd">
                {financeStore.collectionPlans.totalDebt}
              </td>
              <td className="pricesTableTd">
                {financeStore.collectionPlans.discountedDebt} ( %
                {financeStore.collectionPlans.discountRate} )
              </td>
              <td className="pricesTableTd">
                <p>{moment(plan.installmentDate).utc().format('DD-MM-YYYY')}</p>
              </td>
              <td className="pricesTableTd">{plan.installmentNumber}</td>
              <td className="pricesTableTd">{plan.installmentAmount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* plan sayısı table end */}
    </div>
  )
})

// export default observer(ReportPatientCount);
