import { runInAction, makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { store } from "./store";
import { toast } from "react-toastify";

export default class UserStore {
  user = null;
  deleted = false;
  roles = undefined;
  selectedRole = undefined;
  loggedIn = undefined;
  loading = false;
  editing = false;
  editNewUser = false;
  refreshTokenTimeout = undefined;
  selectedUser = undefined;
  closeWizard = false;
  rolesLoaded = false;
  usersLoaded = false;
  roleSelected = false;
  grandPermissions = JSON.parse(sessionStorage.getItem("grandPermissions"));
  permissions = undefined;
  users = undefined;
  initialPermissions = {
    none: false,
    //*!User
    userRead: false,
    userCreate: false,
    userDelete: false,
    userUpdate: false,
    //*!Patient
    patientRead: false,
    patientCreate: false,
    patientDelete: false,
    patientUpdate: false,
    //*!Doctor
    doctorRead: false,
    doctorCreate: false,
    doctorDelete: false,
    doctorUpdate: false,
    //*!Appointment
    appointmentRead: false,
    appointmentCreate: false,
    appointmentDelete: false,
    appointmentUpdate: false,
    //*!Price
    priceRead: false,
    priceCreate: false,
    priceDelete: false,
    priceUpdate: false,
    //*!Plan
    planRead: false,
    planCreate: false,
    planDelete: false,
    planUpdate: false,
    //*!CollectionPlan
    collectionPlanRead: false,
    collectionPlanCreate: false,
    collectionPlanDelete: false,
    collectionPlanUpdate: false,
    //*!Collection
    collectionRead: false,
    collectionCreate: false,
    collectionDelete: false,
    collectionUpdate: false,
    //*!Payment
    paymentRead: false,
    paymentCreate: false,
    paymentDelete: false,
    paymentUpdate: false,
    //Permission
    permissionRead: false,
    permissionCreate: false,
    permissionDelete: false,
    permissionUpdate: false,
    //Document
    documentRead: false,
    documentCreate: false,
    documentDelete: false,
    documentUpdate: false,
  };
  constructor() {
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    return this.user.isSuccess;
  }

  signin = async (creds) => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const user = await agent.Account.login(creds);
      if (user.isSuccess === true) {
        store.commonStore.setToken(user.data.token);
        this.startRefreshTokenTimer(user.data);
        runInAction(() => {
          this.user = user.data;
          this.loggedIn = true;
        });
        sessionStorage.setItem("userName", this.user.fullName);
        sessionStorage.setItem("userId", this.user.id);
        this.grandPermissions = JSON.parse(
          JSON.stringify(this.initialPermissions)
        );
        let loggedRole = await agent.Account.getRole(user.data.roleCode);
        loggedRole.data[0].permissions.forEach((permission) => {
          runInAction(() => {
            if (permission === 1) this.grandPermissions.userRead = true;
            if (permission === 2) this.grandPermissions.userCreate = true; // ? OK
            if (permission === 3) this.grandPermissions.userDelete = true; // ! Kullanılmıyor
            if (permission === 4) this.grandPermissions.userUpdate = true; // ? OK
            if (permission === 5) this.grandPermissions.patientRead = true; // ? OK
            if (permission === 6) this.grandPermissions.patientCreate = true; // ? OK
            if (permission === 7) this.grandPermissions.patientDelete = true; // ? OK
            if (permission === 8) this.grandPermissions.patientUpdate = true; // ? OK
            if (permission === 9) this.grandPermissions.doctorRead = true; // ? OK
            if (permission === 10) this.grandPermissions.doctorCreate = true; // ? OK
            if (permission === 11) this.grandPermissions.doctorDelete = true; // ? OK
            if (permission === 12) this.grandPermissions.doctorUpdate = true; // ? OK
            if (permission === 13) this.grandPermissions.appointmentRead = true; // ? OK
            if (permission === 14)
              this.grandPermissions.appointmentCreate = true; // ? OK
            if (permission === 15)
              this.grandPermissions.appointmentDelete = true; // ? OK
            if (permission === 16)
              this.grandPermissions.appointmentUpdate = true; // ? OK
            if (permission === 17) this.grandPermissions.priceRead = true; // ? OK
            if (permission === 18) this.grandPermissions.priceCreate = true; // ! Kullanılmıyor
            if (permission === 19) this.grandPermissions.priceDelete = true; // ! Kullanılmıyor
            if (permission === 20) this.grandPermissions.priceUpdate = true; // ? OK
            if (permission === 21) this.grandPermissions.planRead = true; // ! Kullanılmıyor
            if (permission === 22) this.grandPermissions.planCreate = true; // ? OK
            if (permission === 23) this.grandPermissions.planDelete = true; // ? OK
            if (permission === 24) this.grandPermissions.planUpdate = true; // ? OK
            if (permission === 25)
              this.grandPermissions.collectionPlanRead = true; // ? OK
            if (permission === 26)
              this.grandPermissions.collectionPlanCreate = true; // ? OK
            if (permission === 27)
              this.grandPermissions.collectionPlanDelete = true; // ! Kullanılmıyor
            if (permission === 28)
              this.grandPermissions.collectionPlanUpdate = true; // ? OK
            if (permission === 29) this.grandPermissions.collectionRead = true; // ? OK
            if (permission === 30)
              this.grandPermissions.collectionCreate = true; // ? OK
            if (permission === 31)
              this.grandPermissions.collectionDelete = true; // ! Kullanılmıyor
            if (permission === 32)
              this.grandPermissions.collectionUpdate = true; // ! Kullanılmıyor
            if (permission === 33) this.grandPermissions.paymentRead = true; // ? OK
            if (permission === 34) this.grandPermissions.paymentCreate = true; // ? OK
            if (permission === 35) this.grandPermissions.paymentDelete = true; // ! Kullanılmıyor
            if (permission === 36) this.grandPermissions.paymentUpdate = true; // ! Kullanılmıyor
            if (permission === 37) this.grandPermissions.permissionRead = true; // ? OK
            if (permission === 38)
              this.grandPermissions.permissionCreate = true; // ! Kullanılmıyor
            if (permission === 39)
              this.grandPermissions.permissionDelete = true; // ! Kullanılmıyor
            if (permission === 40)
              this.grandPermissions.permissionUpdate = true; // ? OK
              if (permission === 41) this.grandPermissions.documentRead = true; // ? OK
              if (permission === 42)
                this.grandPermissions.documentCreate = true; // ! Kullanılmıyor
              if (permission === 43)
                this.grandPermissions.documentDelete = true; // ! Kullanılmıyor
              if (permission === 44)
                this.grandPermissions.documentUpdate = true; // ? OK
          });
        });
        sessionStorage.setItem(
          "grandPermissions",
          JSON.stringify(this.grandPermissions)
        );
        runInAction(() => {
          this.loading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
      throw error;
    }
  };
  updateUser = async (creds) => {
    try {
      runInAction(() => {
        this.loading = true;
        this.editNewUser = true;
      });
      let updatedUser = {
        Id: creds.id,
        FullName: creds.fullName,
        UserName: creds.userName,
        Email: creds.email,
        RoleCode: creds.roleCode,
        OldPassword: creds.oldPassword,
        NewPassword: creds.newPassword,
      };
      console.log(JSON.stringify(updatedUser));
      const user = await agent.Account.update(creds);
      if (user.isSuccess === true) {
        toast.success("Kullanıcı güncelleme işlemi başarılı.");
        runInAction(() => {
          this.user = user.data;
          console.log(this.user);
        });
        runInAction(() => {
          this.loading = false;
          this.editNewUser = false;
        });
      }
    } catch (error) {
      throw error;
    }
  };
  newUser = async (creds) => {
    try {
      runInAction(() => {
        this.loading = true;
        this.editNewUser = true;
      });

      const user = await agent.Account.register(creds);
      if (user.isSuccess === true) {
        toast.success("Kullanıcı eklendi.");
        runInAction(() => {
          this.users.push(user);
          console.log(this.user);
        });
        runInAction(() => {
          this.loading = false;
          this.editNewUser = false;
        });
      }
    } catch (error) {
      throw error;
    }
  };

  logout = () => {
    store.commonStore.setToken(null);
    sessionStorage.clear();
    runInAction(() => {
      this.user = null;
      this.loggedIn = false;
    });
  };

  getUser = async () => {
    try {
      runInAction(() => (this.loading = true));
      const user = await agent.Account.current();
      runInAction(() => {
        this.user = user.data;
        this.loading = false;
      });
      store.commonStore.setToken(this.user.token);
      this.startRefreshTokenTimer(this.user);
    } catch (error) {
      runInAction(() => (this.loading = false));
      console.log(error);
    }
  };
  getUsers = async () => {
    try {
      runInAction(() => {
        this.usersLoaded = true;
      });
      const users = await agent.Account.getUsers();
      runInAction(() => {
        this.users = users.data;
        this.users = [...this.users.filter((a) => a.isActive !== 0 )];
        this.usersLoaded = false;
      });
    } catch (error) {
      runInAction(() => (this.usersLoaded = false));
      console.log(error);
    }
  };
  getRoles = async () => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const roles = await agent.Account.getRoles();
      runInAction(() => (this.roles = roles.data));
      runInAction(() => {
        this.loading = false;
        this.rolesLoaded = true;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
      console.log(error);
    }
  };
  getRole = async (roleCode) => {
    try {
      runInAction(() => {
        this.loading = true;
        this.permissions = JSON.parse(JSON.stringify(this.initialPermissions));
      });
      let selectedRole = await agent.Account.getRole(roleCode);
      selectedRole.data[0].permissions.forEach((permission) => {
        runInAction(() => {
          if (permission === 1) this.permissions.userRead = true;
          if (permission === 2) this.permissions.userCreate = true;
          if (permission === 3) this.permissions.userDelete = true;
          if (permission === 4) this.permissions.userUpdate = true;
          if (permission === 5) this.permissions.patientRead = true;
          if (permission === 6) this.permissions.patientCreate = true;
          if (permission === 7) this.permissions.patientDelete = true;
          if (permission === 8) this.permissions.patientUpdate = true;
          if (permission === 9) this.permissions.doctorRead = true;
          if (permission === 10) this.permissions.doctorCreate = true;
          if (permission === 11) this.permissions.doctorDelete = true;
          if (permission === 12) this.permissions.doctorUpdate = true;
          if (permission === 13) this.permissions.appointmentRead = true;
          if (permission === 14) this.permissions.appointmentCreate = true;
          if (permission === 15) this.permissions.appointmentDelete = true;
          if (permission === 16) this.permissions.appointmentUpdate = true;
          if (permission === 17) this.permissions.priceRead = true;
          if (permission === 18) this.permissions.priceCreate = true;
          if (permission === 19) this.permissions.priceDelete = true;
          if (permission === 20) this.permissions.priceUpdate = true;
          if (permission === 21) this.permissions.planRead = true;
          if (permission === 22) this.permissions.planCreate = true;
          if (permission === 23) this.permissions.planDelete = true;
          if (permission === 24) this.permissions.planUpdate = true;
          if (permission === 25) this.permissions.collectionPlanRead = true;
          if (permission === 26) this.permissions.collectionPlanCreate = true;
          if (permission === 27) this.permissions.collectionPlanDelete = true;
          if (permission === 28) this.permissions.collectionPlanUpdate = true;
          if (permission === 29) this.permissions.collectionRead = true;
          if (permission === 30) this.permissions.collectionCreate = true;
          if (permission === 31) this.permissions.collectionDelete = true;
          if (permission === 32) this.permissions.collectionUpdate = true;
          if (permission === 33) this.permissions.paymentRead = true;
          if (permission === 34) this.permissions.paymentCreate = true;
          if (permission === 35) this.permissions.paymentDelete = true;
          if (permission === 36) this.permissions.paymentUpdate = true;
          if (permission === 37) this.permissions.permissionRead = true;
          if (permission === 38) this.permissions.permissionCreate = true;
          if (permission === 39) this.permissions.permissionDelete = true;
          if (permission === 40) this.permissions.permissionUpdate = true;
          if (permission === 41) this.permissions.documentRead = true;
          if (permission === 42) this.permissions.documentCreate = true;
          if (permission === 43) this.permissions.documentDelete = true;
          if (permission === 44) this.permissions.documentUpdate = true;
        });
      });
      runInAction(() => (this.selectedRole = selectedRole.data[0]));
      runInAction(() => {
        this.loading = false;
        this.roleSelected = true;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
      console.log(error);
    }
  };

  setPermission(event) {
    if (event.checked === true) {
      if (event.value === "1") this.permissions.userRead = true;
      else if (event.value === "2") this.permissions.userCreate = true;
      else if (event.value === "3") this.permissions.userDelete = true;
      else if (event.value === "4") this.permissions.userUpdate = true;
      if (event.value === "5") this.permissions.patientRead = true;
      else if (event.value === "6") this.permissions.patientCreate = true;
      else if (event.value === "7") this.permissions.patientDelete = true;
      else if (event.value === "8") this.permissions.patientUpdate = true;
      if (event.value === "9") this.permissions.doctorRead = true;
      else if (event.value === "10") this.permissions.doctorCreate = true;
      else if (event.value === "11") this.permissions.doctorDelete = true;
      else if (event.value === "12") this.permissions.doctorUpdate = true;
      if (event.value === "13") this.permissions.appointmentRead = true;
      else if (event.value === "14") this.permissions.appointmentCreate = true;
      else if (event.value === "15") this.permissions.appointmentDelete = true;
      else if (event.value === "16") this.permissions.appointmentUpdate = true;
      if (event.value === "17") this.permissions.priceRead = true;
      else if (event.value === "18") this.permissions.priceCreate = true;
      else if (event.value === "19") this.permissions.priceDelete = true;
      else if (event.value === "20") this.permissions.priceUpdate = true;
      if (event.value === "21") this.permissions.planRead = true;
      else if (event.value === "22") this.permissions.planCreate = true;
      else if (event.value === "23") this.permissions.planDelete = true;
      else if (event.value === "24") this.permissions.planUpdate = true;
      if (event.value === "25") this.permissions.collectionPlanRead = true;
      else if (event.value === "26")
        this.permissions.collectionPlanCreate = true;
      else if (event.value === "27")
        this.permissions.collectionPlanDelete = true;
      else if (event.value === "28")
        this.permissions.collectionPlanUpdate = true;
      if (event.value === "29") this.permissions.collectionRead = true;
      else if (event.value === "30") this.permissions.collectionCreate = true;
      else if (event.value === "31") this.permissions.collectionDelete = true;
      else if (event.value === "32") this.permissions.collectionUpdate = true;
      if (event.value === "33") this.permissions.paymentRead = true;
      else if (event.value === "34") this.permissions.paymentCreate = true;
      else if (event.value === "35") this.permissions.paymentDelete = true;
      else if (event.value === "36") this.permissions.paymentUpdate = true;
      if (event.value === "37") this.permissions.permissionRead = true;
      else if (event.value === "38") this.permissions.permissionCreate = true;
      else if (event.value === "39") this.permissions.permissionDelete = true;
      else if (event.value === "40") this.permissions.permissionUpdate = true;
      if (event.value === "41") this.permissions.documentRead = true;
      else if (event.value === "42") this.permissions.documentCreate = true;
      else if (event.value === "43") this.permissions.documentDelete = true;
      else if (event.value === "44") this.permissions.documentUpdate = true;
    } else {
      if (event.value === "1") this.permissions.userRead = false;
      else if (event.value === "2") this.permissions.userCreate = false;
      else if (event.value === "3") this.permissions.userDelete = false;
      else if (event.value === "4") this.permissions.userUpdate = false;
      if (event.value === "5") this.permissions.patientRead = false;
      else if (event.value === "6") this.permissions.patientCreate = false;
      else if (event.value === "7") this.permissions.patientDelete = false;
      else if (event.value === "8") this.permissions.patientUpdate = false;
      if (event.value === "9") this.permissions.doctorRead = false;
      else if (event.value === "10") this.permissions.doctorCreate = false;
      else if (event.value === "11") this.permissions.doctorDelete = false;
      else if (event.value === "12") this.permissions.doctorUpdate = false;
      if (event.value === "13") this.permissions.appointmentRead = false;
      else if (event.value === "14") this.permissions.appointmentCreate = false;
      else if (event.value === "15") this.permissions.appointmentDelete = false;
      else if (event.value === "16") this.permissions.appointmentUpdate = false;
      if (event.value === "17") this.permissions.priceRead = false;
      else if (event.value === "18") this.permissions.priceCreate = false;
      else if (event.value === "19") this.permissions.priceDelete = false;
      else if (event.value === "20") this.permissions.priceUpdate = false;
      if (event.value === "21") this.permissions.planRead = false;
      else if (event.value === "22") this.permissions.planCreate = false;
      else if (event.value === "23") this.permissions.planDelete = false;
      else if (event.value === "24") this.permissions.planUpdate = false;
      if (event.value === "25") this.permissions.collectionPlanRead = false;
      else if (event.value === "26")
        this.permissions.collectionPlanCreate = false;
      else if (event.value === "27")
        this.permissions.collectionPlanDelete = false;
      else if (event.value === "28")
        this.permissions.collectionPlanUpdate = false;
      if (event.value === "29") this.permissions.collectionRead = false;
      else if (event.value === "30") this.permissions.collectionCreate = false;
      else if (event.value === "31") this.permissions.collectionDelete = false;
      else if (event.value === "32") this.permissions.collectionUpdate = false;
      if (event.value === "33") this.permissions.paymentRead = false;
      else if (event.value === "34") this.permissions.paymentCreate = false;
      else if (event.value === "35") this.permissions.paymentDelete = false;
      else if (event.value === "36") this.permissions.paymentUpdate = false;
      if (event.value === "37") this.permissions.permissionRead = false;
      else if (event.value === "38") this.permissions.permissionCreate = false;
      else if (event.value === "39") this.permissions.permissionDelete = false;
      else if (event.value === "40") this.permissions.permissionUpdate = false;
      if (event.value === "41") this.permissions.documentRead = false;
      else if (event.value === "42") this.permissions.documentCreate = false;
      else if (event.value === "43") this.permissions.documentDelete = false;
      else if (event.value === "44") this.permissions.documentUpdate = false;
    }
  }
  updatePermissions = async () => {
    let updatedPermissions = {
      id: this.selectedRole.id,
      permissions: [],
    };
    if (this.permissions.userRead === true)
      updatedPermissions.permissions.push(1);
    if (this.permissions.userCreate === true)
      updatedPermissions.permissions.push(2);
    if (this.permissions.userDelete === true)
      updatedPermissions.permissions.push(3);
    if (this.permissions.userUpdate === true)
      updatedPermissions.permissions.push(4);
    if (this.permissions.patientRead === true)
      updatedPermissions.permissions.push(5);
    if (this.permissions.patientCreate === true)
      updatedPermissions.permissions.push(6);
    if (this.permissions.patientDelete === true)
      updatedPermissions.permissions.push(7);
    if (this.permissions.patientUpdate === true)
      updatedPermissions.permissions.push(8);
    if (this.permissions.doctorRead === true)
      updatedPermissions.permissions.push(9);
    if (this.permissions.doctorCreate === true)
      updatedPermissions.permissions.push(10);
    if (this.permissions.doctorDelete === true)
      updatedPermissions.permissions.push(11);
    if (this.permissions.doctorUpdate === true)
      updatedPermissions.permissions.push(12);
    if (this.permissions.appointmentRead === true)
      updatedPermissions.permissions.push(13);
    if (this.permissions.appointmentCreate === true)
      updatedPermissions.permissions.push(14);
    if (this.permissions.appointmentDelete === true)
      updatedPermissions.permissions.push(15);
    if (this.permissions.appointmentUpdate === true)
      updatedPermissions.permissions.push(16);
    if (this.permissions.priceRead === true)
      updatedPermissions.permissions.push(17);
    if (this.permissions.priceCreate === true)
      updatedPermissions.permissions.push(18);
    if (this.permissions.priceDelete === true)
      updatedPermissions.permissions.push(19);
    if (this.permissions.priceUpdate === true)
      updatedPermissions.permissions.push(20);
    if (this.permissions.planRead === true)
      updatedPermissions.permissions.push(21);
    if (this.permissions.planCreate === true)
      updatedPermissions.permissions.push(22);
    if (this.permissions.planDelete === true)
      updatedPermissions.permissions.push(23);
    if (this.permissions.planUpdate === true)
      updatedPermissions.permissions.push(24);
    if (this.permissions.collectionPlanRead === true)
      updatedPermissions.permissions.push(25);
    if (this.permissions.collectionPlanCreate === true)
      updatedPermissions.permissions.push(26);
    if (this.permissions.collectionPlanDelete === true)
      updatedPermissions.permissions.push(27);
    if (this.permissions.collectionPlanUpdate === true)
      updatedPermissions.permissions.push(28);
    if (this.permissions.collectionRead === true)
      updatedPermissions.permissions.push(29);
    if (this.permissions.collectionCreate === true)
      updatedPermissions.permissions.push(30);
    if (this.permissions.collectionDelete === true)
      updatedPermissions.permissions.push(31);
    if (this.permissions.collectionUpdate === true)
      updatedPermissions.permissions.push(32);
    if (this.permissions.paymentRead === true)
      updatedPermissions.permissions.push(33);
    if (this.permissions.paymentCreate === true)
      updatedPermissions.permissions.push(34);
    if (this.permissions.paymentDelete === true)
      updatedPermissions.permissions.push(35);
    if (this.permissions.paymentUpdate === true)
      updatedPermissions.permissions.push(36);
    if (this.permissions.permissionRead === true)
      updatedPermissions.permissions.push(37);
    if (this.permissions.permissionCreate === true)
      updatedPermissions.permissions.push(38);
    if (this.permissions.permissionDelete === true)
      updatedPermissions.permissions.push(39);
    if (this.permissions.permissionUpdate === true)
      updatedPermissions.permissions.push(40);
      if (this.permissions.documentRead === true)
      updatedPermissions.permissions.push(41);
    if (this.permissions.documentCreate === true)
      updatedPermissions.permissions.push(42);
    if (this.permissions.documentDelete === true)
      updatedPermissions.permissions.push(43);
    if (this.permissions.documentUpdate === true)
      updatedPermissions.permissions.push(44);

    const result = await agent.Account.updatePermission(updatedPermissions);
    if (result.isSuccess === true)
      toast.success("Yetki güncelleme işlemi başarılı.");
  };

  refreshToken = async () => {
    this.stopRefreshTokenTimer();
    try {
      const user = await agent.Account.refreshToken();
      runInAction(() => (this.user = user.data));
      store.commonStore.setToken(this.user.token);
      this.startRefreshTokenTimer(this.user);
    } catch (error) {
      console.log(error);
    }
  };

  startRefreshTokenTimer(user) {
    const jwtToken = JSON.parse(atob(user.token.split(".")[1]));
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 20 * 1000;
    this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
  }
  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
  deleteUser = async (id) => {
    try {
      runInAction(() => {
        this.deleted = true;
      });
      let result = await agent.Account.delete(id);
      runInAction(() => {
        this.users = [...this.users.filter((a) => a.id !== id)];
        this.deleted = false;
      });
      runInAction(() => {
        this.deleted = false;
      });
    } catch (error) {
      console.log(error);
    }
  };
  selectUser = (id) => {
    try {
      const user = this.users.find((x) => x.id === id);
      runInAction(() => {
        this.selectedUser = user;
      });
      } catch (error) {
      console.log(error);
    }
  };
}
