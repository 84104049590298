import "./addPatient.css";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import moment from "moment";
import { toast } from "react-toastify";

function AddPatient() {
  const { patientStore } = useStore();
  const { selectedPatient, createPatient, updatePatient } = patientStore;

  const initialState = patientStore.selectedPatient ?? {
    id: " ",
    identityNumber: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    gender: true,
    passportNumber: "",
    birthDate: "",
    address: "",
    advisorId: "",
    note: "",
  };

  const [patient, setPatient] = useState(initialState);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setPatient(initialState);
  }, [selectedPatient]);

  function handleSubmit() {
    console.log(patient.id);
    if (
      patient.email !== "" &&
      patient.email.includes("@") &&
      patient.identityNumber !== "" &&
      patient.fullName !== "" &&
      patient.phoneNumber !== ""
    )
      createPatient(patient);
  }

  function handleUpdate() {
    if (
      patient.email !== "" &&
      patient.email.includes("@") &&
      patient.identityNumber !== "" &&
      patient.fullName !== "" &&
      patient.phoneNumber !== ""
    )
      updatePatient(patient);
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    setPatient({ ...patient, [name]: value });
  }

  return (
    <div className="cardComponent">
      <Container className="">
        <Form className="my-4">
          <Row>
            <Col>
              <TextField
                name="fullName"
                className="my-1"
                value={patient.fullName}
                onChange={handleInputChange}
                label="İsim Soyisim"
              />
            </Col>
            <Col>
              <TextField
                name="email"
                className="my-1"
                value={patient.email}
                onChange={handleInputChange}
                label="Email"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                name="identityNumber"
                className="my-1"
                label="TC Kimlik No"
                value={patient.identityNumber}
                onChange={handleInputChange}
              />

              <TextField
                name="passportNumber"
                className="my-1"
                label="Pasaport No"
                value={patient.passportNumber}
                onChange={handleInputChange}
              />
            </Col>
            <Col>
              <TextField
                name="phoneNumber"
                className="my-1"
                label="Telefon Numarası"
                value={patient.phoneNumber}
                onChange={handleInputChange}
              />
              <TextField
                style={{ width: "13rem" }}
                focused
                color="grey"
                name="birthDate"
                className="my-1"
                type="date"
                label="Doğum Tarihi"
                value={moment(patient.birthDate).utc().format("YYYY-MM-DD")}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          {/* <div className="">
            <input type="radio" className="" name="gender" />
            <span className="genderMale"> Male</span>
            <input type="radio" className="" name="gender" />
            <span className="genderFemale"> Female</span>
          </div> */}
          <TextField
            name="address"
            style={{ width: "100%" }}
            className="my-1"
            label="Adres"
            value={patient.address}
            onChange={handleInputChange}
          />
          <div>
          <Autocomplete
                    style={{width:"100%"}}
                    // onChange={(event, option) => handleRoleCode(option.value)}
                    name="roleCode"
                    onChange={(event, newValue) => {
                      patient.advisorId = newValue.id;
                      console.log(patient)
                    }}
                    className="my-1"
                        disablePortal
                        id="combo-box-demo"
                        
                        options={patientStore.advisors}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Hasta Danışmanı Seç" />
                        )}
                      />
          </div>
          <TextField
            name="note"
            style={{ width: "100%" }}
            className="my-1"
            label="Açıklama"
            value={patient.note}
            onChange={handleInputChange}
          />

        </Form>
        {patient.id === " " && 
        <Button
          disabled={patientStore.loading}
          onClick={() => {
            handleSubmit();
            if (patient.fullName === "")
              toast.error("Lütfen bir isim soyisim giriniz!");
            if (patient.email === "" || !patient.email.includes("@"))
              toast.error("Lütfen geçerli bir email giriniz!");
            if (patient.phoneNumber === "")
              toast.error("Lütfen bir telefon numarası giriniz!");
            if (patient.identityNumber === "")
              toast.error("Lütfen TC kimlik no giriniz!");
          }}
          type="submit"
          variant="outline-dark"
          className="doctorAddBtn"
        >
          {patientStore.loading ? "Ekleniyor..." : "Eklemek için tıklayınız"}
        </Button>
        }
        {patient.id !== " " && 
        <Button

          disabled={patientStore.loading}
          onClick={() => {
            handleUpdate();
            if (patient.fullName === "")
              toast.error("Lütfen bir isim soyisim giriniz!");
            if (patient.email === "" || !patient.email.includes("@"))
              toast.error("Lütfen geçerli bir email giriniz!");
            if (patient.phoneNumber === "")
              toast.error("Lütfen bir telefon numarası giriniz!");
            if (patient.identityNumber === "")
              toast.error("Lütfen TC kimlik no giriniz!");
          }}
          type="submit"
          variant="outline-dark"
          className="doctorAddBtn"
        >
          {patientStore.loading ? "Güncelleniyor..." : "Güncelle"}
        </Button>
        }
      </Container>
    </div>
  );
}

export default observer(AddPatient);
