import "../patientPlansList/patientPlansList.css";
import "../patientsCard/patientsCard.css";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
import DoctorPlansList from "../doctorPlanList/DoctorPlansList";
import DoctorUpdate from "../doctors/DoctorUpdate";
import DoctorTreatments from "../doctorTreatments/DoctorTreatments";
import DoctorPayment from "../finance/DoctorPayment";
import "./doctorCard.css";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import moment from "moment";

function DoctorCard() {
  const { drStore, planStore, userStore } = useStore();
  const { filterPlans } = planStore;

  const initialState = {
    id: "",
    startDate: "",
    endDate: "",
  };
  const [filter, setFilter] = useState(initialState);
  const initialDoctor = drStore.selectedDoctor ?? {
    id: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    branch: "",
    commissionPercentage: 0,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    mondayStart: 0,
    mondayEnd: 0,
    wednesdayStart: 0,
    tuesdayEnd: 0,
    wednesdayStart: 0,
    wednesdayEnd: 0,
    thursdayStart: 0,
    thursdayEnd: 0,
    fridayStart: 0,
    fridayEnd: 0,
    saturdayStart: 0,
    saturdayEnd: 0,
    sundayStart: 0,
    sundayEnd: 0,
  };
  const [doctor, setDoctor] = useState(initialDoctor);
  useEffect(() => {
    setDoctor(initialDoctor);
  }, [drStore.resetSelect]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    drStore.editing = true;
  };

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value, id: drStore.selectedDoctor.id });
  }

  function handleFilter() {
    if (filter.startDate !== "" && filter.endDate !== "") {
      filterPlans(filter);
    }
  }

  return (
    <div style={{ width: "100%" }} className="patientsadd">
      <div className="patientsAddHead">
        <div className="patientsColumnGroup">
          <div>
            <label className="patientInfo">
              <span className="spanText">
                İsim Soyisim:{" "}
              </span>
              {doctor.fullName}
            </label>
            <label className="patientInfo">
              <span className="spanText">
                Mail:{" "}
              </span>{" "}
              {doctor.email}
            </label>
            <label className="patientInfo">
              <span className="spanText">
                Telefon Numarası:{" "}
              </span>
              {doctor.phoneNumber}
            </label>
            <label className="patientInfo">
              <span className="spanText">
                Branş:{" "}
              </span>
              {doctor.branch}
            </label>
            <label className="patientInfo">
              <span className="spanText">
                Komisyon Yüzdesi:{" "}
              </span>
              {"%" + doctor.commissionPercentage}
            </label>
            <label className="patientInfo">
              <span className="spanText">
                Durumu:{" "}
              </span>
              {doctor.isActive === 2 && (
                <div
                  style={{
                    display: "inline-block",
                    color: "red",
                    fontWeight: "bold",
                    width: "6rem",
                  }}
                >
                  Aktif
                </div>
              )}
              {doctor.isActive === 1 && (
                <div
                  style={{
                    display: "inline-block",
                    color: "green",
                    fontWeight: "bold",
                    width: "6rem",
                  }}
                >
                  Aktif
                </div>
              )}
            </label>
            {userStore.grandPermissions.doctorUpdate && (
              <Button
                className=""
                variant="outline-dark"
                style={{ height: "3rem", marginLeft: "1rem" }}
                onClick={handleShow}
              >
                Düzenle
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="patientsAddFormWrapper">
        {drStore.editing && (
          <Modal size="lg" show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Düzenle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DoctorUpdate />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <div className="row">
          <div className="col-sm-12 my-1">
            <div className="card cardStyle">
              <div className="card-body">
                <h5 className="card-title patientCardTitle">Tarih Aralığı</h5>
                <div>
                  <span className="spanText">Başlangıç Tarihi:</span>
                  <input
                    className=" dateRangePicker"
                    type="date"
                    name="startDate"
                    value={filter.startDate}
                    onChange={handleInputChange}
                  />
                  <span className="spanText">Bitiş Tarihi:</span>
                  <input
                    className="dateRangePicker "
                    type="date"
                    name="endDate"
                    value={filter.endDate}
                    onChange={handleInputChange}
                  />
                  <Button
                    variant="outline-dark"
                    disabled={planStore.loading}
                    onClick={() => {
                      handleFilter();
                      if (filter.startDate === "") {
                        toast.error("Lütfen başlangıç tarihi seçiniz!");
                      }
                      if (filter.endDate === "") {
                        toast.error("Lütfen bitiş tarihi seçiniz!");
                      }
                    }}
                  >
                    {planStore.loading ? "Yükleniyor..." : "Tarih aralığı seç"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 my-1">
            <div>{<DoctorPlansList />}</div>
          </div>
          <div className="col-sm-6 my-1">
            <div className="card cardStyle">
              <div className="card-body">
                <h5 className="card-title patientCardTitle">Tedaviler</h5>
                <DoctorTreatments />
              </div>
            </div>
          </div>
          {userStore.grandPermissions.paymentRead && (
            <div className="col-sm-6 my-1">
              <div className="card cardStyle">
                <div className="card-body">
                  <h5 className="card-title patientCardTitle">Hakedişler</h5>
                  <DoctorPayment />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* <Row style={{ textAlign: "start" }}>
          <Col xs="12" className="my-2">
            <Card className="cardStyle" style={{ textAlign: "center" }}>
              <Card.Body>
                <Card.Title className="doctorCardTitle">
                  Tarih Aralığı
                </Card.Title>
                <div>
                  <input
                    className=" dateRangePicker"
                    type="date"
                    name="startDate"
                    value={filter.startDate}
                    onChange={handleInputChange}
                  />
                  <input
                    className="dateRangePicker "
                    type="date"
                    name="endDate"
                    value={filter.endDate}
                    onChange={handleInputChange}
                  />
                  <Button
                    variant="outline-primary"
                    disabled={planStore.loading}
                    onClick={() => {
                      handleFilter();
                      if (filter.startDate === "") {
                        toast.error("Lütfen başlangıç tarihi seçiniz!");
                      }
                      if (filter.endDate === "") {
                        toast.error("Lütfen bitiş tarihi seçiniz!");
                      }
                    }}
                  >
                    {planStore.loading ? "Yükleniyor..." : "Tarih aralığı seç"}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" className="my-2">
            {<DoctorPlansList />}
          </Col>
          <Col xs="6" className="my-2">
            <Card className="cardStyle">
              <Card.Body>
                <Card.Title className="doctorCardTitle">Tedaviler</Card.Title>
                <DoctorTreatments />
              </Card.Body>
            </Card>
          </Col>
          <Col xs="6" className="my-2">
            <Card className="cardStyle">
              <Card.Body>
                <Card.Title className="doctorCardTitle">Hakedişler</Card.Title>
                <DoctorPayment />
                <Col></Col>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
      </div>
    </div>
  );
}

export default observer(DoctorCard);
