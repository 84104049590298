import { runInAction, makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

export default class financeStore {
  loading = false;
  collections = undefined;
  collection = undefined;
  editCollection = false;
  InstallmentCount = [];
  resetSelectedCollectionPlan = false;
  selectedCollectionPlan = undefined;
  resetPlanForm = false;
  collectionPlans = undefined;
  resetGetCollection = false;
  collectionPlanSelected = false;
  resetReceiptState = false;
  totalPaid = 0;
  editPlan = false;
  installmentId = undefined;
  selectedPayment = undefined;
  payments = [];
  paymentsLoaded = false;
  saveButton = true;
  addingPayment = false;

  constructor() {
    makeAutoObservable(this);
  }

  addCollection = async (creds) => {
    if (creds.id === " ") {
      creds.id = uuid();
    }
    creds.userId = sessionStorage.getItem("userId");
    creds.collectionByCash = parseInt(creds.collectionByCash);
    try {
      runInAction(() => {
        this.loading = true;
        this.editCollection = true;
      });
      const collection = await agent.Finance.create(creds);
      if (collection.isSuccess === true) {
        runInAction(() => {
          this.totalPaid = this.totalPaid + creds.totalCollection;
        });
        await agent.Finance.updateInstallment(creds.installmentId);
        const updatedInstallment =
          this.selectedCollectionPlan.installments.find(
            (x) => x.id === creds.installmentId
          );
        updatedInstallment.isPaid = true;
        runInAction(() => {
          this.selectedCollectionPlan.installments = [
            ...this.selectedCollectionPlan.installments.filter(
              (a) => a.id !== creds.installmentId
            ),
          ];
          this.selectedCollectionPlan.installments.push(updatedInstallment);
          this.selectedCollectionPlan.installments =
            this.selectedCollectionPlan.installments.sort(
              (a, b) =>
                parseFloat(a.installmentNumber) -
                parseFloat(b.installmentNumber)
            );
        });
        //this.resetPlanForm = !this.resetPlanForm;
        toast.success("Ödeme yapıldı.");
        runInAction(() => {
          this.collection = collection.data;
        });
      }
      runInAction(() => {
        this.loading = false;
        this.editCollection = false;
      });
    } catch (error) {
      throw error;
    }
  };
  getCollectionsByInstallmentId = async (id, value) => {
    try {
      runInAction(() => {
        //this.loading = true;
      });
      if (value === true) {
        const collection = await agent.Finance.detailsByInstallmentId(id);
        if (collection.isSuccess === true) {
          runInAction(() => {
            this.collections = collection.data[0];
            this.resetReceiptState = !this.resetReceiptState;
          });
        } else {
          this.collections = undefined;
        }
        runInAction(() => {
          this.loading = false;
        });
      } else {
        runInAction(() => {
          this.collections = undefined;
          this.installmentId = id;
          this.resetReceiptState = !this.resetReceiptState;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.collections = undefined;
      });
      throw error;
    }
  };

  setInstallments = (value) => {
    runInAction(() => {
      this.selectedCollectionPlan.installments = [];
      for (let index = 0; index < value; index++) {
        this.selectedCollectionPlan.installments.push({
          installmentNumber: index + 1,
          installmentDate: "Tarih Seçiniz",
          installmentAmount: this.selectedCollectionPlan.discountedDebt / value,
          isPaid: false,
        });
      }
      this.selectedCollectionPlan.numberOfInstallments = value;
    });
    this.resetSelectedCollectionPlan = !this.resetSelectedCollectionPlan;
    console.log(JSON.stringify(this.selectedCollectionPlan));
  };

  setInstallmentDate = (value, index) => {
    runInAction(() => {
      this.selectedCollectionPlan.installments[index].installmentDate =
        new Date(value).toISOString();
    });
  };
  setCollectionType = (value) => {
    runInAction(() => {
      this.selectedCollectionPlan.collectionType = value;
    });
  };
  setDiscountRate = (value) => {
    runInAction(() => {
      this.selectedCollectionPlan.discountedDebt =
        this.selectedCollectionPlan.totalDebt * (1 - value / 100);
      this.selectedCollectionPlan.discountRate = value;
    });
  };

  setSelectedCollectionPlan = (plan, patientID, planId, debt) => {
    console.log(debt);
    if (plan === undefined) {
      runInAction(() => {
        this.selectedCollectionPlan.totalDebt = debt;
      });
    } else {
      runInAction(() => {
        this.selectedCollectionPlan = plan;
        this.selectedCollectionPlan.patientId = patientID;
        this.selectedCollectionPlan.planId = planId;
        this.selectedCollectionPlan.totalDebt = debt;
        this.selectedCollectionPlan.discountedDebt = debt;
        this.collectionPlanSelected = true;
      });
    }
  };

  addCollectionPlan = async (data) => {
    runInAction(() => {
      this.editPlan = true;
    });
    console.log(JSON.stringify(data));
    data.collectionType = parseInt(data.collectionType);

    try {
      runInAction(() => {
        this.loading = true;
      });
      const collectionPlan = await agent.Finance.createCollectionPlan(data);
      if (collectionPlan.isSuccess === true) {
        toast.success("Ödeme planı oluşturuldu");
        runInAction(() => {
          this.collectionPlan = collectionPlan.data;
          this.selectedCollectionPlan = collectionPlan.data;
        });
        console.log(collectionPlan.data);
      }
      runInAction(() => {
        this.loading = false;
        this.editPlan = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
      throw error;
    }
  };
  updateCollectionPlan = async (data) => {
    runInAction(() => {
      this.editPlan = true;
    });
    console.log(JSON.stringify(data));
    data.collectionType = parseInt(data.collectionType);
    try {
      runInAction(() => {
        this.loading = true;
      });
      const collectionPlan = await agent.Finance.updateCollectionPlan(data);
      if (collectionPlan.isSuccess === true) {
        toast.success("Ödeme planı güncellendi");
        runInAction(() => {
          this.collectionPlan = collectionPlan.data;
        });
      }
      runInAction(() => {
        this.loading = false;
        this.editPlan = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
      throw error;
    }
  };
  getCollectionPlanByPlanId = async (id, value) => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const collectionPlan = await agent.Finance.collectionPlanByPlanId(id);
      if (collectionPlan.isSuccess === true) {
        runInAction(() => {
          this.selectedCollectionPlan = collectionPlan.data[0];
          this.selectedCollectionPlan.installments =
            this.selectedCollectionPlan.installments.sort(
              (a, b) =>
                parseFloat(a.installmentNumber) -
                parseFloat(b.installmentNumber)
            );

          sessionStorage.setItem(
            "totalDebt",
            this.selectedCollectionPlan.totalDebt
          );
          sessionStorage.setItem(
            "selectedCollectionPlan",
            JSON.stringify(this.selectedCollectionPlan)
          );
          this.totalPaid = 0;
          this.selectedCollectionPlan.installments.forEach((element) => {
            if (element.isPaid === true) {
              this.totalPaid = this.totalPaid + element.installmentAmount;
            }
          });
          this.resetPlanForm = !this.resetPlanForm;
          this.collectionPlanSelected = true;
        });
      } else {
        this.selectedCollectionPlan = undefined;
        this.collectionPlanSelected = false;
      }
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.selectedCollectionPlan = undefined;
        this.collectionPlanSelected = false;
      });
      throw error;
    }
  };

  loadCollectionPlans = async () => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const collectionPlan = await agent.Finance.collections();
      if (collectionPlan.isSuccess === true) {
        runInAction(() => {
          this.collectionPlans = collectionPlan.data[0];
          this.resetGetCollection = !this.resetGetCollection;
        });
      } else {
        this.collectionPlans = undefined;
      }
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.collectionPlans = undefined;
      });
      throw error;
    }
  };
  setReceipt = (collection, patientId) => {
    runInAction(() => {
      this.collections = collection;
      this.collections.patientId = patientId;
      this.collections.collectionDate = new Date(Date.now()).toISOString();
      this.collections.installmentId = this.installmentId;
    });
  };
  setReceiptState = (event) => {
    if (event.target.value !== "") {
      runInAction(() => {
        if (event.target.name === "collectionByCash") {
          this.collections.collectionByCash = event.target.value;
        }
        if (event.target.name === "collectionByCreditCard") {
          this.collections.collectionByCreditCard = event.target.value;
        }
        if (event.target.name === "collectionByCheck") {
          this.collections.collectionByCheck = event.target.value;
        }
      });
    } else {
      runInAction(() => {
        if (event.target.name === "collectionByCash") {
          this.collections.collectionByCash = 0;
        }
        if (event.target.name === "collectionByCreditCard") {
          this.collections.collectionByCreditCard = 0;
        }
        if (event.target.name === "collectionByCheck") {
          this.collections.collectionByCheck = 0;
        }
      });
    }
    this.collections.totalCollection =
      parseInt(this.collections.collectionByCash) +
      parseInt(this.collections.collectionByCreditCard) +
      parseInt(this.collections.collectionByCheck);
    this.resetReceiptState = !this.resetReceiptState;
  };

  addPayment = async (creds, doctorId) => {
    if (creds.id === " ") {
      creds.id = uuid();
      creds.userId = sessionStorage.getItem("userId");
      creds.doctorId = doctorId;
      creds.createdTime = new Date(creds.createdTime).toISOString();
    }
    try {
      runInAction(() => {
        this.loading = true;
        this.addingPayment=true
      });
      const payment = await agent.Finance.createPayment(creds);
      if (payment.isSuccess === true) {
        this.payments.push(creds);
        this.paymentsLoaded = true;
        this.saveButton = false;
        this.addingPayment=false
        toast.success("Ödeme yapıldı.");
      }
      runInAction(() => {
        this.loading = false;
        this.addingPayment=false
      });
    } catch (error) {
      this.loading = false;
      this.addingPayment=false

      throw error;
    }
  };

  getPaymentsByDoctorId = async (id) => {
    try {
      let payments;
      runInAction(() => {
        this.loading = true;
      });
      payments = await agent.Finance.getPaymentsByDoctorId(id);
      if (payments.isSuccess === true) {
        runInAction(() => {
          this.payments = payments.data;
          this.payments = this.payments.sort(
            (a, b) => parseFloat(a.createdTime) - parseFloat(b.createdTime)
          );
          this.paymentsLoaded = true;
        });
      }
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
      throw error;
    }
  };
  selectPayment = async (id) => {
    try {
      let payment;
      if (id !== undefined) {
        this.saveButton = false;
        payment = this.payments.find((x) => x.id === id);
      } else {
        this.saveButton = true;
        payment = undefined;
      }

      runInAction(() => {
        this.selectedPayment = payment;
      });
    } catch (error) {
      console.log(error);
    }
  };
}
