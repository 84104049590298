import "./planmenu.css";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";

function PlanMenu() {
  const { menuStore } = useStore();
  return (
    <div className="planMenu">
      <button
        className="planMenuBtn btn fcs"
        onClick={() => menuStore.selectTreatmentMenu("preventionHygieneMenu")}
      >
        Önleme Hijyen
      </button>
      <button
        className="planMenuBtn btn fcs"
        onClick={() => menuStore.selectTreatmentMenu("perioMenu")}
      >
        Dönem
      </button>
      <button
        className="planMenuBtn btn fcs"
        onClick={() => menuStore.selectTreatmentMenu("endodonticsMenu")}
      >
        Endodonti
      </button>
      <button
        className="planMenuBtn btn fcs"
        onClick={() => menuStore.selectTreatmentMenu("surgeryImplantsMenu")}
      >
        Cerrahi İmplantlar
      </button>
      <button
        className="planMenuBtn btn fcs"
        onClick={() => menuStore.selectTreatmentMenu("restorativeBleachMenu")}
      >
        Restoratif Ağartıcı
      </button>
      <button
        className="planMenuBtn btn fcs"
        onClick={() => menuStore.selectTreatmentMenu("prosthodonticsMenu")}
      >
        Protez
      </button>
      <button
        className="planMenuBtn btn fcs"
        onClick={() => menuStore.selectTreatmentMenu("orthoMenu")}
      >
        Orto
      </button>
      <button
        className="planMenuBtn btn fcs"
        onClick={() =>
          menuStore.selectTreatmentMenu("tmjOcclussionMusclesMenu")
        }
      >
        TME Oklüzyon Kasları
      </button>
    </div>
  );
}
export default observer(PlanMenu);
