import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button} from "react-bootstrap";
import { useStore } from "../../stores/store";
import moment from "moment";
import TextField from "@mui/material/TextField";

const Collection = () => {
  function handleCreate() {
    addCollection(collection);
    financeStore.editCollection=false
  }
  const { financeStore, patientStore } = useStore();
  const {
    selectedCollectionPlan,
    collections,
    resetReceiptState,
    setReceipt,
    setReceiptState,
    addCollection,
  } = financeStore;
  const { selectedPatient, selectPatient } = patientStore;

  const componentRef = useRef();

  var str = "";

  const initialState2 = selectedCollectionPlan ?? {
    id: " ",
    patientId: "",
    planId: "",
    totalDebt: 0,
    collectionType: 0,
    discountRate: 0,
    discountedDebt: 0,
    numberOfInstallments: 0,
    installments: [],
  };
  const [collectionPlan, setCollectionPlan] = useState(initialState2);

  useEffect(() => {
    setCollectionPlan(initialState2);
  }, []);

  const initialPatientState = selectedPatient ?? {
    id: " ",
    identityNumber: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    gender: true,
    note: "",
    isActive: 1,
  };
  const [patient, setPatient] = useState(initialPatientState);

  const initialState = collections ?? {
    id: " ",
    patientId: " ",
    installmentId: "",
    collectionByCash: 0,
    collectionByCreditCard: 0,
    collectionByCheck: 0,
    totalCollection: 0,
    currency: 0,
    collectionDate: "",
    userId: "",
  };
  const [collection, setCollection] = useState(initialState);

  useEffect(() => {
    setCollection(initialState);
    setPatient(initialPatientState);
    if (collection.patientId === " ") {
      setReceipt(collection, collectionPlan.patientId);
      setCollection(initialState);
    }
  }, [resetReceiptState]);

  function handleInputChange(event) {
    setReceiptState(event);
  }
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <div>
        <input
          className="datePicker"
          value={moment(collection.collectionDate).utc().format("YYYY-MM-DD")}
        />
      </div>
      <div>
        <TextField
          style={{ marginBottom: "1rem", width: "80%" }}
          name="fullName"
          value={patient.fullName}
          onChange={handleInputChange}
          label="İsim Soyisim"
        />
      </div>
      <div>
        <TextField
          style={{ marginBottom: "1rem", width: "80%" }}
          name="identityNumber"
          value={patient.identityNumber}
          onChange={handleInputChange}
          label="Kimlik Numarası"
        />
      </div>
      <div>
        <TextField
          style={{ marginBottom: "1rem", width: "80%" }}
          name="collectionByCash"
          value={
            collection.collectionByCash - (collection.collectionByCash % 1)
          }
          onChange={handleInputChange}
          label="Ödenen Miktar"
        />
      </div>
      <Button variant="outline-primary" onClick={handleCreate}>Ödeme Yap</Button>
    </div>
  );
};
export default observer(Collection);
