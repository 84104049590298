import { runInAction, makeAutoObservable } from "mobx";
import React from "react";
import agent from "../api/agent";

export default class TreatmentStore {
  constructor() {
    makeAutoObservable(this);
  }
  resetForm2 = false;
  resetForm3 = false;
  loadedCount = 0;
  selectedPlan = JSON.parse(sessionStorage.getItem("planID"));
  selectedTeeth = [];
  selectedSurfaces = [];
  selectedLocations = undefined;
  selectedDegree = undefined;
  currentTreatment = undefined;
  selectedTreatment = undefined;
  treatmentTypes = undefined;
  tooth = {
    name: "",
    selected: "",
    missing: "",
    class: "tooth",
    preventionAndHygiene: false, //1
    professionalToothCleaning: false, //2
    topicalFluoride: false, //3
    professionalToothCleaning2: false, //4
    scalingOrRootPlanning: false, //5
    pokcetReduction: false, //6
    boneRegeneration: false, //7
    gingivalGraft: false, //8
    plasticPerioSurgery: false, //9
    crownLengthening: false, //10
    gingivectomy: false, //11
    laserGingivectomy: false, //12
    RootCanalTreatment: {
      //13
      oneCanal: false,
      twoCanal: false,
      threeCanal: false,
      threeOrMoreCanal: false,
    },
    RootCanalReTreatment: {
      //14
      oneCanal: false,
      twoCanal: false,
      threeCanal: false,
      threeOrMoreCanal: false,
    },
    // RootCanalReTreatmentInstrumentRemoval : false, //15
    apicectomy: false, //16
    implant: false, //17
    extraction: false, //18
    wisdomToothExtraction: false, //19
    sinusLift: false, //20
    boneRegeneration2: false, //21
    fullArchImplantsOrDenture: false, //22
    removeImplant: false, //23
    surgicalGuide: false, //24
    restoration: {
      //25
      buccal: false,
      distal: false,
      lingual: false,
      mesial: false,
      occlusal: false,
    },
    post: false, //26
    splint: false, //27
    externalBleach: false, //28
    internalBleach: false, //29
    coreBuldUp: {
      //30
      buccal: false,
      distal: false,
      lingual: false,
      mesial: false,
      occlusal: false,
    },
    provisionalRestoration: {
      //31
      buccal: false,
      distal: false,
      lingual: false,
      mesial: false,
      occlusal: false,
    },
    veneer: false, //32
    onlay: false, //33
    crown: false, //34
    bridge: false, //35
    adhesiveBridge: false, //36
    denture: {
      //37
      chromeCobalt: false,
      acrylic: false,
    },
    removeBridgeOrDenture: false, //38
    articulatedStudyModels: false, //39
    provisionalBridge: false, //40
    provisionalCrown: false, //41
    fixedBraces: {
      //42
      fixedBraces: false,
      metalBraces: false,
      ceramicBraces: false,
    },
    lingualBraces: false, //43
    clearAligners: false, //44
    removableAppliance: false, //45
    functionalAppliance: false, //46
    cosmeticOrthodontics: false, //47
    retainer: false, //48
    miniImplantsForAnchorage: false, //49
    tractionOfRetainedTeeth: false, //50
    articulatedStudyModels2: false, //51
    behaviouralAdviceAndStressControl: false, //52
    occlusalSplint: false, //53
    occlusalEquilibration: false, //54
    articulatedStudyModels3: false, //55
    gengivaDefault: true,
    boneDefault: true,
    boneLineDefault: true,
    //buradan sonrası plan için
  };

  treatmentModel = {
    treatmentStageId: "",
    treatmentType: 0,
    teeths: [],
    price: 0,
    channel: 0,
    dentureType: 0,
    braceTypes: 0,
    toothSurfaces: [],
    observation: "",
  };
  odontogram2 = [];

  magicFunction2 = (
    treatmentType,
    teeth,
    toothSurface,
    toothSurfaceValue,
    locations,
    degree,
    status,
    treatmentObject
  ) => {
    if (this.treatmentTypes === undefined) this.loadTreatmentTypes(1,100);
    if (teeth !== undefined) {
      let item = this.selectedTeeth.find((x) => x.name === teeth);
      if (item) {
        runInAction(() => {
          let testTeeth = this.selectedTeeth.filter((z) => z.name !== teeth);
          this.selectedTeeth = testTeeth;
          this.currentTreatment.teeths = [];
          this.selectedTeeth.forEach((element) => {
            this.currentTreatment.teeths.push(
              parseInt(element.name.substr(-2))
            );
          });
          this.currentTreatment.teeths = this.currentTreatment.teeths.sort();
        });
      } else {
        runInAction(() => {
          let addedItem = this.tooth;
          addedItem.name = teeth;
          this.selectedTeeth.push(this.cloneOdontogram(addedItem));
          this.currentTreatment.teeths = [];
          this.selectedTeeth.forEach((element) => {
            this.currentTreatment.teeths.push(
              parseInt(element.name.substr(-2))
            );
          });
          this.currentTreatment.teeths = this.currentTreatment.teeths.sort();
        });
      }
    }
    if (treatmentType !== undefined) {
      this.currentTreatment.treatmentType = parseInt(treatmentType);
      if (this.selectedTreatment === undefined) {
        this.selectedTreatment = parseInt(treatmentType);
      } else if (this.selectedTreatment === treatmentType) {
        this.selectedTreatment = parseInt(treatmentType);
      } else {
        this.selectedTreatment = parseInt(treatmentType);
      }
    }
    if (toothSurface !== undefined) {
      toothSurfaceValue
        ? runInAction(() => {
            this.selectedSurfaces.push(parseInt(toothSurface));
            this.currentTreatment.toothSurfaces = this.selectedSurfaces;
            this.currentTreatment.toothSurfaces =
              this.currentTreatment.toothSurfaces.sort();
          })
        : runInAction(() => {
            let surface = this.selectedSurfaces.filter(
              (z) => z !== parseInt(toothSurface)
            );
            this.selectedSurfaces = surface;
            this.currentTreatment.toothSurfaces = this.selectedSurfaces;
          });
    }
    if (locations !== undefined) {
      runInAction(() => {
        this.selectedLocations = parseInt(locations);
        this.currentTreatment.locations = parseInt(locations);
      });
    }

    if (degree !== undefined) {
      runInAction(() => {
        this.selectedDegree = parseInt(degree);
        this.currentTreatment.degree = parseInt(degree);
      });
    }
    if (status !== undefined) {
      runInAction(() => {
        this.selectedStatus = parseInt(status);
        this.currentTreatment.status = parseInt(status);
      });
    }
    if (this.currentTreatment.teeths.length !== 0 && this.currentTreatment.treatmentType !== 0 ){
      this.currentTreatment.price = this.currentTreatment.teeths.length * this.treatmentTypes.find(x => x.treatmentCode ===this.currentTreatment.treatmentType).price;
      this.currentTreatment.currency = this.treatmentTypes.find(x => x.treatmentCode ===this.currentTreatment.treatmentType).currency;
    }
    if (treatmentObject !== undefined) {
    }
    
    this.loadOdontogram2();
  };

  loadOdontogram2(passedPlan) {
    if (passedPlan !== undefined) this.selectedPlan = passedPlan; //gönderilen plan varsa o planınn özerlliklerine göre ekranı güncelliyor
    if (this.currentTreatment === undefined)
      this.currentTreatment = JSON.parse(JSON.stringify(this.treatmentModel));
    this.odontogram2 = [];

    for (let j = 11; j <= 48; j++) {
      let addedItem = JSON.parse(JSON.stringify(this.tooth));
      addedItem.name = "tooth" + j;
      let selectedCheck = this.selectedTeeth.find(
        (item) => item.name === addedItem.name
      );
      if (selectedCheck) {
        addedItem.selected = "selected";

        if (this.selectedTreatment !== undefined) {
          if (this.selectedTreatment === 1) {
            addedItem.preventionAndHygiene = !addedItem.preventionAndHygiene;
          } else if (this.selectedTreatment === 2) {
            addedItem.professionalToothCleaning =
              !addedItem.professionalToothCleaning;
          } else if (this.selectedTreatment === 3) {
            addedItem.topicalFluoride = !addedItem.topicalFluoride;
          } else if (this.selectedTreatment === 4) {
            addedItem.professionalToothCleaning2 =
              !addedItem.professionalToothCleaning2;
          } else if (this.selectedTreatment === 5) {
            addedItem.scalingOrRootPlanning = !addedItem.scalingOrRootPlanning;
          } else if (this.selectedTreatment === 6) {
            addedItem.pokcetReduction = !addedItem.pokcetReduction;
          } else if (this.selectedTreatment === 7) {
            addedItem.boneRegeneration = !addedItem.boneRegeneration;
          } else if (this.selectedTreatment === 8) {
            addedItem.gingivalGraft = !addedItem.gingivalGraft;
          } else if (this.selectedTreatment === 9) {
            addedItem.plasticPerioSurgery = !addedItem.plasticPerioSurgery;
          } else if (this.selectedTreatment === 10) {
            addedItem.crownLengthening = !addedItem.crownLengthening;
          } else if (this.selectedTreatment === 11) {
            addedItem.gingivectomy = !addedItem.gingivectomy;
          } else if (this.selectedTreatment === 12) {
            addedItem.laserGingivectomy = !addedItem.laserGingivectomy;
          } else if (this.selectedTreatment === 13) {
            this.selectedSurfaces?.forEach((element) => {
              if (element === 1) addedItem.rootCanalTreatment.oneCanal = true;
              if (element === 2) addedItem.rootCanalTreatment.twoCanal = true;
              if (element === 3) addedItem.rootCanalTreatment.threeCanal = true;
              if (element === 4)
                addedItem.rootCanalTreatment.threeOrMoreCanal = true;
            });
          } else if (this.selectedTreatment === 14) {
            this.selectedSurfaces?.forEach((element) => {
              if (element === 1) addedItem.rootCanalReTreatment.oneCanal = true;
              if (element === 2) addedItem.rootCanalReTreatment.twoCanal = true;
              if (element === 3)
                addedItem.rootCanalReTreatment.threeCanal = true;
              if (element === 4)
                addedItem.rootCanalReTreatment.threeOrMoreCanal = true;
            });
          }
          // else if (this.selectedTreatment === 15) {
          //     addedItem.crownLengthening = !addedItem.crownLengthening;
          // }
          else if (this.selectedTreatment === 16) {
            addedItem.apicectomy = !addedItem.apicectomy;
          } else if (this.selectedTreatment === 17) {
            addedItem.implant = !addedItem.implant;
          } else if (this.selectedTreatment === 18) {
            addedItem.extraction = !addedItem.extraction;
          } else if (this.selectedTreatment === 19) {
            addedItem.wisdomToothExtraction = !addedItem.wisdomToothExtraction;
          } else if (this.selectedTreatment === 20) {
            addedItem.sinusLift = !addedItem.sinusLift;
          } else if (this.selectedTreatment === 21) {
            addedItem.boneRegeneration2 = !addedItem.boneRegeneration2;
          } else if (this.selectedTreatment === 22) {
            addedItem.fullArchImplantsOrDenture =
              !addedItem.fullArchImplantsOrDenture;
          } else if (this.selectedTreatment === 23) {
            addedItem.removeImplant = !addedItem.removeImplant;
          } else if (this.selectedTreatment === 24) {
            addedItem.surgicalGuide = !addedItem.surgicalGuide;
          } else if (this.selectedTreatment === 25) {
            this.selectedSurfaces?.forEach((element) => {
              if (element === 1) addedItem.restoration.buccal = true;
              if (element === 2) addedItem.restoration.distal = true;
              if (element === 3) addedItem.restoration.lingual = true;
              if (element === 4) addedItem.restoration.mesial = true;
              if (element === 5) addedItem.restoration.occlusal = true;
            });
          } else if (this.selectedTreatment === 26) {
            addedItem.post = !addedItem.post;
          } else if (this.selectedTreatment === 27) {
            addedItem.splint = !addedItem.splint;
          } else if (this.selectedTreatment === 28) {
            addedItem.externalBleach = !addedItem.externalBleach;
          } else if (this.selectedTreatment === 29) {
            addedItem.internalBleach = !addedItem.internalBleach;
          } else if (this.selectedTreatment === 30) {
            this.selectedSurfaces?.forEach((element) => {
              if (element === 1) addedItem.coreBuldUp.buccal = true;
              if (element === 2) addedItem.coreBuldUp.distal = true;
              if (element === 3) addedItem.coreBuldUp.lingual = true;
              if (element === 4) addedItem.coreBuldUp.mesial = true;
              if (element === 5) addedItem.coreBuldUp.occlusal = true;
            });
          } else if (this.selectedTreatment === 31) {
            this.selectedSurfaces?.forEach((element) => {
              if (element === 1) addedItem.provisionalRestoration.buccal = true;
              if (element === 2) addedItem.provisionalRestoration.distal = true;
              if (element === 3)
                addedItem.provisionalRestoration.lingual = true;
              if (element === 4) addedItem.provisionalRestoration.mesial = true;
              if (element === 5)
                addedItem.provisionalRestoration.occlusal = true;
            });
          } else if (this.selectedTreatment === 32) {
            addedItem.veneer = !addedItem.veneer;
          } else if (this.selectedTreatment === 33) {
            addedItem.onlay = !addedItem.onlay;
          } else if (this.selectedTreatment === 34) {
            addedItem.crown = !addedItem.crown;
          } else if (this.selectedTreatment === 35) {
            addedItem.bridge = !addedItem.bridge;
          } else if (this.selectedTreatment === 36) {
            addedItem.adhesiveBridge = !addedItem.adhesiveBridge;
          } else if (this.selectedTreatment === 37) {
            this.selectedSurfaces?.forEach((element) => {
              if (element === 1) addedItem.denture.chromeCobalt = true;
              if (element === 2) addedItem.denture.acrylic = true;
            });
          } else if (this.selectedTreatment === 38) {
            addedItem.removeBridgeOrDenture = !addedItem.removeBridgeOrDenture;
          } else if (this.selectedTreatment === 39) {
            addedItem.articulatedStudyModels =
              !addedItem.articulatedStudyModels;
          } else if (this.selectedTreatment === 40) {
            addedItem.provisionalBridge = !addedItem.provisionalBridge;
          } else if (this.selectedTreatment === 41) {
            addedItem.provisionalCrown = !addedItem.provisionalCrown;
          } else if (this.selectedTreatment === 42) {
            this.selectedSurfaces?.forEach((element) => {
              if (element === 1) addedItem.fixedBraces.fixedBraces = true;
              if (element === 2) addedItem.fixedBraces.metalBraces = true;
              if (element === 3) addedItem.fixedBraces.ceramicBraces = true;
            });
          } else if (this.selectedTreatment === 43) {
            addedItem.lingualBraces = !addedItem.lingualBraces;
          } else if (this.selectedTreatment === 44) {
            addedItem.clearAligners = !addedItem.clearAligners;
          } else if (this.selectedTreatment === 45) {
            addedItem.removableAppliance = !addedItem.removableAppliance;
          } else if (this.selectedTreatment === 46) {
            addedItem.functionalAppliance = !addedItem.functionalAppliance;
          } else if (this.selectedTreatment === 47) {
            addedItem.cosmeticOrthodontics = !addedItem.cosmeticOrthodontics;
          } else if (this.selectedTreatment === 48) {
            addedItem.retainer = !addedItem.retainer;
          } else if (this.selectedTreatment === 49) {
            addedItem.miniImplantsForAnchorage =
              !addedItem.miniImplantsForAnchorage;
          } else if (this.selectedTreatment === 50) {
            addedItem.tractionOfRetainedTeeth =
              !addedItem.tractionOfRetainedTeeth;
          } else if (this.selectedTreatment === 51) {
            addedItem.articulatedStudyModels2 =
              !addedItem.articulatedStudyModels2;
          } else if (this.selectedTreatment === 52) {
            addedItem.behaviouralAdviceAndStressControl =
              !addedItem.behaviouralAdviceAndStressControl;
          } else if (this.selectedTreatment === 53) {
            addedItem.occlusalSplint = !addedItem.occlusalSplint;
          } else if (this.selectedTreatment === 54) {
            addedItem.occlusalEquilibration = !addedItem.occlusalEquilibration;
          } else if (this.selectedTreatment === 55) {
            addedItem.articulatedStudyModels3 =
              !addedItem.articulatedStudyModels3;
          }
        }
        this.odontogram2.push(this.cloneOdontogram(addedItem));
      } else this.odontogram2.push(this.cloneOdontogram(addedItem));
      if (j === 18) j = 20;
      if (j === 29) j = 30;
      if (j === 39) j = 40;
    } //buradan sonrası backend den gelen datayı ekrana yazdırıyor
    this.loadedCount = 0;
    if (this.selectedPlan.id !== undefined) {
      this.selectedPlan.treatmentStages.forEach((stage) => {
        sessionStorage.setItem("stage", JSON.stringify(stage));

        stage.treatments.forEach((treatment) => {
          this.loadedCount += 1;
          if (treatment.treatmentType === 1) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.preventionAndHygiene = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 2) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.professionalToothCleaning = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 3) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.topicalFluoride = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 4) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.professionalToothCleaning2 = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 5) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.scalingOrRootPlanning = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 6) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.pokcetReduction = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 7) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.boneRegeneration = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 8) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.gingivalGraft = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 9) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.plasticPerioSurgery = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 10) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.crownLengthening = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 11) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.gingivectomy = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 12) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.laserGingivectomy = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 13) {
            treatment.teeths.forEach((teeth) => {
              treatment.toothSurfaces.forEach((surface) => {
                if (surface === 1) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.rootCanalTreatment.oneCanal = true;
                }
                if (surface === 2) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.rootCanalTreatment.twoCanal = true;
                }
                if (surface === 3) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.rootCanalTreatment.threeCanal = true;
                }
                if (surface === 4) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.rootCanalTreatment.threeOrMoreCanal = true;
                }
                let updatedOdontogram = this.odontogram2.filter(
                  (z) => z.name !== teeth.name
                );
                runInAction(() => {
                  this.odontogram2 = updatedOdontogram;
                });
              });
            });
          }
          if (treatment.treatmentType === 14) {
            treatment.teeths.forEach((teeth) => {
              treatment.toothSurfaces.forEach((surface) => {
                if (surface === 1) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.rootCanalReTreatment.oneCanal = true;
                }
                if (surface === 2) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.rootCanalReTreatment.twoCanal = true;
                }
                if (surface === 3) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.rootCanalReTreatment.threeCanal = true;
                }
                if (surface === 4) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.rootCanalReTreatment.threeOrMoreCanal = true;
                }
                let updatedOdontogram = this.odontogram2.filter(
                  (z) => z.name !== teeth.name
                );
                runInAction(() => {
                  this.odontogram2 = updatedOdontogram;
                });
              });
            });
          }
          // if (treatment.treatmentType === 15){
          //     treatment.teeths.forEach(teeth => {
          //     let updatedTeeth = this.odontogram.find(x=> x.name.substr(-2) === teeth.toString());
          //     updatedTeeth.laserGingivectomy = true;
          //     let updatedOdontogram = this.odontogram.filter(z => z.name !== teeth.name);
          //         runInAction(() => {
          //             this.odontogram = updatedOdontogram;
          //         })
          //     });
          // }
          if (treatment.treatmentType === 16) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.apicectomy = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 17) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.implant = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 18) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.extraction = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 19) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.wisdomToothExtraction = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 20) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.sinusLift = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 21) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.boneRegeneration2 = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 22) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.fullArchImplantsOrDenture = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 23) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.removeImplant = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 24) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.surgicalGuide = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 25) {
            treatment.teeths.forEach((teeth) => {
              treatment.toothSurfaces.forEach((surface) => {
                if (surface === 1) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.restoration.buccal = true;
                }
                if (surface === 2) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.restoration.distal = true;
                }
                if (surface === 3) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.restoration.lingual = true;
                }
                if (surface === 4) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.restoration.mesial = true;
                }
                if (surface === 5) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.restoration.occlusal = true;
                }
                let updatedOdontogram = this.odontogram2.filter(
                  (z) => z.name !== teeth.name
                );
                runInAction(() => {
                  this.odontogram2 = updatedOdontogram;
                });
              });
            });
          }
          if (treatment.treatmentType === 26) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.post = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 27) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.splint = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 28) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.externalBleach = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 29) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.internalBleach = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 30) {
            treatment.teeths.forEach((teeth) => {
              treatment.toothSurfaces.forEach((surface) => {
                if (surface === 1) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.coreBuldUp.buccal = true;
                }
                if (surface === 2) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.coreBuldUp.distal = true;
                }
                if (surface === 3) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.coreBuldUp.lingual = true;
                }
                if (surface === 4) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.coreBuldUp.mesial = true;
                }
                if (surface === 5) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.coreBuldUp.occlusal = true;
                }
                let updatedOdontogram = this.odontogram2.filter(
                  (z) => z.name !== teeth.name
                );
                runInAction(() => {
                  this.odontogram2 = updatedOdontogram;
                });
              });
            });
          }
          if (treatment.treatmentType === 31) {
            treatment.teeths.forEach((teeth) => {
              treatment.toothSurfaces.forEach((surface) => {
                if (surface === 1) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.provisionalRestoration.buccal = true;
                }
                if (surface === 2) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.provisionalRestoration.distal = true;
                }
                if (surface === 3) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.provisionalRestoration.lingual = true;
                }
                if (surface === 4) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.provisionalRestoration.mesial = true;
                }
                if (surface === 5) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.provisionalRestoration.occlusal = true;
                }
                let updatedOdontogram = this.odontogram2.filter(
                  (z) => z.name !== teeth.name
                );
                runInAction(() => {
                  this.odontogram2 = updatedOdontogram;
                });
              });
            });
          }
          if (treatment.treatmentType === 32) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.veneer = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 33) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.onlay = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 34) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.crown = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 35) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.bridge = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 36) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.adhesiveBridge = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 37) {
            treatment.teeths.forEach((teeth) => {
              treatment.toothSurfaces.forEach((surface) => {
                if (surface === 1) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.denture.chromeCobalt = true;
                }
                if (surface === 2) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.denture.acrylic = true;
                }

                let updatedOdontogram = this.odontogram2.filter(
                  (z) => z.name !== teeth.name
                );
                runInAction(() => {
                  this.odontogram2 = updatedOdontogram;
                });
              });
            });
          }
          if (treatment.treatmentType === 38) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.removeBridgeOrDenture = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 39) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.articulatedStudyModels = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 40) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.provisionalBridge = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 41) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.provisionalCrown = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 38) {
            treatment.teeths.forEach((teeth) => {
              treatment.toothSurfaces.forEach((surface) => {
                if (surface === 1) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.fixedBraces.fixedBraces = true;
                }
                if (surface === 2) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.fixedBraces.metalBraces = true;
                }
                if (surface === 3) {
                  let updatedTeeth = this.odontogram2.find(
                    (x) => x.name.substr(-2) === teeth.toString()
                  );
                  updatedTeeth.fixedBraces.ceramicBraces = true;
                }

                let updatedOdontogram = this.odontogram2.filter(
                  (z) => z.name !== teeth.name
                );
                runInAction(() => {
                  this.odontogram2 = updatedOdontogram;
                });
              });
            });
          }
          if (treatment.treatmentType === 43) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.lingualBraces = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 44) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.clearAligners = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 45) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.removableAppliance = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 46) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.functionalAppliance = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 47) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.cosmeticOrthodontics = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 48) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.retainer = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 49) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.miniImplantsForAnchorage = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 50) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.tractionOfRetainedTeeth = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 51) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.articulatedStudyModels2 = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 52) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.behaviouralAdviceAndStressControl = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 53) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.occlusalSplint = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 54) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.occlusalEquilibration = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
          if (treatment.treatmentType === 55) {
            treatment.teeths.forEach((teeth) => {
              let updatedTeeth = this.odontogram2.find(
                (x) => x.name.substr(-2) === teeth.toString()
              );
              updatedTeeth.articulatedStudyModels3 = true;
              let updatedOdontogram = this.odontogram2.filter(
                (z) => z.name !== teeth.name
              );
              runInAction(() => {
                this.odontogram2 = updatedOdontogram;
              });
            });
          }
        });
      });
    }
  }

  createTreatment = async (treatment) => {
    try {
      let result = await agent.Treatment.create(treatment);

      runInAction(() => {
        this.selectedPlan.treatmentStages.find((x) => x.id ===treatment.treatmentStageId).treatments.push(result.data);
        this.resetForm2 = !this.resetForm2;
        this.currentTreatment = undefined;
        this.selectedTeeth = [];
        this.selectedTreatment = undefined;
        this.selectedSurfaces = [];
      });
    } catch (error) {
      console.log(error);
    }
  };

  deleteTreatment = async (id) => {
    try {
      await agent.Treatment.delete(id);
      runInAction(() => {
        this.resetForm2 = !this.resetForm2;  // hangi stageden silineceğine bakılacak. 
        this.selectedPlan.treatmentStages[0].treatments = [
          ...this.selectedPlan.treatmentStages[0].treatments.filter((a) => a.id !== id),
        ];
      });
    } catch (error) {
      console.log(error);
    }
  };
  updateTreatment = async (treatment) => {
    try {
      var result = await agent.Treatment.update(treatment);
      runInAction(() => {
        this.selectedPlan.treatmentStages[0].treatments = [
          ...this.selectedPlan.treatmentStages[0].treatments.filter((a) => a.id !== result.data.id),
          result.data,
        ];
        sessionStorage.setItem("planID", JSON.stringify(this.selectedPlan));
        console.log(JSON.stringify((this.selectedPlan.treatmentStages[0].treatments)))
        this.resetForm2 = !this.resetForm2;
      });
    } catch (error) {
      console.log(error);
    }
  };
  selectTeeth(teeth) {
    let item = this.selectedTeeth.find((x) => x.name === teeth);
    item
      ? runInAction(() => {
          let testTeeth = this.selectedTeeth.filter((z) => z.name !== teeth);
          this.selectedTeeth = testTeeth;
          let teethNumber = teeth.split("h");
          teethNumber[1] = teethNumber[1] + ", ";
          this.currentTreatment.Teeths = [
            ...this.currentTreatment.Teeths.filter((a) => a !== teethNumber[1]),
          ];
        })
      : runInAction(() => {
          let addedItem = this.tooth;
          addedItem.name = teeth;
          this.selectedTeeth.push(this.cloneOdontogram(addedItem));
          let teethNumber = teeth.split("h");
          teethNumber[1] = teethNumber[1] + ", ";
          this.currentTreatment.Teeths.push(teethNumber[1]);
        });

    this.odontogram2.forEach((element) => {
      if (element.name === teeth && element.selected === "selected") {
        runInAction(() => {
          element.selected = "";
        });
      } else if (element.name === teeth) {
        runInAction(() => {
          element.selected = "selected";
        });
      }
    });
  }

  selectPreventionAndHygiene = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.preventionAndHygiene = !item.preventionAndHygiene;
        });
      }
    });
  };
  selectProfessionalToothCleaning = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.professionalToothCleaning = !item.professionalToothCleaning;
        });
      }
    });
  };
  selectTopicalFluoride = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.topicalFluoride = !item.topicalFluoride;
        });
      }
    });
  };
  selectProfessionalToothCleaning2 = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.professionalToothCleaning2 = !item.professionalToothCleaning2;
        });
      }
    });
  };
  selectScalingOrRootPlanning = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.scalingOrRootPlanning = !item.scalingOrRootPlanning;
        });
      }
    });
  };
  selectPokcetReduction = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.pokcetReduction = !item.pokcetReduction;
        });
      }
    });
  };
  selectBoneRegeneration = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.boneRegeneration = !item.boneRegeneration;
        });
      }
    });
  };
  selectGingivalGraft = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.gingivalGraft = !item.gingivalGraft;
        });
      }
    });
  };
  selectPlasticPerioSurgery = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.plasticPerioSurgery = !item.plasticPerioSurgery;
        });
      }
    });
  };
  selectCrownLengthening = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.crownLengthening = !item.crownLengthening;
        });
      }
    });
  };
  selectGingivectomy = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.gingivectomy = !item.gingivectomy;
        });
      }
    });
  };
  selectLaserGingivectomy = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.laserGingivectomy = !item.laserGingivectomy;
        });
      }
    });
  };
  selectRootCanalTreatment = (value, checked) => {
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "oneCanal") {
            item.rootCanalTreatment.oneCanal = checked;
            item.rootCanalTreatment.twoCanal = !checked;
            item.rootCanalTreatment.threeCanal = !checked;
            item.rootCanalTreatment.threeOrMoreCanal = !checked;
          } else if (value === "twoCanal") {
            item.rootCanalTreatment.oneCanal = !checked;
            item.rootCanalTreatment.twoCanal = checked;
            item.rootCanalTreatment.threeCanal = !checked;
            item.rootCanalTreatment.threeOrMoreCanal = !checked;
          } else if (value === "threeCanal") {
            item.rootCanalTreatment.oneCanal = !checked;
            item.rootCanalTreatment.twoCanal = !checked;
            item.rootCanalTreatment.threeCanal = checked;
            item.rootCanalTreatment.threeOrMoreCanal = !checked;
          } else if (value === "threeOrMoreCanal") {
            item.rootCanalTreatment.oneCanal = !checked;
            item.rootCanalTreatment.twoCanal = !checked;
            item.rootCanalTreatment.threeCanal = !checked;
            item.rootCanalTreatment.threeOrMoreCanal = checked;
          }
        });
      }
    });
  };
  selectRootCanalReTreatment = (value, checked) => {
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "oneCanal") {
            item.rootCanalReTreatment.oneCanal = checked;
            item.rootCanalReTreatment.twoCanal = !checked;
            item.rootCanalReTreatment.threeCanal = !checked;
            item.rootCanalReTreatment.threeOrMoreCanal = !checked;
          } else if (value === "twoCanal") {
            item.rootCanalReTreatment.oneCanal = !checked;
            item.rootCanalReTreatment.twoCanal = checked;
            item.rootCanalReTreatment.threeCanal = !checked;
            item.rootCanalReTreatment.threeOrMoreCanal = !checked;
          } else if (value === "threeCanal") {
            item.rootCanalReTreatment.oneCanal = !checked;
            item.rootCanalReTreatment.twoCanal = !checked;
            item.rootCanalReTreatment.threeCanal = checked;
            item.rootCanalReTreatment.threeOrMoreCanal = !checked;
          } else if (value === "threeOrMoreCanal") {
            item.rootCanalReTreatment.oneCanal = !checked;
            item.rootCanalReTreatment.twoCanal = !checked;
            item.rootCanalReTreatment.threeCanal = !checked;
            item.rootCanalReTreatment.threeOrMoreCanal = checked;
          }
        });
      }
    });
  };

  selectApicectomy = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.apicectomy = !item.apicectomy;
        });
      }
    });
  };
  selectImplant = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.implant = !item.implant;
        });
      }
    });
  };
  selectExtraction = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.extraction = !item.extraction;
        });
      }
    });
  };
  selectWisdomToothExtraction = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.wisdomToothExtraction = !item.wisdomToothExtraction;
        });
      }
    });
  };
  selectSinusLift = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.sinusLift = !item.sinusLift;
        });
      }
    });
  };
  selectBoneRegeneration2 = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.boneRegeneration2 = !item.boneRegeneration2;
        });
      }
    });
  };
  selectFullArchImplantsOrDenture = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.fullArchImplantsOrDenture = !item.fullArchImplantsOrDenture;
        });
      }
    });
  };
  selectRemoveImplant = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.removeImplant = !item.removeImplant;
        });
      }
    });
  };
  selectSurgicalGuide = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.surgicalGuide = !item.surgicalGuide;
        });
      }
    });
  };
  selectRestoration = (value, checked) => {
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "buccal") item.restoration.buccal = checked;
          if (value === "distal") item.restoration.distal = checked;
          if (value === "lingual") item.restoration.lingual = checked;
          if (value === "mesial") item.restoration.mesial = checked;
          if (value === "occlusal") item.restoration.occlusal = checked;
        });
      }
    });
  };

  selectPost = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.post = !item.post;
        });
      }
    });
  };

  selectSplint = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.splint = !item.splint;
        });
      }
    });
  };
  selectExternalBleach = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.externalBleach = !item.externalBleach;
        });
      }
    });
  };
  selectInternalBleach = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.internalBleach = !item.internalBleach;
        });
      }
    });
  };
  selectCoreBuldUp = (value, checked) => {
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "buccal") item.coreBuldUp.buccal = checked;
          if (value === "distal") item.coreBuldUp.distal = checked;
          if (value === "lingual") item.coreBuldUp.lingual = checked;
          if (value === "mesial") item.coreBuldUp.mesial = checked;
          if (value === "occlusal") item.coreBuldUp.occlusal = checked;
        });
      }
    });
  };
  selectProvisionalRestoration = (value, checked) => {
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "buccal") item.provisionalRestoration.buccal = checked;
          if (value === "distal") item.provisionalRestoration.distal = checked;
          if (value === "lingual")
            item.provisionalRestoration.lingual = checked;
          if (value === "mesial") item.provisionalRestoration.mesial = checked;
          if (value === "occlusal")
            item.provisionalRestoration.occlusal = checked;
        });
      }
    });
  };

  selectVeneer = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.veneer = !item.veneer;
        });
      }
    });
  };
  selectOnlay = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.onlay = !item.onlay;
        });
      }
    });
  };
  selectCrown = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.crown = !item.crown;
        });
      }
    });
  };
  selectBridge = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.bridge = !item.bridge;
        });
      }
    });
  };
  selectAdhesiveBridge = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.adhesiveBridge = !item.adhesiveBridge;
        });
      }
    });
  };
  selectDenture = (value, checked) => {
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "chromeCobalt") {
            item.denture.chromeCobalt = checked;
            item.denture.acrylic = !checked;
          } else if (value === "acrylic") {
            item.denture.chromeCobalt = !checked;
            item.denture.acrylic = checked;
          }
        });
      }
    });
  };
  selectRemoveBridgeOrDenture = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.removeBridgeOrDenture = !item.removeBridgeOrDenture;
        });
      }
    });
  };
  selectArticulatedStudyModels = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.articulatedStudyModels = !item.articulatedStudyModels;
        });
      }
    });
  };
  selectProvisionalBridge = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.provisionalBridge = !item.provisionalBridge;
        });
      }
    });
  };
  selectProvisionalCrown = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.provisionalCrown = !item.provisionalCrown;
        });
      }
    });
  };
  selectFixedBraces = (value, checked) => {
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          if (value === "fixedBraces") {
            item.fixedBraces.fixedBraces = checked;
            item.fixedBraces.metalBraces = !checked;
            item.fixedBraces.ceramicBraces = !checked;
          } else if (value === "metalBraces") {
            item.fixedBraces.fixedBraces = !checked;
            item.fixedBraces.metalBraces = checked;
            item.fixedBraces.ceramicBraces = !checked;
          } else if (value === "ceramicBraces") {
            item.fixedBraces.fixedBraces = !checked;
            item.fixedBraces.metalBraces = !checked;
            item.fixedBraces.ceramicBraces = checked;
          }
        });
      }
    });
  };

  selectLingualBraces = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.lingualBraces = !item.lingualBraces;
        });
      }
    });
  };
  selectClearAligners = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.clearAligners = !item.clearAligners;
        });
      }
    });
  };
  selectRemovableAppliance = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.removableAppliance = !item.removableAppliance;
        });
      }
    });
  };
  selectFunctionalAppliance = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.functionalAppliance = !item.functionalAppliance;
        });
      }
    });
  };
  selectCosmeticOrthodontics = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.cosmeticOrthodontics = !item.cosmeticOrthodontics;
        });
      }
    });
  };

  selectRetainer = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.retainer = !item.retainer;
        });
      }
    });
  };
  selectMiniImplantsForAnchorage = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.miniImplantsForAnchorage = !item.miniImplantsForAnchorage;
        });
      }
    });
  };
  selectTractionOfRetainedTeeth = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.tractionOfRetainedTeeth = !item.tractionOfRetainedTeeth;
        });
      }
    });
  };
  selectArticulatedStudyModels2 = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.articulatedStudyModels2 = !item.articulatedStudyModels2;
        });
      }
    });
  };
  selectBehaviouralAdviceAndStressControl = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.behaviouralAdviceAndStressControl =
            !item.behaviouralAdviceAndStressControl;
        });
      }
    });
  };
  selectOcclusalSplint = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.occlusalSplint = !item.occlusalSplint;
        });
      }
    });
  };

  selectOcclusalEquilibration = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.occlusalEquilibration = !item.occlusalEquilibration;
        });
      }
    });
  };
  selectArticulatedStudyModels3 = (value) => {
    this.selectTreatment(value);
    this.odontogram2.forEach((item) => {
      if (item.selected === "selected") {
        runInAction(() => {
          item.articulatedStudyModels3 = !item.articulatedStudyModels3;
        });
      }
    });
  };

  cloneOdontogram(item) {
    var clone = {};
    for (var key in item) {
      if (item.hasOwnProperty(key))
        //ensure not adding inherited props
        clone[key] = item[key];
    }
    return clone;
  }
  loadTreatmentTypes = async (page, size) => {
    try {
      const treatmentTypes = await agent.Prices.listPages(page, size);
      if (treatmentTypes.isSuccess === true) {
        runInAction(() => {
          this.treatmentTypes = treatmentTypes.data
        });
      }
    } catch (error) {
      throw error;
    }
  };
  createTreatmentDescription = async (description) => {
    try {
      let result = await agent.Treatment.createDescription(description);

      console.log(JSON.stringify(this.selectedTreatment.descriptions))
      runInAction(() => {
        this.selectedPlan.treatmentStages[0].treatments.find((x) => x.id ===description.treatmentId).descriptions.push(result.data);
        sessionStorage.setItem("planID", JSON.stringify(this.selectedPlan));
      });
      console.log(JSON.stringify(this.selectedTreatment.descriptions))

      console.log(JSON.stringify(result.data))
    } catch (error) {
      console.log(error);
    }
  };

  selectTreatment = async (id) => {
    try {
      runInAction(() => {
       this.selectedTreatment = this.selectedPlan.treatmentStages[0].treatments.find(x=> x.id === id);
      });
    } catch (error) {
      throw error;
    }
  };
}
