import { runInAction, makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { toast } from "react-toastify";

export default class PlanStore {
  plans = undefined;
  selectedPlan = JSON.parse(sessionStorage.getItem("planID"));
  loading = false;
  planSelected = false;
  resetForm = false;
  resetForm2 = false;
  totalDebt = sessionStorage.getItem("totalDebt");
  deleted = false;
  dateFilter = false;
  plansByDoctor = undefined;
  debtCalculated = false;

  constructor() {
    makeAutoObservable(this);
  }
  getPlans = async () => {
    runInAction(() => {
      this.loading = true;
    });

    try {
      const plans = await agent.Plans.list();

      runInAction(() => {
        this.plans = plans.data;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  loadPlans = async (id) => {
    runInAction(() => {
      this.loading = true;
    });
    if (Object.keys(id).length === 0) {
      try {
        const plans = await agent.Plans.list();
        runInAction(() => {
          this.plans = plans.data;
          this.loading = false;
        });
      } catch (error) {
        console.log(error);
        runInAction(() => {
          this.loading = false;
        });
      }
    } else {
      try {
        const plans = await agent.Plans.detailsByPatient(id.patientId);
        
        runInAction(() => {
          this.plans = plans.data;
          this.loading = false;
        });
      } catch (error) {
        console.log(error);
        runInAction(() => {
          this.loading = false;
        });
      }
    }
  };

  selectPlan = async (id) => {
    try {
      let plan = undefined;
      if (this.plans !== undefined) {
        plan = this.plans.find((x) => x.id === id);
      } else {
        plan = this.plansByDoctor.find((x) => x.id === id);
      }
      console.log(JSON.stringify(plan));
      sessionStorage.setItem("planID", JSON.stringify(plan));
      runInAction(() => {
        this.selectedPlan = plan;
        sessionStorage.setItem("totalDebt", this.totalDebt);
        this.resetForm = !this.resetForm;
        this.resetForm2 = !this.resetForm2;
        this.planSelected = true;
      });
    } catch (error) {
      console.log(error);
    }
  };

  createPlan = async (patientid, doctorid, planName) => {
    let plan = {
      patientId: patientid,
      doctorId: doctorid,
      name: planName,
    };
    try {
      runInAction(() => {
        this.loading = true;
      });

      const addedPlan = await agent.Plans.create(plan);
      runInAction(() => {
        this.selectedPlan = addedPlan.data;
        sessionStorage.setItem("planID", JSON.stringify(addedPlan));
        this.loading = false;
        console.log(this.selectedPlan);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  calculateDebt = () => {
    try {
      runInAction(() => {
        this.debtCalculated = false;
        this.totalDebt = 0;
        this.selectedPlan.treatmentStages[0].treatments.forEach((element) => {
          if (element.isApproved) this.totalDebt += element.price;
        });
        sessionStorage.setItem("totalDebt", this.totalDebt);
        this.debtCalculated = true;
      });
    } catch (error) {
      console.log(error);
    }
  };

  updatePlan = async (plan) => {
    console.log(plan);
    try {
      await agent.Plans.update(plan);
      console.log(plan);
      runInAction(() => {
        this.selectedPlan.isApproved = plan.isApproved;
      });
    } catch (error) {
      console.log(error);
    }
  };

  dltPlan = async (id) => {
    try {
      runInAction(() => {
        this.deleted = true;
      });
      const deletePlan = await agent.Plans.delete(id);
      if (deletePlan.isSuccess === true) {
        toast.success("Plan silme işlemi başarılı.");
        runInAction(() => (this.deletePlan = deletePlan.data));
      }
      runInAction(() => {
        this.deleted = false;
      });
    } catch (error) {
      throw error;
    }
  };

  filterPlans = async (filter) => {
    filter.endDate = new Date(filter.endDate);
    filter.endDate.setDate(filter.endDate.getDate() + 1);
    filter.startDate = new Date(filter.startDate).toISOString();
    filter.endDate = new Date(filter.endDate).toISOString();
    runInAction(() => {
      this.loading = true;
    });
    try {
      const plans = await agent.Plans.filter(filter);

      runInAction(() => {
        this.plansByDoctor = plans.data;
        this.loading = false;
        this.dateFilter = true;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.dateFilter = false;
        this.loading = false;
      });
    }
  };
}
