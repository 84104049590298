import "./wizardmenu.css";
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import DoctorAdd from "../doctors/DoctorAdd";
import AddPatient from "../addPatient/AddPatient";
import { Button, Modal } from "react-bootstrap";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default observer(function WizardMenu() {
  // Doktor Ekle Modal
  const [showPatient, setShowPatient] = useState(false);

  const handleClosePatient = () => setShowPatient(false);
  const handleShowPatient = () => {
    setShowPatient(true);
    patientStore.adding = true;
  };
  // Doktor Ekle Modal
  // Hasta Ekle Modal
  const [showDoctor, setShowDoctor] = useState(false);

  const handleCloseDoctor = () => {
    setShowDoctor(false);
  };
  const handleShowDoctor = () => {
    setShowDoctor(true);
    drStore.editing2 = true;
  };
  // Hasta Ekle Modal

  const { planStore, patientStore, drStore,userStore } = useStore();
  const { selectedPlan, createPlan } = planStore;
  const { loadPatientWizard, selectPatient } = patientStore;
  const { loadDoctorWizard, selectDoctor } = drStore;

  const [activeStep, setActiveStep] = useState(0);

  const initialState = selectedPlan ?? {
    patientId: "",
    doctorId: "",
    name: "",
  };
  const [plan, setPlan] = useState(initialState);

  useEffect(() => {
    setPlan(initialState);
  }, [selectedPlan]);

  useEffect(() => {
    loadPatientWizard();
  }, [patientStore.patients]);
  useEffect(() => {
    loadDoctorWizard();
  }, [drStore.dr]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNext2 = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    handleCreatePlan();
  };

  const handleCreatePlan = () => {
    plan.name = name;
    plan.patientID = plan.patientID;
    plan.doctorID = plan.doctorID;
    createPlan(plan.patientID, plan.doctorID, plan.name);
  };

  const [name, setName] = useState("");
  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleClose = ()=> {
    console.log(userStore.closeWizard);
    userStore.closeWizard = false;
    console.log(userStore.closeWizard);

  }
  return (
    <div className="wizardMenu">
      <div className="wizardMenuHeader">
        <span className="wizardMenuHeaderTitle">Plan Oluşturma Sihirbazı</span>
      </div>
      <div className="wizardMenuWrapper">
        <div className="wizardMenuBody">
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step>
                <StepLabel>Bu plana bağlı hasta</StepLabel>
                <StepContent>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Autocomplete
                        disablePortal
                        onChange={(event, newValue) => {
                          plan.patientID = newValue.id;
                          selectPatient(newValue.id);
                        }}
                        id="combo-box-demo"
                        options={patientStore.patientsWizard}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Hasta Ara" />
                        )}
                      />

                      <Button variant="outline-dark" style={{margin:10}} onClick={handleShowPatient}>
                        Yeni Hasta Oluştur
                      </Button>
                      {patientStore.adding && (
                        <Modal show={showPatient} onHide={handleClosePatient}>
                          <Modal.Header closeButton>
                            <Modal.Title>Yeni Hasta Oluştur</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <AddPatient />
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleClosePatient}
                            >
                              Kapat
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}

                      <Button
                        variant="danger"
                        onClick={() => handleNext()}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        İleri
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Bu plana bağlı doktor</StepLabel>
                <StepContent>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Autocomplete
                        disablePortal
                        onChange={(event, newValue) => {
                          plan.doctorID = newValue.id;
                          selectDoctor(newValue);
                        }}
                        id="combo-box-demo"
                        options={drStore.doctorWizard}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Doktor Ara" />
                        )}
                      />

                      <Button variant="outline-dark" style={{margin:10}}  onClick={handleShowDoctor}>
                        Yeni Doktor Oluştur
                      </Button>
                      {drStore.editing2 && (
                        <Modal size="lg" show={showDoctor} onHide={handleCloseDoctor}>
                          <Modal.Header closeButton>
                            <Modal.Title>Yeni Doktor Oluştur</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <DoctorAdd />
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleCloseDoctor}
                            >
                              Kapat
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}

                      <Button
                        variant="danger"
                        
                        onClick={() => handleNext()}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        İleri
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Plan Adı</StepLabel>
                <StepContent>
                  <Typography>Planınızda görünecek ad</Typography>
                  <br />
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <TextField
                        label="Plan ismi giriniz."
                        variant="standard"
                        value={name}
                        onChange={handleChange}
                        color="warning"
                        focused
                      />
                      <br />

                      <Button
                      style={{margin:10}} 
                        variant="danger"
                        onClick={() => handleNext2()}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        İleri
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Planı Oluştur</StepLabel>
                <StepContent>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Link to={"/diagnose"}>
                        <Button onClick={handleClose} variant="danger" sx={{ mt: 1, mr: 1 }}>
                          Oluştur
                        </Button>
                      </Link>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            </Stepper>
          </Box>
        </div>
      </div>
    </div>
  );
});
