import "./steps.css";

export default function Steps() {
  return (
    <div className="steps">
      <div className="stepsWrapper">
        <a className="padrig" style={{ color: "black", textDecoration: "none" }} href="/diagnose">
          <div className="stepsNumberOne">
            <div className="stepsNumberIcon">1</div>
            <div className="spanNumberOneTitle">Diagnoz</div>
          </div>
        </a>

        <a className="padrig" style={{ color: "black", textDecoration: "none" }} href="/plan">
          <div className="stepsNumberTwo">
            <div className="stepsNumberIcon">2</div>
            <div className="stepsNumberTwoTitle">Plan</div>
          </div>
        </a>

        <a className="padrig" style={{ color: "black", textDecoration: "none" }} href="/result">
          <div className="stepsNumberThree">
            <div className="stepsNumberIcon">3</div>
            <div className="stepsNumberThree">Teklif</div>
          </div>
        </a>
        <a className="padrig" style={{ color: "black", textDecoration: "none" }} href="/treatments">
          <div className="stepsNumberThree">
            <div className="stepsNumberIcon">4</div>
            <div className="stepsNumberThree">Tedavi</div>
          </div>
        </a>
      </div>
    </div>
  );
}
