import "./home.css";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import { useStore } from "../../stores/store";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
export default function Home() {
  return (
    <div className="home">
      <FeaturedInfo />
    </div>
  );
}
