import './diagnosemenu.css'
import {useStore} from '../../stores/store';
import {observer} from 'mobx-react-lite';
// import { Button, ButtonGroup } from 'react-bootstrap';
import 'bootstrap';

function DiagnoseMenu() {
  const {menuStore} = useStore();
  return (
      <div className='diagnoseMenu'>
        {/* <ButtonGroup aria-label="Basic example">
  <Button variant="secondary">360</Button>
  <Button variant="secondary" onClick={()=>(menuStore.selectMenu('carriesMenu'))}>Caries Fractures Wear</Button>
  <Button variant="secondary" onClick={()=>(menuStore.selectMenu('perioBoneMenu'))}>Perio Bone</Button>
  <Button variant="secondary" onClick={()=>(menuStore.selectMenu('endodonticsMenu'))}>Endodontics</Button>
  <Button variant="secondary" onClick={()=>(menuStore.selectMenu('missingTeethImplantMenu'))}>Missing teeth implants</Button>
  <Button variant="secondary" onClick={()=>(menuStore.selectMenu('restorativeColorMenu'))}>Restorative Color</Button>
  <Button variant="secondary" onClick={()=>(menuStore.selectMenu('prosthodonticMenu'))}>Prosthodontics</Button>
  <Button variant="secondary">Orto Facial TMJ</Button>
  <Button variant="secondary">Other</Button>
</ButtonGroup> */}
        <button className="DiagnoseMenuBtn btn fcs" onClick={()=>(menuStore.selectMenu('carriesMenu'))}>Çürük Kırık ve Aşınmalar</button>
        <button className="DiagnoseMenuBtn btn fcs" onClick={()=>(menuStore.selectMenu('perioBoneMenu'))}>Perio Kemik</button>
        <button className="DiagnoseMenuBtn btn fcs" onClick={()=>(menuStore.selectMenu('endodonticsMenu'))}>Endodonti</button>
        <button className="DiagnoseMenuBtn btn fcs" onClick={()=>(menuStore.selectMenu('missingTeethImplantMenu'))}>Eksik Diş ve İmplantlar</button>
        <button className="DiagnoseMenuBtn btn fcs" onClick={()=>(menuStore.selectMenu('restorativeColorMenu'))}>Onarıcı Renk</button>
        <button className="DiagnoseMenuBtn btn fcs" onClick={()=>(menuStore.selectMenu('prosthodonticMenu'))}>Protez</button>
       
    </div>
  )
}
export default observer(DiagnoseMenu);
