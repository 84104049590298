import React from "react";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Button, Modal, Spinner, ButtonGroup } from "react-bootstrap";
import DoctorAdd from "./DoctorAdd";
import DoctorUpdate from "./DoctorUpdate";
import { useStore } from "../../stores/store";
import { useNavigate } from "react-router-dom";
import "./doctors.css";
import DoctorShowDays from "./DoctorShowDays";

export default observer(function DoctorList() {
  let navigate = useNavigate();
  const { drStore, userStore } = useStore();
  function newDoctor(id) {
    drStore.updtDr(id);
  }

  const [doctors, setdoctors] = useState(null);

  function newDoctor() {
    const model = doctors;
    drStore.newDr(model);
  }

  useEffect(() => {
    drStore.getDr();
  }, [drStore.dr]);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    drStore.editing2 = true;
  };

  const [showUpd, setShowUpd] = useState(false);

  const handleCloseUpd = () => {
    setShowUpd(false);
  };
  const handleShowUpd = (id) => {
    drStore.selectDoctor(id);
    setShowUpd(true);
    drStore.editing = true;
  };
  const handleShowDoctor = (id) => {
    drStore.selectDoctor(id);
    navigate("/doctorcard");
  };

  const [showDays, setShowDays] = useState(false);

  const handleCloseDays = () => setShowDays(false);
  const handleShowDays = (id) => {
    drStore.selectDoctor(id);
    setShowDays(true);
  };

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = (id) => {
    drStore.deleted = true;
    drStore.selectDoctor(id);
    setShowDelete(true);
  };

  function handleDelete(id) {
    drStore.dltDr(id);
  }

  if (drStore.doctors === undefined) {
    return (
      <Button variant="danger" className="loadingBtnStyle" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Doktor Listesi Yükleniyor...
      </Button>
    );
  }
  {
    return (
      <div className="doctorTableList">
        <div className="btnAddDoctor">
          {userStore.grandPermissions.doctorCreate && (
            <Button variant="outline-dark" onClick={handleShow}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-person-plus"
                viewBox="0 0 16 16"
                style={{
                  marginRight: "3px",
                  marginBottom: "3px",
                }}
              >
                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                <path
                  fillRule="evenodd"
                  d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                />
              </svg>
              Doktor Ekle
            </Button>
          )}
        </div>
        <table className="doctorListTable table table-hover table-lg table-responsive-md">
          <thead>
            <tr className="doctorTableThHead">
              <th className="doctorTableTh">İsim Soyisim</th>
              <th className="doctorTableTh">Mail</th>
              <th className="doctorTableTh">Telefon Numarası</th>
              <th className="doctorTableTh">Branş</th>
              <th className="doctorTableThViewDays">Gün/Saat</th>
              <th className="doctorTableThViewDays">Durumu</th>
              <th className="doctorTableEdit">Seçenekler</th>
            </tr>
          </thead>
          <tbody className="doctorTableTbody">
            {drStore.doctors.map((doctor) => (
              <tr key={doctor.id}>
                <td className="doctorTableTd">{doctor.fullName}</td>
                <td className="doctorTableTd">{doctor.email}</td>
                <td className="doctorTableTd">{doctor.phoneNumber}</td>
                <td className="doctorTableTd">{doctor.branch}</td>
                <td className="doctorTableTd">
                  <Button
                    variant="outline-dark"
                    onClick={() => handleShowDays(doctor.id)}
                  >
                    Çalışma Günlerini Görmek İçin Tıklayınız
                  </Button>
                </td>
                {doctor.isActive === 1 && (
                  <td className="doctorTableTd">
                    <div
                      style={{
                        display: "inline-block",
                        color: "green",
                        fontWeight: "bold",
                        width: "6rem",
                      }}
                    >
                      Aktif
                    </div>
                  </td>
                )}
                {doctor.isActive === 2 && (
                  <td
                    className="doctorTableTd"
                  >
                    <div
                      style={{
                        display: "inline-block",
                        color: "red",
                        fontWeight: "bold",
                        width: "6rem",
                      }}
                    >
                      Pasif
                    </div>
                  </td>
                )}
                <td className="doctorTableEdit doctorTableTd">
                  <ButtonGroup>
                    <Button
                      onClick={() => handleShowDoctor(doctor.id)}
                      variant="outline-dark"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                      </svg>
                    </Button>
                    {userStore.grandPermissions.doctorUpdate && (
                      <Button
                        onClick={() => handleShowUpd(doctor.id)}
                        variant="outline-dark"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fillRule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                          />
                        </svg>
                      </Button>
                    )}
                    {userStore.grandPermissions.doctorDelete && (
                      <Button
                        onClick={() => {
                          handleShowDelete(doctor.id);
                        }}
                        variant="outline-dark"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-archive"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                        </svg>
                      </Button>
                    )}
                  </ButtonGroup>

                  {/* Delete Modal */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {drStore.editing2 && (
          <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Doktor Ekle</Modal.Title>
            </Modal.Header>
            <DoctorAdd />
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {drStore.editing && (
          <Modal size="lg" show={showUpd} onHide={handleCloseUpd}>
            <Modal.Header closeButton>
              <Modal.Title>Doktor Bilgilerini Güncelle</Modal.Title>
            </Modal.Header>
            <DoctorUpdate />
            <Modal.Footer></Modal.Footer>
          </Modal>
        )}
        {/* Çalışma Günleri Modal */}
        <Modal show={showDays} size="sm" onHide={handleCloseDays}>
          <Modal.Header closeButton>
            <Modal.Title>Çalışma Günleri</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DoctorShowDays />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDays}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Çalışma Günleri Modal */}
        {/* Doktor Silme Modal */}
        {drStore.deleted && (
          <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
              <Modal.Title>Silmek istediğinize emin misiniz?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => handleDelete(drStore.selectedDoctor.id)}
              >
                Evet
              </Button>
              <Button variant="secondary" onClick={handleCloseDelete}>
                Hayır
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {/* Doktor Silme Modal */}
      </div>
    );
  }
});
