import { makeAutoObservable} from 'mobx'; 

export default class MenuStore{
    constructor() {
        makeAutoObservable(this)
    }
    ActiveMenu = {
        carriesMenu:true,
        perioBoneMenu:false,
        endodonticsMenu:false,
        missingTeethImplantMenu:false,
        prosthodonticMenu:false
    }

    ActiveTreatmentMenu = {
        preventionHygieneMenu: true,
        perioMenu: false,
        endodonticsMenu: false,
        surgeryImplantsMenu: false,
        restorativeBleachMenu: false,
        prosthodonticsMenu: false,
        orthoMenu: false,
        tmjOcclussionMusclesMenu: false
    }
    
    selectMenu(value) {
        if(value === "carriesMenu"){
            this.ActiveMenu.carriesMenu=true;
            this.ActiveMenu.perioBoneMenu=false;
            this.ActiveMenu.endodonticsMenu=false;
            this.ActiveMenu.missingTeethImplantMenu=false;
            this.ActiveMenu.restorativeColorMenu=false;
            this.ActiveMenu.prosthodonticMenu=false;
        }else if(value === "perioBoneMenu"){
            this.ActiveMenu.carriesMenu=false;
            this.ActiveMenu.perioBoneMenu=true;
            this.ActiveMenu.endodonticsMenu=false;
            this.ActiveMenu.missingTeethImplantMenu=false;
            this.ActiveMenu.restorativeColorMenu=false;
            this.ActiveMenu.prosthodonticMenu=false;
        }else if(value === "endodonticsMenu"){
            this.ActiveMenu.carriesMenu=false;
            this.ActiveMenu.perioBoneMenu=false;
            this.ActiveMenu.endodonticsMenu=true;
            this.ActiveMenu.missingTeethImplantMenu=false;
            this.ActiveMenu.restorativeColorMenu=false;
            this.ActiveMenu.prosthodonticMenu=false;
        }else if(value === "missingTeethImplantMenu"){
            this.ActiveMenu.carriesMenu=false;
            this.ActiveMenu.perioBoneMenu=false;
            this.ActiveMenu.endodonticsMenu=false;
            this.ActiveMenu.missingTeethImplantMenu=true;
            this.ActiveMenu.restorativeColorMenu=false;
            this.ActiveMenu.prosthodonticMenu=false;
        }else if(value === "restorativeColorMenu"){
            this.ActiveMenu.carriesMenu=false;
            this.ActiveMenu.perioBoneMenu=false;
            this.ActiveMenu.endodonticsMenu=false;
            this.ActiveMenu.missingTeethImplantMenu=false;
            this.ActiveMenu.restorativeColorMenu=true;
            this.ActiveMenu.prosthodonticMenu=false;
        }
        else if(value === "prosthodonticMenu"){
            this.ActiveMenu.carriesMenu=false;
            this.ActiveMenu.perioBoneMenu=false;
            this.ActiveMenu.endodonticsMenu=false;
            this.ActiveMenu.missingTeethImplantMenu=false;
            this.ActiveMenu.restorativeColorMenu=false;
            this.ActiveMenu.prosthodonticMenu=true;
        }
    }

    selectTreatmentMenu(value) {
        if(value === "preventionHygieneMenu"){
            this.ActiveTreatmentMenu.preventionHygieneMenu=true;
            this.ActiveTreatmentMenu.perioMenu=false;
            this.ActiveTreatmentMenu.endodonticsMenu=false;
            this.ActiveTreatmentMenu.surgeryImplantsMenu=false;
            this.ActiveTreatmentMenu.restorativeBleachMenu=false;
            this.ActiveTreatmentMenu.prosthodonticsMenu=false;
            this.ActiveTreatmentMenu.orthoMenu=false;
            this.ActiveTreatmentMenu.tmjOcclussionMusclesMenu=false;

        }else if(value === "perioMenu"){
            this.ActiveTreatmentMenu.preventionHygieneMenu=false;
            this.ActiveTreatmentMenu.perioMenu=true;
            this.ActiveTreatmentMenu.endodonticsMenu=false;
            this.ActiveTreatmentMenu.surgeryImplantsMenu=false;
            this.ActiveTreatmentMenu.restorativeBleachMenu=false;
            this.ActiveTreatmentMenu.prosthodonticsMenu=false;
            this.ActiveTreatmentMenu.orthoMenu=false;
            this.ActiveTreatmentMenu.tmjOcclussionMusclesMenu=false;
        }else if(value === "endodonticsMenu"){
            this.ActiveTreatmentMenu.preventionHygieneMenu=false;
            this.ActiveTreatmentMenu.perioMenu=false;
            this.ActiveTreatmentMenu.endodonticsMenu=true;
            this.ActiveTreatmentMenu.surgeryImplantsMenu=false;
            this.ActiveTreatmentMenu.restorativeBleachMenu=false;
            this.ActiveTreatmentMenu.prosthodonticsMenu=false;
            this.ActiveTreatmentMenu.orthoMenu=false;
            this.ActiveTreatmentMenu.tmjOcclussionMusclesMenu=false;
        }else if(value === "surgeryImplantsMenu"){
            this.ActiveTreatmentMenu.preventionHygieneMenu=false;
            this.ActiveTreatmentMenu.perioMenu=false;
            this.ActiveTreatmentMenu.endodonticsMenu=false;
            this.ActiveTreatmentMenu.surgeryImplantsMenu=true;
            this.ActiveTreatmentMenu.restorativeBleachMenu=false;
            this.ActiveTreatmentMenu.prosthodonticsMenu=false;
            this.ActiveTreatmentMenu.orthoMenu=false;
            this.ActiveTreatmentMenu.tmjOcclussionMusclesMenu=false;
        }else if(value === "restorativeBleachMenu"){
            this.ActiveTreatmentMenu.preventionHygieneMenu=false;
            this.ActiveTreatmentMenu.perioMenu=false;
            this.ActiveTreatmentMenu.endodonticsMenu=false;
            this.ActiveTreatmentMenu.surgeryImplantsMenu=false;
            this.ActiveTreatmentMenu.restorativeBleachMenu=true;
            this.ActiveTreatmentMenu.prosthodonticsMenu=false;
            this.ActiveTreatmentMenu.orthoMenu=false;
            this.ActiveTreatmentMenu.tmjOcclussionMusclesMenu=false;
        }
        else if(value === "prosthodonticsMenu"){
            this.ActiveTreatmentMenu.preventionHygieneMenu=false;
            this.ActiveTreatmentMenu.perioMenu=false;
            this.ActiveTreatmentMenu.endodonticsMenu=false;
            this.ActiveTreatmentMenu.surgeryImplantsMenu=false;
            this.ActiveTreatmentMenu.restorativeBleachMenu=false;
            this.ActiveTreatmentMenu.prosthodonticsMenu=true;
            this.ActiveTreatmentMenu.orthoMenu=false;
            this.ActiveTreatmentMenu.tmjOcclussionMusclesMenu=false;
        }
        else if(value === "orthoMenu"){
            this.ActiveTreatmentMenu.preventionHygieneMenu=false;
            this.ActiveTreatmentMenu.perioMenu=false;
            this.ActiveTreatmentMenu.endodonticsMenu=false;
            this.ActiveTreatmentMenu.surgeryImplantsMenu=false;
            this.ActiveTreatmentMenu.restorativeBleachMenu=false;
            this.ActiveTreatmentMenu.prosthodonticsMenu=false;
            this.ActiveTreatmentMenu.orthoMenu=true;
            this.ActiveTreatmentMenu.tmjOcclussionMusclesMenu=false;
        }
        else if(value === "tmjOcclussionMusclesMenu"){
            this.ActiveTreatmentMenu.preventionHygieneMenu=false;
            this.ActiveTreatmentMenu.perioMenu=false;
            this.ActiveTreatmentMenu.endodonticsMenu=false;
            this.ActiveTreatmentMenu.surgeryImplantsMenu=false;
            this.ActiveTreatmentMenu.restorativeBleachMenu=false;
            this.ActiveTreatmentMenu.prosthodonticsMenu=false;
            this.ActiveTreatmentMenu.orthoMenu=false;
            this.ActiveTreatmentMenu.tmjOcclussionMusclesMenu=true;
        }
    }
}
