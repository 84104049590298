import "./login.css";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useStore } from "../../stores/store";
import { useNavigate } from "react-router-dom";
import {Button} from "react-bootstrap"

export default observer(function Login() {
  let navigate = useNavigate();

  const initialState = {
    email: "",
    password: "",
  };

  const [login, setLogin] = useState(initialState);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setLogin({ ...login, [name]: value });
  }

  const { userStore, commonStore } = useStore();
  const { loading } = userStore;

  useEffect(() => {
    if (commonStore.token) {
      navigate("/home");
      window.location.reload(false);
    }
  }, [loading]);

  function signUp() {
    const model = login;
    userStore.signin(model);
  }

  return(
    <>
    {/* <!--Hey! This is the original version of Simple CSS Waves--> */}

<div class="header">

{/* <!--Content before waves--> */}
<div class="inner-header flex">
{/* <!--Just the logo.. Don't mind this--> */}
{/* <svg version="1.1" class="logo" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
 x="0px" y="0px" viewBox="0 0 500 500" xml:space="preserve">
<path fill="#FFFFFF" stroke="#000000" stroke-width="10" stroke-miterlimit="10" d="M57,283" />
<g><path fill="#fff"
d="M250.4,0.8C112.7,0.8,1,112.4,1,250.2c0,137.7,111.7,249.4,249.4,249.4c137.7,0,249.4-111.7,249.4-249.4
C499.8,112.4,388.1,0.8,250.4,0.8z M383.8,326.3c-62,0-101.4-14.1-117.6-46.3c-17.1-34.1-2.3-75.4,13.2-104.1
c-22.4,3-38.4,9.2-47.8,18.3c-11.2,10.9-13.6,26.7-16.3,45c-3.1,20.8-6.6,44.4-25.3,62.4c-19.8,19.1-51.6,26.9-100.2,24.6l1.8-39.7		c35.9,1.6,59.7-2.9,70.8-13.6c8.9-8.6,11.1-22.9,13.5-39.6c6.3-42,14.8-99.4,141.4-99.4h41L333,166c-12.6,16-45.4,68.2-31.2,96.2	c9.2,18.3,41.5,25.6,91.2,24.2l1.1,39.8C390.5,326.2,387.1,326.3,383.8,326.3z" />
</g>
</svg> */}
{/* <h1>Simple CSS Waves</h1> */}
<div className="row">
  <div className="col-12">
    <div className="row"></div>
    <div className="row"></div>
  </div>
</div>

   <div className="loginContainer">
       <div className="loginWrapper ">
         <main on className="form-signin">
           {/* <img
             className="mb-4"
             alt="logo"
             src="http://www.zrnteknoloji.com/wp-content/uploads/2022/01/zrn-logo-acik.png"
             width={200}
             height={30}
           /> */}
           <img
             
             alt="logo"
             src={require('../../assets/images/loginLogo.png')}
             width={200}
             height={180}
           />
           <h3 className=" mb-3 fw-normal broken-white-text">Lütfen Giriş Yapın</h3>
           <form className="loginFormStyle">
             <div className="col-auto">
               <label for="staticEmail2" >
                 Email
               </label>
               <input
                 name="email"
                 value={login.email}
                 onChange={handleInputChange}
                 type="email"
                 className="form-control loginPageEmail"
                 id="floatingInput"
                 placeholder="Email Adresi Giriniz"
               />
             </div>
             <div class="col-auto">
               <label for="inputPassword2" >
                 Password
               </label>
               <input
                 name="password"
                 value={login.password}
                 onChange={handleInputChange}
                 type="password"
                 className="form-control loginPagePassword"
                 id="floatingPassword"
                 placeholder="Şifre Giriniz"
               />
             </div>
             <div class="col-auto">
               <button
                 type="submit"
                 className="btn main-button-background main-button-text mb-3"
                 onClick={signUp}
                 disabled={userStore.loading}
               >
                 {userStore.loading
                   ? "Giriş yapılıyor."
                   : "Giriş yapmak için tıklayınız."}
               </button>
             </div>
           </form>
           <p className="mt-5 mb-3 broken-white-text">© 2022 KOLAYDENT</p>
         </main>
       </div>
     </div>

</div>

{/* <!--Waves Container--> */}
<div>
<svg class="waves" xmlns="http://www.w3.org/2000/svg"  xmlnsXlink="http://www.w3.org/1999/xlink"
viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
<defs>
<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
</defs>
<g class="parallax">
<use xlinkHref="#gentle-wave" xmlnsXlink="http://www.w3.org/1999/xlink" x="48" y="0" fill="rgba(255,255,255,0.7" />
<use xlinkHref="#gentle-wave" xmlnsXlink="http://www.w3.org/1999/xlink" x="48" y="3" fill="rgba(255,255,255,0.5)" />
<use xlinkHref="#gentle-wave" xmlnsXlink="http://www.w3.org/1999/xlink" x="48" y="5" fill="rgba(255,255,255,0.3)" />
<use xlinkHref="#gentle-wave" xmlnsXlink="http://www.w3.org/1999/xlink" x="48" y="7" fill="#fff" />
</g>
</svg>
</div>
{/* <!--Waves end--> */}

</div>

    </>
  )

  // return (
  //   <div className="loginContainer">
  //     <div className="loginWrapper ">
  //       <main on className="form-signin">
  //         <img
  //           className="mb-4"
  //           src="http://www.zrnteknoloji.com/wp-content/uploads/2022/01/zrn-logo-acik.png"
  //           width={200}
  //           height={30}
  //         />
  //         <h1 className="h3 mb-3 fw-normal">Lütfen Giriş Yapın</h1>

  //         <form className="loginFormStyle">
  //           <div className="col-auto">
  //             <label for="staticEmail2" className="visually-hidden">
  //               Email
  //             </label>
  //             <input
  //               name="email"
  //               value={login.email}
  //               onChange={handleInputChange}
  //               type="email"
  //               className="form-control loginPageEmail"
  //               id="floatingInput"
  //               placeholder="Email Adresi Giriniz"
  //             />
  //           </div>
  //           <div class="col-auto">
  //             <label for="inputPassword2" className="visually-hidden">
  //               Password
  //             </label>
  //             <input
  //               name="password"
  //               value={login.password}
  //               onChange={handleInputChange}
  //               type="password"
  //               className="form-control loginPagePassword"
  //               id="floatingPassword"
  //               placeholder="Şifre Giriniz"
  //             />
  //           </div>
  //           <div class="col-auto">
  //             <button
  //               type="submit"
  //               className="btn btn-outline-primary mb-3"
  //               onClick={signUp}
  //               disabled={userStore.loading}
  //             >
  //               {userStore.loading
  //                 ? "Giriş yapılıyor."
  //                 : "Giriş yapmak için tıklayınız."}
  //             </button>
  //           </div>
  //         </form>
  //         <p className="mt-5 mb-3 text-muted">© 2022 KOLAYDENT</p>
  //       </main>
  //     </div>
  //   </div>
  // );
});
