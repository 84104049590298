import "./planRightMenu.css";
import * as React from "react";
import { Menu } from "@material-ui/icons";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import PlanRightMenuModal from "../planRightMenuModal/PlanRightMenuModal";
import { Dropdown, Button, Modal } from "react-bootstrap";
import PatientTreatments from "../patientTreatments/PatientTreatments";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid rgb(251, 251, 255)",
  boxShadow: 24,
};

function PlanRightMenu() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { treatmentStore, planStore, userStore } = useStore();
  const { selectedPlan } = planStore;
  const { currentTreatment, createTreatment, loadedCount } = treatmentStore;

  const initialState = currentTreatment ?? {
    treatmentStageId: "",
    treatmentType: 0,
    teeths: [0],
    price: 0,
    currency: "",
    realizationDate: "",
    channel: 0,
    dentureType: 0,
    braceTypes: 0,
    toothSurfaces: [0],
    observation: "",
  };

  const [treatment, setTreatment] = useState(initialState);

  useEffect(() => {
    setTreatment(initialState);
  }, [currentTreatment]);

  const initialState2 = selectedPlan ?? {
    id: "",
    name: "",
    patientID: "",
    createdTime: "",
    isActive: 1,
    conditions: [],
    treatmentStages: {
      id: "",
      name: "",
      description: "",
      planId: "",
      createdTime: "",
      isActive: 1,
      treatments: {},
    },
  };
  const [plan, setPlan] = useState(initialState2);

  useEffect(() => {
    setPlan(initialState2);
  }, [planStore.plans]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setTreatment({ ...treatment, [name]: value });
  }
  function handleSubmit(value) {
    createTreatment(value);
  }

  function handleId(id) {
    console.log(id);
    treatment.treatmentStageId = id;
  }

  return (
    <div className="rightPlanMenuContainer">
      {/* <PlanRightMenuModal />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      ></Modal> */}
      {/* test */}
      <div className="currentPlanHeader">
        <span className="currentPlanHeaderTitle spanText">MEVCUT PLAN</span>
        <div className="right">
          {/* <Menu className="planMenuModalOpenBtn" onClick={handleOpen} /> */}
          {/* <Menu
            className="planMenuModalOpenBtn"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#diagnoseConditionsModal"
          /> */}
          <Button onClick={handleShow} variant="danger">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              className="bi bi-list"
              viewBox="0 0 16 16"
              style={{ marginRight: "0.1rem" }}
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
            <span className="loadedCountBox">{loadedCount}</span>
          </Button>
          <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Tedaviler</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PlanRightMenuModal />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <div className="currentPlanWrapper">
        <div className="currentPlanBody">
          {treatment.treatmentType !== 0 && (
            <label className="currentPlanBodyLabel">TEDAVİ</label>
          )}
          {treatment.treatmentType !== 0 && (
            <span className="currentPlanBodySpan">
              {treatment.treatmentType === 1
                ? "Önleme ve Hijyen"
                : "" || treatment.treatmentType === 2
                ? "Profesyonel Diş Temizleme"
                : "" || treatment.treatmentType === 3
                ? "Topikal Florür"
                : "" || treatment.treatmentType === 4
                ? "Profesyonel Diş Temizleme"
                : "" || treatment.treatmentType === 5
                ? "Ölçeklendirme/Kök Planlama"
                : "" || treatment.treatmentType === 6
                ? "Cep Azaltma"
                : "" || treatment.treatmentType === 7
                ? "Kemik Rejenerasyonu"
                : "" || treatment.treatmentType === 8
                ? "Dişeti Grefti"
                : "" || treatment.treatmentType === 9
                ? "Plastik Perio Cerrahisi"
                : "" || treatment.treatmentType === 10
                ? "Kron Uzatma"
                : "" || treatment.treatmentType === 11
                ? "Gingivektomi"
                : "" || treatment.treatmentType === 12
                ? "Lazer Gingivektomi"
                : "" || treatment.treatmentType === 13
                ? "Kök Kanal Tedavisi"
                : "" || treatment.treatmentType === 14
                ? "Kanal Tedavisi"
                : "" || treatment.treatmentType === 16
                ? "Apikektomi"
                : "" || treatment.treatmentType === 17
                ? "İmplant"
                : "" || treatment.treatmentType === 18
                ? "Diş Çekimi"
                : "" || treatment.treatmentType === 19
                ? "Yirmilik Diş Çekimi"
                : "" || treatment.treatmentType === 20
                ? "Sinüs Kaldırma"
                : "" || treatment.treatmentType === 21
                ? "Kemik Rejenerasyonu"
                : "" || treatment.treatmentType === 22
                ? "Tam Ark İmplantları/Protez"
                : "" || treatment.treatmentType === 23
                ? "İmplant Çıkarma"
                : "" || treatment.treatmentType === 24
                ? "Cerrahi Rehber"
                : "" || treatment.treatmentType === 25
                ? "Restorasyon"
                : "" || treatment.treatmentType === 26
                ? "Post"
                : "" || treatment.treatmentType === 27
                ? "Atel"
                : "" || treatment.treatmentType === 28
                ? "Dış Çamaşır Suyu"
                : "" || treatment.treatmentType === 29
                ? "İç Çamaşır Suyu"
                : "" || treatment.treatmentType === 30
                ? "Çekirdek Oluşturma"
                : "" || treatment.treatmentType === 31
                ? "Geçici Restorasyon"
                : "" || treatment.treatmentType === 32
                ? "Kaplama"
                : "" || treatment.treatmentType === 33
                ? "Onlay"
                : "" || treatment.treatmentType === 34
                ? "Taç"
                : "" || treatment.treatmentType === 35
                ? "Köprü"
                : "" || treatment.treatmentType === 36
                ? "Yapışkanlı Köprü"
                : "" || treatment.treatmentType === 37
                ? "Takma Diş"
                : "" || treatment.treatmentType === 38
                ? "Köprü/Protez Çıkarma"
                : "" || treatment.treatmentType === 39
                ? "Eklemli Çalışma Modelleri"
                : "" || treatment.treatmentType === 40
                ? "Geçici Köprü"
                : "" || treatment.treatmentType === 41
                ? "Geçici Taç"
                : "" || treatment.treatmentType === 42
                ? "Sabit Braketler"
                : "" || treatment.treatmentType === 43
                ? "Lingual Braketler"
                : "" || treatment.treatmentType === 44
                ? "Hizalayıcıları Temizle"
                : "" || treatment.treatmentType === 45
                ? "Çıkarılabilir Cihaz"
                : "" || treatment.treatmentType === 46
                ? "Fonksiyonel Cihaz"
                : "" || treatment.treatmentType === 47
                ? "Kozmetik Ortodonti"
                : "" || treatment.treatmentType === 48
                ? "Avans"
                : "" || treatment.treatmentType === 49
                ? "Ankraj için Mini İmplantlar"
                : "" || treatment.treatmentType === 50
                ? "Tutulan Dişlerin Traksiyonu"
                : "" || treatment.treatmentType === 51
                ? "Eklemli Çalışma Modelleri"
                : "" || treatment.treatmentType === 52
                ? "Davranışsal Tavsiye ve Stres Kontrolü"
                : "" || treatment.treatmentType === 53
                ? "Oklüzal Atel"
                : "" || treatment.treatmentType === 54
                ? "Oklüzal Denge"
                : "" || treatment.treatmentType === 55
                ? "Eklemli Çalışma Modelleri"
                : ""}
            </span>
          )}
          {(treatment.treatmentType === 25 ||
            treatment.treatmentType === 30 ||
            treatment.treatmentType === 31) && (
            <label className="currentDiagnosisBodyLabel">YÜZEY</label>
          )}
          {(treatment.treatmentType === 25 ||
            treatment.treatmentType === 30 ||
            treatment.treatmentType === 31) &&
            treatment.toothSurfaces !== 0 && (
              <span className="currentDiagnosisBodySpan">
                {treatment.toothSurfaces}
              </span>
            )}
          {(treatment.treatmentType === 13 ||
            treatment.treatmentType === 14 ||
            treatment.treatmentType === 15) && (
            <label className="currentDiagnosisBodyLabel">KANAL</label>
          )}
          {(treatment.treatmentType === 13 ||
            treatment.treatmentType === 14 ||
            treatment.treatmentType === 15) && (
            <span className="currentDiagnosisBodySpan">3</span>
          )}
          {(treatment.treatmentType === 37 ||
            treatment.treatmentType === 42) && (
            <label className="currentDiagnosisBodyLabel">TİP</label>
          )}
          {(treatment.treatmentType === 37 ||
            treatment.treatmentType === 42) && (
            <span className="currentDiagnosisBodySpan">3</span>
          )}

          {treatment.treatmentType !== 22 && (
            <label className="currentPlanBodyLabel">DİŞ</label>
          )}

          {treatment.treatmentType !== 22 && (
            <span className="currentPlanBodySpan">
              {treatment.teeths + ","}
            </span>
          )}

          <label className="currentPlanBodyLabel">Fiyat</label>
          <label className="planMenuPrice">{treatment.price}</label>
          {treatment.currency !== 0 && (
            <span className="currentPlanBodySpan">
              {treatment.currency === 1
                ? "₺"
                : "" || treatment.currency === 2
                ? "$"
                : "" || treatment.currency === 3
                ? "€"
                : ""}
            </span>
          )}
          <label className="currentPlanBodyLabel">Oluşturma Tarihi</label>
          <input
            className="planRealizationDate"
            type="datetime-local"
            name="realizationDate"
            value={treatment.realizationDate}
            onChange={handleInputChange}
          />
          <label className="currentPlanBodyLabel">Açıklama</label>
          <textarea
            className="currentPlanBodyTextArea"
            rows="5"
            name="observation"
            value={treatment.observation}
            onChange={handleInputChange}
          ></textarea>
          {/* <textarea className="currentPlanBodyTextArea" rows="5"></textarea> */}

          <select className="collectionSelect">
            {plan.treatmentStages?.map((stage) => (
              <option selected onSelect={handleId(stage.id)}>
                {stage.name}
              </option>
            ))}
          </select>
        </div>
        <div className="currentPlanBtn">
          {userStore.grandPermissions.planUpdate && (
            <Button
              className="planCollectionBtn"
              variant="outline-dark"
              disabled={treatmentStore.loading}
              onClick={() => handleSubmit(treatment)}
            >
              {treatmentStore.loading
                ? "Ekleniyor..."
                : "Eklemek İçin Tıklayınız"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(PlanRightMenu);
