import "./documents.css";
import { useStore } from "../../stores/store";
import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Spinner, ButtonGroup, Button, Modal } from "react-bootstrap";
import NewDocument from "./NewDocument";

export default observer(function Documents() {
  const { documentStore } = useStore();
  useEffect(() => {
    documentStore.getDocuments();
  }, []);

  //?Doküman Ekleme Modal State'i
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (id) => {
    documentStore.selectDocument(id);
    setShow(true);
    documentStore.adding = true;
  };

  //?Doküman Güncelleme State'i
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => {
    setShowUpdate(false);
  };
  const handleShowUpdate = (id) => {
    documentStore.selectDocument(id);
    setShowUpdate(true);
    documentStore.updating = true;
  };

  //?Doküman Silme State'i
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = (id) => {
    documentStore.deleted = true;
    documentStore.selectDocument(id);
    setShowDelete(true);
  };
  function handleDelete(id) {
    documentStore.deleteDocument(id);
  }

  if (documentStore.documents === undefined) {
    return (
      <Button className="loadingBtnStyle" variant="danger" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Doküman Listesi Yükleniyor...
      </Button>
    );
  }

  return (
    <div className="documentsTableList">
      <div className="btnAddDocument">
      <Button
        style={{ marginBottom: "1rem" }}
        variant="outline-dark"
        onClick={() => handleShow(undefined)}
      >
        Döküman Ekle
      </Button>
      </div>
      <table className="documentsListTable table table-hover table-lg table-responsive-md">
        <thead style={{ textAlign: "center", fontSize: 20 }}>
          <tr className="documentsTableThHead">
            <th className="documentsTableTh">Doküman İsmi</th>
            <th className="documentsTableTh documentsTableEdit">Seçenekler</th>
          </tr>
        </thead>
        <tbody className="documentsTableTbody">
          {documentStore.documents.map((document) => (
            <tr>
              <td className="documentsTableTd">{document.title}</td>
              <td className="documentsTableTd documentsTableEdit">
                <ButtonGroup>
                  <Button
                    variant="outline-dark"
                    onClick={() => handleShowUpdate(document.id)}
                  >
                    Düzenle
                  </Button>
                  <Button
                    variant="outline-dark"
                    onClick={()=>handleShowDelete(document.id)}
                  >
                    Sil
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Doküman Ekleme Modalı */}
      {documentStore.adding && (
        <Modal size="xl" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Doküman Ekle</Modal.Title>
          </Modal.Header>
          <NewDocument />
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Doküman Güncelleme Modalı */}
      {documentStore.updating && (
        <Modal size="xl" show={showUpdate} onHide={handleCloseUpdate}>
          <Modal.Header closeButton>
            <Modal.Title>Doküman Güncelle</Modal.Title>
          </Modal.Header>
          <NewDocument />
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseUpdate}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      )}
             {/* Doküman Silme Modal */}
             {documentStore.deleted && (
        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Silmek istediğinize emin misiniz?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => handleDelete(documentStore.selectedDocument.id)}
            >
              Evet
            </Button>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Hayır
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
});
