import "../addPatient/addPatient.css";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";

function UpdatePatient() {
  const { patientStore } = useStore();
  const { selectedPatient, createPatient, updatePatient } = patientStore;

  const initialState = selectedPatient ?? {
    id: "",
    identityNumber: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    gender: true,
    note: "",
  };
  const [patient, setPatient] = useState(initialState);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setPatient(initialState);
  }, [selectedPatient]);

  function handleSubmit() {
    console.log(patient.id);
    if (
      patient.email !== "" &&
      patient.email.includes("@") &&
      patient.identityNumber !== "" &&
      patient.fullName !== "" &&
      patient.phoneNumber !== ""
    ){
      patient.id ? updatePatient(patient) : createPatient(patient);
    }
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    setPatient({ ...patient, [name]: value });
  }

  return (
    <div className="cardComponent">
      <Container className="containerStyle">
        <Form className="my-4">
          <Row>
            <Col>
              <TextField
                name="fullName"
                className="my-1"
                value={patient.fullName}
                onChange={handleInputChange}
                label="İsim Soyisim"
              />
            </Col>
            <Col>
              <TextField
                name="identityNumber"
                className="my-1"
                label="TC Kimlik No"
                value={patient.identityNumber}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                name="email"
                className="my-1"
                value={patient.email}
                onChange={handleInputChange}
                label="Email"
              />
            </Col>
            <Col>
              <TextField
                name="phoneNumber"
                className="my-1"
                label="Telefon Numarası"
                value={patient.phoneNumber}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          {/* <div className="">
            <input type="radio" className="" name="gender" />
            <span className="genderMale"> Male</span>
            <input type="radio" className="" name="gender" />
            <span className="genderFemale"> Female</span>
          </div> */}
          <TextField
            name="note"
            style={{ width: "80%" }}
            className="my-1"
            label="Açıklama"
            value={patient.note}
            onChange={handleInputChange}
          />
        </Form>
        <Button
          style={{ width: "80%", marginBottom: "1rem" }}
          variant="outline-primary"
          onClick={() => {handleSubmit()
            if (patient.fullName === "")
              toast.error("Lütfen bir isim soyisim giriniz!");
            if (patient.email === "" || !patient.email.includes("@"))
              toast.error("Lütfen geçerli bir email giriniz!");
            if (patient.phoneNumber === "")
              toast.error("Lütfen bir telefon numarası giriniz!");
            if (patient.identityNumber === "")
              toast.error("Lütfen TC kimlik no giriniz!");
          }}
        >
          Değişiklikleri Kaydet
        </Button>
      </Container>
    </div>
  );
}

export default observer(UpdatePatient);
