import "./diagnoseRightMenu.css";
import * as React from "react";
import { Menu } from "@material-ui/icons";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import DiagnoseRightMenuModal from "../diagnoseRightMenuModal/DiagnoseRightMenuModal";
import { Button, Modal } from "react-bootstrap";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid rgb(251, 251, 255)",
  boxShadow: 24,
};

function DiagnoseRightMenu() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { conditionStore, planStore,userStore } = useStore();
  const { selectedPlan, loading } = planStore;
  const {
    currentCondition,
    createCondition,
    deleteCondition,
    resetForm2,
    loadedCount,
  } = conditionStore;

  const initialState = currentCondition ?? {
    planId: "",
    conditionType: 0,
    teeths: [0],
    toothSurfaces: [0],
    locations: 0,
    degree: 0,
    status: 0,
    observations: "",
  };

  const [condition, setCondition] = useState(initialState);

  useEffect(() => {
    setCondition(initialState);
  }, [currentCondition]);

  const initialState2 = selectedPlan ?? {
    id: "",
    name: "",
    patientID: "",
    createdTime: "",
    isActive: 1,
    conditions: [],
    treatmentStages: {
      id: "",
      name: "",
      description: "",
      planId: "",
      createdTime: "",
      isActive: 1,
      treatments: {},
    },
  };
  const [plan, setPlan] = useState(initialState2);

  useEffect(() => {
    setPlan(initialState2);
  }, [resetForm2]);


  function handleInputChange(event) {
    const { name, value } = event.target;
    setCondition({ ...condition, [name]: value });
  }
  function handleSubmit(value) {
    createCondition(value);
  }

  return (
    <div className="rightMenu" key={resetForm2}>
      {/* test */}
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Teşhisler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DiagnoseRightMenuModal />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
      {/* test */}
      <div className="currentDiagnosisHeader">
        <span className="currentDiagnosisHeaderTitle spanText">GÜNCEL TEŞHİS</span>
        <Button onClick={handleShow} variant="danger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
            style={{ marginRight: "0.1rem" }}
          >
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          <span className="loadedCountBox">{loadedCount}</span>
        </Button>
      </div>
      <div className="currentDiagnosisWrapper">
        <div className="currentDiagnosisBody">
          {condition.conditionType !== 0 && (
            <div className="currentDiagnosisBodyLabel">ŞART</div>
          )}
          {(condition.conditionType === 0 && (
            <span className="currentDiagnosisBodySpan"></span>
          )) ||
            (condition.conditionType === 1 && (
              <span className="currentDiagnosisBodySpan">Çürükler</span>
            )) ||
            (condition.conditionType === 2 && (
              <span className="currentDiagnosisBodySpan">Kırık</span>
            )) ||
            (condition.conditionType === 3 && (
              <span className="currentDiagnosisBodySpan">Ağır Hasarlı</span>
            )) ||
            (condition.conditionType === 4 && (
              <span className="currentDiagnosisBodySpan">Aşınmalar</span>
            )) ||
            (condition.conditionType === 5 && (
              <span className="currentDiagnosisBodySpan">Plak & Hijyen</span>
            )) ||
            (condition.conditionType === 6 && (
              <span className="currentDiagnosisBodySpan">Dişeti İltihabı</span>
            )) ||
            (condition.conditionType === 7 && (
              <span className="currentDiagnosisBodySpan">Dişeti Çekilmesi</span>
            )) ||
            (condition.conditionType === 8 && (
              <span className="currentDiagnosisBodySpan">Periodontitis</span>
            )) ||
            (condition.conditionType === 9 && (
              <span className="currentDiagnosisBodySpan">Hareketlilik</span>
            )) ||
            (condition.conditionType === 10 && (
              <span className="currentDiagnosisBodySpan">
                Sakızlı Gülümseme
              </span>
            )) ||
            (condition.conditionType === 11 && (
              <span className="currentDiagnosisBodySpan">Dişeti Büyümesi</span>
            )) ||
            (condition.conditionType === 12 && (
              <span className="currentDiagnosisBodySpan">
                Büyük Maksiller Sinüs
              </span>
            )) ||
            (condition.conditionType === 13 && (
              <span className="currentDiagnosisBodySpan">Nekroz</span>
            )) ||
            (condition.conditionType === 14 && (
              <span className="currentDiagnosisBodySpan">
                Kök Kanal Tedavisi
              </span>
            )) ||
            (condition.conditionType === 15 && (
              <span className="currentDiagnosisBodySpan">Apikal Lezyon</span>
            )) ||
            (condition.conditionType === 16 && (
              <span className="currentDiagnosisBodySpan">
                Kanaldaki Kırık Enstrüman
              </span>
            )) ||
            (condition.conditionType === 17 && (
              <span className="currentDiagnosisBodySpan">Kök Rezorpsiyonu</span>
            )) ||
            (condition.conditionType === 18 && (
              <span className="currentDiagnosisBodySpan">Eksik Diş</span>
            )) ||
            (condition.conditionType === 19 && (
              <span className="currentDiagnosisBodySpan">İmplant</span>
            )) ||
            (condition.conditionType === 20 && (
              <span className="currentDiagnosisBodySpan">Gömülü Diş</span>
            )) ||
            (condition.conditionType === 21 && (
              <span className="currentDiagnosisBodySpan">
                Gömülü ve Enfekte Diş
              </span>
            )) ||
            (condition.conditionType === 22 && (
              <span className="currentDiagnosisBodySpan">
                Büyük Maksiller Sinüs
              </span>
            )) ||
            (condition.conditionType === 23 && (
              <span className="currentDiagnosisBodySpan">Restorasyon</span>
            )) ||
            (condition.conditionType === 24 && (
              <span className="currentDiagnosisBodySpan">Post</span>
            )) ||
            (condition.conditionType === 25 && (
              <span className="currentDiagnosisBodySpan">Atel</span>
            )) ||
            (condition.conditionType === 26 && (
              <span className="currentDiagnosisBodySpan">Renksiz Diş</span>
            )) ||
            (condition.conditionType === 27 && (
              <span className="currentDiagnosisBodySpan">Kaplama</span>
            )) ||
            (condition.conditionType === 28 && (
              <span className="currentDiagnosisBodySpan">Taç</span>
            )) ||
            (condition.conditionType === 29 && (
              <span className="currentDiagnosisBodySpan">Onlay</span>
            )) ||
            (condition.conditionType === 30 && (
              <span className="currentDiagnosisBodySpan">Köprü</span>
            )) ||
            (condition.conditionType === 31 && (
              <span className="currentDiagnosisBodySpan">Takma Diş</span>
            ))}
          {(condition.conditionType === 1 ||
            condition.conditionType === 23) && (
            <label className="currentDiagnosisBodyLabel">YÜZEY</label>
          )}
          {(condition.conditionType === 1 || condition.conditionType === 23) &&
            condition.toothSurfaces !== 0 && (
              <span className="currentDiagnosisBodySpan">
                {condition.toothSurfaces}
              </span>
            )}
          {(condition.conditionType === 2 ||
            condition.conditionType === 17) && (
            <label className="currentDiagnosisBodyLabel">LOKASYON</label>
          )}
          {(condition.conditionType === 2 || condition.conditionType === 17) &&
            condition.locations !== 0 && (
              <span className="currentDiagnosisBodySpan">
                {condition.locations}
              </span>
            )}
          {(condition.conditionType === 14 ||
            condition.conditionType === 19 ||
            condition.conditionType === 23 ||
            condition.conditionType === 24 ||
            condition.conditionType === 25 ||
            condition.conditionType === 27 ||
            condition.conditionType === 28 ||
            condition.conditionType === 29 ||
            condition.conditionType === 30 ||
            condition.conditionType === 31) && (
            <label className="currentDiagnosisBodyLabel">DURUM</label>
          )}
          {(condition.conditionType === 14 ||
            condition.conditionType === 19 ||
            condition.conditionType === 23 ||
            condition.conditionType === 24 ||
            condition.conditionType === 25 ||
            condition.conditionType === 27 ||
            condition.conditionType === 28 ||
            condition.conditionType === 29 ||
            condition.conditionType === 30 ||
            condition.conditionType === 31) &&
            condition.status !== 0 && (
              <span className="currentDiagnosisBodySpan">
                {condition.status}
              </span>
            )}
          {(condition.conditionType === 4 ||
            condition.conditionType === 5 ||
            condition.conditionType === 6 ||
            condition.conditionType === 7 ||
            condition.conditionType === 8 ||
            condition.conditionType === 9 ||
            condition.conditionType === 15) && (
            <label className="currentDiagnosisBodyLabel">DERECE</label>
          )}
          {(condition.conditionType === 4 ||
            condition.conditionType === 5 ||
            condition.conditionType === 6 ||
            condition.conditionType === 7 ||
            condition.conditionType === 8 ||
            condition.conditionType === 9 ||
            condition.conditionType === 15) &&
            condition.degree !== 0 && (
              <span className="currentDiagnosisBodySpan">
                {condition.degree}
              </span>
            )}
          <div className="currentDiagnosisBodyLabel">DİŞLER</div>
          <span className="currentDiagnosisBodySpan">
            {condition.teeths + ","}{" "}
          </span>
          <label className="currentDiagnosisBodyLabel">GÖZLEMLER</label>
          <textarea
            className="currentDiagnosisBodyTextArea"
            rows="5"
            name="observations"
            value={condition.observations}
            onChange={handleInputChange}
          ></textarea>
        </div>
        {userStore.grandPermissions.planUpdate &&
        <Button
          className="planCollectionBtn"
          variant="outline-dark"
          disabled={conditionStore.loading}
          onClick={() => handleSubmit(condition)}
        >
          {conditionStore.loading ? "Ekleniyor..." : "Eklemek İçin Tıklayınız"}
        </Button>}
      </div>
    </div>
  );
}
export default observer(DiagnoseRightMenu);
