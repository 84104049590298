import { runInAction, makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { store } from "./store";
import { toast } from "react-toastify";

export default class DrStore {
  doctors = undefined;
  selectedDoctor = undefined
  dr = null;
  loggedIn = undefined;
  loading = false;
  editing = false;
  resetSelect = false;
  editing2 = false;
  deleted = false;
  doctorWizard = [];
  paymentCalculated = false;
  earning = {
    totalPlanNumbers: 0,
    totalTreatmentNumbers: 0,
    totalEarning: 0,
    earningLeft:0
  };

  constructor() {
    makeAutoObservable(this);
  }

  get isNewDr() {
    return this.dr.isSuccess;
  }

  newDr = async (creds) => {
    try {
      runInAction(() => {
        this.loading = true;
        this.editing2 = true;
      });

      const dr = await agent.Doctors.create(creds);
      if (dr.isSuccess === true) {
        toast.success("Doktor ekleme işlemi başarılı.");
        runInAction(() => {
          this.doctors.push(dr.data);
          this.loggedIn = true;
        });
      }
      runInAction(() => {
        this.loading = false;
        this.editing2 = false;
      });
    } catch (error) {
      throw error;
    }
  };
  updtDr = async (creds) => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const dr = await agent.Doctors.update(creds);
      if (dr.isSuccess === true) {
        toast.success("Doktor güncelleme işlemi başarılı.");
        runInAction(() => {
          this.selectedDoctor = creds;
          this.resetSelect = !this.resetSelect;
          this.loggedIn = true;
        });
        console.log(dr.data);
      }
      runInAction(() => {
        this.loading = false;
        this.editing = false;
      });
    } catch (error) {
      throw error;
    }
  };
  getDr = async () => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const doctors = await agent.Doctors.list();
      if (doctors.isSuccess === true) {
        runInAction(() => (this.doctors = doctors.data));
      }
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      throw error;
    }
  };
  dltDr = async (id) => {
    try {
      runInAction(() => {
        this.loading = true;
        this.deleted = true;
      });

      const deleteDoctor = await agent.Doctors.delete(id);
      if (deleteDoctor.isSuccess === true) {
        toast.success("Doktor silme işlemi başarılı.");
        runInAction(() => {
          this.dr = deleteDoctor.data;
        });
      }
      runInAction(() => {
        this.loading = false;
        this.deleted = false;
      });
    } catch (error) {
      throw error;
    }
  };

  selectDoctor = (id) => {
    try {
      const doctor = this.doctors.find((x) => x.id === id);
      runInAction(() => {
        this.selectedDoctor = doctor;
        sessionStorage.setItem("selectedDoctor", JSON.stringify(this.selectedDoctor));

      });
    } catch (error) {
      console.log(error);
    }
  };

  loadDoctorWizard = async () => {
    runInAction(() => {
      this.loading = true;
    });
    try {
      const doctors = await agent.Doctors.list();
      runInAction(() => {
        this.doctors = doctors.data;
      });
      this.doctors.forEach((element) => {
        if (element.isActive === 1)
        {runInAction(() => {
          this.doctorWizard.find((x) => x.id === element.id)
            ? (element.id = element.id)
            : this.doctorWizard.push({
                label: element.fullName,
                id: element.id,
              })
          });
        }
      });
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
  calculateDoctorPayments = (payments) => {
    runInAction(() => {
      this.earning = {
        totalPlanNumbers: 0,
        totalTreatmentNumbers: 0,
        totalEarning: 0,
      };
      this.selectedDoctor.plans.forEach((plan) => {
        if (plan.isApproved) this.earning.totalPlanNumbers += 1;
        plan.treatmentStages[0].treatments.forEach((treatment) => {
          if (treatment.isCompleted) {
            this.earning.totalTreatmentNumbers += 1;
            this.earning.totalEarning += (this.selectedDoctor.commissionPercentage * treatment.price)/100;
            this.earning.earningLeft = this.earning.totalEarning;
          }
        });
      });
      if (payments !== undefined){
        payments.forEach(payment => {
          this.earning.earningLeft -= payment.payments;
        });
      }
      this.paymentCalculated = true;
    });
  };
}
