import React, { useEffect, useState } from 'react'
import { useStore } from '../../stores/store'
import './permissions.css'
import { observer } from 'mobx-react-lite'
import Checkbox ,{ checkboxClasses } from '@mui/material/Checkbox'
import { pink, yellow } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, Modal } from 'react-bootstrap'

const { palette } = createTheme();
const theme = createTheme({
  palette: {
    pinky: palette.augmentColor({ color: pink }),
    summer: palette.augmentColor({ color: yellow }),
  },
});

const Permissions = () => {
  //*?Komponenti store'a bağlama.
  const { userStore } = useStore()

  //*?Modal Açma
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
  }
  const cbColor = '#ff0000'

  //*? Backend'e istek atılan fonksiyon
  const getRoles = () => {
    userStore.getRoles()
  }

  const initialPermissions = userStore.permissions
  const [permissions, setPermissions] = useState(initialPermissions)
  useEffect(() => {
    setPermissions(initialPermissions)
  }, [userStore.selectedRole])

  //*? Sayfa açıldığında rollerin getirilmesi
  useEffect(() => {
    getRoles()
  }, [])

  //*?Rol seçimi
  const handleSelectRole = (roleCode) => {
    userStore.getRole(roleCode)
  }

  //*?Yetki seçimi
  const handleSelectPermission = (event) => {
    userStore.setPermission(event)
  }

  const handleSubmit = () => {
    userStore.updatePermissions()
  }

  if (userStore.rolesLoaded === false)
    return (
      <Button className="loadingBtnStyle" variant="danger" disabled>
        Lütfen Bir Rol Seçiniz...
      </Button>
    )
  if (userStore.roleSelected === false)
    return (
      <div>
        <Button className="loadingBtnStyle" variant="danger" disabled>
          Lütfen Bir Rol Seçiniz...
        </Button>
        <select
          className="userSelect"
          onChange={(e) => handleSelectRole(e.target.value)}
        >
          {userStore.roles.map((roleCodes) => (
            <option value={roleCodes.roleCode}>{roleCodes.name}</option>
          ))}
        </select>
      </div>
    )

  return (
    <div className="pricesTableList">
      <div>
        <label className="spanText">Kullanıcı Tipi: </label>
        <select
          className="userSelect"
          onChange={(e) => handleSelectRole(e.target.value)}
        >
          {userStore.roles.map((roleCodes) => (
            <option value={roleCodes.roleCode}>{roleCodes.name}</option>
          ))}
        </select>
        {userStore.grandPermissions.permissionUpdate && (
          <Button variant="outline-dark" onClick={() => handleSubmit()}>
            Güncelle
          </Button>
        )}

        <Button onClick={handleShow} variant="outline-dark" className="mx-2">
          Kullanıcı Listesi
        </Button>
      </div>
      <table className="pricesListTable table table-hover table-lg table-responsive-md">
        <thead
          className="pricesTableBody"
          style={{ textAlign: 'center', fontSize: 20 }}
        >
          <tr className="pricesTableThHead">
            <th className="pricesTableTh">x</th>
            <th className="pricesTableTh">Okuma</th>
            <th className="pricesTableTh">Oluşturma</th>
            <th className="pricesTableTh">Silme</th>
            <th className="pricesTableTh">Güncelleme</th>
          </tr>
        </thead>
        <tbody className="pricesTableTbody">
          <tr>
            <td className="pricesTableTd">Kullanıcı Bilgileri</td>
            <td className="pricesTableTd">
            <ThemeProvider theme={theme}>
              <Checkbox
                name="userRead"
                value={1}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.userRead}
                onChange={(e) => handleSelectPermission(e.target)}
              />
              </ThemeProvider>
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="userCreate"
                value={2}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.userCreate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="userDelete"
                value={3}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.userDelete}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="userUpdate"
                value={4}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.userUpdate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
          </tr>
          <tr>
            <td className="pricesTableTd">Hasta Bilgileri</td>
            <td className="pricesTableTd">
              <Checkbox
                name="patientRead"
                value={5}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.patientRead}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="patientCreate"
                value={6}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.patientCreate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="patientDelete"
                value={7}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.patientDelete}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="patientUpdate"
                value={8}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.patientUpdate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
          </tr>
          <tr>
            <td className="pricesTableTd">Doctor Bilgileri</td>
            <td className="pricesTableTd">
              <Checkbox
                name="doctorRead"
                value={9}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.doctorRead}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="doctorCreate"
                value={10}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.doctorCreate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="doctorDelete"
                value={11}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.doctorDelete}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="doctorUpdate"
                value={12}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.doctorUpdate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
          </tr>
          <tr>
            <td className="pricesTableTd">Randevu Bilgileri</td>
            <td className="pricesTableTd">
              <Checkbox
                name="appointmentRead"
                value={13}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.appointmentRead}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="appointmentCreate"
                value={14}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.appointmentCreate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="appointmentDelete"
                value={15}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.appointmentDelete}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="appointmentUpdate"
                value={16}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.appointmentUpdate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
          </tr>
          <tr>
            <td className="pricesTableTd">Tedavi Fiyatı Bilgileri</td>
            <td className="pricesTableTd">
              <Checkbox
                name="priceRead"
                value={17}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.priceRead}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="priceCreate"
                value={18}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.priceCreate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="priceDelete"
                value={19}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.priceDelete}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="priceUpdate"
                value={20}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.priceUpdate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
          </tr>
          <tr>
            <td className="pricesTableTd">Tedavi Planı Bilgileri</td>
            <td className="pricesTableTd">
              <Checkbox
                name="planRead"
                value={21}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.planRead}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="planCreate"
                value={22}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.planCreate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="planDelete"
                value={23}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.planDelete}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="planUpdate"
                value={24}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.planUpdate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
          </tr>
          <tr>
            <td className="pricesTableTd">Tahsilat Planı Bilgileri</td>
            <td className="pricesTableTd">
              <Checkbox
                name="collectionPlanRead"
                value={25}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.collectionPlanRead}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="collectionPlanCreate"
                value={26}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.collectionPlanCreate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="collectionPlanDelete"
                value={27}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.collectionPlanDelete}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="collectionPlanUpdate"
                value={28}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.collectionPlanUpdate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
          </tr>
          <tr>
            <td className="pricesTableTd">Tahsilat Bilgileri</td>
            <td className="pricesTableTd">
              <Checkbox
                name="collectionRead"
                value={29}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.collectionRead}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="collectionCreate"
                value={30}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.collectionCreate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="collectionDelete"
                value={31}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.collectionDelete}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="collectionUpdate"
                value={32}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.collectionUpdate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
          </tr>
          <tr>
            <td className="pricesTableTd">Doktor Ödeme Bilgileri</td>
            <td className="pricesTableTd">
              <Checkbox
                name="paymentRead"
                value={33}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.paymentRead}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="paymentCreate"
                value={34}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.paymentCreate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="paymentDelete"
                value={35}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.paymentDelete}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="paymentUpdate"
                value={36}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.paymentUpdate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
          </tr>
          <tr>
            <td className="pricesTableTd">Kullanıcı Yetkileri</td>
            <td className="pricesTableTd">
              <Checkbox
                name="permissionRead"
                value={37}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.permissionRead}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="permissionCreate"
                value={38}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.permissionCreate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="permissionDelete"
                value={39}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.permissionDelete}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="permissionUpdate"
                value={40}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.permissionUpdate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
          </tr>
          <tr>
            <td className="pricesTableTd">Döküman Bilgileri</td>
            <td className="pricesTableTd">
              <Checkbox
                name="documentRead"
                value={41}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.documentRead}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="documentCreate"
                value={42}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.documentCreate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="documentDelete"
                value={43}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.documentDelete}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
            <td className="pricesTableTd">
              <Checkbox
                name="documentUpdate"
                value={44}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: cbColor,
                  },
                }}
                checked={permissions.documentUpdate}
                onChange={(e) => handleSelectPermission(e.target)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Kullanıcı Tipi Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addUserTypeDiv">
            <input type="text" className="addUserTypeInput" />
            <Button variant="danger">Ekle</Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default observer(Permissions)
