import "./diagnoseRightMenuModal.css";
import { Close, Delete, ViewList } from "@mui/icons-material";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

function DiagnoseRightMenuModal() {
  const { conditionStore, planStore } = useStore();
  const { selectedPlan, selectPlan } = planStore;
  const {
    currentCondition,
    createCondition,
    deleteCondition,
    resetForm,
    resetForm2,
    loadedCount,
    loading,
  } = conditionStore;

  const initialState = currentCondition ?? {
    planId: "",
    conditionType: 0,
    teeths: [0],
    toothSurfaces: [0],
    locations: 0,
    degree: 0,
    status: 0,
    observations: "",
  };

  const [condition, setCondition] = useState(initialState);

  useEffect(() => {
    setCondition(initialState);
  }, [currentCondition]);

  const initialState2 = selectedPlan ?? {
    id: "",
    name: "",
    patientID: "",
    createdTime: "",
    isActive: 1,
    conditions: [],
    treatmentStages: {
      id: "",
      name: "",
      description: "",
      planId: "",
      createdTime: "",
      isActive: 1,
      treatments: {},
    },
  };
  const [plan, setPlan] = useState(initialState2);

  useEffect(() => {
    setPlan(initialState2);
  }, [resetForm2]);

  function handleDelete(id) {
    deleteCondition(id);
  }
  return (
    <div className="boxStyle">
      <div className="modal-body">
        <div>
          <table id="treatmentsPlanTable">
         
            {plan.conditions?.map((condition) => (
              <tbody>
                <tr className="diagnoseRmTableTd">
                  <td className="tdStyle">
                    <span>Tedavi: </span>
                    {(condition.conditionType === 0 && <label></label>) ||
                      (condition.conditionType === 1 && (
                        <label>Çürükler</label>
                      )) ||
                      (condition.conditionType === 2 && <label>Kırık</label>) ||
                      (condition.conditionType === 3 && (
                        <label>Ağır Hasarlı</label>
                      )) ||
                      (condition.conditionType === 4 && (
                        <label>Aşınmalar</label>
                      )) ||
                      (condition.conditionType === 5 && (
                        <label>Plak & Hijyen</label>
                      )) ||
                      (condition.conditionType === 6 && (
                        <label>Dişeti İltihabı</label>
                      )) ||
                      (condition.conditionType === 7 && (
                        <label>Dişeti Çekilmesi</label>
                      )) ||
                      (condition.conditionType === 8 && (
                        <label>Periodontitis</label>
                      )) ||
                      (condition.conditionType === 9 && (
                        <label>Hareketlilik</label>
                      )) ||
                      (condition.conditionType === 10 && (
                        <label>Sakızlı Gülümseme</label>
                      )) ||
                      (condition.conditionType === 11 && (
                        <label>Dişeti Büyümesi</label>
                      )) ||
                      (condition.conditionType === 12 && (
                        <label>Büyük Maksiller Sinüs</label>
                      )) ||
                      (condition.conditionType === 13 && (
                        <label>Nekroz</label>
                      )) ||
                      (condition.conditionType === 14 && (
                        <label>Kök Kanal Tedavisi</label>
                      )) ||
                      (condition.conditionType === 15 && (
                        <label>Apikal Lezyon</label>
                      )) ||
                      (condition.conditionType === 16 && (
                        <label>Kanaldaki Kırık Enstrüman</label>
                      )) ||
                      (condition.conditionType === 17 && (
                        <label>Kök Rezorpsiyonu</label>
                      )) ||
                      (condition.conditionType === 18 && (
                        <label>Eksik Diş</label>
                      )) ||
                      (condition.conditionType === 19 && (
                        <label>İmplant</label>
                      )) ||
                      (condition.conditionType === 20 && (
                        <label>Gömülü Diş</label>
                      )) ||
                      (condition.conditionType === 21 && (
                        <label>Gömülü ve Enfekte Diş</label>
                      )) ||
                      (condition.conditionType === 22 && (
                        <label>Büyük Maksiller Sinüs</label>
                      )) ||
                      (condition.conditionType === 23 && (
                        <label>Restorasyon</label>
                      )) ||
                      (condition.conditionType === 24 && <label>Post</label>) ||
                      (condition.conditionType === 25 && <label>Atel</label>) ||
                      (condition.conditionType === 26 && (
                        <label>Renksiz Diş</label>
                      )) ||
                      (condition.conditionType === 27 && (
                        <label>Kaplama</label>
                      )) ||
                      (condition.conditionType === 28 && <label>Taç</label>) ||
                      (condition.conditionType === 29 && (
                        <label>Onlay</label>
                      )) ||
                      (condition.conditionType === 30 && (
                        <label>Köprü</label>
                      )) ||
                      (condition.conditionType === 31 && (
                        <label>Takma Diş</label>
                      ))}
                  </td>
                  <td>
                    <span>Dişler: </span> {condition.teeths + ","}
                  </td>
                  <td>
                    <ul className="ulStyle">
                      {(condition.conditionType === 1 ||
                        condition.conditionType === 23) && (
                        <li>
                          <span>Yüzey: </span>
                          {condition.toothSurfaces}
                        </li>
                      )}
                      {(condition.conditionType === 2 ||
                        condition.conditionType === 17) && (
                        <li>
                          <span>Lokasyon: </span>
                          {condition.locations}
                        </li>
                      )}
                      {(condition.conditionType === 4 ||
                        condition.conditionType === 5 ||
                        condition.conditionType === 6 ||
                        condition.conditionType === 7 ||
                        condition.conditionType === 8 ||
                        condition.conditionType === 9 ||
                        condition.conditionType === 15) && (
                        <li>
                          <span>Derece: </span>
                          {condition.degree}
                        </li>
                      )}
                      {(condition.conditionType === 14 ||
                        condition.conditionType === 19 ||
                        condition.conditionType === 23 ||
                        condition.conditionType === 24 ||
                        condition.conditionType === 25 ||
                        condition.conditionType === 27 ||
                        condition.conditionType === 28 ||
                        condition.conditionType === 29 ||
                        condition.conditionType === 30 ||
                        condition.conditionType === 31) && (
                        <li>
                          <span>Durum: </span>
                          {condition.status}
                        </li>
                      )}
                    </ul>
                  </td>

                  <td className="treatmentsPlanTableTd">
                    <Button
                      variant="outline-danger "
                      onClick={() => handleDelete(condition.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-archive"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                      </svg>
                    </Button>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </div>
    </div>
    
  );
}
export default observer(DiagnoseRightMenuModal);
