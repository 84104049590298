import React from "react";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useStore } from "../../stores/store";
import { useNavigate } from "react-router-dom";
import { Button, Form, Dropdown } from "react-bootstrap";
import "./doctors.css";
import { Col, Row, Container } from "react-bootstrap";
import TextField from "@mui/material/TextField";

export default observer(function Doctors() {
  const { drStore } = useStore();

  const initialState = drStore.selectedDoctor ?? {
    id: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    branch: "",
    commissionPercentage: 0,
    isActive: 0,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    mondayStart: 0,
    mondayEnd: 0,
    tuesdayStart: 0,
    tuesdayEnd: 0,
    wednesdayStart: 0,
    wednesdayEnd: 0,
    thursdayStart: 0,
    thursdayEnd: 0,
    fridayStart: 0,
    fridayEnd: 0,
    saturdayStart: 0,
    saturdayEnd: 0,
    start: 0,
    sundayEnd: 0,
  };
  const [drUpdate, setUpdate] = useState(initialState);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setUpdate({ ...drUpdate, [name]: value });
  }

  function updtDoctor() {
    const model = drUpdate;
    drStore.updtDr(model);
  }

  function handleStatus(value) {
    if (value === true) setUpdate({ ...drUpdate, isActive: 1 });
    if (value === false) setUpdate({ ...drUpdate, isActive: 2 });
  }

  function handleSundayStart(value) {
    if (value === "00:00") drUpdate.sundayStart = 1;
    if (value === "00:30") drUpdate.sundayStart = 2;
    if (value === "01:00") drUpdate.sundayStart = 3;
    if (value === "01:30") drUpdate.sundayStart = 4;
    if (value === "02:00") drUpdate.sundayStart = 5;
    if (value === "02:30") drUpdate.sundayStart = 6;
    if (value === "03:00") drUpdate.sundayStart = 7;
    if (value === "03:30") drUpdate.sundayStart = 8;
    if (value === "04:00") drUpdate.sundayStart = 9;
    if (value === "04:30") drUpdate.sundayStart = 10;
    if (value === "05:00") drUpdate.sundayStart = 11;
    if (value === "05:30") drUpdate.sundayStart = 12;
    if (value === "06:00") drUpdate.sundayStart = 13;
    if (value === "06:30") drUpdate.sundayStart = 14;
    if (value === "07:00") drUpdate.sundayStart = 15;
    if (value === "07:30") drUpdate.sundayStart = 16;
    if (value === "08:00") drUpdate.sundayStart = 17;
    if (value === "08:30") drUpdate.sundayStart = 18;
    if (value === "09:00") drUpdate.sundayStart = 19;
    if (value === "09:30") drUpdate.sundayStart = 20;
    if (value === "10:00") drUpdate.sundayStart = 21;
    if (value === "10:30") drUpdate.sundayStart = 22;
    if (value === "11:00") drUpdate.sundayStart = 23;
    if (value === "11:30") drUpdate.sundayStart = 24;
    if (value === "12:00") drUpdate.sundayStart = 25;
    if (value === "12:30") drUpdate.sundayStart = 26;
    if (value === "13:00") drUpdate.sundayStart = 27;
    if (value === "13:30") drUpdate.sundayStart = 28;
    if (value === "14:00") drUpdate.sundayStart = 29;
    if (value === "14:30") drUpdate.sundayStart = 30;
    if (value === "15:00") drUpdate.sundayStart = 31;
    if (value === "15:30") drUpdate.sundayStart = 32;
    if (value === "16:00") drUpdate.sundayStart = 33;
    if (value === "16:30") drUpdate.sundayStart = 34;
    if (value === "17:00") drUpdate.sundayStart = 35;
    if (value === "17:30") drUpdate.sundayStart = 36;
    if (value === "18:00") drUpdate.sundayStart = 37;
    if (value === "18:30") drUpdate.sundayStart = 38;
    if (value === "19:00") drUpdate.sundayStart = 39;
    if (value === "19:30") drUpdate.sundayStart = 40;
    if (value === "20:00") drUpdate.sundayStart = 41;
    if (value === "20:30") drUpdate.sundayStart = 42;
    if (value === "21:00") drUpdate.sundayStart = 43;
    if (value === "21:30") drUpdate.sundayStart = 44;
    if (value === "22:00") drUpdate.sundayStart = 45;
    if (value === "22:30") drUpdate.sundayStart = 46;
    if (value === "23:00") drUpdate.sundayStart = 47;
    if (value === "23:30") drUpdate.sundayStart = 48;
  }
  function handleSundayEnd(value) {
    if (value === "00:00") drUpdate.sundayEnd = 1;
    if (value === "00:30") drUpdate.sundayEnd = 2;
    if (value === "01:00") drUpdate.sundayEnd = 3;
    if (value === "01:30") drUpdate.sundayEnd = 4;
    if (value === "02:00") drUpdate.sundayEnd = 5;
    if (value === "02:30") drUpdate.sundayEnd = 6;
    if (value === "03:00") drUpdate.sundayEnd = 7;
    if (value === "03:30") drUpdate.sundayEnd = 8;
    if (value === "04:00") drUpdate.sundayEnd = 9;
    if (value === "04:30") drUpdate.sundayEnd = 10;
    if (value === "05:00") drUpdate.sundayEnd = 11;
    if (value === "05:30") drUpdate.sundayEnd = 12;
    if (value === "06:00") drUpdate.sundayEnd = 13;
    if (value === "06:30") drUpdate.sundayEnd = 14;
    if (value === "07:00") drUpdate.sundayEnd = 15;
    if (value === "07:30") drUpdate.sundayEnd = 16;
    if (value === "08:00") drUpdate.sundayEnd = 17;
    if (value === "08:30") drUpdate.sundayEnd = 18;
    if (value === "09:00") drUpdate.sundayEnd = 19;
    if (value === "09:30") drUpdate.sundayEnd = 20;
    if (value === "10:00") drUpdate.sundayEnd = 21;
    if (value === "10:30") drUpdate.sundayEnd = 22;
    if (value === "11:00") drUpdate.sundayEnd = 23;
    if (value === "11:30") drUpdate.sundayEnd = 24;
    if (value === "12:00") drUpdate.sundayEnd = 25;
    if (value === "12:30") drUpdate.sundayEnd = 26;
    if (value === "13:00") drUpdate.sundayEnd = 27;
    if (value === "13:30") drUpdate.sundayEnd = 28;
    if (value === "14:00") drUpdate.sundayEnd = 29;
    if (value === "14:30") drUpdate.sundayEnd = 30;
    if (value === "15:00") drUpdate.sundayEnd = 31;
    if (value === "15:30") drUpdate.sundayEnd = 32;
    if (value === "16:00") drUpdate.sundayEnd = 33;
    if (value === "16:30") drUpdate.sundayEnd = 34;
    if (value === "17:00") drUpdate.sundayEnd = 35;
    if (value === "17:30") drUpdate.sundayEnd = 36;
    if (value === "18:00") drUpdate.sundayEnd = 37;
    if (value === "18:30") drUpdate.sundayEnd = 38;
    if (value === "19:00") drUpdate.sundayEnd = 39;
    if (value === "19:30") drUpdate.sundayEnd = 40;
    if (value === "20:00") drUpdate.sundayEnd = 41;
    if (value === "20:30") drUpdate.sundayEnd = 42;
    if (value === "21:00") drUpdate.sundayEnd = 43;
    if (value === "21:30") drUpdate.sundayEnd = 44;
    if (value === "22:00") drUpdate.sundayEnd = 45;
    if (value === "22:30") drUpdate.sundayEnd = 46;
    if (value === "23:00") drUpdate.sundayEnd = 47;
    if (value === "23:30") drUpdate.sundayEnd = 48;
  }
  function handleTuesdayStart(value) {
    if (value === "00:00") drUpdate.tuesdayStart = 1;
    if (value === "00:30") drUpdate.tuesdayStart = 2;
    if (value === "01:00") drUpdate.tuesdayStart = 3;
    if (value === "01:30") drUpdate.tuesdayStart = 4;
    if (value === "02:00") drUpdate.tuesdayStart = 5;
    if (value === "02:30") drUpdate.tuesdayStart = 6;
    if (value === "03:00") drUpdate.tuesdayStart = 7;
    if (value === "03:30") drUpdate.tuesdayStart = 8;
    if (value === "04:00") drUpdate.tuesdayStart = 9;
    if (value === "04:30") drUpdate.tuesdayStart = 10;
    if (value === "05:00") drUpdate.tuesdayStart = 11;
    if (value === "05:30") drUpdate.tuesdayStart = 12;
    if (value === "06:00") drUpdate.tuesdayStart = 13;
    if (value === "06:30") drUpdate.tuesdayStart = 14;
    if (value === "07:00") drUpdate.tuesdayStart = 15;
    if (value === "07:30") drUpdate.tuesdayStart = 16;
    if (value === "08:00") drUpdate.tuesdayStart = 17;
    if (value === "08:30") drUpdate.tuesdayStart = 18;
    if (value === "09:00") drUpdate.tuesdayStart = 19;
    if (value === "09:30") drUpdate.tuesdayStart = 20;
    if (value === "10:00") drUpdate.tuesdayStart = 21;
    if (value === "10:30") drUpdate.tuesdayStart = 22;
    if (value === "11:00") drUpdate.tuesdayStart = 23;
    if (value === "11:30") drUpdate.tuesdayStart = 24;
    if (value === "12:00") drUpdate.tuesdayStart = 25;
    if (value === "12:30") drUpdate.tuesdayStart = 26;
    if (value === "13:00") drUpdate.tuesdayStart = 27;
    if (value === "13:30") drUpdate.tuesdayStart = 28;
    if (value === "14:00") drUpdate.tuesdayStart = 29;
    if (value === "14:30") drUpdate.tuesdayStart = 30;
    if (value === "15:00") drUpdate.tuesdayStart = 31;
    if (value === "15:30") drUpdate.tuesdayStart = 32;
    if (value === "16:00") drUpdate.tuesdayStart = 33;
    if (value === "16:30") drUpdate.tuesdayStart = 34;
    if (value === "17:00") drUpdate.tuesdayStart = 35;
    if (value === "17:30") drUpdate.tuesdayStart = 36;
    if (value === "18:00") drUpdate.tuesdayStart = 37;
    if (value === "18:30") drUpdate.tuesdayStart = 38;
    if (value === "19:00") drUpdate.tuesdayStart = 39;
    if (value === "19:30") drUpdate.tuesdayStart = 40;
    if (value === "20:00") drUpdate.tuesdayStart = 41;
    if (value === "20:30") drUpdate.tuesdayStart = 42;
    if (value === "21:00") drUpdate.tuesdayStart = 43;
    if (value === "21:30") drUpdate.tuesdayStart = 44;
    if (value === "22:00") drUpdate.tuesdayStart = 45;
    if (value === "22:30") drUpdate.tuesdayStart = 46;
    if (value === "23:00") drUpdate.tuesdayStart = 47;
    if (value === "23:30") drUpdate.tuesdayStart = 48;
  }
  function handleTuesdayEnd(value) {
    if (value === "00:00") drUpdate.tuesdayEnd = 1;
    if (value === "00:30") drUpdate.tuesdayEnd = 2;
    if (value === "01:00") drUpdate.tuesdayEnd = 3;
    if (value === "01:30") drUpdate.tuesdayEnd = 4;
    if (value === "02:00") drUpdate.tuesdayEnd = 5;
    if (value === "02:30") drUpdate.tuesdayEnd = 6;
    if (value === "03:00") drUpdate.tuesdayEnd = 7;
    if (value === "03:30") drUpdate.tuesdayEnd = 8;
    if (value === "04:00") drUpdate.tuesdayEnd = 9;
    if (value === "04:30") drUpdate.tuesdayEnd = 10;
    if (value === "05:00") drUpdate.tuesdayEnd = 11;
    if (value === "05:30") drUpdate.tuesdayEnd = 12;
    if (value === "06:00") drUpdate.tuesdayEnd = 13;
    if (value === "06:30") drUpdate.tuesdayEnd = 14;
    if (value === "07:00") drUpdate.tuesdayEnd = 15;
    if (value === "07:30") drUpdate.tuesdayEnd = 16;
    if (value === "08:00") drUpdate.tuesdayEnd = 17;
    if (value === "08:30") drUpdate.tuesdayEnd = 18;
    if (value === "09:00") drUpdate.tuesdayEnd = 19;
    if (value === "09:30") drUpdate.tuesdayEnd = 20;
    if (value === "10:00") drUpdate.tuesdayEnd = 21;
    if (value === "10:30") drUpdate.tuesdayEnd = 22;
    if (value === "11:00") drUpdate.tuesdayEnd = 23;
    if (value === "11:30") drUpdate.tuesdayEnd = 24;
    if (value === "12:00") drUpdate.tuesdayEnd = 25;
    if (value === "12:30") drUpdate.tuesdayEnd = 26;
    if (value === "13:00") drUpdate.tuesdayEnd = 27;
    if (value === "13:30") drUpdate.tuesdayEnd = 28;
    if (value === "14:00") drUpdate.tuesdayEnd = 29;
    if (value === "14:30") drUpdate.tuesdayEnd = 30;
    if (value === "15:00") drUpdate.tuesdayEnd = 31;
    if (value === "15:30") drUpdate.tuesdayEnd = 32;
    if (value === "16:00") drUpdate.tuesdayEnd = 33;
    if (value === "16:30") drUpdate.tuesdayEnd = 34;
    if (value === "17:00") drUpdate.tuesdayEnd = 35;
    if (value === "17:30") drUpdate.tuesdayEnd = 36;
    if (value === "18:00") drUpdate.tuesdayEnd = 37;
    if (value === "18:30") drUpdate.tuesdayEnd = 38;
    if (value === "19:00") drUpdate.tuesdayEnd = 39;
    if (value === "19:30") drUpdate.tuesdayEnd = 40;
    if (value === "20:00") drUpdate.tuesdayEnd = 41;
    if (value === "20:30") drUpdate.tuesdayEnd = 42;
    if (value === "21:00") drUpdate.tuesdayEnd = 43;
    if (value === "21:30") drUpdate.tuesdayEnd = 44;
    if (value === "22:00") drUpdate.tuesdayEnd = 45;
    if (value === "22:30") drUpdate.tuesdayEnd = 46;
    if (value === "23:00") drUpdate.tuesdayEnd = 47;
    if (value === "23:30") drUpdate.tuesdayEnd = 48;
  }
  function handleWednesdayStart(value) {
    if (value === "00:00") drUpdate.wednesdayStart = 1;
    if (value === "00:30") drUpdate.wednesdayStart = 2;
    if (value === "01:00") drUpdate.wednesdayStart = 3;
    if (value === "01:30") drUpdate.wednesdayStart = 4;
    if (value === "02:00") drUpdate.wednesdayStart = 5;
    if (value === "02:30") drUpdate.wednesdayStart = 6;
    if (value === "03:00") drUpdate.wednesdayStart = 7;
    if (value === "03:30") drUpdate.wednesdayStart = 8;
    if (value === "04:00") drUpdate.wednesdayStart = 9;
    if (value === "04:30") drUpdate.wednesdayStart = 10;
    if (value === "05:00") drUpdate.wednesdayStart = 11;
    if (value === "05:30") drUpdate.wednesdayStart = 12;
    if (value === "06:00") drUpdate.wednesdayStart = 13;
    if (value === "06:30") drUpdate.wednesdayStart = 14;
    if (value === "07:00") drUpdate.wednesdayStart = 15;
    if (value === "07:30") drUpdate.wednesdayStart = 16;
    if (value === "08:00") drUpdate.wednesdayStart = 17;
    if (value === "08:30") drUpdate.wednesdayStart = 18;
    if (value === "09:00") drUpdate.wednesdayStart = 19;
    if (value === "09:30") drUpdate.wednesdayStart = 20;
    if (value === "10:00") drUpdate.wednesdayStart = 21;
    if (value === "10:30") drUpdate.wednesdayStart = 22;
    if (value === "11:00") drUpdate.wednesdayStart = 23;
    if (value === "11:30") drUpdate.wednesdayStart = 24;
    if (value === "12:00") drUpdate.wednesdayStart = 25;
    if (value === "12:30") drUpdate.wednesdayStart = 26;
    if (value === "13:00") drUpdate.wednesdayStart = 27;
    if (value === "13:30") drUpdate.wednesdayStart = 28;
    if (value === "14:00") drUpdate.wednesdayStart = 29;
    if (value === "14:30") drUpdate.wednesdayStart = 30;
    if (value === "15:00") drUpdate.wednesdayStart = 31;
    if (value === "15:30") drUpdate.wednesdayStart = 32;
    if (value === "16:00") drUpdate.wednesdayStart = 33;
    if (value === "16:30") drUpdate.wednesdayStart = 34;
    if (value === "17:00") drUpdate.wednesdayStart = 35;
    if (value === "17:30") drUpdate.wednesdayStart = 36;
    if (value === "18:00") drUpdate.wednesdayStart = 37;
    if (value === "18:30") drUpdate.wednesdayStart = 38;
    if (value === "19:00") drUpdate.wednesdayStart = 39;
    if (value === "19:30") drUpdate.wednesdayStart = 40;
    if (value === "20:00") drUpdate.wednesdayStart = 41;
    if (value === "20:30") drUpdate.wednesdayStart = 42;
    if (value === "21:00") drUpdate.wednesdayStart = 43;
    if (value === "21:30") drUpdate.wednesdayStart = 44;
    if (value === "22:00") drUpdate.wednesdayStart = 45;
    if (value === "22:30") drUpdate.wednesdayStart = 46;
    if (value === "23:00") drUpdate.wednesdayStart = 47;
    if (value === "23:30") drUpdate.wednesdayStart = 48;
  }
  function handleWednesdayEnd(value) {
    if (value === "00:00") drUpdate.wednesdayEnd = 1;
    if (value === "00:30") drUpdate.wednesdayEnd = 2;
    if (value === "01:00") drUpdate.wednesdayEnd = 3;
    if (value === "01:30") drUpdate.wednesdayEnd = 4;
    if (value === "02:00") drUpdate.wednesdayEnd = 5;
    if (value === "02:30") drUpdate.wednesdayEnd = 6;
    if (value === "03:00") drUpdate.wednesdayEnd = 7;
    if (value === "03:30") drUpdate.wednesdayEnd = 8;
    if (value === "04:00") drUpdate.wednesdayEnd = 9;
    if (value === "04:30") drUpdate.wednesdayEnd = 10;
    if (value === "05:00") drUpdate.wednesdayEnd = 11;
    if (value === "05:30") drUpdate.wednesdayEnd = 12;
    if (value === "06:00") drUpdate.wednesdayEnd = 13;
    if (value === "06:30") drUpdate.wednesdayEnd = 14;
    if (value === "07:00") drUpdate.wednesdayEnd = 15;
    if (value === "07:30") drUpdate.wednesdayEnd = 16;
    if (value === "08:00") drUpdate.wednesdayEnd = 17;
    if (value === "08:30") drUpdate.wednesdayEnd = 18;
    if (value === "09:00") drUpdate.wednesdayEnd = 19;
    if (value === "09:30") drUpdate.wednesdayEnd = 20;
    if (value === "10:00") drUpdate.wednesdayEnd = 21;
    if (value === "10:30") drUpdate.wednesdayEnd = 22;
    if (value === "11:00") drUpdate.wednesdayEnd = 23;
    if (value === "11:30") drUpdate.wednesdayEnd = 24;
    if (value === "12:00") drUpdate.wednesdayEnd = 25;
    if (value === "12:30") drUpdate.wednesdayEnd = 26;
    if (value === "13:00") drUpdate.wednesdayEnd = 27;
    if (value === "13:30") drUpdate.wednesdayEnd = 28;
    if (value === "14:00") drUpdate.wednesdayEnd = 29;
    if (value === "14:30") drUpdate.wednesdayEnd = 30;
    if (value === "15:00") drUpdate.wednesdayEnd = 31;
    if (value === "15:30") drUpdate.wednesdayEnd = 32;
    if (value === "16:00") drUpdate.wednesdayEnd = 33;
    if (value === "16:30") drUpdate.wednesdayEnd = 34;
    if (value === "17:00") drUpdate.wednesdayEnd = 35;
    if (value === "17:30") drUpdate.wednesdayEnd = 36;
    if (value === "18:00") drUpdate.wednesdayEnd = 37;
    if (value === "18:30") drUpdate.wednesdayEnd = 38;
    if (value === "19:00") drUpdate.wednesdayEnd = 39;
    if (value === "19:30") drUpdate.wednesdayEnd = 40;
    if (value === "20:00") drUpdate.wednesdayEnd = 41;
    if (value === "20:30") drUpdate.wednesdayEnd = 42;
    if (value === "21:00") drUpdate.wednesdayEnd = 43;
    if (value === "21:30") drUpdate.wednesdayEnd = 44;
    if (value === "22:00") drUpdate.wednesdayEnd = 45;
    if (value === "22:30") drUpdate.wednesdayEnd = 46;
    if (value === "23:00") drUpdate.wednesdayEnd = 47;
    if (value === "23:30") drUpdate.wednesdayEnd = 48;
  }
  function handleThursdayStart(value) {
    if (value === "00:00") drUpdate.thursdayStart = 1;
    if (value === "00:30") drUpdate.thursdayStart = 2;
    if (value === "01:00") drUpdate.thursdayStart = 3;
    if (value === "01:30") drUpdate.thursdayStart = 4;
    if (value === "02:00") drUpdate.thursdayStart = 5;
    if (value === "02:30") drUpdate.thursdayStart = 6;
    if (value === "03:00") drUpdate.thursdayStart = 7;
    if (value === "03:30") drUpdate.thursdayStart = 8;
    if (value === "04:00") drUpdate.thursdayStart = 9;
    if (value === "04:30") drUpdate.thursdayStart = 10;
    if (value === "05:00") drUpdate.thursdayStart = 11;
    if (value === "05:30") drUpdate.thursdayStart = 12;
    if (value === "06:00") drUpdate.thursdayStart = 13;
    if (value === "06:30") drUpdate.thursdayStart = 14;
    if (value === "07:00") drUpdate.thursdayStart = 15;
    if (value === "07:30") drUpdate.thursdayStart = 16;
    if (value === "08:00") drUpdate.thursdayStart = 17;
    if (value === "08:30") drUpdate.thursdayStart = 18;
    if (value === "09:00") drUpdate.thursdayStart = 19;
    if (value === "09:30") drUpdate.thursdayStart = 20;
    if (value === "10:00") drUpdate.thursdayStart = 21;
    if (value === "10:30") drUpdate.thursdayStart = 22;
    if (value === "11:00") drUpdate.thursdayStart = 23;
    if (value === "11:30") drUpdate.thursdayStart = 24;
    if (value === "12:00") drUpdate.thursdayStart = 25;
    if (value === "12:30") drUpdate.thursdayStart = 26;
    if (value === "13:00") drUpdate.thursdayStart = 27;
    if (value === "13:30") drUpdate.thursdayStart = 28;
    if (value === "14:00") drUpdate.thursdayStart = 29;
    if (value === "14:30") drUpdate.thursdayStart = 30;
    if (value === "15:00") drUpdate.thursdayStart = 31;
    if (value === "15:30") drUpdate.thursdayStart = 32;
    if (value === "16:00") drUpdate.thursdayStart = 33;
    if (value === "16:30") drUpdate.thursdayStart = 34;
    if (value === "17:00") drUpdate.thursdayStart = 35;
    if (value === "17:30") drUpdate.thursdayStart = 36;
    if (value === "18:00") drUpdate.thursdayStart = 37;
    if (value === "18:30") drUpdate.thursdayStart = 38;
    if (value === "19:00") drUpdate.thursdayStart = 39;
    if (value === "19:30") drUpdate.thursdayStart = 40;
    if (value === "20:00") drUpdate.thursdayStart = 41;
    if (value === "20:30") drUpdate.thursdayStart = 42;
    if (value === "21:00") drUpdate.thursdayStart = 43;
    if (value === "21:30") drUpdate.thursdayStart = 44;
    if (value === "22:00") drUpdate.thursdayStart = 45;
    if (value === "22:30") drUpdate.thursdayStart = 46;
    if (value === "23:00") drUpdate.thursdayStart = 47;
    if (value === "23:30") drUpdate.thursdayStart = 48;
  }
  function handleThursdayEnd(value) {
    if (value === "00:00") drUpdate.thursdayEnd = 1;
    if (value === "00:30") drUpdate.thursdayEnd = 2;
    if (value === "01:00") drUpdate.thursdayEnd = 3;
    if (value === "01:30") drUpdate.thursdayEnd = 4;
    if (value === "02:00") drUpdate.thursdayEnd = 5;
    if (value === "02:30") drUpdate.thursdayEnd = 6;
    if (value === "03:00") drUpdate.thursdayEnd = 7;
    if (value === "03:30") drUpdate.thursdayEnd = 8;
    if (value === "04:00") drUpdate.thursdayEnd = 9;
    if (value === "04:30") drUpdate.thursdayEnd = 10;
    if (value === "05:00") drUpdate.thursdayEnd = 11;
    if (value === "05:30") drUpdate.thursdayEnd = 12;
    if (value === "06:00") drUpdate.thursdayEnd = 13;
    if (value === "06:30") drUpdate.thursdayEnd = 14;
    if (value === "07:00") drUpdate.thursdayEnd = 15;
    if (value === "07:30") drUpdate.thursdayEnd = 16;
    if (value === "08:00") drUpdate.thursdayEnd = 17;
    if (value === "08:30") drUpdate.thursdayEnd = 18;
    if (value === "09:00") drUpdate.thursdayEnd = 19;
    if (value === "09:30") drUpdate.thursdayEnd = 20;
    if (value === "10:00") drUpdate.thursdayEnd = 21;
    if (value === "10:30") drUpdate.thursdayEnd = 22;
    if (value === "11:00") drUpdate.thursdayEnd = 23;
    if (value === "11:30") drUpdate.thursdayEnd = 24;
    if (value === "12:00") drUpdate.thursdayEnd = 25;
    if (value === "12:30") drUpdate.thursdayEnd = 26;
    if (value === "13:00") drUpdate.thursdayEnd = 27;
    if (value === "13:30") drUpdate.thursdayEnd = 28;
    if (value === "14:00") drUpdate.thursdayEnd = 29;
    if (value === "14:30") drUpdate.thursdayEnd = 30;
    if (value === "15:00") drUpdate.thursdayEnd = 31;
    if (value === "15:30") drUpdate.thursdayEnd = 32;
    if (value === "16:00") drUpdate.thursdayEnd = 33;
    if (value === "16:30") drUpdate.thursdayEnd = 34;
    if (value === "17:00") drUpdate.thursdayEnd = 35;
    if (value === "17:30") drUpdate.thursdayEnd = 36;
    if (value === "18:00") drUpdate.thursdayEnd = 37;
    if (value === "18:30") drUpdate.thursdayEnd = 38;
    if (value === "19:00") drUpdate.thursdayEnd = 39;
    if (value === "19:30") drUpdate.thursdayEnd = 40;
    if (value === "20:00") drUpdate.thursdayEnd = 41;
    if (value === "20:30") drUpdate.thursdayEnd = 42;
    if (value === "21:00") drUpdate.thursdayEnd = 43;
    if (value === "21:30") drUpdate.thursdayEnd = 44;
    if (value === "22:00") drUpdate.thursdayEnd = 45;
    if (value === "22:30") drUpdate.thursdayEnd = 46;
    if (value === "23:00") drUpdate.thursdayEnd = 47;
    if (value === "23:30") drUpdate.thursdayEnd = 48;
  }
  function handleFridayStart(value) {
    if (value === "00:00") drUpdate.fridayStart = 1;
    if (value === "00:30") drUpdate.fridayStart = 2;
    if (value === "01:00") drUpdate.fridayStart = 3;
    if (value === "01:30") drUpdate.fridayStart = 4;
    if (value === "02:00") drUpdate.fridayStart = 5;
    if (value === "02:30") drUpdate.fridayStart = 6;
    if (value === "03:00") drUpdate.fridayStart = 7;
    if (value === "03:30") drUpdate.fridayStart = 8;
    if (value === "04:00") drUpdate.fridayStart = 9;
    if (value === "04:30") drUpdate.fridayStart = 10;
    if (value === "05:00") drUpdate.fridayStart = 11;
    if (value === "05:30") drUpdate.fridayStart = 12;
    if (value === "06:00") drUpdate.fridayStart = 13;
    if (value === "06:30") drUpdate.fridayStart = 14;
    if (value === "07:00") drUpdate.fridayStart = 15;
    if (value === "07:30") drUpdate.fridayStart = 16;
    if (value === "08:00") drUpdate.fridayStart = 17;
    if (value === "08:30") drUpdate.fridayStart = 18;
    if (value === "09:00") drUpdate.fridayStart = 19;
    if (value === "09:30") drUpdate.fridayStart = 20;
    if (value === "10:00") drUpdate.fridayStart = 21;
    if (value === "10:30") drUpdate.fridayStart = 22;
    if (value === "11:00") drUpdate.fridayStart = 23;
    if (value === "11:30") drUpdate.fridayStart = 24;
    if (value === "12:00") drUpdate.fridayStart = 25;
    if (value === "12:30") drUpdate.fridayStart = 26;
    if (value === "13:00") drUpdate.fridayStart = 27;
    if (value === "13:30") drUpdate.fridayStart = 28;
    if (value === "14:00") drUpdate.fridayStart = 29;
    if (value === "14:30") drUpdate.fridayStart = 30;
    if (value === "15:00") drUpdate.fridayStart = 31;
    if (value === "15:30") drUpdate.fridayStart = 32;
    if (value === "16:00") drUpdate.fridayStart = 33;
    if (value === "16:30") drUpdate.fridayStart = 34;
    if (value === "17:00") drUpdate.fridayStart = 35;
    if (value === "17:30") drUpdate.fridayStart = 36;
    if (value === "18:00") drUpdate.fridayStart = 37;
    if (value === "18:30") drUpdate.fridayStart = 38;
    if (value === "19:00") drUpdate.fridayStart = 39;
    if (value === "19:30") drUpdate.fridayStart = 40;
    if (value === "20:00") drUpdate.fridayStart = 41;
    if (value === "20:30") drUpdate.fridayStart = 42;
    if (value === "21:00") drUpdate.fridayStart = 43;
    if (value === "21:30") drUpdate.fridayStart = 44;
    if (value === "22:00") drUpdate.fridayStart = 45;
    if (value === "22:30") drUpdate.fridayStart = 46;
    if (value === "23:00") drUpdate.fridayStart = 47;
    if (value === "23:30") drUpdate.fridayStart = 48;
  }
  function handleFridayEnd(value) {
    if (value === "00:00") drUpdate.fridayEnd = 1;
    if (value === "00:30") drUpdate.fridayEnd = 2;
    if (value === "01:00") drUpdate.fridayEnd = 3;
    if (value === "01:30") drUpdate.fridayEnd = 4;
    if (value === "02:00") drUpdate.fridayEnd = 5;
    if (value === "02:30") drUpdate.fridayEnd = 6;
    if (value === "03:00") drUpdate.fridayEnd = 7;
    if (value === "03:30") drUpdate.fridayEnd = 8;
    if (value === "04:00") drUpdate.fridayEnd = 9;
    if (value === "04:30") drUpdate.fridayEnd = 10;
    if (value === "05:00") drUpdate.fridayEnd = 11;
    if (value === "05:30") drUpdate.fridayEnd = 12;
    if (value === "06:00") drUpdate.fridayEnd = 13;
    if (value === "06:30") drUpdate.fridayEnd = 14;
    if (value === "07:00") drUpdate.fridayEnd = 15;
    if (value === "07:30") drUpdate.fridayEnd = 16;
    if (value === "08:00") drUpdate.fridayEnd = 17;
    if (value === "08:30") drUpdate.fridayEnd = 18;
    if (value === "09:00") drUpdate.fridayEnd = 19;
    if (value === "09:30") drUpdate.fridayEnd = 20;
    if (value === "10:00") drUpdate.fridayEnd = 21;
    if (value === "10:30") drUpdate.fridayEnd = 22;
    if (value === "11:00") drUpdate.fridayEnd = 23;
    if (value === "11:30") drUpdate.fridayEnd = 24;
    if (value === "12:00") drUpdate.fridayEnd = 25;
    if (value === "12:30") drUpdate.fridayEnd = 26;
    if (value === "13:00") drUpdate.fridayEnd = 27;
    if (value === "13:30") drUpdate.fridayEnd = 28;
    if (value === "14:00") drUpdate.fridayEnd = 29;
    if (value === "14:30") drUpdate.fridayEnd = 30;
    if (value === "15:00") drUpdate.fridayEnd = 31;
    if (value === "15:30") drUpdate.fridayEnd = 32;
    if (value === "16:00") drUpdate.fridayEnd = 33;
    if (value === "16:30") drUpdate.fridayEnd = 34;
    if (value === "17:00") drUpdate.fridayEnd = 35;
    if (value === "17:30") drUpdate.fridayEnd = 36;
    if (value === "18:00") drUpdate.fridayEnd = 37;
    if (value === "18:30") drUpdate.fridayEnd = 38;
    if (value === "19:00") drUpdate.fridayEnd = 39;
    if (value === "19:30") drUpdate.fridayEnd = 40;
    if (value === "20:00") drUpdate.fridayEnd = 41;
    if (value === "20:30") drUpdate.fridayEnd = 42;
    if (value === "21:00") drUpdate.fridayEnd = 43;
    if (value === "21:30") drUpdate.fridayEnd = 44;
    if (value === "22:00") drUpdate.fridayEnd = 45;
    if (value === "22:30") drUpdate.fridayEnd = 46;
    if (value === "23:00") drUpdate.fridayEnd = 47;
    if (value === "23:30") drUpdate.fridayEnd = 48;
  }
  function handleSaturdayStart(value) {
    if (value === "00:00") drUpdate.saturdayStart = 1;
    if (value === "00:30") drUpdate.saturdayStart = 2;
    if (value === "01:00") drUpdate.saturdayStart = 3;
    if (value === "01:30") drUpdate.saturdayStart = 4;
    if (value === "02:00") drUpdate.saturdayStart = 5;
    if (value === "02:30") drUpdate.saturdayStart = 6;
    if (value === "03:00") drUpdate.saturdayStart = 7;
    if (value === "03:30") drUpdate.saturdayStart = 8;
    if (value === "04:00") drUpdate.saturdayStart = 9;
    if (value === "04:30") drUpdate.saturdayStart = 10;
    if (value === "05:00") drUpdate.saturdayStart = 11;
    if (value === "05:30") drUpdate.saturdayStart = 12;
    if (value === "06:00") drUpdate.saturdayStart = 13;
    if (value === "06:30") drUpdate.saturdayStart = 14;
    if (value === "07:00") drUpdate.saturdayStart = 15;
    if (value === "07:30") drUpdate.saturdayStart = 16;
    if (value === "08:00") drUpdate.saturdayStart = 17;
    if (value === "08:30") drUpdate.saturdayStart = 18;
    if (value === "09:00") drUpdate.saturdayStart = 19;
    if (value === "09:30") drUpdate.saturdayStart = 20;
    if (value === "10:00") drUpdate.saturdayStart = 21;
    if (value === "10:30") drUpdate.saturdayStart = 22;
    if (value === "11:00") drUpdate.saturdayStart = 23;
    if (value === "11:30") drUpdate.saturdayStart = 24;
    if (value === "12:00") drUpdate.saturdayStart = 25;
    if (value === "12:30") drUpdate.saturdayStart = 26;
    if (value === "13:00") drUpdate.saturdayStart = 27;
    if (value === "13:30") drUpdate.saturdayStart = 28;
    if (value === "14:00") drUpdate.saturdayStart = 29;
    if (value === "14:30") drUpdate.saturdayStart = 30;
    if (value === "15:00") drUpdate.saturdayStart = 31;
    if (value === "15:30") drUpdate.saturdayStart = 32;
    if (value === "16:00") drUpdate.saturdayStart = 33;
    if (value === "16:30") drUpdate.saturdayStart = 34;
    if (value === "17:00") drUpdate.saturdayStart = 35;
    if (value === "17:30") drUpdate.saturdayStart = 36;
    if (value === "18:00") drUpdate.saturdayStart = 37;
    if (value === "18:30") drUpdate.saturdayStart = 38;
    if (value === "19:00") drUpdate.saturdayStart = 39;
    if (value === "19:30") drUpdate.saturdayStart = 40;
    if (value === "20:00") drUpdate.saturdayStart = 41;
    if (value === "20:30") drUpdate.saturdayStart = 42;
    if (value === "21:00") drUpdate.saturdayStart = 43;
    if (value === "21:30") drUpdate.saturdayStart = 44;
    if (value === "22:00") drUpdate.saturdayStart = 45;
    if (value === "22:30") drUpdate.saturdayStart = 46;
    if (value === "23:00") drUpdate.saturdayStart = 47;
    if (value === "23:30") drUpdate.saturdayStart = 48;
  }
  function handleSaturdayEnd(value) {
    if (value === "00:00") drUpdate.saturdayEnd = 1;
    if (value === "00:30") drUpdate.saturdayEnd = 2;
    if (value === "01:00") drUpdate.saturdayEnd = 3;
    if (value === "01:30") drUpdate.saturdayEnd = 4;
    if (value === "02:00") drUpdate.saturdayEnd = 5;
    if (value === "02:30") drUpdate.saturdayEnd = 6;
    if (value === "03:00") drUpdate.saturdayEnd = 7;
    if (value === "03:30") drUpdate.saturdayEnd = 8;
    if (value === "04:00") drUpdate.saturdayEnd = 9;
    if (value === "04:30") drUpdate.saturdayEnd = 10;
    if (value === "05:00") drUpdate.saturdayEnd = 11;
    if (value === "05:30") drUpdate.saturdayEnd = 12;
    if (value === "06:00") drUpdate.saturdayEnd = 13;
    if (value === "06:30") drUpdate.saturdayEnd = 14;
    if (value === "07:00") drUpdate.saturdayEnd = 15;
    if (value === "07:30") drUpdate.saturdayEnd = 16;
    if (value === "08:00") drUpdate.saturdayEnd = 17;
    if (value === "08:30") drUpdate.saturdayEnd = 18;
    if (value === "09:00") drUpdate.saturdayEnd = 19;
    if (value === "09:30") drUpdate.saturdayEnd = 20;
    if (value === "10:00") drUpdate.saturdayEnd = 21;
    if (value === "10:30") drUpdate.saturdayEnd = 22;
    if (value === "11:00") drUpdate.saturdayEnd = 23;
    if (value === "11:30") drUpdate.saturdayEnd = 24;
    if (value === "12:00") drUpdate.saturdayEnd = 25;
    if (value === "12:30") drUpdate.saturdayEnd = 26;
    if (value === "13:00") drUpdate.saturdayEnd = 27;
    if (value === "13:30") drUpdate.saturdayEnd = 28;
    if (value === "14:00") drUpdate.saturdayEnd = 29;
    if (value === "14:30") drUpdate.saturdayEnd = 30;
    if (value === "15:00") drUpdate.saturdayEnd = 31;
    if (value === "15:30") drUpdate.saturdayEnd = 32;
    if (value === "16:00") drUpdate.saturdayEnd = 33;
    if (value === "16:30") drUpdate.saturdayEnd = 34;
    if (value === "17:00") drUpdate.saturdayEnd = 35;
    if (value === "17:30") drUpdate.saturdayEnd = 36;
    if (value === "18:00") drUpdate.saturdayEnd = 37;
    if (value === "18:30") drUpdate.saturdayEnd = 38;
    if (value === "19:00") drUpdate.saturdayEnd = 39;
    if (value === "19:30") drUpdate.saturdayEnd = 40;
    if (value === "20:00") drUpdate.saturdayEnd = 41;
    if (value === "20:30") drUpdate.saturdayEnd = 42;
    if (value === "21:00") drUpdate.saturdayEnd = 43;
    if (value === "21:30") drUpdate.saturdayEnd = 44;
    if (value === "22:00") drUpdate.saturdayEnd = 45;
    if (value === "22:30") drUpdate.saturdayEnd = 46;
    if (value === "23:00") drUpdate.saturdayEnd = 47;
    if (value === "23:30") drUpdate.saturdayEnd = 48;
  }
  function handleMondayStart(value) {
    if (value === "00:00") drUpdate.mondayStart = 1;
    if (value === "00:30") drUpdate.mondayStart = 2;
    if (value === "01:00") drUpdate.mondayStart = 3;
    if (value === "01:30") drUpdate.mondayStart = 4;
    if (value === "02:00") drUpdate.mondayStart = 5;
    if (value === "02:30") drUpdate.mondayStart = 6;
    if (value === "03:00") drUpdate.mondayStart = 7;
    if (value === "03:30") drUpdate.mondayStart = 8;
    if (value === "04:00") drUpdate.mondayStart = 9;
    if (value === "04:30") drUpdate.mondayStart = 10;
    if (value === "05:00") drUpdate.mondayStart = 11;
    if (value === "05:30") drUpdate.mondayStart = 12;
    if (value === "06:00") drUpdate.mondayStart = 13;
    if (value === "06:30") drUpdate.mondayStart = 14;
    if (value === "07:00") drUpdate.mondayStart = 15;
    if (value === "07:30") drUpdate.mondayStart = 16;
    if (value === "08:00") drUpdate.mondayStart = 17;
    if (value === "08:30") drUpdate.mondayStart = 18;
    if (value === "09:00") drUpdate.mondayStart = 19;
    if (value === "09:30") drUpdate.mondayStart = 20;
    if (value === "10:00") drUpdate.mondayStart = 21;
    if (value === "10:30") drUpdate.mondayStart = 22;
    if (value === "11:00") drUpdate.mondayStart = 23;
    if (value === "11:30") drUpdate.mondayStart = 24;
    if (value === "12:00") drUpdate.mondayStart = 25;
    if (value === "12:30") drUpdate.mondayStart = 26;
    if (value === "13:00") drUpdate.mondayStart = 27;
    if (value === "13:30") drUpdate.mondayStart = 28;
    if (value === "14:00") drUpdate.mondayStart = 29;
    if (value === "14:30") drUpdate.mondayStart = 30;
    if (value === "15:00") drUpdate.mondayStart = 31;
    if (value === "15:30") drUpdate.mondayStart = 32;
    if (value === "16:00") drUpdate.mondayStart = 33;
    if (value === "16:30") drUpdate.mondayStart = 34;
    if (value === "17:00") drUpdate.mondayStart = 35;
    if (value === "17:30") drUpdate.mondayStart = 36;
    if (value === "18:00") drUpdate.mondayStart = 37;
    if (value === "18:30") drUpdate.mondayStart = 38;
    if (value === "19:00") drUpdate.mondayStart = 39;
    if (value === "19:30") drUpdate.mondayStart = 40;
    if (value === "20:00") drUpdate.mondayStart = 41;
    if (value === "20:30") drUpdate.mondayStart = 42;
    if (value === "21:00") drUpdate.mondayStart = 43;
    if (value === "21:30") drUpdate.mondayStart = 44;
    if (value === "22:00") drUpdate.mondayStart = 45;
    if (value === "22:30") drUpdate.mondayStart = 46;
    if (value === "23:00") drUpdate.mondayStart = 47;
    if (value === "23:30") drUpdate.mondayStart = 48;
  }
  function handleMondayEnd(value) {
    if (value === "00:00") drUpdate.mondayEnd = 1;
    if (value === "00:30") drUpdate.mondayEnd = 2;
    if (value === "01:00") drUpdate.mondayEnd = 3;
    if (value === "01:30") drUpdate.mondayEnd = 4;
    if (value === "02:00") drUpdate.mondayEnd = 5;
    if (value === "02:30") drUpdate.mondayEnd = 6;
    if (value === "03:00") drUpdate.mondayEnd = 7;
    if (value === "03:30") drUpdate.mondayEnd = 8;
    if (value === "04:00") drUpdate.mondayEnd = 9;
    if (value === "04:30") drUpdate.mondayEnd = 10;
    if (value === "05:00") drUpdate.mondayEnd = 11;
    if (value === "05:30") drUpdate.mondayEnd = 12;
    if (value === "06:00") drUpdate.mondayEnd = 13;
    if (value === "06:30") drUpdate.mondayEnd = 14;
    if (value === "07:00") drUpdate.mondayEnd = 15;
    if (value === "07:30") drUpdate.mondayEnd = 16;
    if (value === "08:00") drUpdate.mondayEnd = 17;
    if (value === "08:30") drUpdate.mondayEnd = 18;
    if (value === "09:00") drUpdate.mondayEnd = 19;
    if (value === "09:30") drUpdate.mondayEnd = 20;
    if (value === "10:00") drUpdate.mondayEnd = 21;
    if (value === "10:30") drUpdate.mondayEnd = 22;
    if (value === "11:00") drUpdate.mondayEnd = 23;
    if (value === "11:30") drUpdate.mondayEnd = 24;
    if (value === "12:00") drUpdate.mondayEnd = 25;
    if (value === "12:30") drUpdate.mondayEnd = 26;
    if (value === "13:00") drUpdate.mondayEnd = 27;
    if (value === "13:30") drUpdate.mondayEnd = 28;
    if (value === "14:00") drUpdate.mondayEnd = 29;
    if (value === "14:30") drUpdate.mondayEnd = 30;
    if (value === "15:00") drUpdate.mondayEnd = 31;
    if (value === "15:30") drUpdate.mondayEnd = 32;
    if (value === "16:00") drUpdate.mondayEnd = 33;
    if (value === "16:30") drUpdate.mondayEnd = 34;
    if (value === "17:00") drUpdate.mondayEnd = 35;
    if (value === "17:30") drUpdate.mondayEnd = 36;
    if (value === "18:00") drUpdate.mondayEnd = 37;
    if (value === "18:30") drUpdate.mondayEnd = 38;
    if (value === "19:00") drUpdate.mondayEnd = 39;
    if (value === "19:30") drUpdate.mondayEnd = 40;
    if (value === "20:00") drUpdate.mondayEnd = 41;
    if (value === "20:30") drUpdate.mondayEnd = 42;
    if (value === "21:00") drUpdate.mondayEnd = 43;
    if (value === "21:30") drUpdate.mondayEnd = 44;
    if (value === "22:00") drUpdate.mondayEnd = 45;
    if (value === "22:30") drUpdate.mondayEnd = 46;
    if (value === "23:00") drUpdate.mondayEnd = 47;
    if (value === "23:30") drUpdate.mondayEnd = 48;
  }

  return (
    <div>
      <Container style={{ width: "95%" }} className="containerStyle">
        <Form className="my-4">
          <Row>
            <Col className="my-5">
              <Row>
                <Col style={{ textAlign: "center" }}>
                  <div
                    style={{
                      border: "1px solid grey",
                      width: "13.1rem",
                      position: "relative",
                      left: "3rem",
                      bottom:"1rem",
                      height:"1.6rem",
                      borderRadius:"3px"
                    }}
                  >
                    <Form>
                      {drUpdate.isActive === 1 && (
                        <Form.Check
                          style={{
                            width: "3rem",
                            marginLeft: "4rem",
                            marginBottom: "1rem",
                          }}
                          type="switch"
                          id="custom-switch"
                          label="Aktif"
                          name="isActive"
                          onClick={() => handleStatus(false)}
                          checked
                        />
                      )}
                      {drUpdate.isActive === 2 && (
                        <Form.Check
                          style={{
                            width: "3rem",
                            marginLeft: "4rem",
                            marginBottom: "1rem",
                          }}
                          type="switch"
                          id="custom-switch"
                          label="Pasif"
                          name="isActive"
                          onClick={() => handleStatus(true)}
                        />
                      )}
                    </Form>
                  </div>
                  <TextField
                    name="fullName"
                    className="my-1"
                    onChange={handleInputChange}
                    value={drUpdate.fullName}
                    label="İsim Soyisim"
                  />
                  <TextField
                    className="my-1"
                    label="Email"
                    name="email"
                    onChange={handleInputChange}
                    value={drUpdate.email}
                  />
                  <TextField
                    className="my-1"
                    onChange={handleInputChange}
                    label="Telefon Numarası"
                    name="phoneNumber"
                    value={drUpdate.phoneNumber}
                  />
                  <TextField
                    className="my-1"
                    label="Branş"
                    onChange={handleInputChange}
                    name="branch"
                    value={drUpdate.branch}
                  />
                  <TextField
                    className="my-1"
                    label="Komisyon Yüzdesi %"
                    onChange={handleInputChange}
                    name="commissionPercentage"
                    value={drUpdate.commissionPercentage}
                  />
                </Col>
              </Row>
            </Col>
            <Col style={{ marginTop: "4.5rem" }}>
              <div className="daySelectWrapper">
                <div className="doctorDateHeader">
                  <span className="doctorDateHeaderDay">Gün Seçimi</span>
                  <span className="doctorDateHeaderStart">Başlangıç Saati</span>
                  <span className="doctorDateHeaderEnd">Bitiş Saati</span>
                </div>
                <div className="daysMenuBody">
                  <div className="doctorsTimeDays">
                    <Form>
                      {drUpdate.sunday === true && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Pazartesi"
                          onClick={() => (drUpdate.sunday = false)}
                          checked
                        />
                      )}
                      {drUpdate.sunday === false && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Pazartesi"
                          onClick={() => (drUpdate.sunday = true)}
                        />
                      )}
                      {drUpdate.tuesday === true && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Salı"
                          onClick={() => (drUpdate.tuesday = false)}
                          checked
                        />
                      )}
                      {drUpdate.tuesday === false && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Salı"
                          onClick={() => (drUpdate.tuesday = true)}
                        />
                      )}
                      {drUpdate.wednesday === true && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Çarşamba"
                          onClick={() => (drUpdate.wednesday = false)}
                          checked
                        />
                      )}
                      {drUpdate.wednesday === false && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Çarşamba"
                          onClick={() => (drUpdate.wednesday = true)}
                        />
                      )}
                      {drUpdate.thursday === true && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Perşembe"
                          onClick={() => (drUpdate.thursday = false)}
                          checked
                        />
                      )}
                      {drUpdate.thursday === false && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Perşembe"
                          onClick={() => (drUpdate.thursday = true)}
                        />
                      )}
                      {drUpdate.friday === true && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Cuma"
                          onClick={() => (drUpdate.friday = false)}
                          checked
                        />
                      )}
                      {drUpdate.friday === false && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Cuma"
                          onClick={() => (drUpdate.friday = true)}
                        />
                      )}
                      {drUpdate.saturday === true && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Cumartesi"
                          onClick={() => (drUpdate.saturday = false)}
                          checked
                        />
                      )}
                      {drUpdate.saturday === false && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Cumartesi"
                          onClick={() => (drUpdate.saturday = true)}
                        />
                      )}
                      {drUpdate.monday === true && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Pazar"
                          onClick={() => (drUpdate.monday = false)}
                          checked
                        />
                      )}
                      {drUpdate.monday === false && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Pazar"
                          onClick={() => (drUpdate.monday = true)}
                        />
                      )}
                    </Form>
                  </div>
                  <div className="doctorsTimeStart">
                    <select onChange={(e) => handleSundayStart(e.target.value)}>
                      {drUpdate.sundayStart === 1 && <option>00:00</option>}
                      {drUpdate.sundayStart === 2 && <option>00:30</option>}
                      {drUpdate.sundayStart === 3 && <option>01:00</option>}
                      {drUpdate.sundayStart === 4 && <option>01:30</option>}
                      {drUpdate.sundayStart === 5 && <option>02:00</option>}
                      {drUpdate.sundayStart === 6 && <option>02:30</option>}
                      {drUpdate.sundayStart === 7 && <option>03:00</option>}
                      {drUpdate.sundayStart === 8 && <option>03:30</option>}
                      {drUpdate.sundayStart === 9 && <option>04:00</option>}
                      {drUpdate.sundayStart === 10 && <option>04:30</option>}
                      {drUpdate.sundayStart === 11 && <option>05:00</option>}
                      {drUpdate.sundayStart === 12 && <option>05:30</option>}
                      {drUpdate.sundayStart === 13 && <option>06:00</option>}
                      {drUpdate.sundayStart === 14 && <option>06:30</option>}
                      {drUpdate.sundayStart === 15 && <option>07:00</option>}
                      {drUpdate.sundayStart === 16 && <option>07:30</option>}
                      {drUpdate.sundayStart === 17 && <option>08:00</option>}
                      {drUpdate.sundayStart === 18 && <option>08:30</option>}
                      {drUpdate.sundayStart === 19 && <option>09:00</option>}
                      {drUpdate.sundayStart === 20 && <option>09:30</option>}
                      {drUpdate.sundayStart === 21 && <option>10:00</option>}
                      {drUpdate.sundayStart === 22 && <option>10:30</option>}
                      {drUpdate.sundayStart === 23 && <option>11:00</option>}
                      {drUpdate.sundayStart === 24 && <option>11:30</option>}
                      {drUpdate.sundayStart === 25 && <option>12:00</option>}
                      {drUpdate.sundayStart === 26 && <option>12:30</option>}
                      {drUpdate.sundayStart === 27 && <option>13:00</option>}
                      {drUpdate.sundayStart === 28 && <option>13:30</option>}
                      {drUpdate.sundayStart === 29 && <option>14:00</option>}
                      {drUpdate.sundayStart === 30 && <option>14:30</option>}
                      {drUpdate.sundayStart === 31 && <option>15:00</option>}
                      {drUpdate.sundayStart === 32 && <option>15:30</option>}
                      {drUpdate.sundayStart === 33 && <option>16:00</option>}
                      {drUpdate.sundayStart === 34 && <option>16:30</option>}
                      {drUpdate.sundayStart === 35 && <option>17:00</option>}
                      {drUpdate.sundayStart === 36 && <option>17:30</option>}
                      {drUpdate.sundayStart === 37 && <option>18:00</option>}
                      {drUpdate.sundayStart === 38 && <option>18:30</option>}
                      {drUpdate.sundayStart === 39 && <option>19:00</option>}
                      {drUpdate.sundayStart === 40 && <option>19:30</option>}
                      {drUpdate.sundayStart === 41 && <option>20:00</option>}
                      {drUpdate.sundayStart === 42 && <option>20:30</option>}
                      {drUpdate.sundayStart === 43 && <option>21:00</option>}
                      {drUpdate.sundayStart === 44 && <option>21:30</option>}
                      {drUpdate.sundayStart === 45 && <option>22:00</option>}
                      {drUpdate.sundayStart === 46 && <option>22:30</option>}
                      {drUpdate.sundayStart === 47 && <option>23:00</option>}
                      {drUpdate.sundayStart === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                    <select
                      onChange={(e) => handleTuesdayStart(e.target.value)}
                    >
                      {drUpdate.tuesdayStart === 1 && <option>00:00</option>}
                      {drUpdate.tuesdayStart === 2 && <option>00:30</option>}
                      {drUpdate.tuesdayStart === 3 && <option>01:00</option>}
                      {drUpdate.tuesdayStart === 4 && <option>01:30</option>}
                      {drUpdate.tuesdayStart === 5 && <option>02:00</option>}
                      {drUpdate.tuesdayStart === 6 && <option>02:30</option>}
                      {drUpdate.tuesdayStart === 7 && <option>03:00</option>}
                      {drUpdate.tuesdayStart === 8 && <option>03:30</option>}
                      {drUpdate.tuesdayStart === 9 && <option>04:00</option>}
                      {drUpdate.tuesdayStart === 10 && <option>04:30</option>}
                      {drUpdate.tuesdayStart === 11 && <option>05:00</option>}
                      {drUpdate.tuesdayStart === 12 && <option>05:30</option>}
                      {drUpdate.tuesdayStart === 13 && <option>06:00</option>}
                      {drUpdate.tuesdayStart === 14 && <option>06:30</option>}
                      {drUpdate.tuesdayStart === 15 && <option>07:00</option>}
                      {drUpdate.tuesdayStart === 16 && <option>07:30</option>}
                      {drUpdate.tuesdayStart === 17 && <option>08:00</option>}
                      {drUpdate.tuesdayStart === 18 && <option>08:30</option>}
                      {drUpdate.tuesdayStart === 19 && <option>09:00</option>}
                      {drUpdate.tuesdayStart === 20 && <option>09:30</option>}
                      {drUpdate.tuesdayStart === 21 && <option>10:00</option>}
                      {drUpdate.tuesdayStart === 22 && <option>10:30</option>}
                      {drUpdate.tuesdayStart === 23 && <option>11:00</option>}
                      {drUpdate.tuesdayStart === 24 && <option>11:30</option>}
                      {drUpdate.tuesdayStart === 25 && <option>12:00</option>}
                      {drUpdate.tuesdayStart === 26 && <option>12:30</option>}
                      {drUpdate.tuesdayStart === 27 && <option>13:00</option>}
                      {drUpdate.tuesdayStart === 28 && <option>13:30</option>}
                      {drUpdate.tuesdayStart === 29 && <option>14:00</option>}
                      {drUpdate.tuesdayStart === 30 && <option>14:30</option>}
                      {drUpdate.tuesdayStart === 31 && <option>15:00</option>}
                      {drUpdate.tuesdayStart === 32 && <option>15:30</option>}
                      {drUpdate.tuesdayStart === 33 && <option>16:00</option>}
                      {drUpdate.tuesdayStart === 34 && <option>16:30</option>}
                      {drUpdate.tuesdayStart === 35 && <option>17:00</option>}
                      {drUpdate.tuesdayStart === 36 && <option>17:30</option>}
                      {drUpdate.tuesdayStart === 37 && <option>18:00</option>}
                      {drUpdate.tuesdayStart === 38 && <option>18:30</option>}
                      {drUpdate.tuesdayStart === 39 && <option>19:00</option>}
                      {drUpdate.tuesdayStart === 40 && <option>19:30</option>}
                      {drUpdate.tuesdayStart === 41 && <option>20:00</option>}
                      {drUpdate.tuesdayStart === 42 && <option>20:30</option>}
                      {drUpdate.tuesdayStart === 43 && <option>21:00</option>}
                      {drUpdate.tuesdayStart === 44 && <option>21:30</option>}
                      {drUpdate.tuesdayStart === 45 && <option>22:00</option>}
                      {drUpdate.tuesdayStart === 46 && <option>22:30</option>}
                      {drUpdate.tuesdayStart === 47 && <option>23:00</option>}
                      {drUpdate.tuesdayStart === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                    <select
                      onChange={(e) => handleWednesdayStart(e.target.value)}
                    >
                      {drUpdate.wednesdayStart === 1 && <option>00:00</option>}
                      {drUpdate.wednesdayStart === 2 && <option>00:30</option>}
                      {drUpdate.wednesdayStart === 3 && <option>01:00</option>}
                      {drUpdate.wednesdayStart === 4 && <option>01:30</option>}
                      {drUpdate.wednesdayStart === 5 && <option>02:00</option>}
                      {drUpdate.wednesdayStart === 6 && <option>02:30</option>}
                      {drUpdate.wednesdayStart === 7 && <option>03:00</option>}
                      {drUpdate.wednesdayStart === 8 && <option>03:30</option>}
                      {drUpdate.wednesdayStart === 9 && <option>04:00</option>}
                      {drUpdate.wednesdayStart === 10 && <option>04:30</option>}
                      {drUpdate.wednesdayStart === 11 && <option>05:00</option>}
                      {drUpdate.wednesdayStart === 12 && <option>05:30</option>}
                      {drUpdate.wednesdayStart === 13 && <option>06:00</option>}
                      {drUpdate.wednesdayStart === 14 && <option>06:30</option>}
                      {drUpdate.wednesdayStart === 15 && <option>07:00</option>}
                      {drUpdate.wednesdayStart === 16 && <option>07:30</option>}
                      {drUpdate.wednesdayStart === 17 && <option>08:00</option>}
                      {drUpdate.wednesdayStart === 18 && <option>08:30</option>}
                      {drUpdate.wednesdayStart === 19 && <option>09:00</option>}
                      {drUpdate.wednesdayStart === 20 && <option>09:30</option>}
                      {drUpdate.wednesdayStart === 21 && <option>10:00</option>}
                      {drUpdate.wednesdayStart === 22 && <option>10:30</option>}
                      {drUpdate.wednesdayStart === 23 && <option>11:00</option>}
                      {drUpdate.wednesdayStart === 24 && <option>11:30</option>}
                      {drUpdate.wednesdayStart === 25 && <option>12:00</option>}
                      {drUpdate.wednesdayStart === 26 && <option>12:30</option>}
                      {drUpdate.wednesdayStart === 27 && <option>13:00</option>}
                      {drUpdate.wednesdayStart === 28 && <option>13:30</option>}
                      {drUpdate.wednesdayStart === 29 && <option>14:00</option>}
                      {drUpdate.wednesdayStart === 30 && <option>14:30</option>}
                      {drUpdate.wednesdayStart === 31 && <option>15:00</option>}
                      {drUpdate.wednesdayStart === 32 && <option>15:30</option>}
                      {drUpdate.wednesdayStart === 33 && <option>16:00</option>}
                      {drUpdate.wednesdayStart === 34 && <option>16:30</option>}
                      {drUpdate.wednesdayStart === 35 && <option>17:00</option>}
                      {drUpdate.wednesdayStart === 36 && <option>17:30</option>}
                      {drUpdate.wednesdayStart === 37 && <option>18:00</option>}
                      {drUpdate.wednesdayStart === 38 && <option>18:30</option>}
                      {drUpdate.wednesdayStart === 39 && <option>19:00</option>}
                      {drUpdate.wednesdayStart === 40 && <option>19:30</option>}
                      {drUpdate.wednesdayStart === 41 && <option>20:00</option>}
                      {drUpdate.wednesdayStart === 42 && <option>20:30</option>}
                      {drUpdate.wednesdayStart === 43 && <option>21:00</option>}
                      {drUpdate.wednesdayStart === 44 && <option>21:30</option>}
                      {drUpdate.wednesdayStart === 45 && <option>22:00</option>}
                      {drUpdate.wednesdayStart === 46 && <option>22:30</option>}
                      {drUpdate.wednesdayStart === 47 && <option>23:00</option>}
                      {drUpdate.wednesdayStart === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                    <select
                      onChange={(e) => handleThursdayStart(e.target.value)}
                    >
                      {drUpdate.thursdayStart === 1 && <option>00:00</option>}
                      {drUpdate.thursdayStart === 2 && <option>00:30</option>}
                      {drUpdate.thursdayStart === 3 && <option>01:00</option>}
                      {drUpdate.thursdayStart === 4 && <option>01:30</option>}
                      {drUpdate.thursdayStart === 5 && <option>02:00</option>}
                      {drUpdate.thursdayStart === 6 && <option>02:30</option>}
                      {drUpdate.thursdayStart === 7 && <option>03:00</option>}
                      {drUpdate.thursdayStart === 8 && <option>03:30</option>}
                      {drUpdate.thursdayStart === 9 && <option>04:00</option>}
                      {drUpdate.thursdayStart === 10 && <option>04:30</option>}
                      {drUpdate.thursdayStart === 11 && <option>05:00</option>}
                      {drUpdate.thursdayStart === 12 && <option>05:30</option>}
                      {drUpdate.thursdayStart === 13 && <option>06:00</option>}
                      {drUpdate.thursdayStart === 14 && <option>06:30</option>}
                      {drUpdate.thursdayStart === 15 && <option>07:00</option>}
                      {drUpdate.thursdayStart === 16 && <option>07:30</option>}
                      {drUpdate.thursdayStart === 17 && <option>08:00</option>}
                      {drUpdate.thursdayStart === 18 && <option>08:30</option>}
                      {drUpdate.thursdayStart === 19 && <option>09:00</option>}
                      {drUpdate.thursdayStart === 20 && <option>09:30</option>}
                      {drUpdate.thursdayStart === 21 && <option>10:00</option>}
                      {drUpdate.thursdayStart === 22 && <option>10:30</option>}
                      {drUpdate.thursdayStart === 23 && <option>11:00</option>}
                      {drUpdate.thursdayStart === 24 && <option>11:30</option>}
                      {drUpdate.thursdayStart === 25 && <option>12:00</option>}
                      {drUpdate.thursdayStart === 26 && <option>12:30</option>}
                      {drUpdate.thursdayStart === 27 && <option>13:00</option>}
                      {drUpdate.thursdayStart === 28 && <option>13:30</option>}
                      {drUpdate.thursdayStart === 29 && <option>14:00</option>}
                      {drUpdate.thursdayStart === 30 && <option>14:30</option>}
                      {drUpdate.thursdayStart === 31 && <option>15:00</option>}
                      {drUpdate.thursdayStart === 32 && <option>15:30</option>}
                      {drUpdate.thursdayStart === 33 && <option>16:00</option>}
                      {drUpdate.thursdayStart === 34 && <option>16:30</option>}
                      {drUpdate.thursdayStart === 35 && <option>17:00</option>}
                      {drUpdate.thursdayStart === 36 && <option>17:30</option>}
                      {drUpdate.thursdayStart === 37 && <option>18:00</option>}
                      {drUpdate.thursdayStart === 38 && <option>18:30</option>}
                      {drUpdate.thursdayStart === 39 && <option>19:00</option>}
                      {drUpdate.thursdayStart === 40 && <option>19:30</option>}
                      {drUpdate.thursdayStart === 41 && <option>20:00</option>}
                      {drUpdate.thursdayStart === 42 && <option>20:30</option>}
                      {drUpdate.thursdayStart === 43 && <option>21:00</option>}
                      {drUpdate.thursdayStart === 44 && <option>21:30</option>}
                      {drUpdate.thursdayStart === 45 && <option>22:00</option>}
                      {drUpdate.thursdayStart === 46 && <option>22:30</option>}
                      {drUpdate.thursdayStart === 47 && <option>23:00</option>}
                      {drUpdate.thursdayStart === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                    <select onChange={(e) => handleFridayStart(e.target.value)}>
                      {drUpdate.fridayStart === 1 && <option>00:00</option>}
                      {drUpdate.fridayStart === 2 && <option>00:30</option>}
                      {drUpdate.fridayStart === 3 && <option>01:00</option>}
                      {drUpdate.fridayStart === 4 && <option>01:30</option>}
                      {drUpdate.fridayStart === 5 && <option>02:00</option>}
                      {drUpdate.fridayStart === 6 && <option>02:30</option>}
                      {drUpdate.fridayStart === 7 && <option>03:00</option>}
                      {drUpdate.fridayStart === 8 && <option>03:30</option>}
                      {drUpdate.fridayStart === 9 && <option>04:00</option>}
                      {drUpdate.fridayStart === 10 && <option>04:30</option>}
                      {drUpdate.fridayStart === 11 && <option>05:00</option>}
                      {drUpdate.fridayStart === 12 && <option>05:30</option>}
                      {drUpdate.fridayStart === 13 && <option>06:00</option>}
                      {drUpdate.fridayStart === 14 && <option>06:30</option>}
                      {drUpdate.fridayStart === 15 && <option>07:00</option>}
                      {drUpdate.fridayStart === 16 && <option>07:30</option>}
                      {drUpdate.fridayStart === 17 && <option>08:00</option>}
                      {drUpdate.fridayStart === 18 && <option>08:30</option>}
                      {drUpdate.fridayStart === 19 && <option>09:00</option>}
                      {drUpdate.fridayStart === 20 && <option>09:30</option>}
                      {drUpdate.fridayStart === 21 && <option>10:00</option>}
                      {drUpdate.fridayStart === 22 && <option>10:30</option>}
                      {drUpdate.fridayStart === 23 && <option>11:00</option>}
                      {drUpdate.fridayStart === 24 && <option>11:30</option>}
                      {drUpdate.fridayStart === 25 && <option>12:00</option>}
                      {drUpdate.fridayStart === 26 && <option>12:30</option>}
                      {drUpdate.fridayStart === 27 && <option>13:00</option>}
                      {drUpdate.fridayStart === 28 && <option>13:30</option>}
                      {drUpdate.fridayStart === 29 && <option>14:00</option>}
                      {drUpdate.fridayStart === 30 && <option>14:30</option>}
                      {drUpdate.fridayStart === 31 && <option>15:00</option>}
                      {drUpdate.fridayStart === 32 && <option>15:30</option>}
                      {drUpdate.fridayStart === 33 && <option>16:00</option>}
                      {drUpdate.fridayStart === 34 && <option>16:30</option>}
                      {drUpdate.fridayStart === 35 && <option>17:00</option>}
                      {drUpdate.fridayStart === 36 && <option>17:30</option>}
                      {drUpdate.fridayStart === 37 && <option>18:00</option>}
                      {drUpdate.fridayStart === 38 && <option>18:30</option>}
                      {drUpdate.fridayStart === 39 && <option>19:00</option>}
                      {drUpdate.fridayStart === 40 && <option>19:30</option>}
                      {drUpdate.fridayStart === 41 && <option>20:00</option>}
                      {drUpdate.fridayStart === 42 && <option>20:30</option>}
                      {drUpdate.fridayStart === 43 && <option>21:00</option>}
                      {drUpdate.fridayStart === 44 && <option>21:30</option>}
                      {drUpdate.fridayStart === 45 && <option>22:00</option>}
                      {drUpdate.fridayStart === 46 && <option>22:30</option>}
                      {drUpdate.fridayStart === 47 && <option>23:00</option>}
                      {drUpdate.fridayStart === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                    <select
                      onChange={(e) => handleSaturdayStart(e.target.value)}
                    >
                      {drUpdate.saturdayStart === 1 && <option>00:00</option>}
                      {drUpdate.saturdayStart === 2 && <option>00:30</option>}
                      {drUpdate.saturdayStart === 3 && <option>01:00</option>}
                      {drUpdate.saturdayStart === 4 && <option>01:30</option>}
                      {drUpdate.saturdayStart === 5 && <option>02:00</option>}
                      {drUpdate.saturdayStart === 6 && <option>02:30</option>}
                      {drUpdate.saturdayStart === 7 && <option>03:00</option>}
                      {drUpdate.saturdayStart === 8 && <option>03:30</option>}
                      {drUpdate.saturdayStart === 9 && <option>04:00</option>}
                      {drUpdate.saturdayStart === 10 && <option>04:30</option>}
                      {drUpdate.saturdayStart === 11 && <option>05:00</option>}
                      {drUpdate.saturdayStart === 12 && <option>05:30</option>}
                      {drUpdate.saturdayStart === 13 && <option>06:00</option>}
                      {drUpdate.saturdayStart === 14 && <option>06:30</option>}
                      {drUpdate.saturdayStart === 15 && <option>07:00</option>}
                      {drUpdate.saturdayStart === 16 && <option>07:30</option>}
                      {drUpdate.saturdayStart === 17 && <option>08:00</option>}
                      {drUpdate.saturdayStart === 18 && <option>08:30</option>}
                      {drUpdate.saturdayStart === 19 && <option>09:00</option>}
                      {drUpdate.saturdayStart === 20 && <option>09:30</option>}
                      {drUpdate.saturdayStart === 21 && <option>10:00</option>}
                      {drUpdate.saturdayStart === 22 && <option>10:30</option>}
                      {drUpdate.saturdayStart === 23 && <option>11:00</option>}
                      {drUpdate.saturdayStart === 24 && <option>11:30</option>}
                      {drUpdate.saturdayStart === 25 && <option>12:00</option>}
                      {drUpdate.saturdayStart === 26 && <option>12:30</option>}
                      {drUpdate.saturdayStart === 27 && <option>13:00</option>}
                      {drUpdate.saturdayStart === 28 && <option>13:30</option>}
                      {drUpdate.saturdayStart === 29 && <option>14:00</option>}
                      {drUpdate.saturdayStart === 30 && <option>14:30</option>}
                      {drUpdate.saturdayStart === 31 && <option>15:00</option>}
                      {drUpdate.saturdayStart === 32 && <option>15:30</option>}
                      {drUpdate.saturdayStart === 33 && <option>16:00</option>}
                      {drUpdate.saturdayStart === 34 && <option>16:30</option>}
                      {drUpdate.saturdayStart === 35 && <option>17:00</option>}
                      {drUpdate.saturdayStart === 36 && <option>17:30</option>}
                      {drUpdate.saturdayStart === 37 && <option>18:00</option>}
                      {drUpdate.saturdayStart === 38 && <option>18:30</option>}
                      {drUpdate.saturdayStart === 39 && <option>19:00</option>}
                      {drUpdate.saturdayStart === 40 && <option>19:30</option>}
                      {drUpdate.saturdayStart === 41 && <option>20:00</option>}
                      {drUpdate.saturdayStart === 42 && <option>20:30</option>}
                      {drUpdate.saturdayStart === 43 && <option>21:00</option>}
                      {drUpdate.saturdayStart === 44 && <option>21:30</option>}
                      {drUpdate.saturdayStart === 45 && <option>22:00</option>}
                      {drUpdate.saturdayStart === 46 && <option>22:30</option>}
                      {drUpdate.saturdayStart === 47 && <option>23:00</option>}
                      {drUpdate.saturdayStart === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                    <select onChange={(e) => handleMondayStart(e.target.value)}>
                      {drUpdate.mondayStart === 1 && <option>00:00</option>}
                      {drUpdate.mondayStart === 2 && <option>00:30</option>}
                      {drUpdate.mondayStart === 3 && <option>01:00</option>}
                      {drUpdate.mondayStart === 4 && <option>01:30</option>}
                      {drUpdate.mondayStart === 5 && <option>02:00</option>}
                      {drUpdate.mondayStart === 6 && <option>02:30</option>}
                      {drUpdate.mondayStart === 7 && <option>03:00</option>}
                      {drUpdate.mondayStart === 8 && <option>03:30</option>}
                      {drUpdate.mondayStart === 9 && <option>04:00</option>}
                      {drUpdate.mondayStart === 10 && <option>04:30</option>}
                      {drUpdate.mondayStart === 11 && <option>05:00</option>}
                      {drUpdate.mondayStart === 12 && <option>05:30</option>}
                      {drUpdate.mondayStart === 13 && <option>06:00</option>}
                      {drUpdate.mondayStart === 14 && <option>06:30</option>}
                      {drUpdate.mondayStart === 15 && <option>07:00</option>}
                      {drUpdate.mondayStart === 16 && <option>07:30</option>}
                      {drUpdate.mondayStart === 17 && <option>08:00</option>}
                      {drUpdate.mondayStart === 18 && <option>08:30</option>}
                      {drUpdate.mondayStart === 19 && <option>09:00</option>}
                      {drUpdate.mondayStart === 20 && <option>09:30</option>}
                      {drUpdate.mondayStart === 21 && <option>10:00</option>}
                      {drUpdate.mondayStart === 22 && <option>10:30</option>}
                      {drUpdate.mondayStart === 23 && <option>11:00</option>}
                      {drUpdate.mondayStart === 24 && <option>11:30</option>}
                      {drUpdate.mondayStart === 25 && <option>12:00</option>}
                      {drUpdate.mondayStart === 26 && <option>12:30</option>}
                      {drUpdate.mondayStart === 27 && <option>13:00</option>}
                      {drUpdate.mondayStart === 28 && <option>13:30</option>}
                      {drUpdate.mondayStart === 29 && <option>14:00</option>}
                      {drUpdate.mondayStart === 30 && <option>14:30</option>}
                      {drUpdate.mondayStart === 31 && <option>15:00</option>}
                      {drUpdate.mondayStart === 32 && <option>15:30</option>}
                      {drUpdate.mondayStart === 33 && <option>16:00</option>}
                      {drUpdate.mondayStart === 34 && <option>16:30</option>}
                      {drUpdate.mondayStart === 35 && <option>17:00</option>}
                      {drUpdate.mondayStart === 36 && <option>17:30</option>}
                      {drUpdate.mondayStart === 37 && <option>18:00</option>}
                      {drUpdate.mondayStart === 38 && <option>18:30</option>}
                      {drUpdate.mondayStart === 39 && <option>19:00</option>}
                      {drUpdate.mondayStart === 40 && <option>19:30</option>}
                      {drUpdate.mondayStart === 41 && <option>20:00</option>}
                      {drUpdate.mondayStart === 42 && <option>20:30</option>}
                      {drUpdate.mondayStart === 43 && <option>21:00</option>}
                      {drUpdate.mondayStart === 44 && <option>21:30</option>}
                      {drUpdate.mondayStart === 45 && <option>22:00</option>}
                      {drUpdate.mondayStart === 46 && <option>22:30</option>}
                      {drUpdate.mondayStart === 47 && <option>23:00</option>}
                      {drUpdate.mondayStart === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                  </div>
                  <div className="doctorsTimeEnd">
                    <select onChange={(e) => handleSundayEnd(e.target.value)}>
                      {drUpdate.sundayEnd === 1 && <option>00:00</option>}
                      {drUpdate.sundayEnd === 2 && <option>00:30</option>}
                      {drUpdate.sundayEnd === 3 && <option>01:00</option>}
                      {drUpdate.sundayEnd === 4 && <option>01:30</option>}
                      {drUpdate.sundayEnd === 5 && <option>02:00</option>}
                      {drUpdate.sundayEnd === 6 && <option>02:30</option>}
                      {drUpdate.sundayEnd === 7 && <option>03:00</option>}
                      {drUpdate.sundayEnd === 8 && <option>03:30</option>}
                      {drUpdate.sundayEnd === 9 && <option>04:00</option>}
                      {drUpdate.sundayEnd === 10 && <option>04:30</option>}
                      {drUpdate.sundayEnd === 11 && <option>05:00</option>}
                      {drUpdate.sundayEnd === 12 && <option>05:30</option>}
                      {drUpdate.sundayEnd === 13 && <option>06:00</option>}
                      {drUpdate.sundayEnd === 14 && <option>06:30</option>}
                      {drUpdate.sundayEnd === 15 && <option>07:00</option>}
                      {drUpdate.sundayEnd === 16 && <option>07:30</option>}
                      {drUpdate.sundayEnd === 17 && <option>08:00</option>}
                      {drUpdate.sundayEnd === 18 && <option>08:30</option>}
                      {drUpdate.sundayEnd === 19 && <option>09:00</option>}
                      {drUpdate.sundayEnd === 20 && <option>09:30</option>}
                      {drUpdate.sundayEnd === 21 && <option>10:00</option>}
                      {drUpdate.sundayEnd === 22 && <option>10:30</option>}
                      {drUpdate.sundayEnd === 23 && <option>11:00</option>}
                      {drUpdate.sundayEnd === 24 && <option>11:30</option>}
                      {drUpdate.sundayEnd === 25 && <option>12:00</option>}
                      {drUpdate.sundayEnd === 26 && <option>12:30</option>}
                      {drUpdate.sundayEnd === 27 && <option>13:00</option>}
                      {drUpdate.sundayEnd === 28 && <option>13:30</option>}
                      {drUpdate.sundayEnd === 29 && <option>14:00</option>}
                      {drUpdate.sundayEnd === 30 && <option>14:30</option>}
                      {drUpdate.sundayEnd === 31 && <option>15:00</option>}
                      {drUpdate.sundayEnd === 32 && <option>15:30</option>}
                      {drUpdate.sundayEnd === 33 && <option>16:00</option>}
                      {drUpdate.sundayEnd === 34 && <option>16:30</option>}
                      {drUpdate.sundayEnd === 35 && <option>17:00</option>}
                      {drUpdate.sundayEnd === 36 && <option>17:30</option>}
                      {drUpdate.sundayEnd === 37 && <option>18:00</option>}
                      {drUpdate.sundayEnd === 38 && <option>18:30</option>}
                      {drUpdate.sundayEnd === 39 && <option>19:00</option>}
                      {drUpdate.sundayEnd === 40 && <option>19:30</option>}
                      {drUpdate.sundayEnd === 41 && <option>20:00</option>}
                      {drUpdate.sundayEnd === 42 && <option>20:30</option>}
                      {drUpdate.sundayEnd === 43 && <option>21:00</option>}
                      {drUpdate.sundayEnd === 44 && <option>21:30</option>}
                      {drUpdate.sundayEnd === 45 && <option>22:00</option>}
                      {drUpdate.sundayEnd === 46 && <option>22:30</option>}
                      {drUpdate.sundayEnd === 47 && <option>23:00</option>}
                      {drUpdate.sundayEnd === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                    <select onChange={(e) => handleTuesdayEnd(e.target.value)}>
                      {drUpdate.tuesdayEnd === 1 && <option>00:00</option>}
                      {drUpdate.tuesdayEnd === 2 && <option>00:30</option>}
                      {drUpdate.tuesdayEnd === 3 && <option>01:00</option>}
                      {drUpdate.tuesdayEnd === 4 && <option>01:30</option>}
                      {drUpdate.tuesdayEnd === 5 && <option>02:00</option>}
                      {drUpdate.tuesdayEnd === 6 && <option>02:30</option>}
                      {drUpdate.tuesdayEnd === 7 && <option>03:00</option>}
                      {drUpdate.tuesdayEnd === 8 && <option>03:30</option>}
                      {drUpdate.tuesdayEnd === 9 && <option>04:00</option>}
                      {drUpdate.tuesdayEnd === 10 && <option>04:30</option>}
                      {drUpdate.tuesdayEnd === 11 && <option>05:00</option>}
                      {drUpdate.tuesdayEnd === 12 && <option>05:30</option>}
                      {drUpdate.tuesdayEnd === 13 && <option>06:00</option>}
                      {drUpdate.tuesdayEnd === 14 && <option>06:30</option>}
                      {drUpdate.tuesdayEnd === 15 && <option>07:00</option>}
                      {drUpdate.tuesdayEnd === 16 && <option>07:30</option>}
                      {drUpdate.tuesdayEnd === 17 && <option>08:00</option>}
                      {drUpdate.tuesdayEnd === 18 && <option>08:30</option>}
                      {drUpdate.tuesdayEnd === 19 && <option>09:00</option>}
                      {drUpdate.tuesdayEnd === 20 && <option>09:30</option>}
                      {drUpdate.tuesdayEnd === 21 && <option>10:00</option>}
                      {drUpdate.tuesdayEnd === 22 && <option>10:30</option>}
                      {drUpdate.tuesdayEnd === 23 && <option>11:00</option>}
                      {drUpdate.tuesdayEnd === 24 && <option>11:30</option>}
                      {drUpdate.tuesdayEnd === 25 && <option>12:00</option>}
                      {drUpdate.tuesdayEnd === 26 && <option>12:30</option>}
                      {drUpdate.tuesdayEnd === 27 && <option>13:00</option>}
                      {drUpdate.tuesdayEnd === 28 && <option>13:30</option>}
                      {drUpdate.tuesdayEnd === 29 && <option>14:00</option>}
                      {drUpdate.tuesdayEnd === 30 && <option>14:30</option>}
                      {drUpdate.tuesdayEnd === 31 && <option>15:00</option>}
                      {drUpdate.tuesdayEnd === 32 && <option>15:30</option>}
                      {drUpdate.tuesdayEnd === 33 && <option>16:00</option>}
                      {drUpdate.tuesdayEnd === 34 && <option>16:30</option>}
                      {drUpdate.tuesdayEnd === 35 && <option>17:00</option>}
                      {drUpdate.tuesdayEnd === 36 && <option>17:30</option>}
                      {drUpdate.tuesdayEnd === 37 && <option>18:00</option>}
                      {drUpdate.tuesdayEnd === 38 && <option>18:30</option>}
                      {drUpdate.tuesdayEnd === 39 && <option>19:00</option>}
                      {drUpdate.tuesdayEnd === 40 && <option>19:30</option>}
                      {drUpdate.tuesdayEnd === 41 && <option>20:00</option>}
                      {drUpdate.tuesdayEnd === 42 && <option>20:30</option>}
                      {drUpdate.tuesdayEnd === 43 && <option>21:00</option>}
                      {drUpdate.tuesdayEnd === 44 && <option>21:30</option>}
                      {drUpdate.tuesdayEnd === 45 && <option>22:00</option>}
                      {drUpdate.tuesdayEnd === 46 && <option>22:30</option>}
                      {drUpdate.tuesdayEnd === 47 && <option>23:00</option>}
                      {drUpdate.tuesdayEnd === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                    <select
                      onChange={(e) => handleWednesdayEnd(e.target.value)}
                    >
                      {drUpdate.wednesdayEnd === 1 && <option>00:00</option>}
                      {drUpdate.wednesdayEnd === 2 && <option>00:30</option>}
                      {drUpdate.wednesdayEnd === 3 && <option>01:00</option>}
                      {drUpdate.wednesdayEnd === 4 && <option>01:30</option>}
                      {drUpdate.wednesdayEnd === 5 && <option>02:00</option>}
                      {drUpdate.wednesdayEnd === 6 && <option>02:30</option>}
                      {drUpdate.wednesdayEnd === 7 && <option>03:00</option>}
                      {drUpdate.wednesdayEnd === 8 && <option>03:30</option>}
                      {drUpdate.wednesdayEnd === 9 && <option>04:00</option>}
                      {drUpdate.wednesdayEnd === 10 && <option>04:30</option>}
                      {drUpdate.wednesdayEnd === 11 && <option>05:00</option>}
                      {drUpdate.wednesdayEnd === 12 && <option>05:30</option>}
                      {drUpdate.wednesdayEnd === 13 && <option>06:00</option>}
                      {drUpdate.wednesdayEnd === 14 && <option>06:30</option>}
                      {drUpdate.wednesdayEnd === 15 && <option>07:00</option>}
                      {drUpdate.wednesdayEnd === 16 && <option>07:30</option>}
                      {drUpdate.wednesdayEnd === 17 && <option>08:00</option>}
                      {drUpdate.wednesdayEnd === 18 && <option>08:30</option>}
                      {drUpdate.wednesdayEnd === 19 && <option>09:00</option>}
                      {drUpdate.wednesdayEnd === 20 && <option>09:30</option>}
                      {drUpdate.wednesdayEnd === 21 && <option>10:00</option>}
                      {drUpdate.wednesdayEnd === 22 && <option>10:30</option>}
                      {drUpdate.wednesdayEnd === 23 && <option>11:00</option>}
                      {drUpdate.wednesdayEnd === 24 && <option>11:30</option>}
                      {drUpdate.wednesdayEnd === 25 && <option>12:00</option>}
                      {drUpdate.wednesdayEnd === 26 && <option>12:30</option>}
                      {drUpdate.wednesdayEnd === 27 && <option>13:00</option>}
                      {drUpdate.wednesdayEnd === 28 && <option>13:30</option>}
                      {drUpdate.wednesdayEnd === 29 && <option>14:00</option>}
                      {drUpdate.wednesdayEnd === 30 && <option>14:30</option>}
                      {drUpdate.wednesdayEnd === 31 && <option>15:00</option>}
                      {drUpdate.wednesdayEnd === 32 && <option>15:30</option>}
                      {drUpdate.wednesdayEnd === 33 && <option>16:00</option>}
                      {drUpdate.wednesdayEnd === 34 && <option>16:30</option>}
                      {drUpdate.wednesdayEnd === 35 && <option>17:00</option>}
                      {drUpdate.wednesdayEnd === 36 && <option>17:30</option>}
                      {drUpdate.wednesdayEnd === 37 && <option>18:00</option>}
                      {drUpdate.wednesdayEnd === 38 && <option>18:30</option>}
                      {drUpdate.wednesdayEnd === 39 && <option>19:00</option>}
                      {drUpdate.wednesdayEnd === 40 && <option>19:30</option>}
                      {drUpdate.wednesdayEnd === 41 && <option>20:00</option>}
                      {drUpdate.wednesdayEnd === 42 && <option>20:30</option>}
                      {drUpdate.wednesdayEnd === 43 && <option>21:00</option>}
                      {drUpdate.wednesdayEnd === 44 && <option>21:30</option>}
                      {drUpdate.wednesdayEnd === 45 && <option>22:00</option>}
                      {drUpdate.wednesdayEnd === 46 && <option>22:30</option>}
                      {drUpdate.wednesdayEnd === 47 && <option>23:00</option>}
                      {drUpdate.wednesdayEnd === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                    <select onChange={(e) => handleThursdayEnd(e.target.value)}>
                      {drUpdate.thursdayEnd === 1 && <option>00:00</option>}
                      {drUpdate.thursdayEnd === 2 && <option>00:30</option>}
                      {drUpdate.thursdayEnd === 3 && <option>01:00</option>}
                      {drUpdate.thursdayEnd === 4 && <option>01:30</option>}
                      {drUpdate.thursdayEnd === 5 && <option>02:00</option>}
                      {drUpdate.thursdayEnd === 6 && <option>02:30</option>}
                      {drUpdate.thursdayEnd === 7 && <option>03:00</option>}
                      {drUpdate.thursdayEnd === 8 && <option>03:30</option>}
                      {drUpdate.thursdayEnd === 9 && <option>04:00</option>}
                      {drUpdate.thursdayEnd === 10 && <option>04:30</option>}
                      {drUpdate.thursdayEnd === 11 && <option>05:00</option>}
                      {drUpdate.thursdayEnd === 12 && <option>05:30</option>}
                      {drUpdate.thursdayEnd === 13 && <option>06:00</option>}
                      {drUpdate.thursdayEnd === 14 && <option>06:30</option>}
                      {drUpdate.thursdayEnd === 15 && <option>07:00</option>}
                      {drUpdate.thursdayEnd === 16 && <option>07:30</option>}
                      {drUpdate.thursdayEnd === 17 && <option>08:00</option>}
                      {drUpdate.thursdayEnd === 18 && <option>08:30</option>}
                      {drUpdate.thursdayEnd === 19 && <option>09:00</option>}
                      {drUpdate.thursdayEnd === 20 && <option>09:30</option>}
                      {drUpdate.thursdayEnd === 21 && <option>10:00</option>}
                      {drUpdate.thursdayEnd === 22 && <option>10:30</option>}
                      {drUpdate.thursdayEnd === 23 && <option>11:00</option>}
                      {drUpdate.thursdayEnd === 24 && <option>11:30</option>}
                      {drUpdate.thursdayEnd === 25 && <option>12:00</option>}
                      {drUpdate.thursdayEnd === 26 && <option>12:30</option>}
                      {drUpdate.thursdayEnd === 27 && <option>13:00</option>}
                      {drUpdate.thursdayEnd === 28 && <option>13:30</option>}
                      {drUpdate.thursdayEnd === 29 && <option>14:00</option>}
                      {drUpdate.thursdayEnd === 30 && <option>14:30</option>}
                      {drUpdate.thursdayEnd === 31 && <option>15:00</option>}
                      {drUpdate.thursdayEnd === 32 && <option>15:30</option>}
                      {drUpdate.thursdayEnd === 33 && <option>16:00</option>}
                      {drUpdate.thursdayEnd === 34 && <option>16:30</option>}
                      {drUpdate.thursdayEnd === 35 && <option>17:00</option>}
                      {drUpdate.thursdayEnd === 36 && <option>17:30</option>}
                      {drUpdate.thursdayEnd === 37 && <option>18:00</option>}
                      {drUpdate.thursdayEnd === 38 && <option>18:30</option>}
                      {drUpdate.thursdayEnd === 39 && <option>19:00</option>}
                      {drUpdate.thursdayEnd === 40 && <option>19:30</option>}
                      {drUpdate.thursdayEnd === 41 && <option>20:00</option>}
                      {drUpdate.thursdayEnd === 42 && <option>20:30</option>}
                      {drUpdate.thursdayEnd === 43 && <option>21:00</option>}
                      {drUpdate.thursdayEnd === 44 && <option>21:30</option>}
                      {drUpdate.thursdayEnd === 45 && <option>22:00</option>}
                      {drUpdate.thursdayEnd === 46 && <option>22:30</option>}
                      {drUpdate.thursdayEnd === 47 && <option>23:00</option>}
                      {drUpdate.thursdayEnd === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                    <select onChange={(e) => handleFridayEnd(e.target.value)}>
                      {drUpdate.fridayEnd === 1 && <option>00:00</option>}
                      {drUpdate.fridayEnd === 2 && <option>00:30</option>}
                      {drUpdate.fridayEnd === 3 && <option>01:00</option>}
                      {drUpdate.fridayEnd === 4 && <option>01:30</option>}
                      {drUpdate.fridayEnd === 5 && <option>02:00</option>}
                      {drUpdate.fridayEnd === 6 && <option>02:30</option>}
                      {drUpdate.fridayEnd === 7 && <option>03:00</option>}
                      {drUpdate.fridayEnd === 8 && <option>03:30</option>}
                      {drUpdate.fridayEnd === 9 && <option>04:00</option>}
                      {drUpdate.fridayEnd === 10 && <option>04:30</option>}
                      {drUpdate.fridayEnd === 11 && <option>05:00</option>}
                      {drUpdate.fridayEnd === 12 && <option>05:30</option>}
                      {drUpdate.fridayEnd === 13 && <option>06:00</option>}
                      {drUpdate.fridayEnd === 14 && <option>06:30</option>}
                      {drUpdate.fridayEnd === 15 && <option>07:00</option>}
                      {drUpdate.fridayEnd === 16 && <option>07:30</option>}
                      {drUpdate.fridayEnd === 17 && <option>08:00</option>}
                      {drUpdate.fridayEnd === 18 && <option>08:30</option>}
                      {drUpdate.fridayEnd === 19 && <option>09:00</option>}
                      {drUpdate.fridayEnd === 20 && <option>09:30</option>}
                      {drUpdate.fridayEnd === 21 && <option>10:00</option>}
                      {drUpdate.fridayEnd === 22 && <option>10:30</option>}
                      {drUpdate.fridayEnd === 23 && <option>11:00</option>}
                      {drUpdate.fridayEnd === 24 && <option>11:30</option>}
                      {drUpdate.fridayEnd === 25 && <option>12:00</option>}
                      {drUpdate.fridayEnd === 26 && <option>12:30</option>}
                      {drUpdate.fridayEnd === 27 && <option>13:00</option>}
                      {drUpdate.fridayEnd === 28 && <option>13:30</option>}
                      {drUpdate.fridayEnd === 29 && <option>14:00</option>}
                      {drUpdate.fridayEnd === 30 && <option>14:30</option>}
                      {drUpdate.fridayEnd === 31 && <option>15:00</option>}
                      {drUpdate.fridayEnd === 32 && <option>15:30</option>}
                      {drUpdate.fridayEnd === 33 && <option>16:00</option>}
                      {drUpdate.fridayEnd === 34 && <option>16:30</option>}
                      {drUpdate.fridayEnd === 35 && <option>17:00</option>}
                      {drUpdate.fridayEnd === 36 && <option>17:30</option>}
                      {drUpdate.fridayEnd === 37 && <option>18:00</option>}
                      {drUpdate.fridayEnd === 38 && <option>18:30</option>}
                      {drUpdate.fridayEnd === 39 && <option>19:00</option>}
                      {drUpdate.fridayEnd === 40 && <option>19:30</option>}
                      {drUpdate.fridayEnd === 41 && <option>20:00</option>}
                      {drUpdate.fridayEnd === 42 && <option>20:30</option>}
                      {drUpdate.fridayEnd === 43 && <option>21:00</option>}
                      {drUpdate.fridayEnd === 44 && <option>21:30</option>}
                      {drUpdate.fridayEnd === 45 && <option>22:00</option>}
                      {drUpdate.fridayEnd === 46 && <option>22:30</option>}
                      {drUpdate.fridayEnd === 47 && <option>23:00</option>}
                      {drUpdate.fridayEnd === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                    <select onChange={(e) => handleSaturdayEnd(e.target.value)}>
                      {drUpdate.saturdayEnd === 1 && <option>00:00</option>}
                      {drUpdate.saturdayEnd === 2 && <option>00:30</option>}
                      {drUpdate.saturdayEnd === 3 && <option>01:00</option>}
                      {drUpdate.saturdayEnd === 4 && <option>01:30</option>}
                      {drUpdate.saturdayEnd === 5 && <option>02:00</option>}
                      {drUpdate.saturdayEnd === 6 && <option>02:30</option>}
                      {drUpdate.saturdayEnd === 7 && <option>03:00</option>}
                      {drUpdate.saturdayEnd === 8 && <option>03:30</option>}
                      {drUpdate.saturdayEnd === 9 && <option>04:00</option>}
                      {drUpdate.saturdayEnd === 10 && <option>04:30</option>}
                      {drUpdate.saturdayEnd === 11 && <option>05:00</option>}
                      {drUpdate.saturdayEnd === 12 && <option>05:30</option>}
                      {drUpdate.saturdayEnd === 13 && <option>06:00</option>}
                      {drUpdate.saturdayEnd === 14 && <option>06:30</option>}
                      {drUpdate.saturdayEnd === 15 && <option>07:00</option>}
                      {drUpdate.saturdayEnd === 16 && <option>07:30</option>}
                      {drUpdate.saturdayEnd === 17 && <option>08:00</option>}
                      {drUpdate.saturdayEnd === 18 && <option>08:30</option>}
                      {drUpdate.saturdayEnd === 19 && <option>09:00</option>}
                      {drUpdate.saturdayEnd === 20 && <option>09:30</option>}
                      {drUpdate.saturdayEnd === 21 && <option>10:00</option>}
                      {drUpdate.saturdayEnd === 22 && <option>10:30</option>}
                      {drUpdate.saturdayEnd === 23 && <option>11:00</option>}
                      {drUpdate.saturdayEnd === 24 && <option>11:30</option>}
                      {drUpdate.saturdayEnd === 25 && <option>12:00</option>}
                      {drUpdate.saturdayEnd === 26 && <option>12:30</option>}
                      {drUpdate.saturdayEnd === 27 && <option>13:00</option>}
                      {drUpdate.saturdayEnd === 28 && <option>13:30</option>}
                      {drUpdate.saturdayEnd === 29 && <option>14:00</option>}
                      {drUpdate.saturdayEnd === 30 && <option>14:30</option>}
                      {drUpdate.saturdayEnd === 31 && <option>15:00</option>}
                      {drUpdate.saturdayEnd === 32 && <option>15:30</option>}
                      {drUpdate.saturdayEnd === 33 && <option>16:00</option>}
                      {drUpdate.saturdayEnd === 34 && <option>16:30</option>}
                      {drUpdate.saturdayEnd === 35 && <option>17:00</option>}
                      {drUpdate.saturdayEnd === 36 && <option>17:30</option>}
                      {drUpdate.saturdayEnd === 37 && <option>18:00</option>}
                      {drUpdate.saturdayEnd === 38 && <option>18:30</option>}
                      {drUpdate.saturdayEnd === 39 && <option>19:00</option>}
                      {drUpdate.saturdayEnd === 40 && <option>19:30</option>}
                      {drUpdate.saturdayEnd === 41 && <option>20:00</option>}
                      {drUpdate.saturdayEnd === 42 && <option>20:30</option>}
                      {drUpdate.saturdayEnd === 43 && <option>21:00</option>}
                      {drUpdate.saturdayEnd === 44 && <option>21:30</option>}
                      {drUpdate.saturdayEnd === 45 && <option>22:00</option>}
                      {drUpdate.saturdayEnd === 46 && <option>22:30</option>}
                      {drUpdate.saturdayEnd === 47 && <option>23:00</option>}
                      {drUpdate.saturdayEnd === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                    <select onChange={(e) => handleMondayEnd(e.target.value)}>
                      {drUpdate.mondayEnd === 1 && <option>00:00</option>}
                      {drUpdate.mondayEnd === 2 && <option>00:30</option>}
                      {drUpdate.mondayEnd === 3 && <option>01:00</option>}
                      {drUpdate.mondayEnd === 4 && <option>01:30</option>}
                      {drUpdate.mondayEnd === 5 && <option>02:00</option>}
                      {drUpdate.mondayEnd === 6 && <option>02:30</option>}
                      {drUpdate.mondayEnd === 7 && <option>03:00</option>}
                      {drUpdate.mondayEnd === 8 && <option>03:30</option>}
                      {drUpdate.mondayEnd === 9 && <option>04:00</option>}
                      {drUpdate.mondayEnd === 10 && <option>04:30</option>}
                      {drUpdate.mondayEnd === 11 && <option>05:00</option>}
                      {drUpdate.mondayEnd === 12 && <option>05:30</option>}
                      {drUpdate.mondayEnd === 13 && <option>06:00</option>}
                      {drUpdate.mondayEnd === 14 && <option>06:30</option>}
                      {drUpdate.mondayEnd === 15 && <option>07:00</option>}
                      {drUpdate.mondayEnd === 16 && <option>07:30</option>}
                      {drUpdate.mondayEnd === 17 && <option>08:00</option>}
                      {drUpdate.mondayEnd === 18 && <option>08:30</option>}
                      {drUpdate.mondayEnd === 19 && <option>09:00</option>}
                      {drUpdate.mondayEnd === 20 && <option>09:30</option>}
                      {drUpdate.mondayEnd === 21 && <option>10:00</option>}
                      {drUpdate.mondayEnd === 22 && <option>10:30</option>}
                      {drUpdate.mondayEnd === 23 && <option>11:00</option>}
                      {drUpdate.mondayEnd === 24 && <option>11:30</option>}
                      {drUpdate.mondayEnd === 25 && <option>12:00</option>}
                      {drUpdate.mondayEnd === 26 && <option>12:30</option>}
                      {drUpdate.mondayEnd === 27 && <option>13:00</option>}
                      {drUpdate.mondayEnd === 28 && <option>13:30</option>}
                      {drUpdate.mondayEnd === 29 && <option>14:00</option>}
                      {drUpdate.mondayEnd === 30 && <option>14:30</option>}
                      {drUpdate.mondayEnd === 31 && <option>15:00</option>}
                      {drUpdate.mondayEnd === 32 && <option>15:30</option>}
                      {drUpdate.mondayEnd === 33 && <option>16:00</option>}
                      {drUpdate.mondayEnd === 34 && <option>16:30</option>}
                      {drUpdate.mondayEnd === 35 && <option>17:00</option>}
                      {drUpdate.mondayEnd === 36 && <option>17:30</option>}
                      {drUpdate.mondayEnd === 37 && <option>18:00</option>}
                      {drUpdate.mondayEnd === 38 && <option>18:30</option>}
                      {drUpdate.mondayEnd === 39 && <option>19:00</option>}
                      {drUpdate.mondayEnd === 40 && <option>19:30</option>}
                      {drUpdate.mondayEnd === 41 && <option>20:00</option>}
                      {drUpdate.mondayEnd === 42 && <option>20:30</option>}
                      {drUpdate.mondayEnd === 43 && <option>21:00</option>}
                      {drUpdate.mondayEnd === 44 && <option>21:30</option>}
                      {drUpdate.mondayEnd === 45 && <option>22:00</option>}
                      {drUpdate.mondayEnd === 46 && <option>22:30</option>}
                      {drUpdate.mondayEnd === 47 && <option>23:00</option>}
                      {drUpdate.mondayEnd === 48 && <option>23:30</option>}
                      <option>00:00</option>
                      <option>00:30</option>
                      <option>01:00</option>
                      <option>01:30</option>
                      <option>02:00</option>
                      <option>02:30</option>
                      <option>03:00</option>
                      <option>03:30</option>
                      <option>04:00</option>
                      <option>04:30</option>
                      <option>05:00</option>
                      <option>05:30</option>
                      <option>06:00</option>
                      <option>06:30</option>
                      <option>07:00</option>
                      <option>07:30</option>
                      <option>08:00</option>
                      <option>08:30</option>
                      <option>09:00</option>
                      <option>09:30</option>
                      <option>10:00</option>
                      <option>10:30</option>
                      <option>11:00</option>
                      <option>11:30</option>
                      <option>12:00</option>
                      <option>12:30</option>
                      <option>13:00</option>
                      <option>13:30</option>
                      <option>14:00</option>
                      <option>14:30</option>
                      <option>15:00</option>
                      <option>15:30</option>
                      <option>16:00</option>
                      <option>16:30</option>
                      <option>17:00</option>
                      <option>17:30</option>
                      <option>18:00</option>
                      <option>18:30</option>
                      <option>19:00</option>
                      <option>19:30</option>
                      <option>20:00</option>
                      <option>20:30</option>
                      <option>21:00</option>
                      <option>21:30</option>
                      <option>22:00</option>
                      <option>22:30</option>
                      <option>23:00</option>
                      <option>23:30</option>
                    </select>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <div className="flexCenter">
        <Button
          className="doctorAddBtn"
          variant="danger"
          disabled={drStore.loading}
          onClick={updtDoctor}
        >
          {drStore.loading
            ? "Güncelleniyor..."
            : "Güncellemek için tıklayınız."}
        </Button>
        </div>
      </Container>
    </div>
  );
});
