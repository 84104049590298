import "../patientPlansList/patientPlansList.css";
import { useEffect, useState } from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Modal, Card } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import ReactEcharts from "echarts-for-react";
import EchartTest from "./echartTest";
import moment from "moment";
import "../doctorPlanList/doctorPlansList.css";

function ReportApprovedPlanList() {
  const { planStore } = useStore();
  const { selectPlan, dateFilter } = planStore;
  useEffect(() => {
    planStore.getPlans();
  }, []);

  return (
    <div className="patientsPlanGroup">
      

      <div className="row text-center">
        <span className="reportTitle">Onaylanan Plan Sayısı</span>
      </div>

      {/* plan sayısı table start */}
      {/* <Table className="table table-hover table-lg table-responsive-md"> */}
      <Table  bordered hover size="sm">
        <thead
          className="pricesTableBody"
          style={{ textAlign: "center", fontSize: 20 }}
        >
          <tr className="pricesTableThHead">
            <th className="pricesTableTh">Plan İsmi</th>
            <th className="pricesTableTh">Oluşturulma Tarihi</th>
            <th className="pricesTableTh">Plan Durumu</th>
          </tr>
        </thead>
        <tbody className="pricesTableTbody">
          {planStore.plans?.map(
            (plan) =>
              plan.isApproved === true && (
                <tr key={plan.id}>
                  <td className="pricesTableTd">{plan.name}</td>
                  <td className="pricesTableTd">
                    <p>{moment(plan.createdTime).utc().format("DD-MM-YYYY")}</p>
                  </td>
                  <td className="pricesTableTd">
                    {plan.isApproved === true && (
                      <p
                        className="approvedBtn"
                        style={{ color: "white", backgroundColor: "green" }}
                      >
                        {" "}
                        <span className="mx-1">ONAYLANDI</span>{" "}
                      </p>
                    )}
                    {plan.isApproved === false && (
                      <p
                        className="approvedBtn"
                        style={{ color: "white", backgroundColor: "red" }}
                      >
                        <span className="mx-1">ONAYLANMADI</span>
                      </p>
                    )}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </Table>
      {/* plan sayısı table end */}
    </div>
  );
}

export default observer(ReportApprovedPlanList);
