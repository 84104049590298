import "./patientList.css";
import { useEffect, useState } from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import "react-bootstrap";
import { Spinner, Button, Modal, ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddPatient from "../addPatient/AddPatient";
function PatientList() {
  const { patientStore, userStore } = useStore();
  const { loading } = patientStore;
  let navigate = useNavigate();
  useEffect(() => {
    patientStore.loadPatients();
  }, [patientStore]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    patientStore.selectPatient(id);
    patientStore.adding = true;
    setShow(true);
  };
  const handleShowPatient = (id) => {
    patientStore.selectPatient(id);
    navigate("/patientscard");
  };
  const handleShowPatientAnamnesis = (id) => {
    patientStore.selectPatient(id);
    navigate("/anamnesis");
  };

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = (id) => {
    patientStore.deleted = true;
    patientStore.selectPatient(id);
    setShowDelete(true);
  };

  function handleDelete(id) {
    patientStore.deletePatient(id);
  }

  if (loading)
    return (
      <Button className="loadingBtnStyle" variant="danger" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Hasta Listesi Yükleniyor...
      </Button>
    );

  // <LoadingComponent content='Hasta listesi yükleniyor...'/>

  return (
    <div className="patientsTableList">
      {userStore.grandPermissions.patientCreate && (
        <div className="btnPatientAdd">
          <Button
          style={{ marginBottom: "1rem" }}
          variant="outline-dark"
          onClick={handleShow}
          className="btnPatientAdd"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="bi bi-person-plus"
            viewBox="0 0 16 16"
            style={{
              marginRight: "3px",
              marginBottom: "3px",
            }}
          >
            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
            <path
              fillRule="evenodd"
              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
            />
          </svg>
          Hasta Ekle
        </Button>
        </div>
      )}
      {patientStore.adding && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Hasta Ekle</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddPatient />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <table className="patientsListTable table table-hover table-lg table-responsive-md">
        <thead
          className="patientsTableBody"
          style={{ textAlign: "center", fontSize: 20 }}
        >
          <tr className="patientsTableThHead">
            <th className="patientsTableTh">TC Kimlik No</th>
            <th className="patientsTableTh">Hasta İsmi</th>
            <th className="patientsTableTh">Telefon Numarası</th>
            <th className="patientsTableTh">Mail</th>
            <th className="patientsTableTh">Hasta Danışmanı</th>
            <th className="doctorTableEdit patientsTableTh">Seçenekler</th>
          </tr>
        </thead>
        <tbody className="patientsTableTbody">
          {patientStore.patients?.map((patient) => (
            <tr className="patientSearchBody" key={patient.id}>
              <td className="patientsTableTd">{patient.identityNumber}</td>
              <td className="patientsTableTd">{patient.fullName}</td>
              <td className="patientsTableTd">{patient.phoneNumber}</td>
              <td className="patientsTableTd">{patient.email}</td>
              {patient.advisor !== null && (
                <td className="patientsTableTd">{patient.advisor.fullName}</td>
              )}
              {patient.advisor === null && (
                <td className="patientsTableTd">Danışman Atanmamış</td>
              )}
              <td className="doctorTableEdit patientsTableTd">
                <ButtonGroup>
                  <Button
                    variant="outline-dark "
                    onClick={() => handleShowPatient(patient.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                  </Button>
                  <Button
                    variant="outline-dark "
                    onClick={() => handleShowPatientAnamnesis(patient.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-bandaid"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14.121 1.879a3 3 0 0 0-4.242 0L8.733 3.026l4.261 4.26 1.127-1.165a3 3 0 0 0 0-4.242ZM12.293 8 8.027 3.734 3.738 8.031 8 12.293 12.293 8Zm-5.006 4.994L3.03 8.737 1.879 9.88a3 3 0 0 0 4.241 4.24l.006-.006 1.16-1.121ZM2.679 7.676l6.492-6.504a4 4 0 0 1 5.66 5.653l-1.477 1.529-5.006 5.006-1.523 1.472a4 4 0 0 1-5.653-5.66l.001-.002 1.505-1.492.001-.002Z" />
                      <path d="M5.56 7.646a.5.5 0 1 1-.706.708.5.5 0 0 1 .707-.708Zm1.415-1.414a.5.5 0 1 1-.707.707.5.5 0 0 1 .707-.707ZM8.39 4.818a.5.5 0 1 1-.708.707.5.5 0 0 1 .707-.707Zm0 5.657a.5.5 0 1 1-.708.707.5.5 0 0 1 .707-.707ZM9.803 9.06a.5.5 0 1 1-.707.708.5.5 0 0 1 .707-.707Zm1.414-1.414a.5.5 0 1 1-.706.708.5.5 0 0 1 .707-.708ZM6.975 9.06a.5.5 0 1 1-.707.708.5.5 0 0 1 .707-.707ZM8.39 7.646a.5.5 0 1 1-.708.708.5.5 0 0 1 .707-.708Zm1.413-1.414a.5.5 0 1 1-.707.707.5.5 0 0 1 .707-.707Z" />
                    </svg>
                  </Button>
                  {userStore.grandPermissions.patientDelete && (
                    <Button
                      variant="outline-dark "
                      onClick={() => {
                        handleShowDelete(patient.id);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-archive"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                      </svg>
                    </Button>
                  )}
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Hasta Silme Modal */}
      {patientStore.deleted && (
        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Silmek istediğinize emin misiniz?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="dark"
              onClick={() => handleDelete(patientStore.selectedPatient.id)}
            >
              Evet
            </Button>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Hayır
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Hasta Silme Modal */}
    </div>
  );
}

export default observer(PatientList);
