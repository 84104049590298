import "../diagnoseRightMenuModal/diagnoseRightMenuModal.css";
import { Close, Delete, ViewList } from "@mui/icons-material";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { runInAction } from "mobx";
import "./planRightMenuModal.css";

function PlanRightMenuModal() {
  const { treatmentStore, planStore, userStore } = useStore();
  const { selectedPlan } = planStore;
  const {
    currentTreatment,
    createTreatment,
    deleteTreatment,
    resetForm2,
    resetForm3,
    loadedCount,
    updateTreatment,
  } = treatmentStore;

  const initialState = currentTreatment ?? {
    treatmentStageId: "",
    treatmentType: 0,
    teeths: [0],
    price: 0,
    realizationDate: "",
    channel: 0,
    dentureType: 0,
    braceTypes: 0,
    toothSurfaces: [0],
    observation: "",
  };

  const [treatment, setTreatment] = useState(initialState);

  useEffect(() => {
    setTreatment(initialState);
  }, [currentTreatment]);

  const initialState2 = selectedPlan ?? {
    id: "",
    name: "",
    patientID: "",
    createdTime: "",
    isActive: 1,
    conditions: [],
    treatmentStages: {
      id: "",
      name: "",
      description: "",
      planId: "",
      createdTime: "",
      isActive: 1,
      treatments: [],
    },
  };
  const [plan, setPlan] = useState(initialState2);

  useEffect(() => {
    setPlan(initialState2);
  }, [resetForm2]);

  function handleDelete(id) {
    deleteTreatment(id);
  }
  return (
    <div className="boxStyle">
      <div className="modal-body">
        <div>
          <table id="treatmentsPlanTable">
            {plan.treatmentStages?.map((stages) =>
              stages.treatments?.map((treat) => (
                <tbody>
                  <tr className="diagnoseRmTableTd">
                    <td className="tdStyle">
                      <span style={{ color: "#0d68c8" }}>Tedavi: </span>
                      {treat.treatmentType === 1
                        ? "Önleme ve Hijyen"
                        : "" || treat.treatmentType === 2
                        ? "Profesyonel Diş Temizleme"
                        : "" || treat.treatmentType === 3
                        ? "Topikal Florür"
                        : "" || treat.treatmentType === 4
                        ? "Profesyonel Diş Temizleme"
                        : "" || treat.treatmentType === 5
                        ? "Ölçeklendirme/Kök Planlama"
                        : "" || treat.treatmentType === 6
                        ? "Cep Azaltma"
                        : "" || treat.treatmentType === 7
                        ? "Kemik Rejenerasyonu"
                        : "" || treat.treatmentType === 8
                        ? "Dişeti Grefti"
                        : "" || treat.treatmentType === 9
                        ? "Plastik Perio Cerrahisi"
                        : "" || treat.treatmentType === 10
                        ? "Kron Uzatma"
                        : "" || treat.treatmentType === 11
                        ? "Gingivektomi"
                        : "" || treat.treatmentType === 12
                        ? "Lazer Gingivektomi"
                        : "" || treat.treatmentType === 13
                        ? "Kök Kanal Tedavisi"
                        : "" || treat.treatmentType === 14
                        ? "Kanal Tedavisi"
                        : "" || treat.treatmentType === 16
                        ? "Apikektomi"
                        : "" || treat.treatmentType === 17
                        ? "İmplant"
                        : "" || treat.treatmentType === 18
                        ? "Diş Çekimi"
                        : "" || treat.treatmentType === 19
                        ? "Yirmilik Diş Çekimi"
                        : "" || treat.treatmentType === 20
                        ? "Sinüs Kaldırma"
                        : "" || treat.treatmentType === 21
                        ? "Kemik Rejenerasyonu"
                        : "" || treat.treatmentType === 22
                        ? "Tam Ark İmplantları/Protez"
                        : "" || treat.treatmentType === 23
                        ? "İmplant Çıkarma"
                        : "" || treat.treatmentType === 24
                        ? "Cerrahi Rehber"
                        : "" || treat.treatmentType === 25
                        ? "Restorasyon"
                        : "" || treat.treatmentType === 26
                        ? "Post"
                        : "" || treat.treatmentType === 27
                        ? "Atel"
                        : "" || treat.treatmentType === 28
                        ? "Dış Çamaşır Suyu"
                        : "" || treat.treatmentType === 29
                        ? "İç Çamaşır Suyu"
                        : "" || treat.treatmentType === 30
                        ? "Çekirdek Oluşturma"
                        : "" || treat.treatmentType === 31
                        ? "Geçici Restorasyon"
                        : "" || treat.treatmentType === 32
                        ? "Kaplama"
                        : "" || treat.treatmentType === 33
                        ? "Onlay"
                        : "" || treat.treatmentType === 34
                        ? "Taç"
                        : "" || treat.treatmentType === 35
                        ? "Köprü"
                        : "" || treat.treatmentType === 36
                        ? "Yapışkanlı Köprü"
                        : "" || treat.treatmentType === 37
                        ? "Takma Diş"
                        : "" || treat.treatmentType === 38
                        ? "Köprü/Protez Çıkarma"
                        : "" || treat.treatmentType === 39
                        ? "Eklemli Çalışma Modelleri"
                        : "" || treat.treatmentType === 40
                        ? "Geçici Köprü"
                        : "" || treat.treatmentType === 41
                        ? "Geçici Taç"
                        : "" || treat.treatmentType === 42
                        ? "Sabit Braketler"
                        : "" || treat.treatmentType === 43
                        ? "Lingual Braketler"
                        : "" || treat.treatmentType === 44
                        ? "Hizalayıcıları Temizle"
                        : "" || treat.treatmentType === 45
                        ? "Çıkarılabilir Cihaz"
                        : "" || treat.treatmentType === 46
                        ? "Fonksiyonel Cihaz"
                        : "" || treat.treatmentType === 47
                        ? "Kozmetik Ortodonti"
                        : "" || treat.treatmentType === 48
                        ? "Avans"
                        : "" || treat.treatmentType === 49
                        ? "Ankraj için Mini İmplantlar"
                        : "" || treat.treatmentType === 50
                        ? "Tutulan Dişlerin Traksiyonu"
                        : "" || treat.treatmentType === 51
                        ? "Eklemli Çalışma Modelleri"
                        : "" || treat.treatmentType === 52
                        ? "Davranışsal Tavsiye ve Stres Kontrolü"
                        : "" || treat.treatmentType === 53
                        ? "Oklüzal Atel"
                        : "" || treat.treatmentType === 54
                        ? "Oklüzal Denge"
                        : "" || treat.treatmentType === 55
                        ? "Eklemli Çalışma Modelleri"
                        : ""}
                    </td>
                    <td>
                      <span style={{ color: "#0d68c8" }}>Dişler: </span>{" "}
                      {treat.teeths + ","}
                    </td>
                    <td>
                      <ul className="ulStyle">
                        {(treat.treatmentType === 25 ||
                          treat.treatmentType === 30 ||
                          treat.treatmentType === 31) && (
                          <li>
                            <span style={{ color: "#0d68c8" }}>Yüzey: </span>
                            {treat.toothSurfaces + ","}
                          </li>
                        )}

                        {(treat.treatmentType === 13 ||
                          treat.treatmentType === 14 ||
                          treat.treatmentType === 15) && (
                          <li>
                            <span style={{ color: "#0d68c8" }}>Kanal: </span>3
                          </li>
                        )}

                        {(treat.treatmentType === 37 ||
                          treat.treatmentType === 42) && (
                          <li>
                            <span style={{ color: "#0d68c8" }}>Tip: </span>3
                          </li>
                        )}
                      </ul>
                    </td>
                    <td>
                      <span style={{ color: "#0d68c8" }}>Fiyat: </span>{" "}
                      {treat.price}{" "}
                      {treat.currency === 1
                        ? "₺"
                        : "" || treat.currency === 2
                        ? "$"
                        : "" || treat.currency === 3
                        ? "€"
                        : ""}
                    </td>
                    {userStore.grandPermissions.planUpdate && (
                      <td>
                        <Form
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          
                        </Form>
                      </td>
                    )}
                    {userStore.grandPermissions.planUpdate && (
                      <td>
                        <Button
                          variant="outline-danger "
                          onClick={() => handleDelete(treat.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-archive"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                          </svg>
                        </Button>
                      </td>
                    )}
                  </tr>
                </tbody>
              ))
            )}
          </table>
        </div>
      </div>
    </div>
    // <div className="diagnoseModalConditionsContainer">
    //   <div
    //     className="modal fade"
    //     id="diagnoseConditionsModal"
    //     tabIndex={-1}
    //     aria-labelledby="diagnoseConditionsModal"
    //     aria-hidden="true"
    //   >
    //     <div className="modal-dialog modal-dialog-centered modal-lg">
    //       <div className="modal-content">
    //         <div className="modal-header">
    //           <h5 className="modal-title" id="diagnoseConditionsModal">
    //             Plan Koşulları
    //           </h5>
    //           <button
    //             type="button"
    //             className="btn-close"
    //             data-bs-dismiss="modal"
    //             aria-label="Close"
    //           />
    //         </div>
    //         <div className="modal-body">
    //           {plan.treatmentStages?.map((stages) =>
    //             stages.treatments?.map((treat) => (
    //               <div className="diagnoseConditionsModalWrapper d-flex align-items-center justify-content-between">
    //                 <div className="diagnoseConditionsContent d-flex align-items-center">
    //                   <ViewList />
    //                   <div className="diagnoseConditionsGroup d-flex flex-column">
    //                     <small className="diagnoseContentCondition">
    //                       Tedavi:{" "}
    // {treat.treatType === 1
    //   ? "Önleme ve Hijyen"
    //   : "" || treat.treatmentType === 2
    //   ? "Profesyonel Diş Temizleme"
    //   : "" || treat.treatmentType === 3
    //   ? "Topikal Florür"
    //   : "" || treat.treatmentType === 4
    //   ? "Profesyonel Diş Temizleme"
    //   : "" || treat.treatmentType === 5
    //   ? "Ölçeklendirme/Kök Planlama"
    //   : "" || treat.treatmentType === 6
    //   ? "Cep Azaltma"
    //   : "" || treat.treatmentType === 7
    //   ? "Kemik Rejenerasyonu"
    //   : "" || treat.treatmentType === 8
    //   ? "Dişeti Grefti"
    //   : "" || treat.treatmentType === 9
    //   ? "Plastik Perio Cerrahisi"
    //   : "" || treat.treatmentType === 10
    //   ? "Kron Uzatma"
    //   : "" || treat.treatmentType === 11
    //   ? "Gingivektomi"
    //   : "" || treat.treatmentType === 12
    //   ? "Lazer Gingivektomi"
    //   : "" || treat.treatmentType === 13
    //   ? "Kök Kanal Tedavisi"
    //   : "" || treat.treatmentType === 14
    //   ? "Kanal Tedavisi"
    //   : "" || treat.treatmentType === 16
    //   ? "Apikektomi"
    //   : "" || treat.treatmentType === 17
    //   ? "İmplant"
    //   : "" || treat.treatmentType === 18
    //   ? "Diş Çekimi"
    //   : "" || treat.treatmentType === 19
    //   ? "Yirmilik Diş Çekimi"
    //   : "" || treat.treatmentType === 20
    //   ? "Sinüs Kaldırma"
    //   : "" || treat.treatmentType === 21
    //   ? "Kemik Rejenerasyonu"
    //   : "" || treat.treatmentType === 22
    //   ? "Tam Ark İmplantları/Protez"
    //   : "" || treat.treatmentType === 23
    //   ? "İmplant Çıkarma"
    //   : "" || treat.treatmentType === 24
    //   ? "Cerrahi Rehber"
    //   : "" || treat.treatmentType === 25
    //   ? "Restorasyon"
    //   : "" || treat.treatmentType === 26
    //   ? "Post"
    //   : "" || treat.treatmentType === 27
    //   ? "Atel"
    //   : "" || treat.treatmentType === 28
    //   ? "Dış Çamaşır Suyu"
    //   : "" || treat.treatmentType === 29
    //   ? "İç Çamaşır Suyu"
    //   : "" || treat.treatmentType === 30
    //   ? "Çekirdek Oluşturma"
    //   : "" || treat.treatmentType === 31
    //   ? "Geçici Restorasyon"
    //   : "" || treat.treatmentType === 32
    //   ? "Kaplama"
    //   : "" || treat.treatmentType === 33
    //   ? "Onlay"
    //   : "" || treat.treatmentType === 34
    //   ? "Taç"
    //   : "" || treat.treatmentType === 35
    //   ? "Köprü"
    //   : "" || treat.treatmentType === 36
    //   ? "Yapışkanlı Köprü"
    //   : "" || treat.treatmentType === 37
    //   ? "Takma Diş"
    //   : "" || treat.treatmentType === 38
    //   ? "Köprü/Protez Çıkarma"
    //   : "" || treat.treatmentType === 39
    //   ? "Eklemli Çalışma Modelleri"
    //   : "" || treat.treatmentType === 40
    //   ? "Geçici Köprü"
    //   : "" || treat.treatmentType === 41
    //   ? "Geçici Taç"
    //   : "" || treat.treatmentType === 42
    //   ? "Sabit Braketler"
    //   : "" || treat.treatmentType === 43
    //   ? "Lingual Braketler"
    //   : "" || treat.treatmentType === 44
    //   ? "Hizalayıcıları Temizle"
    //   : "" || treat.treatmentType === 45
    //   ? "Çıkarılabilir Cihaz"
    //   : "" || treat.treatmentType === 46
    //   ? "Fonksiyonel Cihaz"
    //   : "" || treat.treatmentType === 47
    //   ? "Kozmetik Ortodonti"
    //   : "" || treat.treatmentType === 48
    //   ? "Avans"
    //   : "" || treat.treatmentType === 49
    //   ? "Ankraj için Mini İmplantlar"
    //   : "" || treat.treatmentType === 50
    //   ? "Tutulan Dişlerin Traksiyonu"
    //   : "" || treat.treatmentType === 51
    //   ? "Eklemli Çalışma Modelleri"
    //   : "" || treat.treatmentType === 52
    //   ? "Davranışsal Tavsiye ve Stres Kontrolü"
    //   : "" || treat.treatmentType === 53
    //   ? "Oklüzal Atel"
    //   : "" || treat.treatmentType === 54
    //   ? "Oklüzal Denge"
    //   : "" || treat.treatmentType === 55
    //   ? "Eklemli Çalışma Modelleri"
    //   : ""}
    //                     </small>
    //                     <small className="diagnoseContentTeeth">
    //                       Diş: {treat.teeths + ","}
    //                     </small>
    //                     <small className="diagnoseContentSurfaces">
    //                       {(treat.treatmentType === 25 ||
    //                         treat.treatmentType === 30 ||
    //                         treat.treatmentType === 31) && (
    //                         <label className="currentDiagnosisBodyLabel">
    //                           YÜZEY
    //                         </label>
    //                       )}
    //                       {(treat.treatmentType === 25 ||
    //                         treat.treatmentType === 30 ||
    //                         treat.treatmentType === 31) &&
    //                         treat.toothSurfaces !== 0 && (
    //                           <span className="currentDiagnosisBodySpan">
    //                             {treat.toothSurfaces}
    //                           </span>
    //                         )}
    //                       {(treat.treatmentType === 13 ||
    //                         treat.treatmentType === 14 ||
    //                         treat.treatmentType === 15) && (
    //                         <label className="currentDiagnosisBodyLabel">
    //                           KANAL
    //                         </label>
    //                       )}
    //                       {(treat.treatmentType === 13 ||
    //                         treat.treatmentType === 14 ||
    //                         treat.treatmentType === 15) && (
    //                         <span className="currentDiagnosisBodySpan">3</span>
    //                       )}{" "}
    //                       {(treat.treatmentType === 37 ||
    //                         treat.treatmentType === 42) && (
    //                         <label className="currentDiagnosisBodyLabel">
    //                           TİP
    //                         </label>
    //                       )}
    //                       {(treat.treatmentType === 37 ||
    //                         treat.treatmentType === 42) && (
    //                         <span className="currentDiagnosisBodySpan">3</span>
    //                       )}
    //                     </small>
    //                   </div>
    //                 </div>{" "}
    //                 <div>
    // <Form style={{ display: "flex", justifyContent: "center" ,alignItems:"center"}}>
    //   {treat.isCompleted === false && (
    //     <Form.Check
    //       type="switch"
    //       id="custom-switch"
    //       label="Tamamlandı."
    //       onClick={() => handleCompleted(treat.id, true)}
    //     />
    //   )}
    //   {treat.isCompleted === true && (
    //     <Form.Check
    //       type="switch"
    //       id="custom-switch"
    //       label="Tamamlandı."
    //       onClick={() => handleCompleted(treat.id, false)}
    //       checked
    //     />
    //   )}
    // </Form>
    //                 </div>
    //                 <Delete onClick={() => handleDelete(treat.id)} />
    //               </div>
    //             ))
    //           )}
    //         </div>
    //         <div className="modal-footer">
    //           <button
    //             type="button"
    //             className="btn btn-secondary"
    //             data-bs-dismiss="modal"
    //           >
    //             Close
    //           </button>
    //           <button type="button" className="btn btn-primary">
    //             Save changes
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
export default observer(PlanRightMenuModal);
