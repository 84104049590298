import { createContext, useContext } from "react";
import PatientStore from "./patientStore";
import MenuStore from "./menuStore";
import ConditionStore from "./conditionStore";
import PlanStore from "./planStore";
import UserStore from "./userStore";
import CommonStore from "./commonStore";
import TreatmentStore from "./treatmentStore";
import DrStore from "./drStore";
import PricesStore from "./pricesStore";
import financeStore from "./financeStore";
import appointmentStore from "./appointmentStore";
import documentStore from "./documentStore";

export const store = {
  patientStore: new PatientStore(),
  menuStore: new MenuStore(),
  conditionStore: new ConditionStore(),
  planStore: new PlanStore(),
  userStore: new UserStore(),
  commonStore: new CommonStore(),
  treatmentStore: new TreatmentStore(),
  drStore: new DrStore(),
  pricesStore: new PricesStore(),
  financeStore: new financeStore(),
  appointmentStore: new appointmentStore(),
  documentStore: new documentStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
