import React from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import "./patientTreatments.css";
import Steps from "../steps/Steps";
import Resume from "../resume/Resume";
import { runInAction } from "mobx";
import TreatmentDescriptions from "./TreatmentDescriptions";

export default observer(function TreatmentList() {
  const { planStore, treatmentStore, userStore, commonStore } = useStore();
  const { selectedPlan, resetForm, planSelected, deleteTreatment } = planStore;
  const { updateTreatment } = treatmentStore;

  const initialState = selectedPlan ?? {
    id: " ",
    name: "",
    patientID: "",
    createdTime: "",
    isActive: 1,
    conditions: [],
    treatmentStages: {
      id: "",
      name: "",
      description: "",
      planId: "",
      createdTime: "",
      isActive: 1,
      treatments: [],
    },
  };
  const [plan, setPlan] = useState(initialState);
  useEffect(() => {
    setPlan(initialState);
        console.log(JSON.stringify((plan.treatmentStages[0].treatments)))
  }, [resetForm, treatmentStore.resetForm2]);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (treatmentId) => {
    treatmentStore.selectTreatment(treatmentId);
    setShow(true);
  };

  function handleCompleted(id, event) {
    let treatment = {
      treatmentId: id,
      isCompleted: event.target.checked,
      completedDoctor: (sessionStorage.getItem("userName")),
      updateType: 0,
    };
    runInAction(() => {
      selectedPlan.treatmentStages[0].treatments.find(
        (x) => x.id === treatment.treatmentId
      ).isCompleted = treatment.isCompleted;
    });
    updateTreatment(treatment);
  }

  // function handleDelete(id) {
  //   deleteTreatment(id);
  // }


  if (plan.id === " ")
    return (
      <Button className="loadingBtnStyle" disabled>
        Yükleniyor...
      </Button>
    );

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div className="diagnosePlanEditorHeader">
        <Steps />
        <Resume />
      </div>
      <div style={{ width: "18rem" }} className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: "100%",
            backgroundColor: "green",
            "aria-valuenow": "33",
            "aria-valuemin": "0",
            "aria-valuemax": "100",
          }}
        >
          100%
        </div>
      </div>
      <div className="treatmentTableDiv">
        <table className="treatmentTable table table-hover ">
          {plan.treatmentStages.map((stages) =>
            stages.treatments?.map((treat) => (
              <tbody>
                {treat.isApproved === true && (
                  <tr>
                    <td className="treatmentTableTd">
                      <span className="spanText">Tedavi: </span>
                      {treat.treatmentType === 1
                        ? "Önleme ve Hijyen"
                        : "" || treat.treatmentType === 2
                        ? "Profesyonel Diş Temizleme"
                        : "" || treat.treatmentType === 3
                        ? "Topikal Florür"
                        : "" || treat.treatmentType === 4
                        ? "Profesyonel Diş Temizleme"
                        : "" || treat.treatmentType === 5
                        ? "Ölçeklendirme/Kök Planlama"
                        : "" || treat.treatmentType === 6
                        ? "Cep Azaltma"
                        : "" || treat.treatmentType === 7
                        ? "Kemik Rejenerasyonu"
                        : "" || treat.treatmentType === 8
                        ? "Dişeti Grefti"
                        : "" || treat.treatmentType === 9
                        ? "Plastik Perio Cerrahisi"
                        : "" || treat.treatmentType === 10
                        ? "Kron Uzatma"
                        : "" || treat.treatmentType === 11
                        ? "Gingivektomi"
                        : "" || treat.treatmentType === 12
                        ? "Lazer Gingivektomi"
                        : "" || treat.treatmentType === 13
                        ? "Kök Kanal Tedavisi"
                        : "" || treat.treatmentType === 14
                        ? "Kanal Tedavisi"
                        : "" || treat.treatmentType === 16
                        ? "Apikektomi"
                        : "" || treat.treatmentType === 17
                        ? "İmplant"
                        : "" || treat.treatmentType === 18
                        ? "Diş Çekimi"
                        : "" || treat.treatmentType === 19
                        ? "Yirmilik Diş Çekimi"
                        : "" || treat.treatmentType === 20
                        ? "Sinüs Kaldırma"
                        : "" || treat.treatmentType === 21
                        ? "Kemik Rejenerasyonu"
                        : "" || treat.treatmentType === 22
                        ? "Tam Ark İmplantları/Protez"
                        : "" || treat.treatmentType === 23
                        ? "İmplant Çıkarma"
                        : "" || treat.treatmentType === 24
                        ? "Cerrahi Rehber"
                        : "" || treat.treatmentType === 25
                        ? "Restorasyon"
                        : "" || treat.treatmentType === 26
                        ? "Post"
                        : "" || treat.treatmentType === 27
                        ? "Atel"
                        : "" || treat.treatmentType === 28
                        ? "Dış Çamaşır Suyu"
                        : "" || treat.treatmentType === 29
                        ? "İç Çamaşır Suyu"
                        : "" || treat.treatmentType === 30
                        ? "Çekirdek Oluşturma"
                        : "" || treat.treatmentType === 31
                        ? "Geçici Restorasyon"
                        : "" || treat.treatmentType === 32
                        ? "Kaplama"
                        : "" || treat.treatmentType === 33
                        ? "Onlay"
                        : "" || treat.treatmentType === 34
                        ? "Taç"
                        : "" || treat.treatmentType === 35
                        ? "Köprü"
                        : "" || treat.treatmentType === 36
                        ? "Yapışkanlı Köprü"
                        : "" || treat.treatmentType === 37
                        ? "Takma Diş"
                        : "" || treat.treatmentType === 38
                        ? "Köprü/Protez Çıkarma"
                        : "" || treat.treatmentType === 39
                        ? "Eklemli Çalışma Modelleri"
                        : "" || treat.treatmentType === 40
                        ? "Geçici Köprü"
                        : "" || treat.treatmentType === 41
                        ? "Geçici Taç"
                        : "" || treat.treatmentType === 42
                        ? "Sabit Braketler"
                        : "" || treat.treatmentType === 43
                        ? "Lingual Braketler"
                        : "" || treat.treatmentType === 44
                        ? "Hizalayıcıları Temizle"
                        : "" || treat.treatmentType === 45
                        ? "Çıkarılabilir Cihaz"
                        : "" || treat.treatmentType === 46
                        ? "Fonksiyonel Cihaz"
                        : "" || treat.treatmentType === 47
                        ? "Kozmetik Ortodonti"
                        : "" || treat.treatmentType === 48
                        ? "Avans"
                        : "" || treat.treatmentType === 49
                        ? "Ankraj için Mini İmplantlar"
                        : "" || treat.treatmentType === 50
                        ? "Tutulan Dişlerin Traksiyonu"
                        : "" || treat.treatmentType === 51
                        ? "Eklemli Çalışma Modelleri"
                        : "" || treat.treatmentType === 52
                        ? "Davranışsal Tavsiye ve Stres Kontrolü"
                        : "" || treat.treatmentType === 53
                        ? "Oklüzal Atel"
                        : "" || treat.treatmentType === 54
                        ? "Oklüzal Denge"
                        : "" || treat.treatmentType === 55
                        ? "Eklemli Çalışma Modelleri"
                        : ""}
                    </td>
                    <td className="treatmentTableTd">
                      <span className="spanText">Dişler: </span>{" "}
                      {treat.teeths + ","}
                    </td>
                    <td>
                      <ul className="ulStyle">
                        {(treat.treatmentType === 25 ||
                          treat.treatmentType === 30 ||
                          treat.treatmentType === 31) && (
                          <li>
                            <span className="spanText">Yüzey: </span>
                            {treat.toothSurfaces + ","}
                          </li>
                        )}

                        {(treat.treatmentType === 13 ||
                          treat.treatmentType === 14 ||
                          treat.treatmentType === 15) && (
                          <li>
                            <span className="spanText">Kanal: </span>3
                          </li>
                        )}

                        {(treat.treatmentType === 37 ||
                          treat.treatmentType === 42) && (
                          <li>
                            <span className="spanText">Tip: </span>3
                          </li>
                        )}
                      </ul>
                    </td>
                    <td className="treatmentTableTd">
                      <span className="spanText">Fiyat: </span>{" "}
                      {treat.price}{" "}
                      {treat.currency === 1
                        ? "₺"
                        : "" || treat.currency === 2
                        ? "$"
                        : "" || treat.currency === 3
                        ? "€"
                        : ""}
                    </td>

                    <td className="treatmentTableTd">
                      <span className="spanText">
                        Tamamlayan Doktor:{" "}
                      </span>{" "}
                      {treat.isCompleted === true && (
                        <span> {treat.completedDoctor}</span>
                      )}
                      {treat.isCompleted === false && (
                        <span> Tamamlanmadı</span>
                      )}
                    </td>
                    {console.log(treat.completedDoctor)}
                    <td className="treatmentTableTd">
                      <Button
                        onClick={() => handleShow(treat.id)}
                        variant="outline-dark"
                      >
                        <svg
                          style={{
                            marginRight: "0.4rem",
                            marginBottom: "0.3rem",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-journal"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                          <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                        </svg>
                        Tedavi Notları
                      </Button>
                    </td>
                    {userStore.grandPermissions.planUpdate && (
                      <td>
                        <Form
                          style={{
                            paddingTop: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                         
                            <Form.Check
                              style={{ fontSize: "1rem" }}
                              type="switch"
                              label="Tamamlanmadı"
                              checked={treat.isCompleted}
                              onChange={(event) => handleCompleted(treat.id, event)}
                            />
                          
                         
                        </Form>
                      </td>
                    )}
                    {/* {userStore.grandPermissions.planUpdate && (
                    <td>
                      <Button
                        variant="outline-danger "
                        onClick={() => handleDelete(treat.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-archive"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                        </svg>
                      </Button>
                    </td>
                  )} */}
                  </tr>
                )}
              </tbody>
            ))
          )}
        </table>
        <Modal size="lg" show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Tedavi Yorumları</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TreatmentDescriptions />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
});
