import './patientsCard.css'
import { useStore } from '../../stores/store'
import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import PatientPlansList from '../patientPlansList/PatientPlansList'
import { Modal, Button, Container, Row, Col, Card } from 'react-bootstrap'
import PatientTreatments from '../patientTreatments/PatientTreatments'
import PatientCollections from '../finance/PatientCollections'
import AddPatient from '../addPatient/AddPatient'

function PatientsCard() {
  const { patientStore, userStore } = useStore()
  const { selectedPatient, createPatient, updatePatient } = patientStore

  const initialState = selectedPatient ?? {
    id: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    gender: true,
    note: '',
  }
  const [patient, setPatient] = useState(initialState)

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = (id) => {
    patientStore.selectPatient(id)
    patientStore.updating = true
    setShow(true)
  }

  useEffect(() => {
    setPatient(initialState)
  }, [selectedPatient])

  function handleSubmit() {
    patient.id ? updatePatient(patient) : createPatient(patient)
  }

  function handleInputChange(event) {
    const { name, value } = event.target
    setPatient({ ...patient, [name]: value })
  }

  return (
    <div style={{ width: '100%' }} className="patientsadd">
      <div className="patientsAddHead">
        <div className="patientsColumnGroup">
          <div>
            <label className="patientInfo">
              <span className="spanText">
                İsim Soyisim:{' '}
              </span>
              {patient.fullName}
            </label>
            <label className="patientInfo">
              <span className="spanText">
                Mail:{' '}
              </span>{' '}
              {patient.email}
            </label>
            <label className="patientInfo">
              <span className="spanText">
                Telefon Numarası:{' '}
              </span>
              {patient.phoneNumber}
            </label>
            <label className="patientInfo">
              <span className="spanText">
                Cinsiyet:{' '}
              </span>
              Erkek
            </label>
          </div>
          <div className="patientCommentGroup">
            <label htmlFor="">
              {' '}
              <span className="spanText">Not:</span>
              {patient.note}
            </label>
          </div>
          {userStore.grandPermissions.patientUpdate && (
            <Button
              className=""
              variant="outline-dark"
              style={{ height: '3rem' }}
              onClick={() => handleShow(patient.id)}
            >
              Düzenle
            </Button>
          )}
        </div>
      </div>
      <div className="patientsAddFormWrapper">
        {patientStore.updating && (
          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Düzenle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddPatient />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {/* <Row style={{ justifyContent: "center" }}>
          <Col xs="12">
            {patient.id !== "" && <PatientPlansList patientId={patient.id} />}
          </Col>
          <Col xs="12" className="my-2">
            <Card className="cardStyle">
              <Card.Body>
                <Card.Title className="patientCardTitle">Tedaviler</Card.Title>
                <PatientTreatments />
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" className="my-2">
            <Card className="cardStyle">
              <Card.Body>
                <Card.Title className="patientCardTitle">Ödemeler</Card.Title>
                <Col>
                  <PatientCollections />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
        <div className="row">
          <div className="col-sm-12 my-1">
            <div>
              {patient.id !== '' && <PatientPlansList patientId={patient.id} />}
            </div>
          </div>
          <div className="col-sm-6 my-1">
            <div className="card cardStyle">
              <div className="card-body">
                <h5 className="card-title patientCardTitle">Tedaviler</h5>
                <PatientTreatments />
              </div>
            </div>
          </div>
          {userStore.grandPermissions.collectionRead && (
            <div className="col-sm-6 my-1">
              <div className="card cardStyle">
                <div className="card-body">
                  <h5 className="card-title patientCardTitle">Ödemeler</h5>
                  <PatientCollections />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(PatientsCard)
