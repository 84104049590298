import './diagnose.css'
import Steps from '../steps/Steps'
import Resume from '../resume/Resume'
import DiagnoseMenu from '../diagnoseMenu/DiagnoseMenu'
import Conditions from '../conditions/Conditions'
import Ondogramsection from '../ondogramSection/Ondogramsection'
import DiagnoseRightMenu from '../diagnoseRightMenu/DiagnoseRightMenu'
import { useStore } from '../../stores/store';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
export default function Diagnose() {
  return (
    <div className='diagnoseWorkspace'>
      <div className="diagnosePlanEditor">
        <div className="diagnosePlanEditorHeader">
          <Steps/>
          <Resume/>
          
        </div>
        <div style={{width:"18rem"}} className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: "25%",
            backgroundColor:"red",
            "aria-valuenow": "33",
            "aria-valuemin": "0",
            "aria-valuemax": "100",
          }}
        >
          25%
        </div>
      </div>
        <div className="diagnoseContainer">
          <div className="diagnoseMenu">
            <DiagnoseMenu/>
          </div>
          <div className="diagnoseColumns">
              <div className="ondogram">
                <Conditions/>
                <Ondogramsection/>
                <DiagnoseRightMenu/>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}
