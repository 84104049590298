import "./login.css";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStore } from "../../stores/store";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default observer(function Register() {
  const { userStore } = useStore();


  const initialState = userStore.selectedUser ?? {
    id: " ",
    fullName: "",
    userName: "",
    email: "",
    password: "",
    roleCode: 0,
  };
  const [register, setLogin] = useState(initialState);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setLogin({ ...register, [name]: value });
    console.log(JSON.stringify(register))
  }
  function handleRoleCode(roleCode) {
    setLogin({ ...register, roleCode: roleCode });
  }
  function newUser() {
    var model = register;
    model.roleCode = parseInt(model.roleCode);
    userStore.newUser(model);
  }
  function updateUser() {
    var model = register;
    model.roleCode = parseInt(model.roleCode);
    userStore.updateUser(model);
  }
  
  // //*?Rol seçimi
  // const handleSelectRole = (roleCode) => {
  //   console.log(roleCode)
  //   setLogin({ ...register, [roleCode]: roleCode });
  //   console.log(register)

  // };

  return (
    <div className="loginContainer">
      <div className="loginWrapper text-center">
        <main className="form-signin">
      
          <div className="form-floating">
            <input
              name="fullName"
              value={register.fullName}
              onChange={handleInputChange}
              type="fullName"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
            />
            <br />
            <label htmlFor="floatingInput">İsim Soyisim</label>
          </div>

          <div className="form-floating">
            <input
              name="userName"
              value={register.userName}
              onChange={handleInputChange}
              type="userName"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
            />
            <br />
            <label htmlFor="floatingInput">Kullanıcı Adı</label>
          </div>

          <div className="form-floating">
            <input
              name="email"
              value={register.email}
              onChange={handleInputChange}
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
            />
            <br />
            <label htmlFor="floatingInput">Email Adresi</label>
          </div>

          <div className="form-floating">
            <input
              name="password"
              value={register.password}
              onChange={handleInputChange}
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
            />
            <br />
            <label htmlFor="floatingPassword">Şifre</label>
          </div>
          <div>
          <Autocomplete
                    style={{width:"100%"}}
                    onChange={(event, option) => handleRoleCode(option.value)}
                    name="roleCode"
                    className="my-1"
                        disablePortal
                        id="combo-box-demo"
                        options={[
                          { value: 1, label: "Admin" },
                          { value: 2, label: "Doktor" },
                          { value: 3, label: "Finans" },
                          { value: 4, label: "Asistan" },
                          { value: 5, label: "Banko" },
                          { value: 6, label: "Hasta Danışmanı" }
                        ]}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Kullanıcı Rolü" />
                        )}
                      />
          </div>
          {register.id ===" " &&                 
          <Button
            variant="danger"
            disabled={userStore.loading}
            onClick={newUser}
          >
            {userStore.loading
              ? "Kullanıcı oluşturuluyor."
              : "Kullanıcı oluşturmak için tıklayınız."}
          </Button>
          }
          {register.id !==" " &&                 
          <Button
            variant="danger"
            disabled={userStore.loading}
            onClick={updateUser}
          >
            {userStore.loading
              ? "Kullanıcı bilgileri güncelleniyor"
              : "Güncelle"}
          </Button>
          }

          <p className="mt-5 mb-3 text-muted">© 2022 KOLAYDENT</p>
        </main>
      </div>
    </div>
  );
});
