import React from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "../doctorTreatments/doctorTreatments.css";
export default observer(function ReportCompletedTreatments() {
  const { planStore } = useStore();
  const { dateFilter } = planStore;

  let totalPrice = 0;

  if (planStore.plans === undefined)
    return (
      <Button className="loadingBtnStyle" disabled>
        Yükleniyor...
      </Button>
    );

  return (
    <div>
      <div className="">
        <div>
          <div className="row text-center">
            <span className="reportTitle">Tamamlanan Tedavi Sayısı</span>
          </div>
          <Table bordered hover size="sm">
            <thead
              className="pricesTableBody"
              style={{ textAlign: "center", fontSize: 20 }}
            >
              <tr className="pricesTableThHead">
                <th className="pricesTableTh">Tedavi</th>
                <th className="pricesTableTh">Dişler</th>
                <th className="pricesTableTh">Yüzey</th>
                <th className="pricesTableTh">Fiyat</th>
                <th className="pricesTableTh">Durum</th>
              </tr>
            </thead>
            {planStore.plans.map((plan) =>
              plan.treatmentStages.map((stages) =>
                stages.treatments.map((treat) => (
                  <>
                    <tbody>
                      {treat.isCompleted === true && (
                        <tr className="diagnoseRmTableTd">
                          <td className="tdStyle">
                            
                            {treat.treatmentType === 1
                              ? "Önleme ve Hijyen"
                              : "" || treat.treatmentType === 2
                              ? "Profesyonel Diş Temizleme"
                              : "" || treat.treatmentType === 3
                              ? "Topikal Florür"
                              : "" || treat.treatmentType === 4
                              ? "Profesyonel Diş Temizleme"
                              : "" || treat.treatmentType === 5
                              ? "Ölçeklendirme/Kök Planlama"
                              : "" || treat.treatmentType === 6
                              ? "Cep Azaltma"
                              : "" || treat.treatmentType === 7
                              ? "Kemik Rejenerasyonu"
                              : "" || treat.treatmentType === 8
                              ? "Dişeti Grefti"
                              : "" || treat.treatmentType === 9
                              ? "Plastik Perio Cerrahisi"
                              : "" || treat.treatmentType === 10
                              ? "Kron Uzatma"
                              : "" || treat.treatmentType === 11
                              ? "Gingivektomi"
                              : "" || treat.treatmentType === 12
                              ? "Lazer Gingivektomi"
                              : "" || treat.treatmentType === 13
                              ? "Kök Kanal Tedavisi"
                              : "" || treat.treatmentType === 14
                              ? "Kanal Tedavisi"
                              : "" || treat.treatmentType === 16
                              ? "Apikektomi"
                              : "" || treat.treatmentType === 17
                              ? "İmplant"
                              : "" || treat.treatmentType === 18
                              ? "Diş Çekimi"
                              : "" || treat.treatmentType === 19
                              ? "Yirmilik Diş Çekimi"
                              : "" || treat.treatmentType === 20
                              ? "Sinüs Kaldırma"
                              : "" || treat.treatmentType === 21
                              ? "Kemik Rejenerasyonu"
                              : "" || treat.treatmentType === 22
                              ? "Tam Ark İmplantları/Protez"
                              : "" || treat.treatmentType === 23
                              ? "İmplant Çıkarma"
                              : "" || treat.treatmentType === 24
                              ? "Cerrahi Rehber"
                              : "" || treat.treatmentType === 25
                              ? "Restorasyon"
                              : "" || treat.treatmentType === 26
                              ? "Post"
                              : "" || treat.treatmentType === 27
                              ? "Atel"
                              : "" || treat.treatmentType === 28
                              ? "Dış Çamaşır Suyu"
                              : "" || treat.treatmentType === 29
                              ? "İç Çamaşır Suyu"
                              : "" || treat.treatmentType === 30
                              ? "Çekirdek Oluşturma"
                              : "" || treat.treatmentType === 31
                              ? "Geçici Restorasyon"
                              : "" || treat.treatmentType === 32
                              ? "Kaplama"
                              : "" || treat.treatmentType === 33
                              ? "Onlay"
                              : "" || treat.treatmentType === 34
                              ? "Taç"
                              : "" || treat.treatmentType === 35
                              ? "Köprü"
                              : "" || treat.treatmentType === 36
                              ? "Yapışkanlı Köprü"
                              : "" || treat.treatmentType === 37
                              ? "Takma Diş"
                              : "" || treat.treatmentType === 38
                              ? "Köprü/Protez Çıkarma"
                              : "" || treat.treatmentType === 39
                              ? "Eklemli Çalışma Modelleri"
                              : "" || treat.treatmentType === 40
                              ? "Geçici Köprü"
                              : "" || treat.treatmentType === 41
                              ? "Geçici Taç"
                              : "" || treat.treatmentType === 42
                              ? "Sabit Braketler"
                              : "" || treat.treatmentType === 43
                              ? "Lingual Braketler"
                              : "" || treat.treatmentType === 44
                              ? "Hizalayıcıları Temizle"
                              : "" || treat.treatmentType === 45
                              ? "Çıkarılabilir Cihaz"
                              : "" || treat.treatmentType === 46
                              ? "Fonksiyonel Cihaz"
                              : "" || treat.treatmentType === 47
                              ? "Kozmetik Ortodonti"
                              : "" || treat.treatmentType === 48
                              ? "Avans"
                              : "" || treat.treatmentType === 49
                              ? "Ankraj için Mini İmplantlar"
                              : "" || treat.treatmentType === 50
                              ? "Tutulan Dişlerin Traksiyonu"
                              : "" || treat.treatmentType === 51
                              ? "Eklemli Çalışma Modelleri"
                              : "" || treat.treatmentType === 52
                              ? "Davranışsal Tavsiye ve Stres Kontrolü"
                              : "" || treat.treatmentType === 53
                              ? "Oklüzal Atel"
                              : "" || treat.treatmentType === 54
                              ? "Oklüzal Denge"
                              : "" || treat.treatmentType === 55
                              ? "Eklemli Çalışma Modelleri"
                              : ""}
                          </td>
                          <td>
                            
                            {treat.teeths + ","}
                          </td>
                          {/* fiyat hesaplaması için kullanıldı */}
                          <td style={{ display: "none" }}>
                            <span style={{ color: "#0d68c8" }}>
                              Toplam Fiyat:{" "}
                            </span>{" "}
                            {(totalPrice += treat.price)}
                          </td>
                          {/* fiyat hesaplaması için kullanıldı */}
                          <td>
                            <ul className="ulStyle">
                              {(treat.treatmentType === 25 ||
                                treat.treatmentType === 30 ||
                                treat.treatmentType === 31) && (
                                <li>
                                 
                                  {treat.toothSurfaces + ","}
                                </li>
                              )}

                              {(treat.treatmentType === 13 ||
                                treat.treatmentType === 14 ||
                                treat.treatmentType === 15) && (
                                <li>
                                  <span style={{ color: "#0d68c8" }}>
                                    Kanal:{" "}
                                  </span>
                                  3
                                </li>
                              )}

                              {(treat.treatmentType === 37 ||
                                treat.treatmentType === 42) && (
                                <li>
                                  <span style={{ color: "#0d68c8" }}>
                                    Tip:{" "}
                                  </span>
                                  3
                                </li>
                              )}
                            </ul>
                          </td>
                          <td>
                            
                            {treat.price}{" "}
                            {treat.currency === 1
                              ? "₺"
                              : "" || treat.currency === 2
                              ? "$"
                              : "" || treat.currency === 3
                              ? "€"
                              : ""}
                          </td>
                          <td>
                            {treat.isCompleted === true && (
                              <p
                                className="completedBtn"
                                style={{
                                  color: "white",
                                  backgroundColor: "green",
                                }}
                              >
                                {" "}
                                <span className="mx-1">TAMAMLANDI</span>{" "}
                              </p>
                            )}
                            {treat.isCompleted === false && (
                              <p
                                className="completedBtn"
                                style={{
                                  color: "white",
                                  backgroundColor: "red",
                                }}
                              >
                                <span className="mx-1">TAMAMLANMADI</span>
                              </p>
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </>
                ))
              )
            )}
          </Table>
        </div>
      </div>
    </div>
  );
});
