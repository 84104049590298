import React from "react";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useStore } from "../../stores/store";
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default observer(function UpdatePrice() {
  const { pricesStore } = useStore();
  const initialState = pricesStore.selectedPrice ?? {
    id: "",
    price: 0,
    currency: 0,
    lowestPrice: 0,
    definition: "",
  };
  const [prices, setPrices] = useState(initialState);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setPrices({ ...prices, [name]: value });
  }

  function handleCurrency(value) {
    if (value === "₺") prices.currency = 1;
    if (value === "$") prices.currency = 2;
    if (value === "€") prices.currency = 3;
  }

  function updatePrice() {
    const model = prices;
    model.currency = parseInt(model.currency);
    pricesStore.updatePrice(model);
    console.log(prices);
  }

  return (
    <div className="cardComponent">
      <Container style={{ width: "95%" }} className="containerStyle">
        <Form className="my-4">
          <Row>
            <Col>
              <TextField
                className="my-1"
                onChange={handleInputChange}
                label="Fiyat"
                name="price"
                value={prices.price}
              />
            </Col>
            <Col>
            <TextField
                className="my-1"
                onChange={handleInputChange}
                label="Minimum Fiyat"
                name="lowestPrice"
                value={prices.lowestPrice}
              />
             
            </Col>
          </Row>
          <Row>
            <Col>
            <TextField
                name="definition"
                style={{ width: "100%" }}
                className="my-1"
                label="Açıklama"
                value={prices.definition}
                onChange={handleInputChange}
              />
            </Col>
            
          </Row>
          <select onChange={(e) => handleCurrency(e.target.value)}>
            <option>Para Birimi Seçiniz</option>
            <option>₺</option>
            <option>$</option>
            <option>€</option>
          </select>
        </Form>

        <Button
          variant="danger"
          disabled={pricesStore.loading}
          onClick={updatePrice}
          style={{
            marginBottom: "1rem",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          type="submit"
        >
          {pricesStore.loading ? "Ekleniyor..." : "Eklemek için tıklayınız"}
        </Button>
      </Container>
    </div>
    // <div
    //   style={{
    //     marginLeft: "auto",
    //     marginRight: "auto",
    //     marginTop: "2rem",
    //     textAlign: "center",
    //     width: "80%",
    //   }}
    // >
    //   <form className="row g-3 needs-validation" novalidate>
    //     <div
    //       className="col-md-10"
    //       style={{ marginLeft: "auto", marginRight: "auto" }}
    //     >
    //       <label for="validationCustom02" className="form-label">
    //         Para Birimi
    //       </label>
    //       <select onChange={(e) => handleCurrency(e.target.value)}>
    //         {prices.currency===1&&
    //         <option>₺</option>}
    //         {prices.currency===2&&
    //         <option>$</option>}
    //         {prices.currency===3&&
    //         <option>€</option>}
    //         <option>₺</option>
    //         <option>$</option>
    //         <option>€</option>
    //       </select>
    //     </div>
    //     <div
    //       className="col-md-10"
    //       style={{ marginLeft: "auto", marginRight: "auto" }}
    //     >
    //       <label for="validationCustom02" className="form-label">
    //         Fiyat
    //       </label>
    //       <input
    // name="price"
    //         type="number"
    //         className="form-control"
    //         onChange={handleInputChange}
    //         id="validationCustom02"
    // value={prices.price}
    //         required
    //       />
    //     </div>

    //     <div
    //       className="col-md-10"
    //       style={{ marginLeft: "auto", marginRight: "auto" }}
    //     >
    //       <label for="validationCustom02" className="form-label">
    //         Açıklama
    //       </label>
    //       <input
    //         name="definition"
    //         type="text"
    //         className="form-control"
    //         onChange={handleInputChange}
    //         id="validationCustom02"
    //         value={prices.definition}
    //         required
    //       />
    //     </div>

    //     <div className="col-12">
    //       <div className="daySelectBox"></div>

    // <button
    //   disabled={pricesStore.loading}
    //   onClick={updatePrice}
    //   style={{
    //     marginBottom: "1rem",
    //     marginLeft: "auto",
    //     marginRight: "auto",
    //   }}
    //   className="btn btn-primary"
    //   type="submit"
    // >
    //   {pricesStore.loading
    //     ? "Ekleniyor..."
    //     : "Eklemek için tıklayınız..."}
    // </button>
    //     </div>
    //   </form>
    // </div>
  );
});
