import React from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Container,
  Form,
  ButtonGroup,
} from "react-bootstrap";
import "./finance.css";
import TextField from "@mui/material/TextField";
import Receipt from "../receip/Receipt";
import moment from "moment";
import Payment from "../payment/Payment";
import PaymentAdd from "./PaymentAdd";

export default observer(function PatientCollections() {
  const { drStore, planStore, financeStore, userStore } = useStore();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    financeStore.selectedPayment = undefined;
    financeStore.addingPayment = true;
  };
  const [showPayment, setShowPayment] = useState(false);

  const handleClosePayment = () => {
    setShowPayment(false);
  };
  const handleShowPayment = (id) => {
    if (id === undefined) {
      financeStore.selectPayment(id);
      setShowPayment(true);
    } else {
      financeStore.selectPayment(id);
      setShowPayment(true);
    }
  };

  useEffect(() => {
    financeStore.getPaymentsByDoctorId(drStore.selectedDoctor.id);
    drStore.calculateDoctorPayments(financeStore.payments);
  }, []);

  useEffect(() => {
    drStore.calculateDoctorPayments(financeStore.payments);
  }, [planStore.dateFilter, financeStore.loading]);

  if (!planStore.dateFilter)
    return (
      <Button variant="danger" className="loadingBtnStyle" disabled>
        Lütfen tarih aralığı seçiniz...
      </Button>
    );

  if (!drStore.paymentCalculated)
    return (
      <Button variant="danger" className="loadingBtnStyle" disabled>
        Hesaplanıyor...
      </Button>
    );

  return (
    <div>
      <Container>
        <Form className="my-4" style={{ textAlign: "center" }}>
          <Row>
            <Col>
              <TextField
                className="my-1"
                label="Toplam Plan Sayısı"
                value={drStore.earning.totalPlanNumbers}
              />
            </Col>
            <Col>
              <TextField
                className="my-1"
                label="Toplam Tedavi Sayısı"
                value={drStore.earning.totalTreatmentNumbers}
              />
            </Col>
            <Col>
              <TextField
                className="my-1"
                label="Toplam Hakediş"
                value={drStore.earning.totalEarning}
              />
            </Col>
            <Col>
              <TextField
                className="my-1"
                label="Kalan Hakediş"
                value={drStore.earning.earningLeft}
              />
            </Col>
          </Row>
          {financeStore.paymentsLoaded &&
            financeStore.payments.map((payment, index) => (
              <Row>
                <Col>
                  <TextField
                    className="my-1"
                    label={index + 1 + ". Ödeme"}
                    value={payment.payments}
                  />
                </Col>
                <Col>
                  <TextField
                    className="my-1"
                    label="Ödeme Tarihi"
                    value={moment(payment.createdTime)
                      .utc()
                      .format("DD-MM-YYYY")}
                  />
                </Col>
                <Col>
                  <ButtonGroup className="btnGroupStyle">
                    <Button
                      variant="outline-primary"
                      className="my-1 btnStyle"
                      onClick={() => handleShowPayment(payment.id)}
                    >
                      Görüntüle
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col></Col>
              </Row>
            ))}

          <Row>
            <Col></Col>
            <Col></Col>
            <Col>
              <ButtonGroup className="btnGroupStyle">
                {userStore.grandPermissions.paymentCreate &&
                  drStore.earning.earningLeft !== 0 && (
                    <Button
                      variant="outline-dark"
                      className="my-1 btnStyle"
                      onClick={() => {
                        handleShow();
                      }}
                    >
                      Ödeme Yap
                    </Button>
                  )}
              </ButtonGroup>
            </Col>
            <Col></Col>
          </Row>
          {financeStore.addingPayment && (
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Ödeme Sayfası</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PaymentAdd />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Kapat
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          <Modal size="xl" show={showPayment} onHide={handleClosePayment}>
            <Modal.Header closeButton>
              <Modal.Title>Ödeme Görüntüleme Sayfası</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Payment />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClosePayment}>
                Kapat
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      </Container>
    </div>
  );
});
