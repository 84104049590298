import React, { useState } from "react";
import "./navbar.css";
import { NotificationsNone, Language } from "@material-ui/icons";
import { Dropdown, DropdownButton, Modal, Button } from "react-bootstrap";
import Register from "../../components/login/Register";
import UpdateUser from "../../components/login/UpdateUser";
import { useStore } from "../../stores/store";
import Wizard from "../../components/wizard/Wizard";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";


export default observer(function Navbar() {
  let navigate = useNavigate();

  const { userStore, commonStore } = useStore();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    userStore.editNewUser = true;
  };

  const [updtShow, updtsetShow] = useState(false);

  const updthandleClose = () => updtsetShow(false);
  const updthandleShow = () => {
    updtsetShow(true);
    userStore.editing = true;
  };

  const [showNewTreatment, setShowNewTreatment] = useState(false);

  const handleCloseNewTreatment = () => setShowNewTreatment(false);
  const handleShowNewTreatment = () => {
    setShowNewTreatment(true);
    userStore.closeWizard = true;
  };
  return (
    <div>
      <nav
        style={{ fontSize: 20, borderBottom: "solid 1px #F22E3E" }}
        className="navbar navbar-expand-lg navbar-light"
      >
        <a
          style={{ marginLeft: "1rem", color: "#F22E3E" }}
          className="navbar-brand logo logo-hvr"
          href="/home"
        >
           <img
             
             alt="logo"
             src={require('../../assets/images/loginLogo.png')}
             width={120}
             height={120}
           />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {userStore.grandPermissions.planCreate && (
              <li className="nav-item active">
                <a
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  onClick={handleShowNewTreatment}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-folder-plus"
                    viewBox="0 0 16 16"
                    style={{
                      marginRight: "3px",
                      marginBottom: "3px",
                      color: "#F22E3E",
                    }}
                  >
                    <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z" />
                    <path d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z" />
                  </svg>
                  Tedavi Planı Oluştur
                </a>
              </li>
            )}
            {userStore.grandPermissions.doctorRead && (
              <li className="nav-item">
                <a className="nav-link" href="/doctors">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-book"
                    viewBox="0 0 16 16"
                    style={{
                      marginRight: "3px",
                      marginBottom: "3px",
                      color: "#F22E3E",
                    }}
                  >
                    <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                  </svg>
                  Doktorlar
                </a>
              </li>
            )}
            {userStore.grandPermissions.patientRead && (
              <li className="nav-item" style={{}}>
                <a className="nav-link" href="/patientlist">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-people"
                    viewBox="0 0 16 16"
                    style={{
                      marginRight: "3px",
                      marginBottom: "3px",
                      color: "#F22E3E",
                    }}
                  >
                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                  </svg>
                  Hastalar
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="topBarIconContainer">
          <NotificationsNone
            type="button"
            id="dropdownProfileNotificationBtn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <span className="topIconBadge">1</span>
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownProfileNotificationBtn"
          >
            <div className="list-group dropDownProfileNotificationBtnWrapper">
              <a
                href="/"
                className="list-group-item list-group-item-action active"
                aria-current="true"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-2 profileNoticationItemHead">
                    List group item heading
                  </h5>
                  <small>3 days ago</small>
                </div>
                <p className="mb-1 profileNoticationItemSub">
                  Some placeholder content in a paragraph.
                </p>
              </a>
              <a
                href="/"
                className="list-group-item list-group-item-action"
                aria-current="true"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-2 profileNoticationItemHead">
                    List group item heading
                  </h5>
                  <small>3 days ago</small>
                </div>
                <p className="mb-1 profileNoticationItemSub">
                  Some placeholder content in a paragraph.
                </p>
              </a>
            </div>
          </ul>
        </div>
        <div className="topBarIconContainer">
          <Language
            type="button"
            id="dropdownTopBarLanguageBtn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownTopBarLanguageBtn"
          >
            <div className="topBarLanguageWrapper d-flex flex-column justify-content-center align-items-center">
              <span className="topBarLanguageEnSpan">EN</span>
              <span className="topBarLanguageTrSpan">TR</span>
            </div>
          </ul>
        </div>
        <div
          style={{ marginRight: "1rem" }}
          className="dropdown userAccordionTopProfileMenu border-0"
        >
          <DropdownButton
            id="dropdown-basic-button"
            title={sessionStorage.getItem("userName")}
            variant="danger"
          >
            <Dropdown.Item
              onClick={() => {
                sessionStorage.clear();
                commonStore.logOut();
                navigate("/");
              }}
            >
              Çıkış Yap
            </Dropdown.Item>
            {userStore.grandPermissions.userUpdate && (
              <Dropdown.Item onClick={updthandleShow}>
                Kullanıcı Profili
              </Dropdown.Item>
            )}
            {userStore.grandPermissions.userCreate && (
              <Dropdown.Item onClick={handleShow}>Yeni Kullanıcı</Dropdown.Item>
            )}
          </DropdownButton>
        </div>
        {userStore.editNewUser && (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Yeni Kullanıcı</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Register />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {userStore.editing && (
          <Modal show={updtShow} onHide={updthandleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Kullanıcı Profili</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <UpdateUser />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={updthandleClose}>
                Kapat
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {userStore.closeWizard && (
          <Modal
            size="lg"
            show={showNewTreatment}
            onHide={handleCloseNewTreatment}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Tedavi Planı Oluştur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Wizard />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseNewTreatment}>
                Kapat
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </nav>
    </div>
  );
});
