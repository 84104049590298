import react from 'react'
import { observer } from 'mobx-react-lite'
import { Button, ButtonGroup } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useStore } from '../../stores/store'
import { useState, useEffect } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Modal, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import AddPatient from '../addPatient/AddPatient'

function AddAppointment() {
  let navigate = useNavigate()
  const { appointmentStore, patientStore } = useStore()
  const { editedAppointment } = appointmentStore
  const { loadPatientWizard } = patientStore

  const initialState = editedAppointment ?? {
    id: '',
    doctorId: '',
    patientId: '',
    title: '',
    appointmentStart: '',
    appointmentEnd: '',
    note: '',
    confirmation: false,
  }
  const [appointment, setAppointment] = useState(initialState)
  useEffect(() => {
    setAppointment(initialState)
  }, [editedAppointment])

  useEffect(() => {
    loadPatientWizard()
  }, [patientStore.patients])

  function handleInputChange(event) {
    const { name, value } = event.target
    setAppointment({ ...appointment, [name]: value })
  }
  function handleConfirmation(event) {
    const { name, checked } = event.target
    setAppointment({ ...appointment, [name]: checked })
  }
  function handleUpdateAppointment() {
    appointmentStore.updateAppointment(appointment)
  }
  const [showPatient, setShowPatient] = useState(false)

  const handleClosePatient = () => setShowPatient(false)
  const handleShowPatient = () => {
    setShowPatient(true)
    patientStore.adding = true
  }

  function handleCreateAppointment() {
    appointmentStore.createAppointment(appointment)
  }

  function handleDeleteAppointment() {
    if (
      window.confirm(
        `${appointment.title}' bilgili randevuyu silmek istediğinizden emin misiniz?'`,
      )
    ) {
      appointmentStore.deleteAppointment(appointment.id)
    }
  }

  return (
    <div
      className="container"
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2rem',
        textAlign: 'center',
        width: '80%',
      }}
    >
      <form className="row g-3 needs-validation" novalidate>
        <div
          className="col-md-10"
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        >
          <label />
        </div>
        <div
          className="col-md-10 "
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        >
          {appointment.title !== '' && (
            <label className="patientİnfoStyle">
              <span className="titleStyle">{appointment.title}</span>
            </label>
          )}

          <div>
            {appointment.title !== '' && (
              <div style={{ display: 'flex', marginLeft: '1rem' }}>
                <Autocomplete
                  disablePortal
                  className="patientSelect"
                  onChange={(event, newValue) => {
                    appointment.patientId = newValue.id
                    appointment.title =
                      newValue.identity + ' ' + '-' + ' ' + newValue.label
                    console.log(appointment)
                  }}
                  id="combo-box-demo"
                  options={patientStore.patientsWizard}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Lütfen Bir Hasta Seçiniz" />
                  )}
                />
              </div>
            )}
            {appointment.title === '' && (
              <Autocomplete
                disablePortal
                className="patientSelect"
                onChange={(event, newValue) => {
                  appointment.patientId = newValue.id
                  appointment.title =
                    newValue.identity + ' ' + '-' + ' ' + newValue.label
                }}
                id="combo-box-demo"
                options={patientStore.patientsWizard}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Lütfen Bir Hasta Seçiniz" />
                )}
              />
            )}
            {appointment.title === '' && (
              <Button
                className="newPatientBtn"
                variant="outline-dark"
                onClick={handleShowPatient}
              >
                YENİ HASTA OLUŞTUR
              </Button>
            )}

            {appointment.title !== '' && (
              <TextField
                name="note"
                style={{ width: '100%' }}
                className="my-1"
                label="Not"
                value={appointment.note}
                onChange={handleInputChange}
              />
            )}
            {patientStore.adding && (
              <Modal size="lg" show={showPatient} onHide={handleClosePatient}>
                <Modal.Header closeButton>
                  <Modal.Title>Yeni Hasta Oluştur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <AddPatient />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosePatient}>
                    Kapat
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </div>
        </div>
        <div>
          <label for="validationCustom02" className="form-label">
            <p>
              <span style={{ fontWeight: 'bold' }}>Başlangıç Saati: </span>
              {appointment.appointmentStart.substring(11, 16)}
            </p>
          </label>
          <br />
          <label for="validationCustom02" className="form-label">
            <p>
              <span style={{ fontWeight: 'bold' }}>Bitiş Saati: </span>
              {appointment.appointmentEnd.substring(11, 16)}
            </p>
          </label>
        </div>
        <div className="row">
        <div className="col-12">
          <div className="formCheckButton">
            <input name='confirmation' type="checkBox" onClick={(e) => handleConfirmation(e)}
            checked={appointment.confirmation} id="custom-switch"/>
            <span>{appointment.confirmation ? 'Gelme Durumu: Onaylandı' : 'Gelme Durumu Onaylanmadı'}</span>
          </div>
         
        {/* <Form>
          <Form.Check
            // style={{
            //   width: '3rem',
            //   marginLeft: '4rem',
            //   marginBottom: '1rem',
            // }}
            type="switch"
            id="custom-switch"
            label={
              appointment.confirmation
                ? 'Gelme Durumu: Onaylandı'
                : 'Gelme Durumu: Onaylanmadı'
            }
            name="confirmation"
            onClick={(e) => handleConfirmation(e)}
            checked={appointment.confirmation}
          />
        </Form> */}
        </div>

        </div>
       

        <div className="col-12">
          <div className="daySelectBox"></div>
          
           <div className="appointmentActionButtons">
           {appointment.id === '' && (
              <Button
                variant="danger"
                disabled={appointmentStore.loading}
                onClick={handleCreateAppointment}
                className="mb-3"
                type="submit"
              >
                {appointmentStore.loading ? 'Ekleniyor...' : 'Ekle'}
              </Button>
            )}
            {appointment.id !== '' && (
              <Button
                variant="outline-danger"
                disabled={appointmentStore.loading}
                onClick={handleUpdateAppointment}
                className="mb-3"
                type="submit"
              >
                {appointmentStore.loading ? 'Güncelleniyor...' : 'Güncelle'}
              </Button>
            )}
            <Button
              variant="dark"
              disabled={appointmentStore.loading}
              onClick={() => {
                patientStore.selectPatient(appointment.patientId)
                navigate('/patientscard')
              }}
              className="mb-3"
              type="submit"
            >
              {appointmentStore.loading ? 'Açılıyor...' : 'Hasta Kartını Aç'}
            </Button>
            <Button
              variant="outline-danger"
              disabled={appointmentStore.loading}
              onClick={handleDeleteAppointment}
              className="mb-3"
              type="submit"
            >
              {appointmentStore.loading ? 'Siliniyor...' : 'Randevuyu Sil'}
            </Button>
           </div>
          
        </div>
      </form>
    </div>
  )
}

export default observer(AddAppointment)
