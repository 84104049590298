import React from "react";
import { Button, Spinner, Pagination, Modal } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useStore } from "../../stores/store";
import { useNavigate } from "react-router-dom";
import UpdatePrice from "./UpdatePrice";
import "./prices.css";

export default observer(function PricesList() {
  let navigate = useNavigate();
  const { pricesStore, userStore } = useStore();
  useEffect(() => {
    pricesStore.getPrices();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (id) => {
    pricesStore.selectPrice(id);
    setShow(true);
    pricesStore.editing = true;
  };

  function handleDelete(id) {
    pricesStore.deletePrice(id);
  }
  const handleList = (page, size) => {
    pricesStore.getList(page, size);
  };

  if (pricesStore.prices === undefined) {
    return (
      <Button className="loadingBtnStyle"variant="danger" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Fiyat Listesi Yükleniyor...
      </Button>
    );
  }

  return (
    <div className="pricesTableList">
      <table className="pricesListTable table table-hover table-lg table-responsive-md">
        <thead
          className="pricesTableBody"
          style={{ textAlign: "center", fontSize: 20 }}
        >
          <tr className="pricesTableThHead">
            <th className="pricesTableTh">Tedavi İsmi</th>
            <th className="pricesTableTh">Fiyat</th>
            <th className="pricesTableTh">Minimum Fiyat</th>
            <th className="pricesTableTh">Para Birimi</th>
            <th className="pricesTableTh">Açıklama</th>
            {userStore.grandPermissions.priceUpdate && (
              <th className="pricesTableEdit">Güncelle</th>
            )}
          </tr>
        </thead>
        <tbody className="pricesTableTbody">
          {pricesStore.prices.map((fiyat) => (
            <tr>
              <td className="pricesTableTd">
                {fiyat.name === "Prevention and Hygiene"
                  ? "Önleme ve Hijyen"
                  : "" || fiyat.name === "ProfessionalToothCleaning"
                  ? "Profesyonel Diş Temizleme"
                  : "" || fiyat.name === "TopicalFluoride3"
                  ? "Topikal Florür"
                  : "" || fiyat.name === "ProfessionalToothCleaning2"
                  ? "Profesyonel Diş Temizleme"
                  : "" || fiyat.name === "ScalingOrRootPlanning"
                  ? "Ölçeklendirme/Kök Planlama"
                  : "" || fiyat.name === "PokcetReduction"
                  ? "Cep Azaltma"
                  : "" || fiyat.name === "BoneRegeneration"
                  ? "Kemik Rejenerasyonu"
                  : "" || fiyat.name === "GingivalGraft"
                  ? "Dişeti Grefti"
                  : "" || fiyat.name === "PlasticPerioSurgery"
                  ? "Plastik Perio Cerrahisi"
                  : "" || fiyat.name === "CrownLengthening"
                  ? "Kron Uzatma"
                  : "" || fiyat.name === "Gingivectomy"
                  ? "Gingivektomi"
                  : "" || fiyat.name === "LaserGingivectomy"
                  ? "Lazer Gingivektomi"
                  : "" || fiyat.name === "RootCanalTreatment"
                  ? "Kök Kanal Tedavisi"
                  : "" || fiyat.name === "RootCanalReTreatment"
                  ? "Kanal Tedavisi"
                  : "" || fiyat.name === "RootCanalReTreatmentInstrumentRemoval"
                  ? "Kök Kanalı Yeniden Tedavi"
                  : "" || fiyat.name === "Apicectomy"
                  ? "Apikektomi"
                  : "" || fiyat.name === "Implant"
                  ? "İmplant"
                  : "" || fiyat.name === "Extraction"
                  ? "Diş Çekimi"
                  : "" || fiyat.name === "WisdomToothExtraction"
                  ? "Yirmilik Diş Çekimi"
                  : "" || fiyat.name === "SinusLift"
                  ? "Sinüs Kaldırma"
                  : "" || fiyat.name === "BoneRegeneration2"
                  ? "Kemik Rejenerasyonu"
                  : "" || fiyat.name === "FullArchImplantsOrDenture"
                  ? "Tam Ark İmplantları/Protez"
                  : "" || fiyat.name === "RemoveImplant"
                  ? "İmplant Çıkarma"
                  : "" || fiyat.name === "SurgicalGuide"
                  ? "Cerrahi Rehber"
                  : "" || fiyat.name === "Restoration"
                  ? "Restorasyon"
                  : "" || fiyat.name === "Post"
                  ? "Post"
                  : "" || fiyat.name === "Splint"
                  ? "Atel"
                  : "" || fiyat.name === "ExternalBleach"
                  ? "Dış Çamaşır Suyu"
                  : "" || fiyat.name === "InternalBleach"
                  ? "İç Çamaşır Suyu"
                  : "" || fiyat.name === "CoreBuldUp"
                  ? "Çekirdek Oluşturma"
                  : "" || fiyat.name === "ProvisionalRestoration"
                  ? "Geçici Restorasyon"
                  : "" || fiyat.name === "Veneer"
                  ? "Kaplama"
                  : "" || fiyat.name === "Onlay"
                  ? "Onlay"
                  : "" || fiyat.name === "Onlay"
                  ? "Taç"
                  : "" || fiyat.name === "Bridge"
                  ? "Köprü"
                  : "" || fiyat.name === "AdhesiveBridge"
                  ? "Yapışkanlı Köprü"
                  : "" || fiyat.name === "Denture"
                  ? "Takma Diş"
                  : "" || fiyat.name === "RemoveBridgeOrDenture"
                  ? "Köprü/Protez Çıkarma"
                  : "" || fiyat.name === "ArticulatedStudyModels"
                  ? "Eklemli Çalışma Modelleri"
                  : "" || fiyat.name === "ProvisionalBridge"
                  ? "Geçici Köprü"
                  : "" || fiyat.name === "ProvisionalCrown"
                  ? "Geçici Taç"
                  : "" || fiyat.name === "FixedBraces"
                  ? "Sabit Braketler"
                  : "" || fiyat.name === "LingualBraces"
                  ? "Lingual Braketler"
                  : "" || fiyat.name === "ClearAligners"
                  ? "Hizalayıcıları Temizle"
                  : "" || fiyat.name === "RemovableAppliance"
                  ? "Çıkarılabilir Cihaz"
                  : "" || fiyat.name === "FunctionalAppliance"
                  ? "Fonksiyonel Cihaz"
                  : "" || fiyat.name === "CosmeticOrthodontics"
                  ? "Kozmetik Ortodonti"
                  : "" || fiyat.name === "Retainer"
                  ? "Avans"
                  : "" || fiyat.name === "MiniImplantsForAnchorage"
                  ? "Ankraj için Mini İmplantlar"
                  : "" || fiyat.name === "TractionOfRetainedTeeth"
                  ? "Tutulan Dişlerin Traksiyonu"
                  : "" || fiyat.name === "ArticulatedStudyModels2"
                  ? "Eklemli Çalışma Modelleri"
                  : "" || fiyat.name === "BehaviouralAdviceAndStressControl"
                  ? "Davranışsal Tavsiye ve Stres Kontrolü"
                  : "" || fiyat.name === "OcclusalSplint"
                  ? "Oklüzal Atel"
                  : "" || fiyat.name === "OcclusalEquilibration"
                  ? "Oklüzal Denge"
                  : "" || fiyat.name === "ArticulatedStudyModels3"
                  ? "Eklemli Çalışma Modelleri"
                  : ""}
              </td>
              <td className="pricesTableTd">{fiyat.price}</td>
              <td className="pricesTableTd">{fiyat.lowestPrice}</td>
              <td className="pricesTableTd">
                {fiyat.currency === 0
                  ? ""
                  : fiyat.currency === 1
                  ? "₺"
                  : fiyat.currency === 2
                  ? "$"
                  : "€"}
              </td>
              <td className="pricesTableTd">{fiyat.definition}</td>
              {userStore.grandPermissions.priceUpdate && (
                <td className="pricesTableEdit pricesTableTd">
                  <Button
                    onClick={() => handleShow(fiyat.id)}
                    variant="outline-dark"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fillRule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div></div>
      {pricesStore.editing && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Doktor Bilgileri</Modal.Title>
          </Modal.Header>
          <UpdatePrice />
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Pagination style={{ justifyContent: "center", color:'green' }}>
        <Pagination.First />
        <Pagination.Prev />
        <Pagination.Item className="paginationButtonColor" onClick={() => handleList(1, 10)}><span className="paginationButtonColor">1</span></Pagination.Item>
        <Pagination.Item className="paginationButtonColor" onClick={() => handleList(2, 10)}><span className="paginationButtonColor">2</span></Pagination.Item>
        <Pagination.Item className="paginationButtonColor" onClick={() => handleList(3, 10)}><span className="paginationButtonColor">3</span></Pagination.Item>
        <Pagination.Item className="paginationButtonColor" onClick={() => handleList(4, 10)}><span className="paginationButtonColor">4</span></Pagination.Item>
        <Pagination.Item className="paginationButtonColor" onClick={() => handleList(5, 10)}><span className="paginationButtonColor">5</span></Pagination.Item>
        <Pagination.Item className="paginationButtonColor" onClick={() => handleList(6, 10)}><span className="paginationButtonColor">6</span></Pagination.Item>
        <Pagination.Item  className="paginationButtonColor" onClick={() => handleList(7, 10)}><span className="paginationButtonColor">7</span></Pagination.Item>
        <Pagination.Next />
        <Pagination.Last />
      </Pagination>
    </div>
  );
});
