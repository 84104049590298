import "./login.css";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStore } from "../../stores/store";
import { Button } from "react-bootstrap";

export default observer(function UpdateUser() {
  const { userStore } = useStore();
  const initialState = userStore.user ?? {
    id: "",
    fullName: "",
    userName: "",
    email: "",
    roleCode: 0,
    oldPassword: "",
    newPassword: "",
  };
  const [userUpdate, setUser] = useState(initialState);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setUser({ ...userUpdate, [name]: value });
  }

  function updtUser() {
    const model = userUpdate;
    userStore.updateUser(model);
    console.log(model);
  }

  return (
    <div className="loginContainer">
      <div className="loginWrapper text-center">
        <main className="form-signin">
          <div>
            <label className="spanText">
              Kullanıcı Tipi:{" "}
            </label>
            <select className="userSelect" name="" id="">
              <option value="">Doktor</option>
              <option value="">Hasta</option>
              <option value="">Asistan</option>
              <option value="">Banko</option>
              <option value="">Finans</option>
            </select>
          </div>
          <div className="form-floating">
            <input
              name="fullName"
              value={userUpdate.fullName}
              onChange={handleInputChange}
              type="fullName"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
            />
            <br />
            <label htmlFor="floatingInput">İsim Soyisim</label>
          </div>

          <div className="form-floating">
            <input
              name="userName"
              value={userUpdate.userName}
              onChange={handleInputChange}
              type="userName"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
            />
            <br />
            <label htmlFor="floatingInput">Kullanıcı Adı</label>
          </div>

          <div className="form-floating">
            <input
              name="email"
              value={userUpdate.email}
              onChange={handleInputChange}
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
            />
            <br />
            <label htmlFor="floatingInput">Email Adresi</label>
          </div>

          <div className="form-floating">
            <input
              name="oldPassword"
              value={userUpdate.oldPassword}
              onChange={handleInputChange}
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
            />
            <br />
            <label htmlFor="floatingPassword">Eski Şifre</label>
          </div>
          <div className="form-floating">
            <input
              name="newPassword"
              value={userUpdate.newPassword}
              onChange={handleInputChange}
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
            />
            <br />
            <label htmlFor="floatingPassword">Yeni Şifre</label>
          </div>

          <Button
            variant="outline-success"
            disabled={userStore.loading}
            onClick={updtUser}
          >
            {userStore.loading
              ? "Güncelleniyor."
              : "Güncellemek için tıklayınız."}
          </Button>

          <p className="mt-5 mb-3 text-muted">© 2022 KOLAYDENT</p>
        </main>
      </div>
    </div>
  );
});
