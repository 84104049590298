import React from "react";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import "./test.css";
import "../finance/finance.css";
import { Button, Dropdown, DropdownButton , Accordion} from "react-bootstrap";
export default observer(function Test() {
  return (
    <div >
  <input id="dursunicin" type="text" />
    </div>
  );
});
