import "./ondogramsection.css";
import { useEffect, useLayoutEffect, useState } from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import ReactDOM from "react-dom";

function OndogramSectionResult() {
  const { conditionStore, planStore } = useStore();
  const { odontogram, resetForm } = conditionStore;
  const { selectedPlan } = planStore;

  const initialState = planStore.selectedPlan ?? {
    id: "",
    name: "",
    patientID: "",
    createdTime: "",
    isActive: 1,
    conditions: [],
    treatmentStages: {
      id: "",
      name: "",
      description: "",
      planId: "",
      createdTime: "",
      isActive: 1,
      treatments: {},
    },
  };
  const [plan, setPlan] = useState(initialState);

  useEffect(() => {
    setPlan(initialState);
    sessionStorage.setItem("planID", JSON.stringify(plan));
    conditionStore.loadOdontogram(plan);
  }, [selectedPlan, resetForm]);

  return (
    <div className="odontogram-section">
      <div className="odontogram-container">
        <div className="odontogram" style={{pointerEvents:"none"}}>
          {odontogram.map((teeth, index) => (
            <div
              onClick={() =>
                conditionStore.magicFunction(
                  undefined,
                  teeth.name,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                )
              }
              className={
                teeth.class + " " + teeth.selected + " " + teeth.missing
              }
              id={teeth.name}
              key={index}
            >
              {!teeth.missingTeeth && <div className="tooth-img"></div>}

              {teeth.carries.buccal && (
                <div className="diagnosis caries buccal"></div>
              )}
              {teeth.carries.distal && (
                <div className="diagnosis caries distal"></div>
              )}
              {teeth.carries.lingual && (
                <div className="diagnosis caries lingual"></div>
              )}
              {teeth.carries.mesial && (
                <div className="diagnosis caries mesial"></div>
              )}
              {teeth.carries.occlusal && (
                <div className="diagnosis caries occlusal"></div>
              )}
              {teeth.fracture.crown && (
                <div className="diagnosis fracture crown"></div>
              )}
              {teeth.fracture.root && (
                <div className="diagnosis fracture root"></div>
              )}
              {teeth.severelyDamaged && (
                <div className="diagnosis severely-damaged"></div>
              )}
              {teeth.wear.mild && <div className="diagnosis wear mild"></div>}
              {teeth.wear.moderate && (
                <div className="diagnosis wear moderate"></div>
              )}
              {teeth.wear.severe && (
                <div className="diagnosis wear severe"></div>
              )}
              {teeth.gingivalRecession.mild && (
                <div className="diagnosis recession mild"></div>
              )}
              {teeth.gingivalRecession.mild && (
                <div className="diagnosis periodontitis mild"></div>
              )}
              {teeth.gingivalRecession.moderate && (
                <div className="diagnosis recession moderate"></div>
              )}
              {teeth.gingivalRecession.moderate && (
                <div className="diagnosis periodontitis moderate"></div>
              )}
              {teeth.gingivalRecession.severe && (
                <div className="diagnosis recession severe"></div>
              )}
              {teeth.gingivalRecession.severe && (
                <div className="diagnosis periodontitis severe"></div>
              )}
              {teeth.periodontitis.mild && (
                <div className="diagnosis periodontitis mild"></div>
              )}
              {teeth.periodontitis.moderate && (
                <div className="diagnosis periodontitis moderate"></div>
              )}
              {teeth.periodontitis.severe && (
                <div className="diagnosis periodontitis severe"></div>
              )}
              {teeth.gummySmile && (
                <div className="diagnosis gummy-smile"></div>
              )}
              {teeth.gingivalOvergrowth && (
                <div className="diagnosis gingival-overgrowth"></div>
              )}
              {teeth.largeSinus && (
                <div className="diagnosis large-sinus"></div>
              )}
              {teeth.necrosis && <div className="diagnosis necrosis"></div>}
              {teeth.rootCanalTreatment.unSatisfactory && (
                <div className="diagnosis rct unsatisfactory"></div>
              )}
              {teeth.rootCanalTreatment.satisfactory && (
                <div className="diagnosis rct satisfactory"></div>
              )}
              {teeth.apicalLesion.mild && (
                <div className="diagnosis apical-lesion mild"></div>
              )}
              {teeth.apicalLesion.moderate && (
                <div className="diagnosis apical-lesion moderate"></div>
              )}
              {teeth.apicalLesion.severe && (
                <div className="diagnosis apical-lesion severe"></div>
              )}
              {teeth.brokenInstrumentInCanal && (
                <div className="diagnosis broken-instrument"></div>
              )}
              {teeth.rootResorption.external && (
                <div className="diagnosis root-resorption"></div>
              )}
              {teeth.rootResorption.internal && (
                <div className="diagnosis root-resorption"></div>
              )}
              {teeth.implant.ok && <div className="diagnosis implant ok"></div>}
              {teeth.implant.malpositioned && (
                <div className="diagnosis implant malpositioned"></div>
              )}
              {teeth.implant.withBoneLose && (
                <div className="diagnosis implant malpositioned"></div>
              )}
              {teeth.implant.withGingivalRecession && (
                <div className="diagnosis implant malpositioned"></div>
              )}
              {teeth.impacted && <div className="diagnosis impacted"></div>}
              {teeth.impactedInfected && (
                <div className="diagnosis impacted"></div>
              )}
              {teeth.largeSinus2 && (
                <div className="diagnosis large-sinus"></div>
              )}
              {teeth.restoration.buccal && teeth.restoration.ok && (
                <div className="diagnosis restoration buccal ok"></div>
              )}
              {teeth.restoration.distal && teeth.restoration.ok && (
                <div className="diagnosis restoration distal ok"></div>
              )}
              {teeth.restoration.lingual && teeth.restoration.ok && (
                <div className="diagnosis restoration lingual ok"></div>
              )}
              {teeth.restoration.mesial && teeth.restoration.ok && (
                <div className="diagnosis restoration mesial ok"></div>
              )}
              {teeth.restoration.occlusal && teeth.restoration.ok && (
                <div className="diagnosis restoration occlusal ok"></div>
              )}
              {teeth.post.ok && <div className="diagnosis post ok"></div>}
              {teeth.splint.ok && (
                <div className="diagnosis splint mesial ok"></div>
              )}
              {teeth.disColored && <div className="diagnosis discolored"></div>}
              {teeth.veneer.ok && <div className="diagnosis veneer ok"></div>}
              {teeth.crown.ok && <div className="diagnosis crown ok"></div>}
              {teeth.onlay.ok && <div className="diagnosis onlay ok"></div>}
              {teeth.bridge.ok && <div className="diagnosis denture ok"></div>}
              {teeth.bridge.ok && (
                <div className="diagnosis denture distal ok"></div>
              )}
              {teeth.bridge.ok && (
                <div className="diagnosis denture mesial ok"></div>
              )}
              {teeth.denture.ok && <div className="diagnosis denture ok"></div>}
              {teeth.denture.ok && (
                <div className="diagnosis denture distal ok"></div>
              )}
              {teeth.denture.ok && (
                <div className="diagnosis denture mesial ok"></div>
              )}
              {teeth.gengivaDefault && <div className="gengiva"></div>}
              {teeth.boneDefault && <div className="bone"></div>}
              {teeth.boneLineDefault && <div className="bone-line"></div>}
              <div className="diagnosis missing"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default observer(OndogramSectionResult);
