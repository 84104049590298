import "./ondogramsection.css";
import { useEffect, useState } from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import ReactDOM from "react-dom";

function OndogramSectionPlanResult() {
  const { planStore, treatmentStore } = useStore();
  const { odontogram2, resetForm2 } = treatmentStore;
  const { selectedPlan } = planStore;
  const initialState = planStore.selectedPlan ?? {
    id: "",
    name: "",
    patientID: "",
    createdTime: "",
    isActive: 1,
    conditions: [],
    treatmentStages: {
      id: "",
      name: "",
      description: "",
      planId: "",
      createdTime: "",
      isActive: 1,
      treatments: {},
    },
  };
  const [plan, setPlan] = useState(initialState);
  useEffect(() => {
    setPlan(initialState);
    sessionStorage.setItem("planID", JSON.stringify(plan));
    treatmentStore.loadOdontogram2(plan);
  }, [selectedPlan, resetForm2]);

  return (
    <div className="odontogram-section">
      <div className="odontogram-container">
        <div className="odontogram" style={{pointerEvents:"none"}}>
          {odontogram2.map((teeth, index) => (
            <div
              onClick={() =>
                treatmentStore.magicFunction2(
                  undefined,
                  teeth.name,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                )
              }
              className={
                teeth.class + " " + teeth.selected + " " + teeth.missing
              }
              id={teeth.name}
              key={index}
            >
              {!teeth.extraction && <div className="tooth-img"></div>}
              {/* {!teeth.wisdomToothExtraction && <div className="tooth-img"></div>} */}
              {/* Plandaki manipülasyonların divleri. */}
              {/* {teeth.RootCanalTreatment.oneCanal ||
                teeth.RootCanalTreatment.twoCanal ||
                teeth.RootCanalTreatment.threeCanal ||
                teeth.RootCanalTreatment.threeOrMoreCanal && (
                  <div className="plan rct"></div>
                )} */}
              {teeth.apicectomy && <div className="plan apicectomy"></div>}
              {teeth.implant && <div className="plan implant"></div>}
              {teeth.sinusLift && <div className="plan sinus-lift"></div>}
              {teeth.boneRegeneration && (
                <div className="plan bone-regeneration"></div>
              )}
              {teeth.boneRegeneration2 && (
                <div className="plan bone-regeneration"></div>
              )}
              {teeth.restoration.buccal && (
                <div className="plan bone restoration buccal"></div>
              )}
              {teeth.restoration.distal && (
                <div className="plan bone restoration distal"></div>
              )}
              {teeth.restoration.lingual && (
                <div className="plan bone restoration lingual"></div>
              )}
              {teeth.restoration.mesial && (
                <div className="plan bone restoration mesial"></div>
              )}
              {teeth.restoration.occlusal && (
                <div className="plan bone restoration occlusal"></div>
              )}
              {teeth.coreBuldUp.buccal && (
                <div className="plan core-build-up buccal"></div>
              )}
              {teeth.coreBuldUp.distal && (
                <div className="plan core-build-up distal"></div>
              )}
              {teeth.coreBuldUp.mesial && (
                <div className="plan core-build-up mesial"></div>
              )}
              {teeth.coreBuldUp.occlusal && (
                <div className="plan core-build-up occlusal"></div>
              )}
              {teeth.coreBuldUp.lingual && (
                <div className="plan core-build-up lingual"></div>
              )}
              {teeth.provisionalRestoration.buccal && (
                <div className="plan provisional-restoration buccal"></div>
              )}
              {teeth.provisionalRestoration.distal && (
                <div className="plan provisional-restoration distal"></div>
              )}
              {teeth.provisionalRestoration.mesial && (
                <div className="plan provisional-restoration mesial"></div>
              )}
              {teeth.provisionalRestoration.occlusal && (
                <div className="plan provisional-restoration occlusal"></div>
              )}
              {teeth.provisionalRestoration.lingual && (
                <div className="plan provisional-restoration lingual"></div>
              )}
              {teeth.post && <div className="plan post"></div>}
              {teeth.splint && <div className="plan splint distal"></div>} 
              {teeth.splint && <div className="plan splint mesial"></div>}
              {teeth.veneer && <div className="plan veneer"></div>}
              {teeth.onlay && <div className="plan onlay"></div>}
              {teeth.crown && <div className="plan crown"></div>}
              {teeth.bridge && <div className="plan bridge"></div>}
              {teeth.bridge && <div className="plan bridge mesial"></div>}
              {teeth.bridge && <div className="plan bridge distal"></div>}

              {teeth.adhesiveBridge && (
                <div className="plan adhesive-bridge"></div>
              )}
              {teeth.adhesiveBridge && (
                <div className="plan adhesive-bridge mesial"></div>
              )}
              {teeth.adhesiveBridge && (
                <div className="plan adhesive-bridge distal"></div>
              )}
              {/* <div className="plan denture"></div>
              <div className="plan denture mesial"></div>
              <div className="plan denture distal"></div> */}
              {teeth.provisionalBridge && (
                <div className="plan provisional-bridge"></div>
              )}
              {teeth.provisionalBridge && (
                <div className="plan provisional-bridge distal"></div>
              )}
              {teeth.provisionalBridge && (
                <div className="plan provisional-bridge mesial"></div>
              )}

              {teeth.provisionalCrown && (
                <div className="plan provisional-crown"></div>
              )}
              {teeth.gengivaDefault && <div className="gengiva"></div>}
              {teeth.boneDefault && <div className="bone"></div>}
              {teeth.boneLineDefault && <div className="bone-line"></div>}
              <div className="diagnosis missing"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default observer(OndogramSectionPlanResult);
