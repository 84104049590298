import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./documents.css";

export default observer(function NewDocument() {

  const { documentStore } = useStore();


  const initialState = documentStore.selectedDocument ?? {
    id: " ",
    title: "",
    content: "",
  };
  const [document, setDocument] = useState(initialState);

  useEffect(() => {
    setDocument(initialState);
  }, []);

  function handleInputChange(event) {
    console.log("test")
    const { name, value } = event.target;
    setDocument({ ...document, [name]: value });
  }
  function handleSubmit() {
    documentStore.createDocument(document);
  }
  function handleUpdate() {
    documentStore.updateDocument(document);
  }


  return (
    <div className="newDocumentDiv">
      <input
        placeholder="Doküman başlığını giriniz"
        type="text"
        name="title"
        className="newDocumentHeader"
        value={document.title}
        onChange={handleInputChange}
      />
      <input
        placeholder="Doküman içeriğini giriniz"
        type="text"
        name="content"
        className="newDocumentBody"
        value={document.content}
        onChange={handleInputChange}
      />
      {document.id === " " &&
      <Button
        className="newDocumentSaveButton"
        variant="danger"
        onClick={()=> handleSubmit()}
      >
        Kaydet
      </Button>
      }
      {document.id !== " " &&
      <Button
        className="newDocumentSaveButton"
        variant="danger"
        onClick={()=> handleUpdate()}
      >
        Güncelle
      </Button>
      }
    </div>
  );
});
