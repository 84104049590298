import React from 'react'
import { observer } from 'mobx-react-lite'
import { Form, Modal, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useStore } from '../../stores/store'
import UpdatePatient from '../updatePatient/UpdatePatient'
import TextField from '@mui/material/TextField'
import './anamnesis.css'

export default observer(function Anamnesis() {
  const { patientStore, userStore } = useStore()
  const { selectedPatient, createPatient, updatePatient } = patientStore

  const initialState = selectedPatient ?? {
    id: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    gender: true,
    note: '',
  }
  const [patient, setPatient] = useState(initialState)

  useEffect(() => {
    setPatient(initialState)
  }, [selectedPatient])

  const initialAnamnesis = patientStore.selectedPatient.anamneses ?? {
    id: '',
    patientId: '',
    medicineNone: false,
    medicineBloodThinner: false,
    medicineepilepsy: false,
    medicineNervousSystem: false,
    medicineCytostatic: false,
    medicineAntihistamine: false,
    medicineActhCortisone: false,
    medicineInsulin: false,
    cardiovascularNone: false,
    cardiovascularAnginaPectoris: false,
    cardiovascularMyocardialInfarction: false,
    cardiovascularRheumaticHeartDisease: false,
    cardiovascularAcuteJointRheumatism: false,
    cardiovascularHeartFailure: false,
    cardiovascularCardiovascularOperation: false,
    infectiousDiseasesNone: false,
    infectiousDiseasesHepatitisA: false,
    infectiousDiseasesHepatitisB: false,
    infectiousDiseasesHepatitisC: false,
    infectiousDiseasesAIDS: false,
    infectiousDiseasesCovid19: false,
    infectiousDiseasesCovid19Survived: false,
    otherDiseasesNone: false,
    otherDiseasesDevelopmentalAnomaly: false,
    otherDiseasesKidneyFailure: false,
    otherDiseasesHemodialysis: false,
    otherDiseasesAdrenalInsufficiency: false,
    otherDiseasesRheumatoidArthritis: false,
    otherDiseasesLiverFailure: false,
    surgeriesNone: false,
    surgeriesBypass: false,
    surgeriesRadiotherapy: false,
    surgeriesKidneyTransplastation: false,
    surgeriesStent: false,
    surgeriesAneurysm: false,
    habitsNone: false,
    habitsBruxism: false,
    habitsNailBiting: false,
    habitsCigaret: false,
    habitsAlcohol: false,
    lungDiseasesNone: false,
    lungDiseasesTuberculosisSurvived: false,
    lungDiseasesTuberculosisActive: false,
    lungDiseasesAsthma: false,
    lungDiseasesDyspnea: false,
    entDiseasesNone: false,
    entDiseasesFrontalSinusitis: false,
    entDiseasesMaxillarySinusitis: false,
    entDiseasesDeviation: false,
    entDiseasesHypotrophicTonsils: false,
    neurologicalPsychologicalDiseasesNone: false,
    neurologicalPsychologicalDiseasesEpilepsy: false,
    neurologicalPsychologicalDiseasesParalysis: false,
    neurologicalPsychologicalDiseasesPsychiatricTreatment: false,
    gastrointestinalNone: false,
    gastrointestinalGastritis: false,
    gastrointestinalUlcer: false,
    bloodPressureArterialNone: false,
    bloodPressureArterialHypertension: false,
    bloodPressureArterialHypotension: false,
    endocrineDiseasesNone: false,
    endocrineDiseasesHypothyroidism: false,
    endocrineDiseasesHyperthyroidism: false,
    endocrineDiseasesParathyroidGlands: false,
    notes: '',
  }
  const [anamnesis, setAnamnesis] = useState(initialAnamnesis)
  useEffect(() => {
    setAnamnesis(initialAnamnesis)
  }, [])

  //?Düzenle Modal State'i
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    patientStore.updating = true
    setShow(true)
  }

  function handleInputChange(event) {
    const { name, checked } = event.target
    setAnamnesis({ ...anamnesis, [name]: checked })
  }
  function handleInputNotes(event) {
    const { name, value } = event.target
    setAnamnesis({ ...anamnesis, [name]: value })
  }

  function handleSubmit() {
    patientStore.addAnamnesis(anamnesis)
  }

  function handleUpdate() {
    patientStore.updateAnamnesis(anamnesis)
  }

  return (
    <div style={{ width: '100%' }}>
      {/* Anamnez Kartı Başlığı */}
      <div className="patientsAddHead">
        <div className="patientsColumnGroup">
          <div>
            <label className="patientInfo">
              <span className="spanText">İsim Soyisim: </span>
              {patient.fullName}
            </label>
            <label className="patientInfo">
              <span className="spanText">
                Mail:
              </span>
              {patient.email}
            </label>
            <label className="patientInfo">
              <span className="spanText">Telefon Numarası: </span>
              {patient.phoneNumber}
            </label>
            <label className="patientInfo">
              <span className="spanText">
                Cinsiyet:
              </span>
              Erkek
            </label>
          </div>
          <div className="patientCommentGroup">
            <label htmlFor="">
              
              <span className="spanText">Not:</span>
              {patient.note}
            </label>
          </div>
          {userStore.grandPermissions.patientUpdate && (
            <Button
              className=""
              variant="outline-dark"
              style={{ height: '3rem' }}
              onClick={handleShow}
            >
              Düzenle
            </Button>
          )}
        </div>
      </div>

      {/* Anamnez Bilgileri */}
      <div style={{ margin: '0 auto', width: '95%' }}>
        <div className="row">
          {patientStore.selectedPatient.anamneses === null && (
            <Button
              className="treatmentDescriptionListButton"
              variant="outline-primary"
              onClick={handleSubmit}
            >
              Kaydet
            </Button>
          )}
          {patientStore.selectedPatient.anamneses !== null && (
            <Button
              style={{ height: '5rem', margin: '1rem 0 1rem 0' }}
              variant="outline-dark"
              onClick={handleUpdate}
            >
              Güncelle
            </Button>
          )}

          <div className="col-sm-3 my-1">
            <div className="card cardStyle" style={{ height: '17.5rem' }}>
              <div className="card-body">
                <h5 className="card-title patientCardTitle">
                  Kullanılan İlaçlar
                </h5>
                <Form>
                  <Form.Check
                    className="anamnesisCheck"
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    label="Sağlıklı"
                    name="medicineNone"
                    onChange={handleInputChange}
                    checked={anamnesis.medicineNone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Kan Sulandırıcı"
                    name="medicineBloodThinner"
                    onChange={handleInputChange}
                    checked={anamnesis.medicineBloodThinner}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Sara"
                    name="medicineepilepsy"
                    onChange={handleInputChange}
                    checked={anamnesis.medicineepilepsy}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Sinir Sistemi"
                    name="medicineNervousSystem"
                    onChange={handleInputChange}
                    checked={anamnesis.medicineNervousSystem}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Sitostatik"
                    name="medicineCytostatic"
                    onChange={handleInputChange}
                    checked={anamnesis.medicineCytostatic}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Antihistaminik"
                    name="medicineAntihistamine"
                    onChange={handleInputChange}
                    checked={anamnesis.medicineAntihistamine}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="ACTH - Kortizon"
                    name="medicineActhCortisone"
                    onChange={handleInputChange}
                    checked={anamnesis.medicineActhCortisone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="İnsülin"
                    name="medicineInsulin"
                    onChange={handleInputChange}
                    checked={anamnesis.medicineInsulin}
                  />
                </Form>
              </div>
            </div>
          </div>
          <div className="col-sm-3 my-1">
            <div className="card cardStyle" style={{ height: '17.5rem' }}>
              <div className="card-body">
                <h5 className="card-title patientCardTitle">
                  Kardiyovasküler S.
                </h5>
                <Form>
                  <Form.Check
                    className="anamnesisCheck"
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    label="Sağlıklı"
                    name="cardiovascularNone"
                    onChange={handleInputChange}
                    checked={anamnesis.cardiovascularNone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Angina Pektoris"
                    name="cardiovascularAnginaPectoris"
                    onChange={handleInputChange}
                    checked={anamnesis.cardiovascularAnginaPectoris}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Miyokard Enfarktüs"
                    name="cardiovascularMyocardialInfarction"
                    onChange={handleInputChange}
                    checked={anamnesis.cardiovascularMyocardialInfarction}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Romatizmal Kalp Hastalığı"
                    name="cardiovascularRheumaticHeartDisease"
                    onChange={handleInputChange}
                    checked={anamnesis.cardiovascularRheumaticHeartDisease}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Akut Eklem Romatizma"
                    name="cardiovascularAcuteJointRheumatism"
                    onChange={handleInputChange}
                    checked={anamnesis.cardiovascularAcuteJointRheumatism}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Kalp Yetmezliği"
                    name="cardiovascularHeartFailure"
                    onChange={handleInputChange}
                    checked={anamnesis.cardiovascularHeartFailure}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Kardiyovasküler Operasyon"
                    name="cardiovascularCardiovascularOperation"
                    onChange={handleInputChange}
                    checked={anamnesis.cardiovascularCardiovascularOperation}
                  />
                </Form>
              </div>
            </div>
          </div>
          <div className="col-sm-3 my-1">
            <div className="card cardStyle" style={{ height: '17.5rem' }}>
              <div className="card-body">
                <h5 className="card-title patientCardTitle">
                  Bulaşıcı Hastalıklar
                </h5>
                <Form>
                  <Form.Check
                    className="anamnesisCheck"
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    label="Sağlıklı"
                    name="infectiousDiseasesNone"
                    onChange={handleInputChange}
                    checked={anamnesis.infectiousDiseasesNone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Hepatit A"
                    name="infectiousDiseasesHepatitisA"
                    onChange={handleInputChange}
                    checked={anamnesis.infectiousDiseasesHepatitisA}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Hepatit B"
                    name="infectiousDiseasesHepatitisB"
                    onChange={handleInputChange}
                    checked={anamnesis.infectiousDiseasesHepatitisB}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Hepatit C"
                    name="infectiousDiseasesHepatitisC"
                    onChange={handleInputChange}
                    checked={anamnesis.infectiousDiseasesHepatitisC}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="A.I.D.S"
                    name="infectiousDiseasesAIDS"
                    onChange={handleInputChange}
                    checked={anamnesis.infectiousDiseasesAIDS}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="COVID-19"
                    name="infectiousDiseasesCovid19"
                    onChange={handleInputChange}
                    checked={anamnesis.infectiousDiseasesCovid19}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="COVID-19 (Atlatmış)"
                    name="infectiousDiseasesCovid19Survived"
                    onChange={handleInputChange}
                    checked={anamnesis.infectiousDiseasesCovid19Survived}
                  />
                </Form>
              </div>
            </div>
          </div>

          <div className="col-sm-3 my-1">
            <div className="card cardStyle" style={{ height: '17.5rem' }}>
              <div className="card-body">
                <h5 className="card-title patientCardTitle">
                  Diğer Hastalıklar
                </h5>
                <Form>
                  <Form.Check
                    className="anamnesisCheck"
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    label="Sağlıklı"
                    name="otherDiseasesNone"
                    onChange={handleInputChange}
                    checked={anamnesis.otherDiseasesNone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Gelişim Anomalisi"
                    name="otherDiseasesDevelopmentalAnomaly"
                    onChange={handleInputChange}
                    checked={anamnesis.otherDiseasesDevelopmentalAnomaly}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Böbrek Yetmezliği"
                    name="otherDiseasesKidneyFailure"
                    onChange={handleInputChange}
                    checked={anamnesis.otherDiseasesKidneyFailure}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Hemodiyaliz"
                    name="otherDiseasesHemodialysis"
                    onChange={handleInputChange}
                    checked={anamnesis.otherDiseasesHemodialysis}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Adrenal Yetmezliği"
                    name="otherDiseasesAdrenalInsufficiency"
                    onChange={handleInputChange}
                    checked={anamnesis.otherDiseasesAdrenalInsufficiency}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Romatoid Artrit"
                    name="otherDiseasesRheumatoidArthritis"
                    onChange={handleInputChange}
                    checked={anamnesis.otherDiseasesRheumatoidArthritis}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Karaciğer Yetmezliği"
                    name="otherDiseasesLiverFailure"
                    onChange={handleInputChange}
                    checked={anamnesis.otherDiseasesLiverFailure}
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-3 my-1">
            <div className="card cardStyle" style={{ height: '14rem' }}>
              <div className="card-body">
                <h5 className="card-title patientCardTitle">
                  Geçirilen Operasyonlar
                </h5>
                <Form>
                  <Form.Check
                    className="anamnesisCheck"
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    label="Sağlıklı"
                    name="surgeriesNone"
                    onChange={handleInputChange}
                    checked={anamnesis.surgeriesNone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Bypass"
                    name="surgeriesBypass"
                    onChange={handleInputChange}
                    checked={anamnesis.surgeriesBypass}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Radyoterapi"
                    name="surgeriesRadiotherapy"
                    onChange={handleInputChange}
                    checked={anamnesis.surgeriesRadiotherapy}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Böbrek Transplastasyonu"
                    name="surgeriesKidneyTransplastation"
                    onChange={handleInputChange}
                    checked={anamnesis.surgeriesKidneyTransplastation}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Stent"
                    name="surgeriesStent"
                    onChange={handleInputChange}
                    checked={anamnesis.surgeriesStent}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Anevrizma"
                    name="surgeriesAneurysm"
                    onChange={handleInputChange}
                    checked={anamnesis.surgeriesAneurysm}
                  />
                </Form>
              </div>
            </div>
          </div>
          <div className="col-sm-3 my-1">
            <div className="card cardStyle" style={{ height: '14rem' }}>
              <div className="card-body">
                <h5 className="card-title patientCardTitle">Alışkanlıklar</h5>
                <Form>
                  <Form.Check
                    className="anamnesisCheck"
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    label="Sağlıklı"
                    name="habitsNone"
                    onChange={handleInputChange}
                    checked={anamnesis.habitsNone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Bruxism"
                    name="habitsBruxism"
                    onChange={handleInputChange}
                    checked={anamnesis.habitsBruxism}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Tırnak Yeme"
                    name="habitsNailBiting"
                    onChange={handleInputChange}
                    checked={anamnesis.habitsNailBiting}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Sigara"
                    name="habitsCigaret"
                    onChange={handleInputChange}
                    checked={anamnesis.habitsCigaret}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Alkol"
                    name="habitsAlcohol"
                    onChange={handleInputChange}
                    checked={anamnesis.habitsAlcohol}
                  />
                </Form>
              </div>
            </div>
          </div>
          <div className="col-sm-3 my-1">
            <div className="card cardStyle" style={{ height: '14rem' }}>
              <div className="card-body">
                <h5 className="card-title patientCardTitle">
                  Akciğer Hastalıkları
                </h5>
                <Form>
                  <Form.Check
                    className="anamnesisCheck"
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    label="Sağlıklı"
                    name="lungDiseasesNone"
                    onChange={handleInputChange}
                    checked={anamnesis.lungDiseasesNone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Geçirilmiş Tüberkiloz"
                    name="lungDiseasesTuberculosisSurvived"
                    onChange={handleInputChange}
                    checked={anamnesis.lungDiseasesTuberculosisSurvived}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Aktif Tüberkiloz"
                    name="lungDiseasesTuberculosisActive"
                    onChange={handleInputChange}
                    checked={anamnesis.lungDiseasesTuberculosisActive}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Astım"
                    name="lungDiseasesAsthma"
                    onChange={handleInputChange}
                    checked={anamnesis.lungDiseasesAsthma}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Dispne"
                    name="lungDiseasesDyspnea"
                    onChange={handleInputChange}
                    checked={anamnesis.lungDiseasesDyspnea}
                  />
                </Form>
              </div>
            </div>
          </div>
          <div className="col-sm-3 my-1">
            <div className="card cardStyle" style={{ height: '14rem' }}>
              <div className="card-body">
                <h5 className="card-title patientCardTitle">
                  K.B.B Hastalıkları
                </h5>
                <Form>
                  <Form.Check
                    className="anamnesisCheck"
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    label="Sağlıklı"
                    name="entDiseasesNone"
                    onChange={handleInputChange}
                    checked={anamnesis.entDiseasesNone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Frontal Sinüzit"
                    name="entDiseasesFrontalSinusitis"
                    onChange={handleInputChange}
                    checked={anamnesis.entDiseasesFrontalSinusitis}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Maxillar Sinüzit"
                    name="entDiseasesMaxillarySinusitis"
                    onChange={handleInputChange}
                    checked={anamnesis.entDiseasesMaxillarySinusitis}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Deviasyon"
                    name="entDiseasesDeviation"
                    onChange={handleInputChange}
                    checked={anamnesis.entDiseasesDeviation}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Tonsil Hipotrofisi"
                    name="entDiseasesHypotrophicTonsils"
                    onChange={handleInputChange}
                    checked={anamnesis.entDiseasesHypotrophicTonsils}
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-3 my-1">
            <div className="card cardStyle" style={{ height: '11rem' }}>
              <div className="card-body">
                <h5 className="card-title patientCardTitle">
                  Nörolojik - Psikolojik Hastalıklar
                </h5>
                <Form>
                  <Form.Check
                    className="anamnesisCheck"
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    label="Sağlıklı"
                    name="neurologicalPsychologicalDiseasesNone"
                    onChange={handleInputChange}
                    checked={anamnesis.neurologicalPsychologicalDiseasesNone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Epilepsi"
                    name="neurologicalPsychologicalDiseasesEpilepsy"
                    onChange={handleInputChange}
                    checked={
                      anamnesis.neurologicalPsychologicalDiseasesEpilepsy
                    }
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Paralizi"
                    name="neurologicalPsychologicalDiseasesParalysis"
                    onChange={handleInputChange}
                    checked={
                      anamnesis.neurologicalPsychologicalDiseasesParalysis
                    }
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Psikiyatrik Tedavi"
                    name="neurologicalPsychologicalDiseasesPsychiatricTreatment"
                    onChange={handleInputChange}
                    checked={
                      anamnesis.neurologicalPsychologicalDiseasesPsychiatricTreatment
                    }
                  />
                </Form>
              </div>
            </div>
          </div>
          <div className="col-sm-3 my-1">
            <div className="card cardStyle" style={{ height: '11rem' }}>
              <div className="card-body">
                <h5 className="card-title patientCardTitle">
                  Gastro İntestinal
                </h5>
                <Form>
                  <Form.Check
                    className="anamnesisCheck"
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    label="Sağlıklı"
                    name="gastrointestinalNone"
                    onChange={handleInputChange}
                    checked={anamnesis.gastrointestinalNone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Gastrit"
                    name="gastrointestinalGastritis"
                    onChange={handleInputChange}
                    checked={anamnesis.gastrointestinalGastritis}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Ülser"
                    name="gastrointestinalUlcer"
                    onChange={handleInputChange}
                    checked={anamnesis.gastrointestinalUlcer}
                  />
                </Form>
              </div>
            </div>
          </div>

          <div className="col-sm-3 my-1">
            <div className="card cardStyle" style={{ height: '11rem' }}>
              <div className="card-body">
                <h5 className="card-title patientCardTitle">
                  Tansiyon Arteriyel
                </h5>
                <Form>
                  <Form.Check
                    className="anamnesisCheck"
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    label="Sağlıklı"
                    name="bloodPressureArterialNone"
                    onChange={handleInputChange}
                    checked={anamnesis.bloodPressureArterialNone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Hipertansiyon"
                    name="bloodPressureArterialHypertension"
                    onChange={handleInputChange}
                    checked={anamnesis.bloodPressureArterialHypertension}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Hipotansiyon"
                    name="bloodPressureArterialHypotension"
                    onChange={handleInputChange}
                    checked={anamnesis.bloodPressureArterialHypotension}
                  />
                </Form>
              </div>
            </div>
          </div>

          <div className="col-sm-3 my-1">
            <div className="card cardStyle" style={{ height: '11rem' }}>
              <div className="card-body">
                <h5 className="card-title patientCardTitle">
                  Endokriner Hastalıklar
                </h5>
                <Form>
                  <Form.Check
                    className="anamnesisCheck"
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    label="Sağlıklı"
                    name="endocrineDiseasesNone"
                    onChange={handleInputChange}
                    checked={anamnesis.endocrineDiseasesNone}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Hipotiroidi"
                    name="endocrineDiseasesHypothyroidism"
                    onChange={handleInputChange}
                    checked={anamnesis.endocrineDiseasesHypothyroidism}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Hipertiroidi"
                    name="endocrineDiseasesHyperthyroidism"
                    onChange={handleInputChange}
                    checked={anamnesis.endocrineDiseasesHyperthyroidism}
                  />
                  <Form.Check
                    className="anamnesisCheck"
                    type="switch"
                    label="Paratiroidi"
                    name="endocrineDiseasesParathyroidGlands"
                    onChange={handleInputChange}
                    checked={anamnesis.endocrineDiseasesParathyroidGlands}
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 my-1">
            <div className="card cardStyle">
              <div className="card-body">
                <h5 className="card-title patientCardTitle">Notlar</h5>
                <ul className="treatmentDescriptionList">
                  <div className="treatmentDescriptionListItemBox">
                    <TextField
                      name="notes"
                      className="my-1"
                      style={{ width: '99%' }}
                      label="Not Ekleyin"
                      value={anamnesis.notes}
                      onChange={handleInputNotes}
                    />
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hasta Düzenle Modalı */}
      {patientStore.updating && (
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Düzenle</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UpdatePatient />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
})
