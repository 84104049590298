import Navbar from "./components/navbar/Navbar";
import "./app.css";
import Home from "./components/home/Home";
import { Routes, Route } from "react-router-dom";
import Documents from "./components/documents/Documents";
import Diagnose from "./components/diagnose/Diagnose";
import Plan from "./components/plan/Plan";
import ProfileMenu from "./components/profileMenu/ProfileMenu";
import Login from "./components/login/Login";
import Result from "./components/result/Result";
import DoctorList from "./components/doctors/DoctorList";
import { useStore } from "./stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import LoadingComponent from "./components/loading/loadingComponent";
import { ToastContainer } from "react-toastify";
import Calendar from "./components/calendar/Calendar";
import PricesList from "./components/prices/PricesList";
import PatientsCard from "./components/patientsCard/PatientsCard";
import PatientList from "./components/patientList/PatientList";
import DoctorPlansList from "./components/doctorPlanList/DoctorPlansList";
import AddPatient from "./components/addPatient/AddPatient";
import Test from "./components/test/Test";
import DoctorCard from "./components/doctorCard/DoctorCard";
import { useNavigate } from "react-router-dom";
import Report from "./components/report/Report";
import SpecialReports from "./pages/specialReports/specialReports";
import Permissions from "./components/permissions/Permissions"
import UserList from "./components/userList/UserList";
import TreatmentList from "./components/patientTreatments/TreatmentList";
import Anamnesis from "./components/anamnesis/Anamnesis";

function App() {
  let navigate = useNavigate();
  const { commonStore, userStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore]);

  const { loading } = userStore;

  useEffect(() => {
    if (!commonStore.token) {
      navigate("/");
    }
  }, [loading]);

  return (
    <>
      <ToastContainer position="bottom-right" hideProgressBar />
      {sessionStorage.getItem("userToken") && <Navbar />}
      <div className="appContainer">
        <ProfileMenu />
        <Routes>
          <Route exact path="/" element={<Login />}>
            {" "}
          </Route>
          <Route exact path="/patientscard" element={<PatientsCard />}>
            {" "}
          </Route>
          <Route exact path="/patientlist" element={<PatientList />}>
            {" "}
          </Route>
          <Route path="/documents" element={<Documents />}>
            {" "}
          </Route>
          <Route path="/prices" element={<PricesList />}>
            {" "}
          </Route>
          <Route path="/diagnose" element={<Diagnose />}>
            {" "}
          </Route>
          <Route path="/plan" element={<Plan />}>
            {" "}
          </Route>
          <Route path="/home" element={<Home />}>
            {" "}
          </Route>
          <Route path="/result" element={<Result />}>
            {" "}
          </Route>
          <Route path="/doctors" element={<DoctorList />}>
            {" "}
          </Route>
          <Route path="/doctorplans" element={<DoctorPlansList />}>
            {" "}
          </Route>
          <Route path="/appointment" element={<Calendar />}>
            {" "}
          </Route>
          <Route path="/addpatient" element={<AddPatient />}>
            {" "}
          </Route>
          <Route path="/doctorcard" element={<DoctorCard />}>
            {" "}
          </Route>
          <Route path="/test" element={<Test />}>
            {" "}
          </Route>
          <Route path="/report" element={<Report />}>
            {" "}
          </Route>
          <Route path="/special-reports" element={<SpecialReports />}>
            {" "}
          </Route>
          <Route path="/permissions" element={<Permissions />}>
            {" "}
          </Route>
          <Route path="/users" element={<UserList />}>
            {" "}
          </Route>
          <Route path="/treatments" element={ <TreatmentList />}>
            {" "}
          </Route>
          <Route path="/anamnesis" element={ <Anamnesis />}>
            {" "}
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default observer(App);
