import { runInAction, makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { store } from "./store";
import { toast } from "react-toastify";
import { UndoRounded } from "@material-ui/icons";
import { v4 as uuid } from "uuid";

export default class DrStore {
  documents = undefined;
  loading = false;
  deleted = false;
  adding = false;
  updating = false;
  selectedDocument = UndoRounded;

  constructor() {
    makeAutoObservable(this);
  }

  getDocuments = async () => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const documents = await agent.Document.list();
      if (documents.isSuccess === true) {
        runInAction(() => (this.documents = documents.data));
      }
      console.log(this.documents);
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      throw error;
    }
  };

  createDocument = async (document) => {
    try {
      runInAction(() => {
        this.loading = true;
        this.adding = true;
      });
      document.id = uuid();
      let result = await agent.Document.create(document);
      runInAction(() => {
        this.documents.push(result.data);
        this.adding = false;
      });
      toast.success("Döküman ekleme işlemi başarılı.");
      runInAction(() => {
        this.loading = false;
        this.adding = false;
      });
    } catch (error) {
      console.log(error);
      this.adding = false;
    }
  };

  updateDocument = async (document) => {
    try {
      runInAction(() => {
        this.updating = true;
      });
      await agent.Document.update(document);
      runInAction(() => {
        this.documents = [
          ...this.documents.filter((a) => a.id !== document.id),
          document,
        ];
        this.documents.sort(
          (a, b) => parseFloat(a.title) - parseFloat(b.title)
        );
        toast.success("Döküman güncelleme işlemi başarılı.");
        this.updating = false;
      });
    } catch (error) {
      console.log(error);
      this.updating = false;
    }
  };

  deleteDocument = async (id) => {
    try {
      runInAction(() => {
        this.loading = true;
        this.deleted = true;
      });

      const deleteDocument = await agent.Document.delete(id);
      if (deleteDocument.isSuccess === true) {
        toast.success("Döküman silme işlemi başarılı.");
        runInAction(() => {
          this.documents = [...this.documents.filter((a) => a.id !== id)];
        });
      }
      runInAction(() => {
        this.loading = false;
        this.deleted = false;
      });
    } catch (error) {
      throw error;
    }
  };

  selectDocument = (id) => {
    if (id === undefined) {
      this.selectedDocument = undefined;
    } else {
      try {
        const document = this.documents.find((x) => x.id === id);
        runInAction(() => {
          this.selectedDocument = document;
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
}
