import "./patientPlansList.css";
import { useEffect, useState } from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Modal, Card } from "react-bootstrap";

function PatientPlansList(patientId) {
  let navigate = useNavigate();
  const { planStore, userStore, patientStore } = useStore();
  const { selectPlan } = planStore;

  useEffect(() => {
    planStore.loadPlans(patientId);
  }, [patientId]);
  useEffect(() => {
    planStore.loadPlans(patientId);
  }, [planStore.plans]);

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = (id) => {
    planStore.deleted = true;
    planStore.selectPlan(id);
    setShowDelete(true);
  };

  function handleDelete(id) {
    planStore.dltPlan(id);
  }
  if (planStore.plans === undefined || planStore.plans.length === 0){
      return (
        <Card className="cardStyle" style={{ textAlign: "center" }}>
          <Card.Body>
            <Button className="loadingBtnStyle" variant="danger" disabled>
              Seçili hastaya tanımlı bir plan bulunmamaktadır...
            </Button>
          </Card.Body>
        </Card>
      ); }
  return (
    <div className="patientsPlanGroup">
      <table className="pricesListTable table table-hover table-lg table-responsive-md">
        <thead
          className="pricesTableBody"
          style={{ textAlign: "center", fontSize: 20 }}
        >
          <tr className="pricesTableThHead">
            <th className="pricesTableTh">Plan İsmi</th>
            <th className="pricesTableTh">Oluşturulma Tarihi</th>
            <th className="pricesTableTh">Planı Oluşturan Doktor</th>
            <th className="pricesTableTh">Hasta Danışmanı</th>
            <th className="pricesTableTh">Plan Durumu</th>
            <th className="pricesTableEdit">Seçenekler</th>
          </tr>
        </thead>
        <tbody className="pricesTableTbody">
          {planStore.plans?.map((plan) => (
            <tr key={plan.id}>
              <td className="pricesTableTd">{plan.name}</td>
              <td className="pricesTableTd">
                <p>
                  {`${new Date(plan.createdTime).getDate()}/${
                    new Date(plan.createdTime).getMonth() + 1
                  }/${new Date(plan.createdTime).getFullYear()}`}
                </p>
              </td>
              <td className="pricesTableTd">
                <p>{plan.doctor.fullName}</p>
              </td>
              <td className="pricesTableTd">
                <p>{patientStore.selectedPatient.advisor.fullName}</p>
              </td>
              <td className="pricesTableTd">
                {plan.isApproved === true && (
                  <p
                    className="approvedBtn"
                    style={{ color: "white", backgroundColor: "green" }}
                  >
                    {" "}
                    <span className="mx-1">ONAYLANDI</span>{" "}
                  </p>
                )}
                {plan.isApproved === false && (
                  <p
                    className="approvedBtn"
                    style={{ color: "white", backgroundColor: "red" }}
                  >
                    <span className="mx-1">ONAYLANMADI</span>
                  </p>
                )}
              </td>
              <td className="pricesTableEdit pricesTableTd">
                <ButtonGroup size="sm">
                  <Button
                    className="patientPlanListDeleteBtn"
                    onClick={() => planStore.selectPlan(plan.id)}
                    variant="outline-dark"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-wallet2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
                    </svg>
                  </Button>

                  {userStore.grandPermissions.planRead && (
                    <Link
                      style={{ "padding-left": "3 rem" }}
                      className="nolinkdecoration patientPlanListLinkBtn"
                      to={"/diagnose"}
                      onClick={() => {
                        selectPlan(plan.id);
                      }}
                    >
                      <Button variant="outline-dark">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fillRule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                          />
                        </svg>
                      </Button>
                    </Link>
                  )}
                  {userStore.grandPermissions.planDelete && (
                    <Button
                      className="patientPlanListDeleteBtn"
                      variant="outline-dark"
                      onClick={() => {
                        handleShowDelete(plan.id);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-archive"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                      </svg>
                    </Button>
                  )}
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {planStore.deleted && (
        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Silmek istediğinize emin misiniz?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="dark"
              onClick={() => handleDelete(planStore.selectedPlan.id)}
            >
              Evet
            </Button>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Hayır
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default observer(PatientPlansList);
