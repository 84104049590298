import * as React from "react";
import "./result.css";
import OndogramSectionResult from "../ondogramSection/OndogramSectionResult";
import OndogramSectionPlanResult from "../ondogramSection/OndogramSectionPlanResult";
import "../ondogramSection/ondogramsection.css";
import Steps from "../steps/Steps";
import { Button, Modal, ButtonGroup } from "react-bootstrap";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect, useRef } from "react";
import PlanCollection from "../addCollectionPlan/PlanCollection";
import { useReactToPrint } from "react-to-print";
import "../ondogramSection/ondogramsection.css";
import TreatmentSelection from "../patientTreatments/TreatmentSelection";

export default observer(function Result() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { planStore, patientStore, userStore, priceStore, financeStore } =
    useStore();
  const { selectPlan, selectedPlan, calculateDebt } = planStore;
  const { selectedPatient } = patientStore;
  const { collectionPlanSelected, getCollectionPlanByPlanId } = financeStore;

  const [show, setShow] = useState(false);

  const handleClose = () => {
    planStore.calculateDebt();
    setShow(false);
  };
  const handleShow = () => {
    planStore.calculateDebt();
    financeStore.editPlan = true;
    setShow(true);
  };
  const [showTreatments, setShowTreatments] = useState(false);

  const handleCloseTreatments = () => {
    planStore.calculateDebt();
    setShowTreatments(false);
  };
  const handleShowTreatments = () => {
    planStore.calculateDebt();
    setShowTreatments(true);
  };

  useEffect(() => {
    getCollectionPlanByPlanId(selectedPlan.id);
    selectPlan(selectedPlan.id);
    calculateDebt();
  }, []);

  function handleUpdatePlan(value) {
    let plan = {
      planId: selectedPlan.id,
      isApproved: value,
    };
    planStore.updatePlan(plan);
  }

  return (
    <div className="container containerStyle">
      <div className="stepsStyle">
        <Steps />
        <div>
          <ButtonGroup>
            {selectedPlan.isApproved && (
              <Button
                onClick={() => handleUpdatePlan(false)}
                variant="outline-dark"
              >
                Onayı İptal Et
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                  style={{ marginBottom: "0.2rem", marginLeft: "0.1rem" }}
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </Button>
            )}
            {!selectedPlan.isApproved && (
              <Button
                onClick={() => handleUpdatePlan(true)}
                variant="outline-dark"
              >
                Onayla
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  class="bi bi-check-lg"
                  viewBox="0 0 16 16"
                  style={{ marginBottom: "0.2rem" }}
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
              </Button>
            )}
            {userStore.grandPermissions.collectionPlanRead &&
              collectionPlanSelected && (
                <Button variant="outline-dark" onClick={handleShow}>
                  Ödeme Planı Görüntüle{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    class="bi bi-credit-card-2-front"
                    viewBox="0 0 16 16"
                    style={{ marginLeft: "0.2rem", marginBottom: "0.2rem" }}
                  >
                    <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
                    <path d="M2 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </Button>
              )}
            {userStore.grandPermissions.collectionPlanCreate &&
              !collectionPlanSelected && (
                <Button variant="outline-dark" onClick={handleShow}>
                  Ödeme Planı Oluştur{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    class="bi bi-credit-card-2-front"
                    viewBox="0 0 16 16"
                    style={{ marginLeft: "0.2rem", marginBottom: "0.2rem" }}
                  >
                    <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
                    <path d="M2 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </Button>
              )}
            <Button
              onClick={handleShowTreatments}
              variant="outline-dark"
              className="printBtn"
            >
              Tedavi Listesi
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi bi-list-check"
                viewBox="0 0 16 16"
                style={{ marginLeft: "0.2rem", marginTop: "0.1rem" }}
              >
                <path
                  fill-rule="evenodd"
                  d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </Button>
            <Button
              onClick={handlePrint}
              variant="outline-dark"
              className="printBtn"
            >
              Yazdır{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi bi-printer"
                viewBox="0 0 16 16"
                style={{ marginLeft: "0.2rem", marginTop: "0.1rem" }}
              >
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
              </svg>
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div
        className="progress positionTest"
        style={{
          "text-align": "center",
          width: "18rem",
        }}
      >
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: "75%",
            backgroundColor: "blue",
            "aria-valuenow": "100",
            "aria-valuemin": "0",
            "aria-valuemax": "100",
          }}
        >
          75%
        </div>
      </div>
      <div ref={componentRef}>
        <div className="resultInfo">
          <div className="resultHead">ZRN DENT</div>
          <div className="resultPlanName">{selectedPlan.name}</div>
          <div className="resultPatientName">
            {sessionStorage.getItem("patientName")}
          </div>
          <div className="resultDate">
            <span>Oluşturulma Tarihi: </span>{" "}
            {selectedPlan.createdTime.substring(0, 10)}
          </div>
        </div>
        <div className="ondogramStyle">
          <div className="toothImageHead">Olması Gereken Diş Yapısı</div>
          <div className="toothImage">
            <div className="odontogram-section">
              <div className="odontogram-container">
                <div className="odontogram" style={{ pointerEvents: "none" }}>
                  <div className="tooth  " id="tooth11">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth12">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth13">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth14">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth15">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth16">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth17">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth18">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth21">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth22">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth23">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth24">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth25">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth26">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth27">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth28">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth29">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth31">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth32">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth33">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth34">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth35">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth36">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth37">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth38">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth39">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth41">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth42">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth43">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth44">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth45">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth46">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth47">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                  <div className="tooth  " id="tooth48">
                    <div className="tooth-img"></div>
                    <div className="gengiva"></div>
                    <div className="bone"></div>
                    <div className="bone-line"></div>
                    <div className="diagnosis missing"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ondogramStyle">
          <div className="toothImageHead">Mevcut Durumunuz</div>
          <div className="toothImage">
            <OndogramSectionResult />
          </div>
        </div>

        <div className="ondogramStyle">
          <div className="toothImageHead">Ne yapmayı planlıyoruz?</div>
          <div className="toothImage">
            <OndogramSectionPlanResult />
          </div>
        </div>

        <div className="diagnoseDiv">
          <div className="diagnosisHead">TEŞHİS</div>
          <div className="toothImageDiagnose">
            <OndogramSectionResult />
          </div>
          <table className="table table-bordered my-5">
            <thead className="tableTheadStyle">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Diş</th>
                <th scope="col">Yüzey</th>
                <th scope="col">Derece/Durum</th>
                <th scope="col">Açıklama</th>
              </tr>
            </thead>
            {selectedPlan.conditions?.map((condition) => (
              <tbody>
                <tr>
                  <th scope="row">
                    {" "}
                    {(condition.conditionType === 0 && (
                      <span className="currentDiagnosisBodySpan"></span>
                    )) ||
                      (condition.conditionType === 1 && (
                        <span>Çürükler</span>
                      )) ||
                      (condition.conditionType === 2 && <span>Kırık</span>) ||
                      (condition.conditionType === 3 && (
                        <span>Ağır Hasarlı</span>
                      )) ||
                      (condition.conditionType === 4 && (
                        <span>Aşınmalar</span>
                      )) ||
                      (condition.conditionType === 5 && (
                        <span>Plak & Hijyen</span>
                      )) ||
                      (condition.conditionType === 6 && (
                        <span>Dişeti İltihabı</span>
                      )) ||
                      (condition.conditionType === 7 && (
                        <span>Dişeti Çekilmesi</span>
                      )) ||
                      (condition.conditionType === 8 && (
                        <span>Periodontitis</span>
                      )) ||
                      (condition.conditionType === 9 && (
                        <span>Hareketlilik</span>
                      )) ||
                      (condition.conditionType === 10 && (
                        <span>Sakızlı Gülümseme</span>
                      )) ||
                      (condition.conditionType === 11 && (
                        <span>Dişeti Büyümesi</span>
                      )) ||
                      (condition.conditionType === 12 && (
                        <span>Büyük Maksiller Sinüs</span>
                      )) ||
                      (condition.conditionType === 13 && <span>Nekroz</span>) ||
                      (condition.conditionType === 14 && (
                        <span> Kök Kanal Tedavisi</span>
                      )) ||
                      (condition.conditionType === 15 && (
                        <span>Apikal Lezyon</span>
                      )) ||
                      (condition.conditionType === 16 && (
                        <span>Kanaldaki Kırık Enstrüman</span>
                      )) ||
                      (condition.conditionType === 17 && (
                        <span>Kök Rezorpsiyonu</span>
                      )) ||
                      (condition.conditionType === 18 && (
                        <span>Eksik Diş</span>
                      )) ||
                      (condition.conditionType === 19 && (
                        <span>İmplant</span>
                      )) ||
                      (condition.conditionType === 20 && (
                        <span>Gömülü Diş</span>
                      )) ||
                      (condition.conditionType === 21 && (
                        <span>Gömülü ve Enfekte Diş</span>
                      )) ||
                      (condition.conditionType === 22 && (
                        <span>Büyük Maksiller Sinüs</span>
                      )) ||
                      (condition.conditionType === 23 && (
                        <span>Restorasyon</span>
                      )) ||
                      (condition.conditionType === 24 && <span>Post</span>) ||
                      (condition.conditionType === 25 && <span>Atel</span>) ||
                      (condition.conditionType === 26 && (
                        <span>Renksiz Diş</span>
                      )) ||
                      (condition.conditionType === 27 && <span>Kaplama</span>) ||
                      (condition.conditionType === 28 && <span>Taç</span>) ||
                      (condition.conditionType === 29 && <span>Onlay</span>) ||
                      (condition.conditionType === 30 && <span>Köprü</span>) ||
                      (condition.conditionType === 31 && <span>Takma Diş</span>)}
                  </th>
                  <td>{condition.teeths + ","}</td>
                  <td>{condition.toothSurfaces + ","}</td>
                  <td>
                    {condition.degree + ","}/{condition.status + ","}
                  </td>
                  <td>{condition.observations}</td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
        <div className="planDiv">
          <div className="diagnosisHead">PLAN</div>
          <div className="toothImageDiagnose">
            <OndogramSectionPlanResult />
          </div>
          <table className="table table-bordered my-5">
            <thead className="tableTheadStyle">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Diş</th>
                <th scope="col">Yüzey/Tip</th>
                <th scope="col">Açıklama</th>
                <th scope="col">Fiyat</th>
              </tr>
            </thead>
            {selectedPlan.treatmentStages?.map((stages) =>
              stages.treatments?.map((treat) => (
                <tbody>
                  <tr>
                    <th scope="row">
                      {" "}
                      {treat.treatmentType === 1
                        ? "Önleme ve Hijyen"
                        : "" || treat.treatmentType === 2
                        ? "Profesyonel Diş Temizleme"
                        : "" || treat.treatmentType === 3
                        ? "Topikal Florür"
                        : "" || treat.treatmentType === 4
                        ? "Profesyonel Diş Temizleme"
                        : "" || treat.treatmentType === 5
                        ? "Ölçeklendirme/Kök Planlama"
                        : "" || treat.treatmentType === 6
                        ? "Cep Azaltma"
                        : "" || treat.treatmentType === 7
                        ? "Kemik Rejenerasyonu"
                        : "" || treat.treatmentType === 8
                        ? "Dişeti Grefti"
                        : "" || treat.treatmentType === 9
                        ? "Plastik Perio Cerrahisi"
                        : "" || treat.treatmentType === 10
                        ? "Kron Uzatma"
                        : "" || treat.treatmentType === 11
                        ? "Gingivektomi"
                        : "" || treat.treatmentType === 12
                        ? "Lazer Gingivektomi"
                        : "" || treat.treatmentType === 13
                        ? "Kök Kanal Tedavisi"
                        : "" || treat.treatmentType === 14
                        ? "Kanal Tedavisi"
                        : "" || treat.treatmentType === 16
                        ? "Apikektomi"
                        : "" || treat.treatmentType === 17
                        ? "İmplant"
                        : "" || treat.treatmentType === 18
                        ? "Diş Çekimi"
                        : "" || treat.treatmentType === 19
                        ? "Yirmilik Diş Çekimi"
                        : "" || treat.treatmentType === 20
                        ? "Sinüs Kaldırma"
                        : "" || treat.treatmentType === 21
                        ? "Kemik Rejenerasyonu"
                        : "" || treat.treatmentType === 22
                        ? "Tam Ark İmplantları/Protez"
                        : "" || treat.treatmentType === 23
                        ? "İmplant Çıkarma"
                        : "" || treat.treatmentType === 24
                        ? "Cerrahi Rehber"
                        : "" || treat.treatmentType === 25
                        ? "Restorasyon"
                        : "" || treat.treatmentType === 26
                        ? "Post"
                        : "" || treat.treatmentType === 27
                        ? "Atel"
                        : "" || treat.treatmentType === 28
                        ? "Dış Çamaşır Suyu"
                        : "" || treat.treatmentType === 29
                        ? "İç Çamaşır Suyu"
                        : "" || treat.treatmentType === 30
                        ? "Çekirdek Oluşturma"
                        : "" || treat.treatmentType === 31
                        ? "Geçici Restorasyon"
                        : "" || treat.treatmentType === 32
                        ? "Kaplama"
                        : "" || treat.treatmentType === 33
                        ? "Onlay"
                        : "" || treat.treatmentType === 34
                        ? "Taç"
                        : "" || treat.treatmentType === 35
                        ? "Köprü"
                        : "" || treat.treatmentType === 36
                        ? "Yapışkanlı Köprü"
                        : "" || treat.treatmentType === 37
                        ? "Takma Diş"
                        : "" || treat.treatmentType === 38
                        ? "Köprü/Protez Çıkarma"
                        : "" || treat.treatmentType === 39
                        ? "Eklemli Çalışma Modelleri"
                        : "" || treat.treatmentType === 40
                        ? "Geçici Köprü"
                        : "" || treat.treatmentType === 41
                        ? "Geçici Taç"
                        : "" || treat.treatmentType === 42
                        ? "Sabit Braketler"
                        : "" || treat.treatmentType === 43
                        ? "Lingual Braketler"
                        : "" || treat.treatmentType === 44
                        ? "Hizalayıcıları Temizle"
                        : "" || treat.treatmentType === 45
                        ? "Çıkarılabilir Cihaz"
                        : "" || treat.treatmentType === 46
                        ? "Fonksiyonel Cihaz"
                        : "" || treat.treatmentType === 47
                        ? "Kozmetik Ortodonti"
                        : "" || treat.treatmentType === 48
                        ? "Avans"
                        : "" || treat.treatmentType === 49
                        ? "Ankraj için Mini İmplantlar"
                        : "" || treat.treatmentType === 50
                        ? "Tutulan Dişlerin Traksiyonu"
                        : "" || treat.treatmentType === 51
                        ? "Eklemli Çalışma Modelleri"
                        : "" || treat.treatmentType === 52
                        ? "Davranışsal Tavsiye ve Stres Kontrolü"
                        : "" || treat.treatmentType === 53
                        ? "Oklüzal Atel"
                        : "" || treat.treatmentType === 54
                        ? "Oklüzal Denge"
                        : "" || treat.treatmentType === 55
                        ? "Eklemli Çalışma Modelleri"
                        : ""}
                    </th>
                    <td>{treat.teeths + ","}</td>
                    <td>{treat.toothSurfaces + ","}</td>
                    <td>{treat.observation}</td>
                    <td>{treat.price}</td>
                  </tr>
                </tbody>
              ))
            )}
          </table>
          <div className="totalDebtStyle">
            {" "}
            <span className="totalDebtFontStyle">Toplam Borç</span>{" "}
          </div>
          <div className="deptStyle">€1200.00</div>
        </div>
      </div>
      {financeStore.editPlan && (
        <Modal size="xl" show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Ödeme Planı</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PlanCollection />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal
        size="xl"
        show={showTreatments}
        onHide={handleCloseTreatments}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Tedavi Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TreatmentSelection />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTreatments}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});
