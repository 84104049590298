import React from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import "./patientTreatments.css";
import Steps from "../steps/Steps";
import Resume from "../resume/Resume";
import { runInAction } from "mobx";

export default observer(function TreatmentSelection() {
  const { planStore, treatmentStore, userStore } = useStore();
  const { selectedPlan, resetForm, planSelected, deleteTreatment } = planStore;
  const { updateTreatment } = treatmentStore;

  const initialState = selectedPlan ?? {
    id: " ",
    name: "",
    patientID: "",
    createdTime: "",
    isActive: 1,
    conditions: [],
    treatmentStages: {
      id: "",
      name: "",
      description: "",
      planId: "",
      createdTime: "",
      isActive: 1,
      treatments: [],
    },
  };


  const [plan, setPlan] = useState(initialState);
  useEffect(() => {
    setPlan(initialState);
  }, [resetForm]);

  function handleApproved(id, value) {
    let treatment = {
      treatmentId: id,
      isApproved: value,
      isCompleted: false,
      completedDoctor: "",
      updateType: 1,
    };
    runInAction(() => {
      selectedPlan.treatmentStages[0].treatments.find(
        (x) => x.id === treatment.treatmentId
      ).isApproved = treatment.isApproved;
    });
    updateTreatment(treatment);
  }


  if (plan.id === " ")
    return (
      <Button className="loadingBtnStyle" disabled>
        Yükleniyor...
      </Button>
    );

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div className="treatmentTableDiv">
        <table className="treatmentTable table table-hover ">
          {plan.treatmentStages.map((stages) =>
            stages.treatments?.map((treat) => (
              <tbody>
                <tr>
                  <td className="treatmentTableTd">
                    <span style={{ color: "#0d68c8" }}>Tedavi: </span>
                    {treat.treatmentType === 1
                      ? "Önleme ve Hijyen"
                      : "" || treat.treatmentType === 2
                      ? "Profesyonel Diş Temizleme"
                      : "" || treat.treatmentType === 3
                      ? "Topikal Florür"
                      : "" || treat.treatmentType === 4
                      ? "Profesyonel Diş Temizleme"
                      : "" || treat.treatmentType === 5
                      ? "Ölçeklendirme/Kök Planlama"
                      : "" || treat.treatmentType === 6
                      ? "Cep Azaltma"
                      : "" || treat.treatmentType === 7
                      ? "Kemik Rejenerasyonu"
                      : "" || treat.treatmentType === 8
                      ? "Dişeti Grefti"
                      : "" || treat.treatmentType === 9
                      ? "Plastik Perio Cerrahisi"
                      : "" || treat.treatmentType === 10
                      ? "Kron Uzatma"
                      : "" || treat.treatmentType === 11
                      ? "Gingivektomi"
                      : "" || treat.treatmentType === 12
                      ? "Lazer Gingivektomi"
                      : "" || treat.treatmentType === 13
                      ? "Kök Kanal Tedavisi"
                      : "" || treat.treatmentType === 14
                      ? "Kanal Tedavisi"
                      : "" || treat.treatmentType === 16
                      ? "Apikektomi"
                      : "" || treat.treatmentType === 17
                      ? "İmplant"
                      : "" || treat.treatmentType === 18
                      ? "Diş Çekimi"
                      : "" || treat.treatmentType === 19
                      ? "Yirmilik Diş Çekimi"
                      : "" || treat.treatmentType === 20
                      ? "Sinüs Kaldırma"
                      : "" || treat.treatmentType === 21
                      ? "Kemik Rejenerasyonu"
                      : "" || treat.treatmentType === 22
                      ? "Tam Ark İmplantları/Protez"
                      : "" || treat.treatmentType === 23
                      ? "İmplant Çıkarma"
                      : "" || treat.treatmentType === 24
                      ? "Cerrahi Rehber"
                      : "" || treat.treatmentType === 25
                      ? "Restorasyon"
                      : "" || treat.treatmentType === 26
                      ? "Post"
                      : "" || treat.treatmentType === 27
                      ? "Atel"
                      : "" || treat.treatmentType === 28
                      ? "Dış Çamaşır Suyu"
                      : "" || treat.treatmentType === 29
                      ? "İç Çamaşır Suyu"
                      : "" || treat.treatmentType === 30
                      ? "Çekirdek Oluşturma"
                      : "" || treat.treatmentType === 31
                      ? "Geçici Restorasyon"
                      : "" || treat.treatmentType === 32
                      ? "Kaplama"
                      : "" || treat.treatmentType === 33
                      ? "Onlay"
                      : "" || treat.treatmentType === 34
                      ? "Taç"
                      : "" || treat.treatmentType === 35
                      ? "Köprü"
                      : "" || treat.treatmentType === 36
                      ? "Yapışkanlı Köprü"
                      : "" || treat.treatmentType === 37
                      ? "Takma Diş"
                      : "" || treat.treatmentType === 38
                      ? "Köprü/Protez Çıkarma"
                      : "" || treat.treatmentType === 39
                      ? "Eklemli Çalışma Modelleri"
                      : "" || treat.treatmentType === 40
                      ? "Geçici Köprü"
                      : "" || treat.treatmentType === 41
                      ? "Geçici Taç"
                      : "" || treat.treatmentType === 42
                      ? "Sabit Braketler"
                      : "" || treat.treatmentType === 43
                      ? "Lingual Braketler"
                      : "" || treat.treatmentType === 44
                      ? "Hizalayıcıları Temizle"
                      : "" || treat.treatmentType === 45
                      ? "Çıkarılabilir Cihaz"
                      : "" || treat.treatmentType === 46
                      ? "Fonksiyonel Cihaz"
                      : "" || treat.treatmentType === 47
                      ? "Kozmetik Ortodonti"
                      : "" || treat.treatmentType === 48
                      ? "Avans"
                      : "" || treat.treatmentType === 49
                      ? "Ankraj için Mini İmplantlar"
                      : "" || treat.treatmentType === 50
                      ? "Tutulan Dişlerin Traksiyonu"
                      : "" || treat.treatmentType === 51
                      ? "Eklemli Çalışma Modelleri"
                      : "" || treat.treatmentType === 52
                      ? "Davranışsal Tavsiye ve Stres Kontrolü"
                      : "" || treat.treatmentType === 53
                      ? "Oklüzal Atel"
                      : "" || treat.treatmentType === 54
                      ? "Oklüzal Denge"
                      : "" || treat.treatmentType === 55
                      ? "Eklemli Çalışma Modelleri"
                      : ""}
                  </td>
                  <td className="treatmentTableTd">
                    <span style={{ color: "#0d68c8" }}>Dişler: </span>{" "}
                    {treat.teeths + ","}
                  </td>
                  <td>
                    <ul className="ulStyle">
                      {(treat.treatmentType === 25 ||
                        treat.treatmentType === 30 ||
                        treat.treatmentType === 31) && (
                        <li>
                          <span style={{ color: "#0d68c8" }}>Yüzey: </span>
                          {treat.toothSurfaces + ","}
                        </li>
                      )}

                      {(treat.treatmentType === 13 ||
                        treat.treatmentType === 14 ||
                        treat.treatmentType === 15) && (
                        <li>
                          <span style={{ color: "#0d68c8" }}>Kanal: </span>3
                        </li>
                      )}

                      {(treat.treatmentType === 37 ||
                        treat.treatmentType === 42) && (
                        <li>
                          <span style={{ color: "#0d68c8" }}>Tip: </span>3
                        </li>
                      )}
                    </ul>
                  </td>
                  <td className="treatmentTableTd">
                    <span style={{ color: "#0d68c8" }}>Fiyat: </span>{" "}
                    {treat.price}{" "}
                    {treat.currency === 1
                      ? "₺"
                      : "" || treat.currency === 2
                      ? "$"
                      : "" || treat.currency === 3
                      ? "€"
                      : ""}
                  </td>
                  <td className="treatmentTableTd">
                  {treat.isApproved === false && (
                          <Form.Check
                            style={{ fontSize: "1rem" }}
                            type="switch"
                            id="custom-switch"
                            label="Kabul Edilmedi"
                            onClick={() => handleApproved(treat.id, true)}
                          />
                        )}
                        {treat.isApproved === true && (
                          <Form.Check
                            style={{ fontSize: "1rem" }}
                            type="switch"
                            id="custom-switch"
                            label="Kabul Edildi"
                            onClick={() => handleApproved(treat.id, false)}
                            checked
                          />
                        )}
                  </td>
                  {/* {userStore.grandPermissions.planUpdate && (
                    <td>
                      <Button
                        variant="outline-danger "
                        onClick={() => handleDelete(treat.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-archive"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                        </svg>
                      </Button>
                    </td>
                  )} */}
                </tr>
              </tbody>
            ))
          )}
        </table>
      </div>
    </div>
  );
});
