import React from "react";
import { observer } from "mobx-react-lite";
import TextField from "@mui/material/TextField";
import { Container, Col, Row, Button, Modal, Form } from "react-bootstrap";
import "./planCollection.css";
import { useStore } from "../../stores/store";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import "./planCollection.css";
import moment from "moment";
import "../finance/finance.css";

export default observer(function PlanCollection() {
  const { financeStore, planStore, userStore } = useStore();
  const { selectedPlan, totalDebt, calculateDebt } = planStore;
  const {
    addCollectionPlan,
    setInstallmentDate,
    setInstallments,
    setSelectedCollectionPlan,
    resetSelectedCollectionPlan,
    collectionPlanSelected,
    updateCollectionPlan,
    setCollectionType,
    setDiscountRate,
    selectedCollectionPlan,
  } = financeStore;
  let date = [];
  const initialPlan = selectedCollectionPlan ?? {
    id: " ",
    patientId: "",
    planId: "",
    totalDebt: 0,
    collectionType: 0,
    discountRate: 0,
    discountedDebt: 0,
    numberOfInstallments: 0,
    installments: [],
  };
  const [collectionPlan, setCollectionPlan] = useState(initialPlan);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setCollectionPlan({ ...collectionPlan, [name]: value });
  }
  function handleCollectionType(value) {
    setCollectionType(value);
  }

  const initialState2 = sessionStorage.getItem("totalDebt") ?? 0;
  const [debt, setDebt] = useState(initialState2);

  useEffect(() => {
    setDebt(initialState2);
    if (!collectionPlanSelected) {
      setSelectedCollectionPlan(
        collectionPlan,
        selectedPlan.patientID,
        selectedPlan.id,
        debt
      );
    }else {
      setSelectedCollectionPlan(
        undefined,
        undefined,
        undefined,
        debt
      );
    }
    setCollectionPlan(initialPlan);
  }, [collectionPlanSelected, resetSelectedCollectionPlan]);

  function handleInstallmentCount(value) {
    setInstallments(value);
  }

  function handleDiscount(value) {
    setDiscountRate(value);
  }

  function handleInputDate(value, index) {
    setInstallmentDate(value, index);
  }

  function handleSubmit() {
    if (collectionPlan.id == " ") addCollectionPlan(collectionPlan);
    else updateCollectionPlan(collectionPlan);
  }

  if (!collectionPlanSelected)
    return (
      <Button className="loadingBtnStyle" disabled>
        Yükleniyor...
      </Button>
    );
  
    if (!planStore.debtCalculated)
    return (
      <Button className="loadingBtnStyle" disabled>
        Yükleniyor...
      </Button>
    );

  return (
    <Container className="containerStyle">
      <Form className="my-4">
        <Row>
          <Col style={{ textAlign: "center" }}>
            <TextField
              focused
              color="grey"
              className="my-2 test1"
              label="Toplam Borç"
              name="totalDebt"
              onChange={handleInputChange}
              value={collectionPlan.totalDebt}
              inputProps={{ readOnly: true }}
            />
            <TextField
              focused
              color="grey"
              className="my-2"
              label="İndirim Sonrası Borç"
              name="discountedDebt"
              inputProps={{ readOnly: true }}
              value={collectionPlan.discountedDebt}
            />
            <TextField
              focused
              color="grey"
              className="my-2"
              label="Kalan Borç"
              name="debtLeft"
              inputProps={{ readOnly: true }}
              value={collectionPlan.discountedDebt}
            />
          </Col>
          <Col>
            <Autocomplete
              className="my-2"
              disablePortal
              onChange={(event, newValue) => handleDiscount(newValue.value)}
              defaultValue={{
                value: collectionPlan.discountRate,
                label: "%" + collectionPlan.discountRate,
              }}
              options={[
                { value: 0, label: "%0" },
                { value: 5, label: "%5" },
                { value: 10, label: "%10" },
                { value: 15, label: "%15" },
                { value: 20, label: "%20" },
                { value: 25, label: "%25" },
                { value: 30, label: "%30" },
              ]}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="İskonto Oranı"
                  defaultValue={collectionPlan.discountRate}
                />
              )}
            />
            <Autocomplete
              disablePortal
              onChange={(event, newValue) =>
                handleCollectionType(newValue.value)
              }
              defaultValue={{
                value: collectionPlan.collectionType,
                label:
                  collectionPlan.collectionType == 1
                    ? "Nakit"
                    : collectionPlan.collectionType == 2
                    ? "Kredi kartı ile nakit"
                    : collectionPlan.collectionType == 3
                    ? "Taksitli"
                    : collectionPlan.collectionType == 4
                    ? "Kredi kartı ile taksitli"
                    : "Ödeme türü seçiniz",
              }}
              name="collectionType"
              options={[
                { value: 1, label: "Nakit" },
                { value: 2, label: "Kredi kartı ile nakit" },
                { value: 3, label: "Taksitli" },
                { value: 4, label: "Kredi kartı ile taksitli" },
              ]}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  className="my-2"
                  {...params}
                  label="Ödeme Türü"
                  defaultValue={collectionPlan.collectionType}
                />
              )}
            />
            <Autocomplete
              className="my-2"
              disablePortal
              onChange={(event, newValue) =>
                handleInstallmentCount(newValue.value)
              }
              defaultValue={{
                value: collectionPlan.numberOfInstallments,
                label:
                  collectionPlan.numberOfInstallments == 1
                    ? "Peşin"
                    : collectionPlan.numberOfInstallments == 2
                    ? "2 Taksit"
                    : collectionPlan.numberOfInstallments == 3
                    ? "3 Taksit"
                    : collectionPlan.numberOfInstallments == 4
                    ? "4 Taksit"
                    : collectionPlan.numberOfInstallments == 5
                    ? "5 Taksit"
                    : collectionPlan.numberOfInstallments == 6
                    ? "6 Taksit"
                    : collectionPlan.numberOfInstallments == 7
                    ? "7 Taksit"
                    : collectionPlan.numberOfInstallments == 8
                    ? "8 Taksit"
                    : collectionPlan.numberOfInstallments == 9
                    ? "9 Taksit"
                    : collectionPlan.numberOfInstallments == 10
                    ? "10 Taksit"
                    : collectionPlan.numberOfInstallments == 11
                    ? "11 Taksit"
                    : collectionPlan.numberOfInstallments == 12
                    ? "12 Taksit"
                    : "Taksit sayısı seçiniz",
              }}
              options={[
                { value: 1, label: "Peşin" },
                { value: 2, label: "2 Taksit" },
                { value: 3, label: "3 Taksit" },
                { value: 4, label: "4 Taksit" },
                { value: 5, label: "5 Taksit" },
                { value: 6, label: "6 Taksit" },
                { value: 7, label: "7 Taksit" },
                { value: 8, label: "8 Taksit" },
                { value: 9, label: "9 Taksit" },
                { value: 10, label: "10 Taksit" },
                { value: 11, label: "11 Taksit" },
                { value: 12, label: "12 Taksit" },
              ]}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Taksit Sayısı"
                  defaultValue={collectionPlan.installmentNumber}
                />
              )}
            />
            {userStore.grandPermissions.collectionPlanUpdate &&
              collectionPlan.id === " " && (
                <Button
                  className="planCollectionBtn"
                  variant="outline-success"
                  onClick={() => handleSubmit()}
                >
                  Planı Oluştur
                </Button>
              )}
            {collectionPlan.id !== " " && (
              <Button
                className="planCollectionBtn"
                variant="outline-success"
                onClick={() => handleSubmit()}
              >
                Planı Güncelle
              </Button>
            )}
          </Col>
          <Col>
            {collectionPlan.installments !== undefined &&
              collectionPlan.installments.map((installment, index) => (
                <>
                  <Row key={installment.id}>
                    <Col>
                      <TextField
                        focused
                        color="grey"
                        className="my-2"
                        label={installment.installmentNumber + ". Taksit"}
                        value={installment.installmentAmount}
                      />
                    </Col>

                    <Col>
                      <TextField
                        focused
                        color="grey"
                        className="my-2"
                        name="installmentDate"
                        type="date"
                        label={
                          installment.installmentNumber + ". Taksit Tarihi"
                        }
                        value={moment(installment.installmentDate)
                          .utc()
                          .format("YYYY-MM-DD")}
                        onChange={(e) => handleInputDate(e.target.value, index)}
                      />
                    </Col>
                  </Row>
                </>
              ))}
          </Col>
        </Row>
      </Form>
    </Container>
  );
});
