import "./conditions.css";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
// import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { useState, useEffect } from "react";

function SimpleAccordion() {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    magicFunction(
      panel,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    setExpanded(isExpanded ? panel : false);
  };
  const { menuStore, conditionStore } = useStore();
  const { ActiveMenu } = menuStore;
  const {
    resetForm,
    magicFunction,
    selectCondition,
    selectCarries,
    selectFracture,
    selectSeverelyDamaged,
    selectWear,
    selectPlaqueHygiene,
    selectGingivitis,
    selectGingivalRecession,
    selectPeriodontitis,
    selectMobility,
    selectGummySmile,
    selectGingivalOvergrowth,
    selectLargeSinus,
    selectNecrosis,
    selectRootCanalTreatment,
    selectApicalLesion,
    selectBrokenInstrumentInCanal,
    selectRootResorption,
    selectMissingTeeth,
    selectImplant,
    selectImpacted,
    selectImpactedInfected,
    selectLargeSinus2,
    selectRestoration,
    selectPost,
    selectSplint,
    selectDisColored,
    selectVeneer,
    selectCrown,
    selectOnlay,
    selectBridge,
    selectDenture,
  } = conditionStore;
  const { checked, setChecked } = useState;

  useEffect(() => {
    setExpanded();
  }, [resetForm]);

  function handleCarriesCheckbox(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      value,
      checked,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }
  function handleFractureRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      checked,
      undefined,
      undefined
    );
  }
  function handleWearRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined,
      undefined
    );
  }

  function handlePlaqueHygieneRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined,
      undefined
    );
  }

  function handleGingivitisRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined,
      undefined
    );
  }

  function handleGingivalRecessionRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined,
      undefined
    );
  }
  function handlePeriodontitisRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined,
      undefined
    );
  }

  function handleMobilityRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined,
      undefined
    );
  }

  function handleRootCanalTreatmentRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined
    );
  }

  function handleApicalLesionRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined,
      undefined
    );
  }

  function handleRootResorptionRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      checked,
      undefined,
      undefined
    );
  }

  function handleMissingTeeth(conditionName) {
    selectMissingTeeth(conditionName);
  }

  function handleImplantRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined
    );
  }

  function handleImpactedTeeth(conditionName) {
    selectImpacted(conditionName);
  }

  function handleImpactedInfectedTeeth(conditionName) {
    selectImpactedInfected(conditionName);
  }

  function handleLargeSinus2(conditionName) {
    selectLargeSinus2(conditionName);
  }

  function handleRestorationCheckbox(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      value,
      checked,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }
  function handleRestorationCheckbox2(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined
    );
  }

  function handlePostRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined
    );
  }

  function handleSplintRadio(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined
    );
  }

  function handleVeneerCheckbox(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined
    );
  }

  function handleCrownCheckbox(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined
    );
  }

  function handleOnlayCheckbox(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined
    );
  }

  function handleBridgeCheckbox(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined
    );
  }

  function handleDentureCheckbox(event) {
    const { name, value, checked } = event.target;
    magicFunction(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined
    );
  }

  return (
    <div className="conditionsWrapper" key={resetForm}>
    {/* Carries Fractures Wear Alanı */}
    {ActiveMenu.carriesMenu && (
      <div className="carriesMenu">
        <Accordion
          className="accordion"
          expanded={expanded === "1"}
          onChange={handleChange("1")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="typography">Çürükler</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCarriesCheckbox}
                    name="carries"
                    value={1}
                    checked={checked}
                  />
                }
                label="Yanak"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCarriesCheckbox}
                    name="carries"
                    value={2}
                    checked={checked}
                  />
                }
                label="Distal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCarriesCheckbox}
                    name="carries"
                    value={3}
                    checked={checked}
                  />
                }
                label="Lingual"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCarriesCheckbox}
                    name="carries"
                    value={4}
                    checked={checked}
                  />
                }
                label="Mesial"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCarriesCheckbox}
                    name="carries"
                    value={5}
                    checked={checked}
                  />
                }
                label="Oklüzal"
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "2"}
          onChange={handleChange("2")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">Kırık</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup>
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleFractureRadio}
                      value={1}
                      checked={checked}
                    />
                  }
                  label="Kök"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleFractureRadio}
                      value={2}
                      checked={checked}
                    />
                  }
                  label="Taç"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Button
          onClick={() =>
            magicFunction(
              "3",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Ağır Hasarlı
        </Button>
        <Accordion
          className="accordion"
          expanded={expanded === "4"}
          onChange={handleChange("4")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">Aşınmalar</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleWearRadio}
                      value={1}
                      checked={checked}
                    />
                  }
                  label="Hafif"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleWearRadio}
                      value={2}
                      checked={checked}
                    />
                  }
                  label="Orta"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleWearRadio}
                      value={3}
                      checked={checked}
                    />
                  }
                  label="Ağır"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </div>
    )}
    {/* Perio Bone Butonu */}
    {ActiveMenu.perioBoneMenu && (
      <div className="perioBoneMenu">
        <Accordion
          className="accordion"
          expanded={expanded === "5"}
          onChange={handleChange("5")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">Plak & Hijyen</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handlePlaqueHygieneRadio}
                      value={1}
                      checked={checked}
                    />
                  }
                  label="Hafif"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handlePlaqueHygieneRadio}
                      value={2}
                      checked={checked}
                    />
                  }
                  label="Orta"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handlePlaqueHygieneRadio}
                      value={3}
                      checked={checked}
                    />
                  }
                  label="Ağır"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "6"}
          onChange={handleChange("6")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">Dişeti İltihabı</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleGingivitisRadio}
                      value={1}
                      checked={checked}
                    />
                  }
                  label="Hafif"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleGingivitisRadio}
                      value={2}
                      checked={checked}
                    />
                  }
                  label="Orta"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleGingivitisRadio}
                      value={3}
                      checked={checked}
                    />
                  }
                  label="Ağır"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "7"}
          onChange={handleChange("7")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">Dişeti Çekilmesi</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleGingivalRecessionRadio}
                      value={1}
                      checked={checked}
                    />
                  }
                  label="Hafif"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleGingivalRecessionRadio}
                      value={2}
                      checked={checked}
                    />
                  }
                  label="Orta"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleGingivalRecessionRadio}
                      value={3}
                      checked={checked}
                    />
                  }
                  label="Ağır"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "8"}
          onChange={handleChange("8")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">Periodontitis</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handlePeriodontitisRadio}
                      value={1}
                      checked={checked}
                    />
                  }
                  label="Hafif"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handlePeriodontitisRadio}
                      value={2}
                      checked={checked}
                    />
                  }
                  label="Orta"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handlePeriodontitisRadio}
                      value={3}
                      checked={checked}
                    />
                  }
                  label="Ağır"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "9"}
          onChange={handleChange("9")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">Hareketlilik</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleMobilityRadio}
                      value={1}
                      checked={checked}
                    />
                  }
                  label="Hafif"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleMobilityRadio}
                      value={2}
                      checked={checked}
                    />
                  }
                  label="Orta"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleMobilityRadio}
                      value={3}
                      checked={checked}
                    />
                  }
                  label="Ağır"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Button
          onClick={() =>
            magicFunction(
              "10",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Sakızlı Gülümseme
        </Button>
        <Button
          onClick={() =>
            magicFunction(
              "11",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Dişeti Büyümesi
        </Button>
        <Button
          onClick={() =>
            magicFunction(
              "12",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Büyük Maksiller Sinüs
        </Button>
      </div>
    )}
    {/* Endodontics Başlangıç */}
    {ActiveMenu.endodonticsMenu && (
      <div className="endodonticsMenu">
        <Button
          onClick={() =>
            magicFunction(
              "13",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Nekroz
        </Button>
        <Accordion
          className="accordion"
          expanded={expanded === "14"}
          onChange={handleChange("14")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">
            Kök Kanal Tedavisi
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleRootCanalTreatmentRadio}
                      value={1}
                      checked={checked}
                    />
                  }
                  label="Tatmin Edici"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleRootCanalTreatmentRadio}
                      value={2}
                      checked={checked}
                    />
                  }
                  label="Yetersiz"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "15"}
          onChange={handleChange("15")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">Apikal Lezyon</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleApicalLesionRadio}
                      value={1}
                      checked={checked}
                    />
                  }
                  label="Hafif"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleApicalLesionRadio}
                      value={2}
                      checked={checked}
                    />
                  }
                  label="Orta"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleApicalLesionRadio}
                      value={3}
                      checked={checked}
                    />
                  }
                  label="Ağır"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Button
          onClick={() =>
            magicFunction(
              "16",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Kanaldaki Kırık Enstrüman
        </Button>
        <Accordion
          className="accordion"
          expanded={expanded === "17"}
          onChange={handleChange("17")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">Kök Rezorpsiyonu</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleRootResorptionRadio}
                      value={3}
                      checked={checked}
                    />
                  }
                  label="Harici"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleRootResorptionRadio}
                      value={4}
                      checked={checked}
                    />
                  }
                  label="Dahili"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </div>
    )}
    {/* Missing Teeth Implant Başlangıç */}
    {ActiveMenu.missingTeethImplantMenu && (
      <div className="missingTeethImplantMenu">
        <Button
          onClick={() =>
            magicFunction(
              "18",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Eksik Diş
        </Button>
        <Accordion
          className="accordion"
          expanded={expanded === "19"}
          onChange={handleChange("19")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">İmplant</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleImplantRadio}
                      value={3}
                      checked={checked}
                    />
                  }
                  label="Sorunsuz"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleImplantRadio}
                      value={4}
                      checked={checked}
                    />
                  }
                  label="Yanlış Konumlandırılmış"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleImplantRadio}
                      value={5}
                      checked={checked}
                    />
                  }
                  label="Kemik Kaybı Var"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleImplantRadio}
                      value={6}
                      checked={checked}
                    />
                  }
                  label="Dişeti Çekilmesi Var"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Button
          onClick={() =>
            magicFunction(
              "20",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Gömülü Diş
        </Button>
        <Button
          onClick={() =>
            magicFunction(
              "21",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Gömülü ve Enfekte Diş
        </Button>
        <Button
          onClick={() =>
            magicFunction(
              "22",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Büyük Maksiller Sinüs
        </Button>
      </div>
    )}
    {/* Restorative Color Başlangıç */}
    {ActiveMenu.restorativeColorMenu && (
      <div className="restorativeColorMenu">
        {/* Restorative Color Başlangıç */}
        <Accordion
          className="accordion"
          expanded={expanded === "23"}
          onChange={handleChange("23")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="typography">Restorasyon</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleRestorationCheckbox}
                    value={1}
                    checked={checked}
                  />
                }
                label="Yanak"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleRestorationCheckbox}
                    value={2}
                    checked={checked}
                  />
                }
                label="Distal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleRestorationCheckbox}
                    value={3}
                    checked={checked}
                  />
                }
                label="Lingual"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleRestorationCheckbox}
                    value={4}
                    checked={checked}
                  />
                }
                label="Mesial"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleRestorationCheckbox}
                    value={5}
                    checked={checked}
                  />
                }
                label="Oklüzal"
              />
            </FormGroup>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleRestorationCheckbox2}
                    value={3}
                    checked={checked}
                  />
                }
                label="Sorunsuz"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleRestorationCheckbox2}
                    value={7}
                    checked={checked}
                  />
                }
                label="Sızıntılı"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleRestorationCheckbox2}
                    value={8}
                    checked={checked}
                  />
                }
                label="Yıpranmış"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleRestorationCheckbox2}
                    value={9}
                    checked={checked}
                  />
                }
                label="Kırık"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleRestorationCheckbox2}
                    value={10}
                    checked={checked}
                  />
                }
                label="Renksiz"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleRestorationCheckbox2}
                    value={11}
                    checked={checked}
                  />
                }
                label="Estetik Değil"
              />
            </RadioGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "24"}
          onChange={handleChange("24")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">Post</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handlePostRadio}
                      value={1}
                      checked={checked}
                    />
                  }
                  label="Sorunsuz"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handlePostRadio}
                      value={2}
                      checked={checked}
                    />
                  }
                  label="Yetersiz"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "25"}
          onChange={handleChange("25")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="typography">Atel</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleSplintRadio}
                      value={1}
                      checked={checked}
                    />
                  }
                  label="Sorunsuz"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={handleSplintRadio}
                      value={2}
                      checked={checked}
                    />
                  }
                  label="Yeretsiz"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Button
          onClick={() =>
            magicFunction(
              "26",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          }
          variant="contained"
          className="btnContained"
        >
          Renksiz Diş
        </Button>
      </div>
    )}
    {/* Prosthodontic Başlangıç */}
    {ActiveMenu.prosthodonticMenu && (
      <div className="prosthodonticMenu">
        <Accordion
          className="accordion"
          expanded={expanded === "27"}
          onChange={handleChange("27")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="typography">Kaplama</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={3}
                    checked={checked}
                  />
                }
                label="Sorunsuz"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={7}
                    checked={checked}
                  />
                }
                label="Sızıntılı"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={8}
                    checked={checked}
                  />
                }
                label="Yıpranmış"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={9}
                    checked={checked}
                  />
                }
                label="Kırık"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={10}
                    checked={checked}
                  />
                }
                label="Renksiz"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={11}
                    checked={checked}
                  />
                }
                label="Estetik Değil"
              />
            </RadioGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "28"}
          onChange={handleChange("28")}
        >
          <AccordionSummary
            classame="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="typography">Taç</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={3}
                    checked={checked}
                  />
                }
                label="Sorunsuz"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={7}
                    checked={checked}
                  />
                }
                label="Sızıntılı"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={8}
                    checked={checked}
                  />
                }
                label="Yıpranmış"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={9}
                    checked={checked}
                  />
                }
                label="Kırık"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={10}
                    checked={checked}
                  />
                }
                label="Renksiz"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={11}
                    checked={checked}
                  />
                }
                label="Estetik Değil"
              />
            </RadioGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "29"}
          onChange={handleChange("29")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="typography">Onlay</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={3}
                    checked={checked}
                  />
                }
                label="Sorunsuz"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={7}
                    checked={checked}
                  />
                }
                label="Sızıntılı"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={8}
                    checked={checked}
                  />
                }
                label="Yıpranmış"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={9}
                    checked={checked}
                  />
                }
                label="Kırık"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={10}
                    checked={checked}
                  />
                }
                label="Renksiz"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={11}
                    checked={checked}
                  />
                }
                label="Estetik Değil"
              />
            </RadioGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "30"}
          onChange={handleChange("30")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="typography">Köprü</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={3}
                    checked={checked}
                  />
                }
                label="Sorunsuz"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={7}
                    checked={checked}
                  />
                }
                label="Sızıntılı"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={8}
                    checked={checked}
                  />
                }
                label="Yıpranmış"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={9}
                    checked={checked}
                  />
                }
                label="Kırık"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={10}
                    checked={checked}
                  />
                }
                label="Renksiz"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={11}
                    checked={checked}
                  />
                }
                label="Estetik Değil"
              />
            </RadioGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "31"}
          onChange={handleChange("31")}
        >
          <AccordionSummary
            className="accordionSummary btn"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="typography">Takma Diş</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={3}
                    checked={checked}
                  />
                }
                label="Sorunsuz"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={7}
                    checked={checked}
                  />
                }
                label="Sızıntılı"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={8}
                    checked={checked}
                  />
                }
                label="Yıpranmış"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={9}
                    checked={checked}
                  />
                }
                label="Kırık"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={10}
                    checked={checked}
                  />
                }
                label="Renksiz"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={handleVeneerCheckbox}
                    value={11}
                    checked={checked}
                  />
                }
                label="Estetik Değil"
              />
            </RadioGroup>
          </AccordionDetails>
        </Accordion>
      </div>
    )}
  </div>
  );
}
export default observer(SimpleAccordion);
