import React from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";


export default observer(function TreatmentList() {
  const { planStore, treatmentStore, userStore } = useStore();

  const initialState = {
    treatmentId: " ",
    description: "",
    date: "",
    user: ""
  };
  const [description, setDescription] = useState(initialState);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setDescription({ ...description, [name]: value, 
      treatmentId: treatmentStore.selectedTreatment.id,
      date: new Date().toISOString(),
      user: sessionStorage.getItem("userName") 
    });
  }
  function handleSubmit() {
    console.log(description)
    treatmentStore.createTreatmentDescription(description);
  }

  return (
    <div className="treatmentDescriptionListDiv">
      <ul className="treatmentDescriptionList">
        {treatmentStore.selectedTreatment.descriptions.map((description) =>
        <div className="treatmentDescriptionListItemBox">
        <div className="treatmentDescriptionListDateBox">
          <span className="treatmentDescriptionListDate">
            Eklenme Tarihi:{" "}
          </span>
          <label>{description.date}</label>
          <span style={{borderLeft:"1px solid #0d68c8",marginLeft:"1rem",paddingLeft:"1rem"}} className="treatmentDescriptionListDate">
            Ekleyen:{" "}
          </span>
          <label>{description.user}</label>
        </div>
        <li>
          {description.description}
        </li>
      </div>
        )}
        

        <input
          placeholder="Yeni Bir Açıklama Ekleyin"
          type="text"
          name="description"
          className="treatmentDescriptionListInput"
          onChange={handleInputChange}
        />
        <Button
          className="treatmentDescriptionListButton"
          variant="outline-success"
          onClick={()=> handleSubmit()}
        >
          Ekle
        </Button>
      </ul>
    </div>
  );
});
