import './plan.css'
import Steps from '../../components/steps/Steps'
import Resume from '../../components/resume/Resume'
import PlanMenu from '../../components/planMenu/PlanMenu'
import PlanConditions from '../../components/planConditions/PlanConditions'
import OndogramSectionPlan from '../../components/ondogramSection/OndogramSectionPlan'
import PlanRightMenu from '../planRightMenu/PlanRightMenu'

export default function Diagnose() {
  return (
    <div className='diagnoseWorkspace'>
      <div className="diagnosePlanEditor">
        <div className="diagnosePlanEditorHeader">
          <Steps/><Resume/>
        </div>
        <div style={{width:"18rem"}} className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: "50%",
            backgroundColor:"orange",
            "ariaValuenow": "34",
            "ariaValuemin": "0",
            "ariaValuemax": "100",
          }}
        >
          50%
        </div>
      </div>
        <div className="diagnoseContainer">
          <div className="diagnoseMenu">
            <PlanMenu/>
          </div>
          <div className="diagnoseColumns">
              <div className="ondogram">
                <PlanConditions/>
                <OndogramSectionPlan/>
                <PlanRightMenu/>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}
