import { runInAction, makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { store } from "./store";
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";

export default class AppointmentStore {
  appointments = undefined;
  appointmentsLoaded = false;
  loading = false;
  editedAppointment = undefined;
  addAppointment=false

  constructor() {
    makeAutoObservable(this);
  }

  setEditedAppointment = (model) => {
    console.log(model);
    runInAction(() => {
      this.editedAppointment = model;
    });
  };

  selectAppointment = (info) => {
    runInAction(() => {
      this.editedAppointment = this.appointments.find((a) => a.title === info.event.title &&
      a.appointmentStart === info.event.startStr && a.appointmentEnd === info.event.endStr )
    });
  }

  loadAppointments = async () => {
    runInAction(() => {
      this.loading = true;
    });
    try {
      const appointments = await agent.Appointment.list();
      runInAction(() => {
        this.appointments = appointments.data;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  createAppointment = async (appointment) => {
    appointment.id = uuid();
    try {
      runInAction(() => {
        this.loading = true;
        this.addAppointment=true
      });
      let addedAppointment = await agent.Appointment.create(appointment);
      runInAction(() => {
        this.appointments.push(addedAppointment);
        this.appointmentsLoaded = !this.appointmentsLoaded;
        this.loading = false;
        this.addAppointment=false
        toast.success("Randevu ekleme işlemi başarılı.");
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.addAppointment=false
      });
      console.log(error);
    }
  };

  updateAppointment = async (updatedAppointment) => {
    runInAction(() => {
      this.loading = true;
    });
    try {
        let appointment = this.appointments.find((x) => x.id === updatedAppointment.id)
        appointment.doctorId= updatedAppointment.doctorId;
        appointment.appointmentStart= updatedAppointment.appointmentStart;
        appointment.appointmentEnd= updatedAppointment.appointmentEnd;
        if (updatedAppointment.note !== undefined && updatedAppointment.confirmation !== undefined) {
          appointment.note = updatedAppointment.note;
          appointment.confirmation = updatedAppointment.confirmation;
        }
        await agent.Appointment.update(appointment);
      toast.success("Randevu güncelleme işlemi başarılı.");
      runInAction(() => {
        this.appointments = [
          ...this.appointments.filter((a) => a.id !== appointment.id),
          appointment,
        ];
        this.appointmentsLoaded = !this.appointmentsLoaded;
        runInAction(() => {
          this.loading = false;
          this.addAppointment=false
        });
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.addAppointment=false
      });
      console.log(error);
    }
  };

  deleteAppointment = async (id) => {
    runInAction(() => {
      this.loading = true;
    });
    try {
      await agent.Appointment.delete(id);
      runInAction(() => {
        this.appointments = [...this.appointments.filter((a) => a.id !== id)];
        this.appointmentsLoaded = !this.appointmentsLoaded;
        toast.error("Randevu kaydı silindi.");
        runInAction(() => {
          this.loading = false;
          this.addAppointment=false
        });
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.addAppointment=false
      });
      console.log(error);
    }
  };
}
