import "./userList.css";
import { useEffect, useState } from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import Register from "../../components/login/Register";
import "react-bootstrap";
import { Spinner, Button, ButtonGroup, Modal } from "react-bootstrap";
function UserList() {
  const { userStore } = useStore();

  useEffect(() => {
    userStore.getUsers();
  }, []);

  const [showUpd, setShowUpd] = useState(false);

  const handleCloseUpd = () => {
    setShowUpd(false);
  };
  const handleShowUpd = (id) => {
    userStore.selectUser(id);
    setShowUpd(true);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (id) => {
    setShow(true);
  };

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = (id) => {
    userStore.deleted = true;
    userStore.selectUser(id);
    setShowDelete(true);
  };

  function handleDelete(id) {
    userStore.deleteUser(id);
  }

  if (userStore.users === undefined)
    return (
      <Button className="loadingBtnStyle" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Kullanıcı Listesi Yükleniyor...
      </Button>
    );

  return (
    <div className="patientsTableList">
        <div className="btnAddUser">
        <Button
          style={{ marginBottom: "1rem" }}
          variant="outline-dark"
          onClick={handleShow}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="bi bi-person-plus"
            viewBox="0 0 16 16"
            style={{
              marginRight: "3px",
              marginBottom: "3px",
            }}
          >
            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
            <path
              fillRule="evenodd"
              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
            />
          </svg>
          Kullanıcı Ekle
        </Button>
        </div>
      <table className="patientsListTable table table-hover table-lg table-responsive-md">
        <thead
          className="patientsTableBody"
          style={{ textAlign: "center", fontSize: 20 }}
        >
          <tr className="patientsTableThHead">
            <th className="patientsTableTh">İsim Soyisim</th>
            <th className="patientsTableTh">Kullanıcı Adı</th>
            <th className="patientsTableTh">Mail Adresi</th>
            <th className="patientsTableTh">Kullanıcı Rolü</th>
            <th className="doctorTableEdit patientsTableTh">Seçenekler</th>
          </tr>
        </thead>
        <tbody className="patientsTableTbody">
          {userStore.users.map((user) => (
            <tr className="patientSearchBody" key={user.id}>
              <td className="patientsTableTd">{user.fullName}</td>
              <td className="patientsTableTd">{user.userName}</td>
              <td className="patientsTableTd">{user.email}</td>
              {user.roleCode === 1 && (
                <td className="patientsTableTd"><div>Admin</div></td>
              )}
              {user.roleCode === 2 && (
                <td className="patientsTableTd"><div>Doktor</div></td>
              )}
              {user.roleCode === 3 && (
                <td className="patientsTableTd"><div>Finans</div></td>
              )}
              {user.roleCode === 4 && (
                <td className="patientsTableTd"><div>Asistan</div></td>
              )}
              {user.roleCode === 5 && (
                <td className="patientsTableTd"><div>Banko</div></td>
              )}
              {user.roleCode === 6 && (
                <td className="patientsTableTd"><div>Hasta Danışmanı</div></td>
              )}
              <td className="doctorTableEdit doctorTableTd">
                <ButtonGroup>
                  <Button variant="outline-dark" onClick={()=> handleShowUpd(user.id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fillRule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                  </Button>

                  <Button variant="outline-dark" onClick={()=>handleShowDelete(user.id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-archive"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </Button>
                </ButtonGroup>

              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Silmek istediğinize emin misiniz?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger">Evet</Button>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Hayır
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showUpd} onHide={handleCloseUpd}>
        <Modal.Header closeButton>
          <Modal.Title>Kullanıcı Bilgilerini Güncelle</Modal.Title>
        </Modal.Header>
        <Register />
        <Modal.Footer></Modal.Footer>
      </Modal>
       {/* Hasta Silme Modal */}
       {userStore.deleted && (
        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Silmek istediğinize emin misiniz?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="dark"
              onClick={() => handleDelete(userStore.selectedUser.id)}
            >
              Evet
            </Button>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Hayır
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Kullanıcı Ekle</Modal.Title>
          </Modal.Header>
          <Register/>
          <Modal.Footer>
            <Button
              variant="dark"
              onClick={handleClose}
            >
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
}

export default observer(UserList);
