import axios from "axios";
import CommonStore from "../stores/commonStore";
import { toast } from "react-toastify";
import { history } from "../index";
import { store } from "../stores/store.js";

const sleep = (delay) => {
  //bekleme oluşturan fonksiyon.
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};
axios.defaults.baseURL = "http://demo.zrnteknoloji.com:70/api";

axios.interceptors.response.use(
  async (response) => {
    //isteğin arasına girip bekleme fonksiyonunu çalıştırıyor.
    await sleep();
    return response;
  },
  (error) => {
    // hatalı responsları yakalıyor.
    const { data, status, config, headers } = error.response;
    switch (status) {
      case 400:
        if (typeof data === "string") {
          toast.error(data);
        }
        if (data.startsWith("Yetkiniz olmayan bir ")) {
          store.commonStore.setToken(null);
          sessionStorage.clear();
        }

        if (config.method === "get" && data.errors.hasOwnProperty("id")) {
          history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        }
        break;
      case 401:
        if (
          status === 401 &&
          headers["www-authenticate"].startsWith('Bearer error="invalid_token"')
        ) {
          store.userStore.logout();
          toast.error(
            "Oturumun süresi doldu. Lütfen tekrar kullanıcı girişi yapınız."
          );
        } 
        break;
      case 404:
        history.push("/not-found");
        break;
      case 500:
        store.commonStore.setServerError(data);
        history.push("/server-error");
        break;
    }
    return Promise.reject(error);
  }
);

const responseBody = (response) => response.data;

const requests = {
  get: (url) =>
    axios
      .get(url, {
        headers: {
          Authorization: `bearer ${sessionStorage.getItem("userToken")}`,
        },
      })
      .then(responseBody),
  post: (url, body) =>
    axios
      .post(url, body, {
        headers: {
          Authorization: `bearer ${sessionStorage.getItem("userToken")}`,
        },
      })
      .then(responseBody),
  put: (url, body) =>
    axios
      .put(url, body, {
        headers: {
          Authorization: `bearer ${sessionStorage.getItem("userToken")}`,
        },
      })
      .then(responseBody),
  delete: (url) =>
    axios
      .delete(url, {
        headers: {
          Authorization: `bearer ${sessionStorage.getItem("userToken")}`,
        },
      })
      .then(responseBody),
};

const Patients = {
  list: () => requests.get("patient/get"),
  details: (id) => requests.get(`/patient/getbyid/${id}`),
  create: (patient) => requests.post("patient/insert", patient),
  update: (patient) => requests.post("patient/update", patient),
  delete: (id) => requests.delete(`/patient/delete/${id}`),
  advisorList: () => requests.get("patient/getadvisors"),
  createAnamnesis: (anamnesis) => requests.post("patient/insertAnamnesis", anamnesis),
  updateAnamnesis: (anamnesis) => requests.post("patient/updateAnamnesis", anamnesis),
};

const Plans = {
  list: () => requests.get("plan/get"),
  details: (id) => requests.get(`/plan/getbyid/${id}`),
  detailsByPatient: (id) => requests.get(`/plan/getbypatientid/${id}`),
  create: (plan) => requests.post("plan/insert", plan),
  delete: (id) => requests.delete(`/plan/delete/${id}`),
  update: (plan) => requests.post("/Plan/Update", plan),
  filter: (filter) => requests.post("/Plan/GetDoctorPlansByDate", filter),
};

const Condition = {
  list: () => requests.get("condition/get"),
  details: (id) => requests.get(`/condition/getbyid/${id}`),
  create: (condition) => requests.post("condition/insert", condition),
  delete: (id) => requests.delete(`/condition/delete/${id}`),
};

const Account = {
  current: () => requests.get("account/get"),
  delete: (id) => requests.delete(`Account/Delete/${id}`),
  getUsers: () => requests.get("account/GetUsers"),
  login: (user) => requests.post("account/login", user),
  register: (user) => requests.post("/account/register", user),
  update: (user) => requests.post("/Account/Update", user),
  refreshToken: () => requests.get("account/RefreshToken"),
  getRoles: () => requests.get("/account/GetRoles"),
  getRole: (roleCode) => requests.get(`/account/GetRole/${roleCode}`),
  getPermissions: () => requests.get("/Account/GetPermissions"),
  updatePermission: (permission) =>
    requests.post("/account/UpdatePermissions", permission),
};

const Treatment = {
  list: () => requests.get("treatment/get"),
  details: (id) => requests.get(`/treatment/getbyid/${id}`),
  create: (treatment) => requests.post("/Treatment/Insert", treatment),
  delete: (id) => requests.delete(`/Treatment/delete/${id}`),
  update: (treatment) => requests.post("/Treatment/Update", treatment),
  createDescription: (description) => requests.post("/Treatment/InsertTreatmentDescription", description),

};
const Doctors = {
  list: () => requests.get("/Doctor/Get"),
  create: (dr) => requests.post("/Doctor/Insert", dr),
  update: (dr) => requests.post("/Doctor/Update", dr),
  delete: (id) => requests.delete(`/Doctor/Delete/${id}`),
};
const Prices = {
  list: () => requests.get("/Treatment/GetTreatmentTypes"),
  listPages: (page, size) =>
    requests.get(
      `/Treatment/GetTreatmentTypes?PageNumber=${page}&PageSize=${size}`
    ),
  update: (price) => requests.post("/Treatment/UpdateTreatmentType", price),
  delete: (id) => requests.delete(`/Treatment/DeleteTreatmentType/${id}`),
};
const Finance = {
  detailsByPatientId: (id) =>
    requests.get(`/Finance/GetCollectionsByPatientId/${id}`),
  detailsByInstallmentId: (id) =>
    requests.get(`/Finance/GetCollectionsByInstallmentId/${id}`),
  create: (collection) =>
    requests.post("/Finance/InsertCollection", collection),
  createCollectionPlan: (collectionPlan) =>
    requests.post("/Finance/InsertCollectionPlan", collectionPlan),
  updateCollectionPlan: (collectionPlan) =>
    requests.post("/Finance/UpdateCollectionPlan", collectionPlan),
  collectionPlanByPlanId: (id) =>
    requests.get(`/Finance/GetCollectionPlansByPlanId/${id}`),
  collections: () => requests.get(`/Finance/GetCollectionPlans`),
  updateInstallment: (id) => requests.post(`/Finance/UpdateInstallment/${id}`),
  createPayment: (payment) => requests.post("/Finance/InsertPayment", payment),
  getPaymentsByDoctorId: (id) =>
    requests.get(`/Finance/GetPaymentsByDoctorId/${id}`),
};

const Appointment = {
  list: () => requests.get("/Appointment/Get"),
  listByDate: (dates) => requests.post(`/Appointment/GetByDate`, dates),
  create: (appointment) => requests.post("/Appointment/Insert", appointment),
  update: (appointment) => requests.post("/Appointment/Update", appointment),
  delete: (id) => requests.delete(`/Appointment/Delete/${id}`),
};

const Document = {
  list: () => requests.get("/Document/Get"),
  create: (document) => requests.post("/Document/Insert", document),
  update: (document) => requests.post("/Document/Update", document),
  delete: (id) => requests.delete(`/Document/Delete/${id}`),
};

const agent = {
  Patients,
  Plans,
  Condition,
  Account,
  Treatment,
  Doctors,
  Prices,
  Finance,
  Appointment,
  Document,
};

export default agent;
