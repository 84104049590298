import "../patientPlansList/patientPlansList.css";
import { useEffect, useState } from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Modal, Card } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import moment from "moment";
import "../doctorPlanList/doctorPlansList.css";

function ReportNotApprovedPlanList() {
  const { planStore } = useStore();
  const { selectPlan, dateFilter } = planStore;
  useEffect(() => {
    planStore.getPlans();
  }, []);
  //   if (dateFilter === false)
  //     return (
  //       <Card className="cardStyle" style={{ textAlign: "center" }}>
  //         <Card.Body>
  //           <Button className="loadingBtnStyle" disabled>
  //             Lütfen tarih aralığı seçiniz...
  //           </Button>
  //         </Card.Body>
  //       </Card>
  //     );
  return (
    <div className="patientsPlanGroup">
      <div className="row text-center">
        <span className="reportTitle">Onaylanmayan Plan sayısı</span>
      </div>
      <Table bordered hover size="sm">
        <thead
          className="pricesTableBody"
          style={{ textAlign: "center", fontSize: 20 }}
        >
          <tr className="pricesTableThHead">
            <th className="pricesTableTh">Plan İsmi</th>
            <th className="pricesTableTh">Oluşturulma Tarihi</th>
            <th className="pricesTableTh">Plan Durumu</th>
          </tr>
        </thead>
        <tbody className="pricesTableTbody">
          {planStore.plans?.map(
            (plan) =>
              plan.isApproved === false && (
                <tr key={plan.id}>
                  <td className="pricesTableTd">{plan.name}</td>
                  <td className="pricesTableTd">
                    <p>{moment(plan.createdTime).utc().format("DD-MM-YYYY")}</p>
                  </td>
                  <td className="pricesTableTd">
                    {plan.isApproved === true && (
                      <p
                        className="approvedBtn"
                        style={{ color: "white", backgroundColor: "green" }}
                      >
                        {" "}
                        <span className="mx-1">ONAYLANDI</span>{" "}
                      </p>
                    )}
                    {plan.isApproved === false && (
                      <p
                        className="approvedBtn"
                        style={{ color: "white", backgroundColor: "red" }}
                      >
                        <span className="mx-1">ONAYLANMADI</span>
                      </p>
                    )}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default observer(ReportNotApprovedPlanList);
