import {makeAutoObservable, reaction} from 'mobx'; 

export default class CommonStore{
    token = null || sessionStorage.getItem("userToken");
    user = undefined;
    appLoaded = false;
    constructor(){
        makeAutoObservable(this);

        reaction(
            () => this.token,
            token => {
                if (token) {
                    sessionStorage.setItem("userToken", token);
                } else {
                    sessionStorage.removeItem("userToken");
                }
            }
        )
    }

    logOut = () => {
        this.token = null;
    }
    setToken = (token) => {
        this.token = token;
    }

    setAppLoaded = () => {
        this.appLoaded = true;
    }
}